import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { PartnerService } from 'src/app/services/partner.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild("selectedReportTime") selectedReportTime!: ElementRef
  @ViewChild('multiSelect') multiSelect!: MatSelect;
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;
  @ViewChild('multiSelect2') multiSelect2!: MatSelect;
  @ViewChild('multiSelect3') multiSelect3!: MatSelect;

  allPlanSelected = false
  allPartnerSelected = false
  allAccountManagerSelected = false
  allSelected = false;
  isSubscriptionAvailable: boolean = false
  isSorting: boolean = false;
  misMatchedSerialNumber: boolean = false
  stage: any = [];
  planName: any = [];
  salesPersonId: any = []
  account: any = []
  salesmans !: any[];
  totalProductList!: any[];
  sortValue: any = { id: -1 };
  bsModalRef!: BsModalRef;
  PlanList: any;
  subsList: any;
  partnerList: any;
  accountManagerList: any;
  subType: string = ''
  subTypeTitle: string = 'All Subscriptions'
  activation: string = 'subscripton';
  timeInterval!: string
  subscriptionData: any
  subReportData: any
  excelSubIds: any
  filteredIds: any;
  filterPartnerList: any
  fileName = 'SubscriptionReport.xlsx';
  selectedSubs: { [key: string]: boolean } = {};
  stageOptions = [
    { value: 'Live', label: 'Live' },
    { value: 'Expired', label: 'Expired' },
    { value: 'Trial', label: 'Trial' },
    { value: 'Trial Expired', label: 'Trial Expired' },
    { value: 'Deleted', label: 'Deleted' }
  ];

  subtypeTitles: { [key: string]: string } = {
    'paid': 'Paid Subscriptions',
    'trial': 'Trial Subscriptions',
    'renewal': 'Upcoming Renewal Subscriptions',
    'expired': 'Expired Subscriptions'
  };

  searchForm = new FormGroup({
    startDateFrom: new FormControl(''),
    startDateTo: new FormControl(''),
    expiryDateFrom: new FormControl(''),
    expiryDateTo: new FormControl(''),
    userDetail: new FormControl(''),
    subCode: new FormControl(''),
    status: new FormControl(''),
    planName: new FormControl([]),
    partnerIds: new FormControl([]),
    accountManagerIds: new FormControl([]),
    stage: new FormControl(['Live', 'Trial', 'Expired', 'Trial Expired', 'Deleted']),
    lastDateFrom: new FormControl(''),
    lastDateTo: new FormControl(''),
    searchText: new FormControl(''),
    searchText1: new FormControl(''),
    activate: new FormControl(''),
    tallySerialNo: new FormControl(''),
    isSchedular : new FormControl('')
  });

  constructor(
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private partnerService: PartnerService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private dashboardService: DashboardService

  ) { }

  config = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
  };

  ngOnInit(): void {
    this.filteredIds = this.dashboardService.getData();
    this.activeRoute.queryParams.subscribe(params => {
      this.subType = params['subType']

      if (this.subType) {
        if (this.subType == 'paid' || this.subType == 'renewal') {
          this.searchForm.controls.stage.setValue(['Live']);
          this.allSelected = false;
        }
        else if (this.subType == 'trial') {
          this.searchForm.controls.stage.setValue(['Trial']);
          this.allSelected = false;
        }
        else if (this.subType == 'expired') {
          this.searchForm.controls.stage.setValue(['Expired']);
          this.allSelected = false;
        }
      } else {
        const stageFormControl = this.searchForm.controls.stage as FormControl;
        stageFormControl.patchValue(['Live', 'Trial', 'Expired', 'Trial Expired', 'Deleted']);
        this.allSelected = true;
      }
      this.subscriptionList();
      this.timeInterval = params['timeInterval']
    });
    this.searchForm.controls.accountManagerIds.valueChanges.subscribe((value: any) => {
      this.setPartnerIdInForm()
    })
    this.planList();
    this.allPartnerList()
    this.accountManagerLists()
  }

  ngOnDestroy() {
    const url = this.router.url;
    if (!url.includes('subscriptions-details')) {
      this.dashboardService.releaseData();
    }
  }

  Subtype() {
    this.subTypeTitle = this.subtypeTitles[this.subType] || 'All Subscriptions';
  }

  clickableData(subType: string) {
    const selectedValue = this.timeInterval || 'week';
    if (subType != 'renewal') {
      this.router.navigate(['subscription'], { queryParams: { subType: subType } })
    }
    else {
      this.timeInterval = selectedValue
      this.router.navigate(['subscription'], { queryParams: { subType: subType, timeInterval: selectedValue } })
    }
    if (subType == '') {
      this.router.navigate(['subscription'])
      const stageFormControl = this.searchForm.controls.stage as FormControl;
      stageFormControl.patchValue(['Live', 'Trial', 'Expired', 'Trial Expired', 'Deleted'])
      this.allSelected = true;
    }
    this.subType = subType
  }

  sortingPartner(sortData: string) {
    if (!this.isSorting) {
      this.sortValue = { [sortData]: 1 };
    } else {
      this.sortValue = { [sortData]: -1 };
    }
    this.isSorting = !this.isSorting;
    this.subscriptionList()
  }

  subscriptionList() {
    const selectedValue = this.selectedReportTime?.nativeElement?.value;
    const search = Object.assign(this.searchForm.value,
      { sortValue: this.sortValue },
      { timeInterval: this.timeInterval },
      { misMatchedSerialNumber: this.misMatchedSerialNumber });
    const payload = {
      search: JSON.stringify(search),
      limit: this.config.itemsPerPage,
      page: this.config.currentPage,
      timePeriod: selectedValue,
      subIds: this.filteredIds
    }

    this.subscriptionService.subscriptionList(payload).subscribe((res: any) => {
      if (res.success) {
        if (res.totalSubscription.total === 0) {
          this.toastr.error('No data found');
          this.config.currentPage = 1
          this.config.totalItems = 0
          this.subsList = []
        } else {
          this.subsList = res.subscriptions
          this.subReportData = res.subReport
          this.excelSubIds = res.subIds
          this.subscriptionData = res.totalSubscription
          this.config.currentPage = res.totalSubscription.pages;
          this.config.totalItems = res.totalSubscription.total;
        }
        this.isSubscriptionAvailable = (res['totalSubscription'] == undefined) ? false : true
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      })
    this.Subtype()
  }

  countSelected(): number {
    return Object.values(this.selectedSubs).filter(value => value).length;
  }

  selectAll(event: any): void {
    const isChecked = event.target.checked;
    this.subsList.forEach((subdata: any) => {
      this.selectedSubs[subdata._id] = isChecked;
    });
  }

  toggleSelection(subId: string): void {
    this.selectedSubs[subId] = !this.selectedSubs[subId];
  }
  isAllSelected(): boolean {
    return this.subsList.every((subdata: any) => this.selectedSubs[subdata._id]);
  }
  performBulkAction(): void {
    const selectedIds = Object.keys(this.selectedSubs).filter(key => this.selectedSubs[key]);
    this.subscriptionService.restrictDelition(selectedIds).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.selectedSubs = {}
        this.subscriptionList()
      } else {
        this.toastr.warning(res.message)
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })

  }

  planList() {
    this.subscriptionService.allPlanList().subscribe((res: any) => {
      if (res.success) {
        this.PlanList = res?.plans
      }
    })

  }

  accountManagerLists() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accountManagers
      }
    })
  }

  allPartnerList() {
    this.partnerService.allPartnerList().subscribe((res: any) => {
      if (res.success) {
        this.partnerList = res.partners
        this.filterPartnerList = res.partners
      }
    })
  }

  setPartnerIdInForm() {
    const accountManagerIds = this.searchForm.controls.accountManagerIds.value
    if (accountManagerIds && accountManagerIds.length == 0) {
      this.partnerList = [...this.filterPartnerList];
      this.searchForm.controls.partnerIds.setValue([])
      this.allPartnerSelected = false
    } else {
      this.allPartnerSelected = true
      this.partnerList = this.filterPartnerList.filter((partner: any) => {
        if (accountManagerIds?.some((id: string) => partner.accountManagerId == id)) {
          return partner
        }
      })
      const partnerId = this.partnerList.map((partner: any) => partner._id)

      this.searchForm.controls.partnerIds.setValue(partnerId)
    }
  }

  resetForm() {
    this.searchForm.reset()
    this.subscriptionList()
    this.allSelected = false;
    this.allPlanSelected = false
    this.allPartnerSelected = false
    this.allAccountManagerSelected = false
    this.planName = []
    this.salesPersonId = []
    this.stage = []
    this.config.currentPage = 1;
  }

  submit() {
    const searchFormField = this.searchForm.value
    if (searchFormField.userDetail === "" && searchFormField.startDateFrom === "" && searchFormField.subCode === "" && searchFormField.expiryDateFrom === '' && searchFormField.expiryDateTo === '' && searchFormField.subCode === '' && searchFormField.stage?.length === 0 && searchFormField.planName?.length === 0 && searchFormField.status?.length === 0 && searchFormField.accountManagerIds?.length === 0 && searchFormField.partnerIds?.length === 0) {
      this.toastr.warning('Please fill in data in at least one of the search fields.')
    }
    else {
      this.config.currentPage = 1
      this.subscriptionList()
    }
  }

  excelSubsData() {
    const search = Object.assign(this.searchForm.value, { sortValue: this.sortValue }, { timeInterval: this.timeInterval });
    const payload = {
      search: JSON.stringify(search),
      subIds: this.filteredIds,

    }
    this.subscriptionService.subExcelData(payload).subscribe((res: any) => {
      if (res.success) {
        this.exportexcel(res.subscriptions)
      }
    })
  }

  exportexcel(subData: any): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(subData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.subscriptionList()
  }

  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 0 && this.allSelected) {
          item.select();
        } else if (id === 1 && this.allPlanSelected) {
          item.select();
        } else if (id === 2 && this.allPartnerSelected) {
          item.select();
        } else if (id === 3 && this.allAccountManagerSelected) {
          item.select();
        } else {
          item.deselect();
        }
      });
    } 
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;

    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 0) {
      this.allSelected = newStatus;
    } else if (id === 1) {
      this.allPlanSelected = newStatus;
    } else if (id === 2) {
      this.allPartnerSelected = newStatus;
    } else if (id === 3) {
      this.allAccountManagerSelected = newStatus;
    }
  }
  createNew() {
    this.router.navigate(['new-subscription']);
  }
  get searchText() {
    return this.searchForm.controls.searchText.value || '';

  }
  get searchText1() {
    return this.searchForm.controls.searchText1.value || '';

  }
  get userRole() {
    return this.authService.userRole()
  }


  toggle(activation: any) {
    this.activation = activation;
    if (activation == 'subscripton') {
      this.misMatchedSerialNumber = false
      this.subscriptionList()
    }
    else {
      this.misMatchedSerialNumber = true
      this.subscriptionList()
    }
  }

  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems);
  }

  get userType() {
    return this.authService.userType()
  }
}



