import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddOnService } from 'src/app/services/add-on.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import * as moment from 'moment';
@Component({
  selector: 'app-add-on',
  templateUrl: './add-on.component.html',
  styleUrls: ['./add-on.component.scss']
})
export class AddOnComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() subData: any;
  @Input() subsDataPrice: any;
  @Input() subDataDetails: any
  @Input() addOnId: any
  addOns: any
  isEdit: boolean = false
  preventValueChange: boolean = false
  totalprice: number = 0
  nextAmount: number = 0
  currentPrice: number = 0
  salesPersonList: any
  subDetail: any
  subsDataName: any
  addonInSub = new FormGroup({
    name: new FormControl('', Validators.required),
    price: new FormControl(0, Validators.required),
    unit: new FormControl('', Validators.required),
    quantity: new FormControl(1, Validators.required),
    description: new FormControl('', Validators.required),
    remarks: new FormControl('', Validators.required),
    salesPersonName: new FormControl('direct')
  })

  constructor(
    public bsModalRef: BsModalRef,
    private addOnService: AddOnService,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.addonList()
    setTimeout(() => {
      if (this.subData) {
        if (this.subData?.addOn?.quantity != null) {
          this.isEdit = true
          this.addonInSub.patchValue(this.subData.addOn)
        }
        this.salesPersonLists(this.subData?._id);
        this.setValueOnAddOn()
      } else {
        if (this.subsDataPrice) {
          this.isEdit = true
          this.addonInSub.patchValue(this.subDataDetails.addOn)
        } else {
          if (this.addOnId) {
            this.getDetails(this.addOnId)
          }
          this.isEdit = false
        }
        this.salesPersonLists(this.subDataDetails?._id);
        this.setValueOnAddOn()
      }
    })

  }

  setValueOnAddOn() {
    this.addonInSub.get('name')?.valueChanges.subscribe(value => {
      const selectedAddon = this.addOns.find((addOn: any) => addOn.name == value);
      this.addonInSub.controls.price.setValue(selectedAddon.price)
      this.addonInSub.controls.unit.setValue(selectedAddon.unit)
      this.addonInSub.controls.description.setValue(selectedAddon.description)
    });
    this.addonInSub.get('quantity')?.valueChanges.subscribe(value => {
      this.calculateAmount()
    })
    this.addonInSub.get('price')?.valueChanges.subscribe(value => {
      this.calculateAmount()
    })
  }

  calculateAmount() {
    const currentPrice = Number(this.addonInSub.controls.price.value)
    const currentQuantity = Number(this.addonInSub.controls.quantity.value)
  
    const currentDate = moment()
    const subscriptionEndDate = moment(this.subData?.endDate) || moment(this.subDataDetails?.endDate)
    const subscriptionRemainingDays = subscriptionEndDate.diff(currentDate, 'days'); //how many days of subscription on current date
    
    const previousAddOn = this.subData?.addOn || this.subDataDetails?.addOn
    const subscriptionDays = (this.subData?.plan?.billingCycle === "month") ? 30 : 365 || (this.subDataDetails?.plan?.billingCycle === "month") ? 30 : 365
    const subscriptionTotalDays = (subscriptionDays * this.subData?.plan?.billEvery) || (subscriptionDays * this.subDataDetails?.plan?.billEvery)
   
    const addonOneDayPrice = previousAddOn?.price / subscriptionTotalDays
    const currentAddonOneDayPrice = currentPrice / subscriptionTotalDays
    
    const returnAmount = Math.round(addonOneDayPrice * previousAddOn?.quantity * subscriptionRemainingDays)
    this.currentPrice = Math.round(currentAddonOneDayPrice * currentQuantity * subscriptionRemainingDays)
    
    this.totalprice = this.currentPrice - returnAmount
    this.nextAmount = currentPrice * currentQuantity   
  }

  addonList() {
    this.addOnService.addOnList('645eaad72e2877dbf7ca8e0d').subscribe((res: any) => {
      if (res.success) {
        this.addOns = res.addOns
      }
    })
  }

  submit() {
    if (!this.addonInSub.valid) {
      this.addonInSub.markAllAsTouched()
    } else if (Number(this.addonInSub.controls.quantity) < 1) {
      this.toastr.warning('Please select valid quantity.')
    }
    else {
      const payload = this.addonInSub.value
      Object.assign(payload, { subCode: (this.subData?.code || this.subDataDetails?.code), connectedPerson: this.addonInSub.get('salesPersonName')?.value })
      this.subscriptionService.addUpdateAddOn(payload, this.isEdit).subscribe((res: any) => {
        if (res.success) {
          this.event.emit('ok')
          this.toastr.success(res.message)
          this.closedModal()
        }
        else {
          this.toastr.warning(res.message)
        }
      }, (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  salesPersons(name: string) {
    this.addonInSub.controls.salesPersonName.setValue(name);
  }

  salesPersonLists(subscriptionId: any) {
    this.subscriptionService.subscriptionSalesPersonList(subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.salesPersonList = res.salesPersons
      }
    })
  }

  getDetails(id: any) {
    this.subscriptionService.getNewSubscriptionRequestDetails(id, '').subscribe((res: any) => {
      if (res.success) {
        this.addonInSub.patchValue({ ...res?.subManagementDetails?.addOn })
      }
    })
  }

}
