import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerDetailRoot } from 'src/app/interfaces/partner';
import { PartnerService } from 'src/app/services/partner.service';

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit {
  partnerId: string = '';
  partner: any;

  constructor(
    private partnerService: PartnerService,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe(res => {
      this.partnerId = res['partnerId'];
    })
    this.detailOfPartner();
  }

  detailOfPartner() {
    this.partnerService.partnerDetails(this.partnerId).subscribe((res: PartnerDetailRoot) => {
      if (res.success) {
        this.partner = res.partner;
      }
    })
  }

  editPartner(partnerId: string) {
    this.router.navigate(['reseller/add-reseller'], { queryParams: { partnerId: partnerId } });
  }

}
