import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-change-sub-price',
  templateUrl: './change-sub-price.component.html',
  styleUrls: ['./change-sub-price.component.scss']
})
export class ChangeSubPriceComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() subCode: string;
  @Input() currentPrice: number;
  @Input() planName: string;
  @Input() subscriptionId: any;

  changeSubPriceForm = new FormGroup({
    price: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    currentPrice: new FormControl(0, Validators.required),
    code: new FormControl('', Validators.required),
    remark: new FormControl('', Validators.required),
    salesPersonName: new FormControl('direct')
  })
  salesPersonList: any


  constructor(
    private bsModalRef: BsModalRef,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.changeSubPriceForm.controls.code.setValue(this.subCode)
      this.changeSubPriceForm.controls.name.setValue(this.planName)
      this.changeSubPriceForm.controls.currentPrice.setValue(this.currentPrice)
      this.salesPersonLists(this.subscriptionId)
    }, 0);

  }

  closeModal() {
    this.bsModalRef.hide();
  }

  changeSubPrice() {
    if (!this.changeSubPriceForm.valid) {
      this.changeSubPriceForm.markAllAsTouched()
    }
    else {
      const payload = {
        subCode: this.changeSubPriceForm.value.code,
        price: this.changeSubPriceForm.value.price,
        remark: this.changeSubPriceForm.value.remark,
        connectedPerson: this.changeSubPriceForm.get('salesPersonName')?.value
      }
      this.subscriptionService.subPriceChange(payload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.message);
          this.closeModal();
          this.event.emit('ok')
        } else {
          this.toastr.warning(res.message);
        }
      }, (err) => {
        this.toastr.warning(err.error.message)
      })
    }

  }

  salesPersons(name: string){
    this.changeSubPriceForm.controls.salesPersonName.setValue(name);
  }


  salesPersonLists(subscriptionId: any) {
    this.subscriptionService.subscriptionSalesPersonList(subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.salesPersonList = res.salesPersons
      }
    })
  }
}
