<!-- -------------------login--page--------------- -->
<div class="bg-cloud" >
  <div class="container-fluid ">
    <div class=" d-flex align-items-center auth px-0 justify-content-center">
      <div class="row w-100 mt-5 mb-5">
        <div class="col-12   ">
          <div class="login-form">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="logo">
                <img src='./../assets/images/cocloud-logo.jpg' alt="logo" class="logo">
              </div>
              <h4 class="text-center mt-2 mb-2 signUp"><b>{{changePassword? 'Reset Password' : 'Sign in to CoCloud'}}</b></h4>

              <form class="pt-3" [formGroup]="loginForm" *ngIf="!changePassword">

                <div class="form-group form-field">
                  <input type="email" class="form-control form-control-lg" id="exampleInputEmail1"
                    placeholder="Email address" formControlName="email">
                  <div *ngIf="loginForm.get('email')?.invalid" class="alert mb-0 p-0">
                    <div
                      *ngIf="loginForm.get('email')?.errors?.['required'] && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
                      <p class="text-danger m-0 p-0 "><small>It's mandatory field</small>
                      </p>
                    </div>
                    <div *ngIf="loginForm.get('email')?.errors?.['pattern']">
                      <p class="text-danger m-0 p-0"><small>Please enter valid email id.</small>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="form-group form-field ">
                  <input [type]="passType" class="form-control form-control-lg" id="exampleInputPassword1"
                    placeholder="Password" formControlName="password" minlength="6">
                  <div class="hideshow-password"><i [class]="show_eye ? 'bi bi-eye' : 'bi bi-eye-slash'"
                      (click)="changePasswordType()"> </i></div>
                  <div class="alert mb-0 p-0">
                    <div class="resetPassword">
                      <div *ngIf="loginForm.get('password')?.invalid">
                        <span class="text-danger m-0 p-0"
                          *ngIf="loginForm.get('password')?.errors?.['required'] && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
                          <small>Password is mandatory.</small>
                        </span>
                        <span class="text-danger m-0 p-0 " *ngIf="loginForm.get('password')?.errors?.['minlength']">
                          <small>Please enter a minimum of 6 characters for the password.</small>
                        </span>
                      </div>
                    </div>
                    <p class="mt-1 mb-3 forgotPassword"> <span class=" text-color" (click)="resetPassword()">Forgot
                      password?</span> </p>
                  </div>
                </div>
                <div class="mt-2">
                  <button type="submit" class="comman-btns btn-block" (click)="login()"><b>Sign
                      in</b></button>
                </div>
              </form>

              <form class="pt-1" [formGroup]="resetPasswordForm" *ngIf="changePassword">
                <div class="form-group login-input mt-4">
                  <input type="email" class="form-control form-control-lg" placeholder="Enter registered email id"
                    formControlName="email" [readonly]="otpVerify">
                  <ng-container *ngFor="let validation of validationsReset.email">
                    <div class="error-message"
                      *ngIf="resetPasswordForm.get('email')?.hasError(validation.type)
                                            && (resetPasswordForm.get('email')?.dirty || resetPasswordForm.get('email')?.touched)">

                      <span class="error-msg"> {{ validation.message }}</span>
                    </div>
                  </ng-container>
                </div>

                <div class="form-group pass-field pwd mt-4" *ngIf="otpVerify">
                  <input type="password" class="form-control form-control-lg" id="reset1" placeholder="Enter password"
                    formControlName="password" minlength="6">
                  <span class="p-viewer">
                    <i *ngIf="showPassword" class="bi bi-eye password-icon-1" (click)="hidePassword('reset')"></i>
                    <i *ngIf="!showPassword" class="bi bi-eye-slash password-icon-1"
                      (click)="hidePassword('reset')"></i>
                  </span>
                  <ng-container *ngFor="let validation of validationsReset.password">
                    <div class="error-message"
                      *ngIf="resetPasswordForm.get('password')?.hasError(validation.type)
                                          && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
                      <span class="error-msg"> {{ validation.message }}</span>
                    </div>
                  </ng-container>
                </div>

                <div class="form-group pass-field pwd  mt-4" *ngIf="otpVerify">
                  <input type="password" class="form-control form-control-lg" id="reset2" placeholder="ReEnter password"
                    formControlName="confirmPassword" minlength="6">
                  <span class="p-viewer">
                    <i *ngIf="showPassword1" class="bi bi-eye password-icon-2" (click)="hidePassword('confirm')"></i>
                    <i *ngIf="!showPassword1" class="bi bi-eye-slash password-icon-2"
                      (click)="hidePassword('confirm')"></i>
                  </span>
                  <ng-container *ngFor="let validation of validationsReset.password">
                    <div class="error-message"
                      *ngIf="resetPasswordForm.get('confirmPassword')?.hasError(validation.type)
                                             && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched)">
                      <span class="error-msg"> {{ validation.message }}</span>
                    </div>
                  </ng-container>
                </div>

                <div class="form-group  mt-4" *ngIf="otpVerify">
                  <input type="tel" class="form-control form-control-lg" placeholder="Enter otp" formControlName="otp"
                    maxlength="6">
                  <ng-container *ngFor="let validation of validationsReset.otp">
                    <div class="error-message"
                      *ngIf="resetPasswordForm.get('otp')?.hasError(validation.type)
                                             && (resetPasswordForm.get('otp')?.dirty || resetPasswordForm.get('otp')?.touched)">
                      <span class="error-msg"> {{ validation.message }}</span>
                    </div>
                  </ng-container>
                </div>

                <div class="getOTP-btn mt-4" *ngIf="!otpVerify">
                  <button type="submit" class="comman-btns btn-block"
                    (click)="getOtp()"><b>Request OTP</b>
                  </button>
                </div>
                <div class="getOTP-btn mt-4" *ngIf="otpVerify">
                  <button type="submit" class="comman-btns btn-block"
                    (click)="confirmChange()"><b>Change Password</b>
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>