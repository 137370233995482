import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdminRoot } from 'src/app/interfaces/admin';
import { AdminService } from 'src/app/services/admin.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.scss']
})
export class AccountManagerComponent implements OnInit {

  admins: any = []
  bsModalRef: BsModalRef
  isAdminAvailable: boolean = false

  constructor(
    private adminService: AdminService,
    private router: Router,
    private modalService: BsModalService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
    this.adminList();
  }

  adminList() {
    this.adminService.adminList().subscribe((res: AdminRoot) => {
      if (res.success) {
        this.admins = res.admins;
        this.isAdminAvailable = true
      }
    })
  }

  navigateToAddAdmin(adminId: string) {
    this.router.navigate(['account-manager/add-admin'], { queryParams: { adminId: adminId } })
  }

  deleteModal(adminId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.adminId = adminId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.adminList();
      }
      else {
        this.adminList();
      }
    })
  }

  toggleStatus(value: boolean, adminId: string) {
    const adminUserPayload = {
      adminId: adminId,
      isActive: !value
    }
    this.adminService.updateAdmin(adminUserPayload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.adminList()
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })

  }

}
