<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left mb-2"><b>Extend Expiry Date </b></h4>
</div>
<div class="modal-body  mt-0 pt-0 pb-3">
    <div class="row">
        <div class="col-sm-12">
            <p><b>{{planName | uppercase}} ({{subCode |uppercase}})</b> </p>
        </div>
    </div>
    <form [formGroup]="extendToExpiry">
        <div class="row ">
            <div class="col-sm-12">
                <label for="price" class="mb-2 text-muted"><b>Current Expiry Date:</b></label>
                <div class="select-section">
                    <input type="text" class="form-control" formControlName="currentEndDate" readonly>

                </div>
            </div>
        </div>

        <div class="row mt-2 mb-3">
            <div class="col-sm-12">
                <label for="price" class="mb-2 text-muted"><b>New Expiry Date:</b></label>
                <div class="select-section">
                    <input type="date" class="form-control" formControlName="endDate" [min]="minDate"
                        (change)="onDateChange($event)">

                    <div *ngIf="extendToExpiry.get('endDate')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="extendToExpiry.get('endDate')?.errors?.['required'] && (extendToExpiry.get('endDate')?.dirty || extendToExpiry.get('endDate')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="mb-2 text-muted"><b>Remarks:</b></label>
            <div class="select-section">
                <textarea rows="1" class="form-control " id="fullName" placeholder="Remarks" style="overflow:hidden"
                    formControlName="remark"></textarea>
                <div *ngIf="extendToExpiry.get('remark')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="extendToExpiry.get('remark')?.errors?.['required'] && (extendToExpiry.get('remark')?.dirty || extendToExpiry.get('remark')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
            <p class="mt-3">
                <label>
                    <input type="checkbox" class="me-2 large-checkbox" formControlName="isTempExt">
                    <b class="mb-2 text-muted">IsTempExt</b>
                </label>
            </p>
        </div>
    </form>
</div>
<div class="text-end border-0 mt-0 pt-0">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">Submit</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
</div>