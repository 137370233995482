import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageAdminRoot } from 'src/app/interfaces/admin';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {
  adminId: string = ''
  isEdit: boolean = false;
  passType: string = 'password';
  show_eye: Boolean = false;
  allSelected = false;
  accountManagerList: any;
  currentUserRole: string = ""
  adminDetail: any

  adminForm = this.fb.group({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    mobile: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[1-9]{1}[0-9]{9}$"), Validators.minLength(10), Validators.required,]),
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    password: new FormControl('', [Validators.minLength(8), Validators.required]),
    role: new FormControl('support', [Validators.required]),
    accessibleUserIds: new FormControl([''])
  })

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toasts: ToastrService,
  ) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((data) => {
      this.adminId = data['adminId'];
    })
    if (this.adminId) {
      this.adminForm.controls.password.clearValidators()
      this.adminForm.controls.password.updateValueAndValidity()
      this.isEdit = true;
      this.adminDetails();
    }
    this.accountManagerLists()
  }

  addAdmin() {
    if (!this.adminForm.valid) {
      this.adminForm.markAllAsTouched();
    }
    else {
      this.submit();
    }
  }


  submit() {
    if (this.isEdit) {
      this.updateAdmin();
    }
    else {
      this.createAdmin();
    }
  }


  createAdmin() {
    let adminPayload = this.adminForm.value
    this.adminService.createAdmin(adminPayload).subscribe((res: ManageAdminRoot) => {
      if (res.success) {
        this.router.navigate(['account-manager'])
      }
    })
  }

  adminDetails() {
    this.adminService.adminDetails(this.adminId).subscribe((res: ManageAdminRoot) => {
      if (res.success) {
        this.adminDetail = res.admin
        this.adminForm.patchValue(res.admin);
      }
    })
  }

  updateAdmin() {
    let updatePayload = this.adminForm.value
    updatePayload = Object.assign(this.adminForm.value, { adminId: this.adminId })
    this.adminService.updateAdmin(updatePayload).subscribe((res: ManageAdminRoot) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.router.navigate(['account-manager'])
      }
    })
  }

  changePasswordType() {
    this.show_eye = !this.show_eye;
    if (this.passType == 'password') {
      this.passType = 'text'
    } else {
      this.passType = 'password'
    }
  }

  accountManagerLists() {   
    this.adminService.adminList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.admins.filter((item: any) => {
          if (!item.isSuperAdmin && (item.role == 'manager' || item.role == 'salesManager')) {
            const assignIds = this.adminDetail?.accessibleUserIds && this.adminDetail?.accessibleUserIds.some((id: any) => id == item._id)
            const adminUserId = item.accessibleUserIds && item.accessibleUserIds.some((id: any) => id == this.adminId)
            if (!adminUserId) {
              if (item._id == this.adminId || assignIds) {
                item['isSelected'] = true
              } else {
                item['isSelected'] = false
              }
              return item
            }
          }
        })
      }
    })
  }

  allowAll(event: any) {
    
    this.allSelected = event.target.checked;
    const accountManagerIds: string[] = []
    this.accountManagerList.forEach((user: any) => {
      if (user._id != this.adminId) {
        user.isSelected = event.target.checked
        accountManagerIds.push(user._id)
      }
    });
    this.adminForm.controls.accessibleUserIds.setValue(accountManagerIds)
  }

  toggleUserAccess(event: any, index: number) {
    let accountManagerIds: string[] = []
    this.accountManagerList[index].isSelected = event.target.checked;
    const accountManager = this.accountManagerList[index]
    if (accountManager.accessibleUserIds) {
      accountManagerIds = accountManager.accessibleUserIds
    }
    this.allSelected = this.accountManagerList.every((user: any) => user.isSelected)
    this.accountManagerList.forEach((user: any) => {
      const assignIds = accountManager.accessibleUserIds && accountManager.accessibleUserIds.some((id: any) => id == user._id)
      if (assignIds && user._id != this.adminId) {
        user['isSelected'] = event.target.checked;
      }
      if (user._id != this.adminId && user.isSelected) {
        accountManagerIds.push(user._id)
      }
    });
    this.adminForm.controls.accessibleUserIds.setValue(accountManagerIds)
  }

}
