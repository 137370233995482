import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MrrService {

  constructor(private http: HttpClient) { }

  mrrMovement(mrrReportPlayload: any) {
    return this.http.post(`${environment.apiURL}/admin/mrr/report`, mrrReportPlayload)
  }
  mrrGrowth(mrrGrowthPlayload: any) {
    return this.http.post(`${environment.apiURL}/admin/mrr/growth`, mrrGrowthPlayload)
  }

  mrrDetails(mrrDetailsPlayload: any) {
    // ?month=${month}&billingType=${billingType}
    return this.http.post(`${environment.apiURL}/admin/mrr/detail`, mrrDetailsPlayload)
  }

  accountManagerMrrReport(accountManagerMrrPlayload: any) {
    return this.http.post(`${environment.apiURL}/admin/accManager/mrr/report`,accountManagerMrrPlayload);
  }

  partnerMrrReport(partnerMrrReportPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/partner/mrr/report`, partnerMrrReportPayload);
  }

  partnerSubscriptionReport(partnerSubscriptionReportPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/partner/subs/report`, partnerSubscriptionReportPayload);
  }

  accountManagerSubsReport(accountManagerSubsReportPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/accManager/subs/report`, accountManagerSubsReportPayload);
  }
}
