import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/services/billing.service';
@Component({
  selector: 'app-manual-payment-status',
  templateUrl: './manual-payment-status.component.html',
  styleUrls: ['./manual-payment-status.component.scss']
})
export class ManualPaymentStatusComponent implements OnInit {

  @Input() paymentId!: any
  @Input() orderId!: any

  event: EventEmitter<any> = new EventEmitter();
  constructor(
    private toasts: ToastrService,
    private bsModalRef: BsModalRef,
    private billingService: BillingService
  ) { }

  ngOnInit(): void {
  }

  updateStatusForm = new FormGroup({
    status: new FormControl('', Validators.required),
    recievedAmount: new FormControl('', [Validators.pattern('^(?!0)[0-9]+[.]?[0-9]*$'), Validators.required]),
    remark: new FormControl('', Validators.required)
  })

  closeModal() {
    this.bsModalRef.hide();
  }

  submit() {
    if (!this.updateStatusForm.valid) {
      this.updateStatusForm.markAllAsTouched()
    } else {
      this.updatePaymentStatus()
    }
  }

  updatePaymentStatus() {
    const payload: any = this.updateStatusForm.value
    payload['paymentId'] = this.paymentId
    this.billingService.updatePaymentStatus(payload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.closeModal()
        this.event.emit('ok')
      } else {
        this.toasts.warning(res.message)
      }
    }, (err) => {
      this.toasts.error(err.error.message)
    })
  }
}
