<!-- ---------billing--section--start-------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-6 mb-2">
          <h3 class="font-weight-bold heading"><b>Billing</b></h3>
        </div>
        <!-- --------------Search Form---------------- -->

        <div class="col-md-12">
          <div class="search-form">
            <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              <i class="bi bi-search"></i>
              <span class="ms-5">Search here</span>
            </p>
            <div class="collapse" id="collapseExample">
              <form class="row g-3 mt-2" [formGroup]="searchForm">
                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="inputGroupPrepend">From</span>
                    <input type="date" class="form-control" id="validationCustomUsername" placeholder="DOB"
                      aria-describedby="inputGroupPrepend" formControlName="createFrom" required />
                    <div class="invalid-feedback">
                      Please choose a username.
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="inputGroupPrepend">To</span>
                    <input type="date" class="form-control" id="validationCustomUsername" placeholder="DOB"
                      aria-describedby="inputGroupPrepend" formControlName="createTo" required />
                    <div class="invalid-feedback">
                      Please choose a username.
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Actions</span>
                    <select class="form-select " aria-label="Default select example" formControlName="action">
                      <option selected>Please Select</option>
                      <option value="freeCredit">Free Credit</option>
                      <option value="creditBought">Credit Bought</option>
                      <option value="Adjustment">Adjustment</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Partner Email</span>
                    <input type="email" class="form-control" formControlName="partnerEmail" />
                  </div>
                </div>
                <div class="col-md-12 text-end">
                  <button type="submit" class="comman-btn1 btn-primary me-2" (click)="searchList()">
                    Search
                  </button>
                  <button type="search" class="comman-btn1 btn-secondary text-light" (click)="resetSearchForm()">
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- --------------Search From End Here----------->
        <!-- ---------billing--details--table---start---------->
        <div class="row table-section pe-0 mt-3">
          <div class="col-md-12 grid-margin stretch-card pe-0">
            <div class="card detailed-section">
              <div class="card-body">

                <div class=" mb-4 records">{{ updatePaginationText }}</div>

                <div *ngIf="!isBillingAvailable" class="text-center">
                  <h4>There is no Billing created yet</h4>
                </div>
                <div class="table-responsive">
                  <table class="table table-borderless table-hover text-center" *ngIf="isBillingAvailable">
                    <thead class="roundedTable thead-header">
                      <tr>
                        <th class="box-0"></th>
                        <th scope="col" class="box-1">Date & Time</th>
                        <th scope="col" class="box-2">Starting Balance</th>
                        <th scope="col" class="box-3">Debit</th>
                        <th scope="col">Credit</th>
                        <th scope="col">Balance</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let bill of billing | paginate : config">
                      <tr (click)="toggleTableExpend(bill)">
                        <td>
                          <span>
                            <i *ngIf="bill.collapse" class="bi bi-arrow-right-circle mouseCursor"></i>
                            <i *ngIf="!bill.collapse" class="bi bi-arrow-down-circle mouseCursor text-danger"></i>
                          </span>
                        </td>
                        <td>
                          <b>{{ bill?.createdAt | date : "MMMM,YYYY" }}</b>
                        </td>
                        <td>
                          &#8377;
                          {{ bill?.adminStartingBalance | number : "1.0-2" }}
                        </td>
                        <td class="text-danger">
                          &#8377; {{ bill?.credit | number : "1.0-2" }}
                        </td>
                        <td class="text-success">
                          &#8377; {{ bill?.debit | number : "1.0-2" }}
                        </td>
                        <td>
                          &#8377;
                          {{
                          bill?.adminStartingBalance +
                          bill.debit -
                          bill.credit | number : "1.0-2" : "en-US"
                          }}
                        </td>
                      </tr>
                      <tr class="collapse p-0" [class.show]="bill.collapse == false">
                        <td colspan="12" class="p-0">
                          <div class="table-responsive">
                            <table class="table table-borderless">
                              <thead class="thead-headers">
                                <tr>
                                  <th class="box-type-0">Bill ID</th>
                                  <th scope="col" class="box-type-1">Date & Time</th>
                                  <th scope="col" class="box-type-2">Action</th>
                                  <th scope="col" class="box-type-2">Partner Email</th>
                                  <th scope="col">Credit</th>
                                  <th scope="col">Debit</th>
                                  <th scope="col">Balance</th>
                                  <th scope="col" class="box-type-2">Created By</th>
                                </tr>
                              </thead>
                              <tbody *ngFor="let billDetail of monthBilling | paginate: config">
                                <tr
                                  *ngIf="bill?._id?.month === billDetail.month && bill?._id?.year === billDetail.year">
                                  <td><span
                                      ngbTooltip="{{billDetail?.descriptionByAdmin}}">{{billDetail?.billNumber}}</span>
                                  </td>
                                  <td>{{billDetail?.createdAt | date : "dd/MM/yyyy, hh:mm a": "UTC + 05:30"}}</td>
                                  <td><span [ngbTooltip]="billDetail?.description" placement="top">{{
                                      billDetail?.action | titlecase }}</span></td>
                                  <td>
                                    <span [ngbTooltip]="billDetail?.partnerEmail" placement="top" class="d-inline-block text-truncate" style="max-width: 100px;">{{
                                      billDetail?.partnerEmail | titlecase }}</span>

                                  </td>
                                  <td class="text-success">&#8377;{{ billDetail?.debit | number : "1.0-2" }} </td>
                                  <td class="text-danger "> &#8377;{{billDetail?.credit | number : "1.0-2"}}</td>
                                  <td> &#8377;{{billDetail?.adminStartingBalance -billDetail?.debit +
                                    billDetail?.credit | number : "1.0-2" }}</td>
                                  <td>{{ billDetail?.createdBy | titlecase }}</td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-4 float-end me-4" *ngIf="isBillingAvailable">
                  <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -----biiling--details--end---------- -->
      </div>
    </div>
  </div>
</div>