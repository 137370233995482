<!-- ---------instance------page----- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row mt-2 mb-4">
        <h3 class="font-weight-bold">Subscription Details</h3>
      </div>
      <!-- --------form-------- -->
      <div class="col-md-12 mb-4">
        <div class="card account-information">
          <h4>Details </h4>
          <div class="row">
            <div class="col-md-12 subscription-list px-4">
              <ul>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Sub Id:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.code}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Status:
                    </div>
                    <div class="col-6 plan-details"
                      [ngClass]="[instanceUser[0].isActive? 'text-success': 'text-danger']">
                      {{instanceUser[0]?.isActive? 'Active': 'InActive'}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Stage:
                    </div>
                    <div class="col-6 plan-details"
                      [ngClass]="[instanceUser[0].isExpired? 'text-danger': 'text-success']">
                      {{instanceUser[0]?.isExpired? 'Expired':'live'}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Admin Email:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.customerEmail}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Customer Company:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.customer?.companyName}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Number of User:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.instance?.noOfUsers}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Partner Company:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.partner?.companyName}}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-6 plan-name">
                      Partner Email:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.partner?.email}}
                    </div>
                  </div>
                </li>
                <li *ngIf="userType">
                  <div class="row">
                    <div class="col-6 plan-name">
                      start Date:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.startDate | date:'dd/MM/yyyy'}}
                    </div>
                  </div>
                </li>
                <li *ngIf="userType">
                  <div class="row">
                    <div class="col-6 plan-name">
                      Next billing Date:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.endDate | date:'dd/MM/yyyy'}}
                    </div>
                  </div>
                </li>
                <li *ngIf="userType">
                  <div class="row">
                    <div class="col-6 plan-name">
                      last Renew Date:
                    </div>
                    <div class="col-6 plan-details">
                      {{(instanceUser[0]?.lastRenewDate===null)?(instanceUser[0]?.startDate
                      |date:'dd/MM/yyyy'):(instanceUser[0]?.lastRenewDate |date:'dd/MM/yyyy')}}
                    </div>
                  </div>
                </li>
                <li *ngIf="!userType">
                  <div class="row">
                    <div class="col-6 plan-name">
                      Salesman:
                    </div>
                    <div class="col-6 plan-details">
                      {{instanceUser[0]?.salesPerson?.name}}
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- ------end----- -->
      <div>
        <h3 class="font-weight-bold">Cloud User Details</h3>
      </div>
      <!-- --------table-section-1--------- -->
      <div class="row table-section mt-4">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <!-- <div class="text-center">
        <h4>There is no Instance created yet</h4>
       </div> -->
              <div class="table-responsive">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th scope="col">Cloud User Email Id</th>
                      <th scope="col">Cloud User Name</th>
                      <th scope="col" *ngIf="userType">Cloud Password</th>
                      <th scope="col">System Status</th>
                      <th scope="col">User Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let instanceUser of instanceUser[0].instanceUsers let i=index">
                      <td>{{i+1}}</td>
                      <td>{{instanceUser.email}}</td>
                      <td>{{instanceUser.userName}}</td>
                      <td *ngIf="userType">{{instanceUser.userKey}}</td>
                      <td [ngClass]="[instanceUser?.sysStatus? 'text-success': 'text-danger']">
                        {{instanceUser?.sysStatus?
                        'Online':
                        'Offline'}}</td>
                      <td [ngClass]="[instanceUser?.isActive? 'text-success': 'text-danger']">{{instanceUser?.isActive?
                        'Active':
                        'InActive'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="mt-4 float-end me-4">
                <pagination-controls></pagination-controls>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- ------end----- -->
    </div>
  </div>
</div>