import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MrrService } from 'src/app/services/mrr.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AdminService } from 'src/app/services/admin.service';
@Component({
  selector: 'app-account-manager-report',
  templateUrl: './account-manager-report.component.html',
  styleUrls: ['./account-manager-report.component.scss'],

})
export class AccountManagerReportComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  isShowDetails: boolean = false
  activation = 'mrr'
  hideSubDateRange: boolean = false
  accountManagerList: any
  accountManagerListIds: any
  currentMonthYear: any
  values: any
  totals: any
  mrrDetail: any
  isSorting: boolean = false;
  sortColumn: string = '';
  sortAsc: boolean = true;
  accountManagerSubsReport: any
  accountManagerTotalsSubs: any
  allSelected = false;
  fileName = 'MRR_Details.xlsx';
  searchForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    date: new FormControl(''),
    searchText:new FormControl(''),
    accountManagerIds: new FormControl([]),
  })
  constructor(
    private mrrService: MrrService,
    private toastr: ToastrService,
    private authService: AuthService,
    private adminService:AdminService
  ) { }


  ngOnInit(): void {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substring(0, 7);
    this.searchForm.get('date')?.setValue(formattedDate);
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate);
    this.accountManagerMrrList()
    this. accountManagerLists()
    this.searchForm.get('date')?.valueChanges.subscribe((value: any) => {
      this.isShowDetails = false
      this.accountManagerMrrList()
    })

  }
  toggle(activation: any) {
    this.activation = activation;
    if (activation == 'subcription') {
      this.hideSubDateRange = true
      this.isShowDetails = false
      this.searchForm.controls.accountManagerIds.reset()
      this.accountMangerSubsMrrReport()
    }
    else {
      this.hideSubDateRange = false
      this.accountManagerMrrList()
    }
  }

  accountManagerMrrList() {
    const month = this.searchForm.get('date')?.value
    const accountManagerIds = this.searchForm.get('accountManagerIds')?.value
    const mrrpayload = {
      month: month,
      accountManagerIds: accountManagerIds
    }
    this.mrrService.accountManagerMrrReport(mrrpayload).subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accManagerMrrReport
        this.totals = res?.totals
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }
  accountManagerLists() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerListIds = res?.accountManagers
      }
    })
  }
  mrrDetails(accountManagerId: string, billingType: string) {
    this.isShowDetails = true
    setTimeout(() => {
      if (this.scrollContainer && this.scrollContainer.nativeElement) {
        this.scrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    })

    const mrrDetailsPlayload = {
      accountManagerIds: [accountManagerId],
      month: moment(this.searchForm.get('date')?.value).format("MMM YYYY"),
      billingType: billingType,
      subUserData: false
    }
    this.mrrService.mrrDetails(mrrDetailsPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrDetail = res?.mrrDetails
      }
    })
  }

  exportexcel(): void {
    let excelData
    if (this.hideSubDateRange) {
      excelData = this.accountManagerSubsReport.map((dataObj: any) => {
        return {
          "Account Manager": dataObj?.name || '',
          "No of Partner": dataObj?.totalPartners || 0,
          "Total Subscriptions": dataObj?.totalPaidSub?.totalAmount || 0,
          "New Subscriptions": dataObj?.newPaidSubs?.totalAmount || 0,
          "Total Renewals": dataObj?.totalRenewalsReport?.totalAmount || 0,
          "Renewed": dataObj?.renewedReport?.totalAmount || 0,
          "Upgrades": dataObj?.upgradeReport?.totalAmount || 0,
          "Downgrade": dataObj?.downgradeReport?.totalAmount || 0,
          "Upcoming Renewals": dataObj?.upcomingRenewalsReport?.totalAmount || 0,
          "Expired": dataObj?.expiredReport?.totalAmount || 0,
          "Deleted": dataObj?.deletedReport?.totalAmount || 0,
          "Risky Customers Value": dataObj?.riskySubsReport?.totalAmount || 0,
          "Trial Signups": dataObj?.totalTrialReport?.totalAmount || 0,
          "Trial Expired": dataObj?.expiredTrialReport?.totalAmount || 0,
          "Trial to Paid": dataObj?.trialToPaidReport?.totalAmount || 0,
          "Live Trial": dataObj?.liveTrialReport?.totalAmount || 0,
          "Current Wallet": dataObj?.totalCurrentWallet || 0,
          "Wallet Purchased": dataObj?.totalWalletPurchased || 0,
          "Wallet Consumed": dataObj?.totalWalletConsumed || 0,
        }
      })
    } else {
      excelData = this.accountManagerList.map((dataObj: any) => {
        return {
          "Account Managers": dataObj?.accountManagerName || '',
          "No of partners": dataObj?.partnerCount || 0,
          "New Business MRR": dataObj?.netNewBiz || 0,
          "Expansion MRR ": dataObj?.netUpgrade || 0,
          "Contraction MRR": dataObj?.netDowngrade || 0,
          "Churn MRR": dataObj?.netChurn || 0,
          "Reactivation MRR": dataObj?.netReactivation || 0,
          "Net Churn MRR": dataObj?.netNetChurn || 0,
          "Net MRR Movement": dataObj?.netMrrMovement || 0,
          "Current MRR": dataObj?.currentMrr || 0,
          "Previous MRR": dataObj?.prevMonthMrr || 0,
          "Change": dataObj?.percentageChange || 0,
        }
      })
    }

    const jsonSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, jsonSheet, 'JSON_Sheet');  // Changed sheet name to 'JSON_Sheet'
    XLSX.writeFile(wb, this.hideSubDateRange ? 'accountManager_subscription_Mrr.xlsx' : 'accountManager_Mrr.xlsx');
  }

  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allSelected) {
          item.select();
        }
        else {
          item.deselect()
        }
      });
    }
  }
  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;
    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allSelected = newStatus;
    }
  }
  onButtonClick() {
    if (this.hideSubDateRange) {
      this.isShowDetails = false
      this.accountMangerSubsMrrReport()
    }
    else {
      this.isShowDetails = false
      this.accountManagerMrrList()
    }
  }
  exportExcelMrrDetails() {

    const mrrDetail = document.getElementById('mrr-detail-table')
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(mrrDetail);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }
  get userType() {
    return this.authService.userType()
  }
  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }
  accountMangerSubsMrrReport() {
    const payload = {
      startDate: this.searchForm.get('startDate')?.value,
      endDate: this.searchForm.get('endDate')?.value,
      accountManagerIds: this.searchForm.get('accountManagerIds')?.value
    }
    this.mrrService.accountManagerSubsReport(payload).subscribe((res: any) => {
      if (res.success) {
        this.accountManagerSubsReport = res?.accManagerDetailsData
        this.accountManagerTotalsSubs = res?.totals
      }
    })
  }
  submit() {
    this.isShowDetails = false
    this.accountManagerMrrList()
  }

  sorting(column: string): void {
    if (this.sortColumn === column) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortColumn = column;
      this.sortAsc = true;
    }
    if (this.hideSubDateRange) {
      this.accountManagerSubsReport.sort((a: any, b: any) => {
        const aValue = this.getNestedValue(a, column);
        const bValue = this.getNestedValue(b, column);

        let comparison = 0;
        if (aValue > bValue) {
          comparison = 1;
        } else if (aValue < bValue) {
          comparison = -1;
        }

        return this.sortAsc ? comparison : -comparison;
      });
    } else {
      this.accountManagerList.sort((a: any, b: any) => {
        const aValue = a[column];
        const bValue = b[column];

        let comparison = 0;
        if (aValue > bValue) {
          comparison = 1;
        } else if (aValue < bValue) {
          comparison = -1;
        }

        return this.sortAsc ? comparison : -comparison;
      });
    }

  }
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
}
