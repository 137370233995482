import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MrrService } from 'src/app/services/mrr.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import { stateList } from '../../../../state';
@Component({
  selector: 'app-partner-report',
  templateUrl: './partner-report.component.html',
  styleUrls: ['./partner-report.component.scss']
})
export class PartnerReportComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  activation = 'mrr'
  hideSubDateRange: boolean = false
  partnerReportData: any
  accountManagerList: any
  currentMonthYear: any
  isSorting: boolean = false;
  isShowDetails: boolean = false
  allSelected = false;
  mrrDetail: any
  totals: any
  totalPartnerSubs: any
  stateList: any = stateList
  partnerSupscriptionReport: any
  sortColumn: string = '';
  sortAsc: boolean = true;
  fileName = 'MRR_Details.xlsx';
  zones: any = [
    { name: "East Zone", value: "eastZone" },
    { name: "West Zone", value: "westZone" },
    { name: "North Zone", value: "northZone" },
    { name: "South Zone", value: "southZone" },
    { name: "Overseas", value: "overseas" }
  ]
  searchForm = new FormGroup({
    accountManagerIds: new FormControl([]),
    searchText: new FormControl(''),
    searchText1: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    state: new FormControl([]),
    zone: new FormControl([]),
    date: new FormControl('')
  })
  constructor(
    private mrrService: MrrService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private authService: AuthService
  ) { }


  ngOnInit(): void {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substring(0, 7); // Format as 'YYYY-MM'
    this.searchForm.get('date')?.setValue(formattedDate);
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    this.accountManagerLists()
    this.partnerMrrList()

    this.searchForm.get('date')?.valueChanges.subscribe((value: any) => {
      this.isShowDetails = false
      this.partnerMrrList()
    })
  }
  toggle(activation: any) {
    this.activation = activation;
    if (activation == 'subcription') {
      this.isShowDetails = false
      this.hideSubDateRange = true
      this.searchForm.controls.accountManagerIds.reset()
      this.partnerSubscriptionReport()
    }
    else {
      this.hideSubDateRange = false
    }
  }
  accountManagerLists() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accountManagers
      }
    })
  }
  partnerMrrList() {
    const month = this.searchForm.get('date')?.value
    const accountManagerIds = this.searchForm.get('accountManagerIds')?.value
    const mrrpayload = {
      month: month,
      accountManagerIds: accountManagerIds
    }
    this.mrrService.partnerMrrReport(mrrpayload).subscribe((res: any) => {
      if (res.success) {
        this.partnerReportData = res?.partnerMrrReport
        this.totals = res?.totals
      } else {
        this.toastr.info(res.message)
        this.partnerReportData = []
        this.totals = {}
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }

  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }
  get searchText1() {
    return this.searchForm.controls.searchText1.value || '';
  }


  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allSelected) {
          item.select();
        }
        else {
          item.deselect()
        }
      });
    }
  }
  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;
    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allSelected = newStatus;
    }
  }
  mrrDetails(partnerId: string, billingType: string) {
    this.isShowDetails = true
    setTimeout(() => {
      if (this.scrollContainer && this.scrollContainer.nativeElement) {
        this.scrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    })
    const mrrDetailsPlayload = {
      partnerIds: [partnerId],
      month: moment(this.searchForm.get('date')?.value).format("MMM YYYY"),
      billingType: billingType,
    }
    this.mrrService.mrrDetails(mrrDetailsPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrDetail = res?.mrrDetails
      }
    })
  }

  exportexcel(): void {
    let excelData
    if (this.hideSubDateRange) {
      excelData = this.partnerSupscriptionReport.map((dataObj: any) => {
        return {
          "Account Manager": dataObj?.accountManager || '',
          "Company Name": dataObj?.companyName || '',
          "State": dataObj?.state || '',
          "Partner Since": dataObj?.partnerSince || '',
          "Total Subscriptions": dataObj?.totalPaidSub?.totalAmount || 0,
          "New Subscriptions": dataObj?.newPaidSubs?.totalAmount || 0,
          "Total Renewals": dataObj?.totalRenewalsReport?.totalAmount || 0,
          "Renewed": dataObj?.renewedReport?.totalAmount || 0,
          "Upgrades": dataObj?.upgradeReport?.totalAmount || 0,
          "Downgrade": dataObj?.downgradeReport?.totalAmount || 0,
          "Upcoming Renewals": dataObj?.upcomingRenewalsReport?.totalAmount || 0,
          "Expired": dataObj?.expiredReport?.totalAmount || 0,
          "Deleted": dataObj?.deletedReport?.totalAmount || 0,
          "Risky Customers Value": dataObj?.riskySubsReport?.totalAmount || 0,
          "Trial Signups": dataObj?.totalTrialReport?.totalAmount || 0,
          "Trial Expired": dataObj?.expiredTrialReport?.totalAmount || 0,
          "Trial to Paid": dataObj?.trialToPaidReport?.totalAmount || 0,
          "Live Trial": dataObj?.liveTrialReport?.totalAmount || 0,
          "Current Wallet": dataObj?.totalCurrentWallet || 0,
          "Wallet Purchased": dataObj?.totalWalletPurchased || 0,
          "Wallet Consumed": dataObj?.totalWalletConsumed || 0,
        }
      })
    } else {
      excelData = this.partnerReportData.map((dataObj: any) => {
        return {
          "Company Name": dataObj?.companyName || '',
          "Account Manager": dataObj?.accountManager || '',
          "New Business MRR": dataObj?.netNewBiz || 0,
          "Expansion MRR ": dataObj?.netUpgrade || 0,
          "Contraction MRR": dataObj?.netDowngrade || 0,
          "Churn MRR": dataObj?.netChurn || 0,
          "Reactivation MRR": dataObj?.netReactivation || 0,
          "Net Churn MRR": dataObj?.netNetChurn || 0,
          "Net MRR Movement": dataObj?.netMrrMovement || 0,
          "Current MRR": dataObj?.currentMrr || 0,
          "Previous MRR": dataObj?.prevMonthMrr || 0,
          "Change": dataObj?.percentageChange || 0,
        }
      })
    }

    const jsonSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, jsonSheet, 'JSON_Sheet');  // Changed sheet name to 'JSON_Sheet'
    XLSX.writeFile(wb, this.hideSubDateRange ? 'partner_subscription_Mrr.xlsx' : 'partner_Mrr.xlsx');
  }

  exportExcelMrrDetails() {
    const mrrDetail = document.getElementById('mrr-detail-table')
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(mrrDetail);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }
  get userType() {
    return this.authService.userType()
  }

  partnerSubscriptionReport() {
    const payload = {
      startDate: this.searchForm.get('startDate')?.value,
      endDate: this.searchForm.get('endDate')?.value,
      accountManagerIds: this.searchForm.get('accountManagerIds')?.value,
      state: this.searchForm.get('state')?.value,
      zone: this.searchForm.get('zone')?.value,
    }

    this.mrrService.partnerSubscriptionReport(payload).subscribe((res: any) => {
      if (res.success) {
        this.partnerSupscriptionReport = res?.partnerDetailsData
        this.totalPartnerSubs = res?.totals
      }
    })
  }
  onButtonClick() {
    if (this.hideSubDateRange) {
      this.isShowDetails = false
      this.partnerSubscriptionReport()
    }
    else {
      this.isShowDetails = false
      this.partnerMrrList()
    }
  }

  get userRole() {
    return this.authService.userRole()
  }
  sorting(column: string): void {
    if (this.sortColumn === column) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortColumn = column;
      this.sortAsc = true;
    }
    if (this.hideSubDateRange) {
      this.partnerSupscriptionReport.sort((a: any, b: any) => {
        const aValue = this.getNestedValue(a, column);
        const bValue = this.getNestedValue(b, column);

        let comparison = 0;
        if (aValue > bValue) {
          comparison = 1;
        } else if (aValue < bValue) {
          comparison = -1;
        }

        return this.sortAsc ? comparison : -comparison;
      });
    } else {
      this.partnerReportData.sort((a: any, b: any) => {
        const aValue = a[column];
        const bValue = b[column];

        let comparison = 0;
        if (aValue > bValue) {
          comparison = 1;
        } else if (aValue < bValue) {
          comparison = -1;
        }

        return this.sortAsc ? comparison : -comparison;
      });
    }

  }
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
}
