<!-- ----------partner---details----page ---- -->
<div class="main-panel">
 <div class="content-wrapper">
  <div class="row">
   <div class="col-md-12">
    <div class="row mb-4">
     <div class="col-12 col-xl-8 mb-xl-0 heading-section">
      <h3 class="font-weight-bold">
       <i class="bi bi-arrow-left" [routerLink]="'/reseller'"></i> Reseller Details:
      </h3>
     </div>

     <div class="col-md-12">
      <ul class="list-group">
       <li class="list-group-item">Name<span class="float-end">{{partner?.name}}</span></li>
       <li class="list-group-item">Company Name<span class="float-end">{{partner?.companyName}}</span></li>
       <li class="list-group-item">Mobile No.<span class="float-end">{{partner?.mobile}}</span>
       </li>
       <li class="list-group-item">Email<span class="float-end">{{partner?.email}}</span></li>
       <li class="list-group-item">Created by<span class="float-end">{{partner?.createdBy}}</span>
       </li>
       <li class="list-group-item">Wallet Amount<span class="float-end">&#8377;
         {{partner?.wallet}}</span>
       </li>
      </ul>
     </div>
     <!-- -----Plan-Details----- -->
     <div class="col-md-12 mt-3">
      <h4 class="font-weight-bold mb-3">Plan-Details</h4>
     </div>
     <div class="col-md-12">
      <ul class="list-group">
       <li class="list-group-item">Account <span class="float-end">0</span></li>
       <li class="list-group-item">In Confirmed <span class="float-end">Yes</span></li>
       <li class="list-group-item">Create Date <span class="float-end">17/11/2022 5:45:38 pm</span>
       </li>
       <li class="list-group-item">Expire Date <span class="float-end">17/11/2022 5:45:38 pm</span>
       </li>
       <li class="list-group-item">Windows Build No. <span class="float-end">**********</span></li>
      </ul>
     </div>
     <!-- ---------- -->
     <!-- ----Service-Details------ -->
     <div class="col-md-12 mt-3">
      <h4 class="font-weight-bold mb-3">Service-Details</h4>
     </div>
     <div class="col-md-12">
      <ul class="list-group">
       <li class="list-group-item">Account <span class="float-end">0</span></li>
       <li class="list-group-item">In Confirmed <span class="float-end">Yes</span></li>
      </ul>
     </div>
     <div class="col-md-12 mt-3 text-end">
      <button type="button" class="comman-btn1 btn-primary text-light me-2" (click)="editPartner(partner?._id)">Edit
       details</button>
      <button type="button" class="comman-btn1 btn-secondary text-light" routerLink="/account"
       [routerLink]="['/reseller']">Cancel</button>
     </div>
     <!-- ---------- -->
    </div>
   </div>
  </div>
 </div>
</div>
<!-- ------end-------- -->