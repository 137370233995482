import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { labelListRoot } from 'src/app/interfaces/label';
import { LabelService } from 'src/app/services/label.service';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { AddLabelModalComponent } from '../add-label-modal/add-label-modal.component';

@Component({
  selector: 'app-manage-label',
  templateUrl: './manage-label.component.html',
  styleUrls: ['./manage-label.component.scss']
})
export class ManageLabelComponent implements OnInit {

  labels: any = [];
  bsModalRef!: BsModalRef;
  labelSearchText: string;

  constructor(
    private labelService: LabelService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.labelList();
  }


  labelList() {
    this.labelService.labelList().subscribe((res: labelListRoot) => {
      if (res.success) {
        this.labels = res.labels;
      }
    })
  }

  openModal(labelId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.labelId = labelId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.labelList();
      }
      else {
        this.labelList();
      }
    })
  }


  editLabel(labelId: string) {
    this.bsModalRef = this.modalService.show(AddLabelModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.labelId = labelId;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.labelList();
      }
      else {
        this.labelList();
      }
    })
  }

  labelModal() {
    this.bsModalRef = this.modalService.show(AddLabelModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.labelList();
      }
      else {
        this.labelList();
      }
    })
  }

  labelSearch() {
    this.labelService.searchList(this.labelSearchText).subscribe((res: labelListRoot) => {
      if (res.success) {
        this.labels = res.labels;
      }
    })
  }

}
