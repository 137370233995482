<!-- -------logout----modal--- -->
<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0"><b>Log out?</b></h4>
</div>
<div class="modal-body text-center">
    <h4>Are you sure you want to Log out?</h4>
</div>
<div class="text-end border-0">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="logout()">Log out</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
</div>
<!-- --------------end--------- -->
