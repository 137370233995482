import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddPartnerRoot, DeletePartner, PartnerDetailRoot, RootPartner } from '../interfaces/partner';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private http: HttpClient
  ) { }

  partnerList(page: number, limit: number, sortValue: string) {
    return this.http.get<RootPartner>(`${environment.apiURL}/admin/partner?page=${page}&limit=${limit}&sort=${sortValue}`);
  }

  partnerReqList(page: number, limit: number, sortValue: string) {
    return this.http.get<RootPartner>(`${environment.apiURL}/admin/partnerReq?page=${page}&limit=${limit}&sort=${sortValue}`);
  }

  updateStatus(payload: any) {
    return this.http.put(`${environment.apiURL}/admin/partnerReq`, payload)
  }

  getPartnerDetails(reqPartnerId:any) {
    return this.http.get(`${environment.apiURL}/admin/partnerReqById?partnerId=${reqPartnerId}`)
  }

  searchList(searchPayload: any, page: number, limit: number, sortValue: string) {
    return this.http.get<RootPartner>(`${environment.apiURL}/admin/partner?search=${searchPayload}&page=${page}&limit=${limit}&sort=${sortValue}`);
  }

  createPartner(partnerPayload: any) {
    return this.http.post<AddPartnerRoot>(`${environment.apiURL}/admin/partner`, partnerPayload)
  }

  partnerDetails(partnerId: any) {
    return this.http.get<PartnerDetailRoot>(`${environment.apiURL}/admin/partner/details?partnerId=${partnerId}`)
  }

  updatePartner(updatePayload: any) {
    return this.http.put<AddPartnerRoot>(`${environment.apiURL}/admin/partner`, updatePayload)
  }

  delete(partnerId: string) {
    return this.http.delete<DeletePartner>(`${environment.apiURL}/admin/partner?partnerId=${partnerId}`)
  }

  loginToPartner(payload: any) {
    return this.http.post(`${environment.apiURL}/admin/partner/login`, payload)
  }

  allPartnerList() {
    return this.http.get(`${environment.apiURL}/admin/partner/all`)
  }
}
