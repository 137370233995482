import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(
    private http: HttpClient
  ) { }


  addDiscount(discountPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/discount`, discountPayload)
  }

  discountList(page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/admin/discount?page=${page}&limit=${limit}`)
  }

  discountDetail(subId: string) {
    return this.http.get(`${environment.apiURL}/admin/discount/detail?subCode=${subId}`)
  }

  updateDiscount(discountPayload: any) {
    return this.http.put(`${environment.apiURL}/admin/discount`, discountPayload)
  }

  deleteDiscount(subId: string, remark: string) {
    const remarks = JSON.stringify(remark)
    return this.http.delete(`${environment.apiURL}/admin/discount?subCode=${subId}&remark=${remarks}`)
  }

  createCoupon(couponPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/create/coupon`, couponPayload)
  }

  couponList(page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/admin/coupon?page=${page}&limit=${limit}`)
  }

  deleteCoupon(couponId: any) {
    return this.http.delete(`${environment.apiURL}/admin/coupon?couponId=${couponId}`)
  }

  couponDetail(couponId: string) {
    return this.http.get(`${environment.apiURL}/admin/coupon/detail?couponId=${couponId}`)
  }

  couponUpdate(couponId: any) {
    return this.http.put(`${environment.apiURL}/admin/coupon`, couponId)
  }

  schemeList(page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/admin/scheme?page=${page}&limit=${limit}`)
  }
  schemeDetail(id: string) {
    return this.http.get(`${environment.apiURL}/admin/scheme/detail?schemeId=${id}`)
  }
  updateScheme(schemePayload: any, schemeId: any) {
    return this.http.put(`${environment.apiURL}/admin/scheme?schemeId=${schemeId}`, schemePayload)
  }
  createScheme(schemePayload: any) {
    return this.http.post(`${environment.apiURL}/admin/scheme`, schemePayload)
  }
  deleteScheme(id: any) {
    return this.http.delete(`${environment.apiURL}/admin/scheme?schemeId=${id}`)
  }
  schemeAppliedPartnerList(payload :any) {
    return this.http.post(`${environment.apiURL}/admin/scheme/partnerList`,payload)
  }

  rewardPartner(payload: any) {
    return this.http.put(`${environment.apiURL}/admin/scheme/sub/restrict`, payload)
  }
}
