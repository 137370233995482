import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/services/product.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from 'src/app/services/plan.service';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { AddOnService } from 'src/app/services/add-on.service';
import { PlanRoot } from 'src/app/interfaces/plan';
import { AddOnRoot } from 'src/app/interfaces/addOn';
import { CreateProductRoot, UpdateAndDeleteProduct } from 'src/app/interfaces/product';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  isEdit: boolean = false;
  isAddonAvailable: boolean = false;
  isPlanAvailable: boolean = false;
  productId: string = '';
  plans: any = [];
  addOns: any = [];

  productForm = new FormGroup({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    description: new FormControl(''),
  })

  bsModalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private productService: ProductService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toasts: ToastrService,
    private planService: PlanService,
    private addOnService: AddOnService
  ) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe(data => {
      this.productId = data['productId'];
    })
    if (this.productId) {
      this.isEdit = true;
      this.productDetails(this.productId);
      this.planList()
      this.addOnList()
    }
  }

  submit() {
    if (!this.productForm.valid) {
      this.productForm.markAllAsTouched();
    }
    else {
      this.updateProduct();
    }
  }

  planList() {
    this.planService.planList(this.productId).subscribe((res: PlanRoot) => {
      if (res.success) {
        this.plans = res.plans;
        this.isPlanAvailable = true;
      }
    },
      (error) => {
        this.plans = [];
      })
  }

  addOnList() {
    this.addOnService.addOnList(this.productId).subscribe((res: AddOnRoot) => {
      if (res.success) {
        this.addOns = res.addOns;
        this.isAddonAvailable = true;
      }
    },
      (error) => {
        this.addOns = [];
      })
  }

  productDetails(productId: string) {
    this.productService.productDetail(productId).subscribe((res: CreateProductRoot) => {
      if (res.success) {
        const product = res?.product
        if (product?.addOns.length === 0 && product?.plans.length === 0) {
          this.toasts.success('You have created product successfully, now create Plans & addOns.')
        }
        else if (product?.addOns.length === 0 || product?.plans.length === 0) {
          let message = ''
          message = (product?.addOns.length > 0) ? 'You have created product successfully, now create Plans.' :
            'You have created product successfully, now create AddOns.'
          this.toasts.success(message)
        }


        this.productForm.patchValue(res.product);
      }
    })
  }

  updateProduct() {
    let updatePayload = {}
    updatePayload = Object.assign(this.productForm.value, { productId: this.productId })
    this.productService.editProduct(updatePayload).subscribe((res: UpdateAndDeleteProduct) => {
      if (res.success) {
        this.toasts.success(res.message);
        this.router.navigate(['product']);
      }
      else {
        this.toasts.warning(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      })
  }

  plan() {
    this.router.navigate(['product/new-plan'], { queryParams: { productId: this.productId } })
  }

  navigateToAddOn() {
    this.router.navigate(['product/add-addon'], { queryParams: { productId: this.productId } })
  }

  editPlan(planId: string) {
    this.router.navigate(['product/new-plan'], { queryParams: { productId: this.productId, planId: planId } })
  }

  editAddOns(addOnId: string) {
    this.router.navigate(['product/add-addon'], { queryParams: { productId: this.productId, addOnId: addOnId } })
  }

  deletePlanModal(planId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.planId = planId;
    this.bsModalRef.content.productId = this.productId
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.planList();
      }
      else {
        this.planList();
      }
    })
  }

  deleteAddOnModal(addOnId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.addOnId = addOnId;
    this.bsModalRef.content.productId = this.productId
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.addOnList();
      }
      else {
        this.addOnList();
      }
    })
  }
}
