import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InstanceRoot } from 'src/app/interfaces/instance';
import { InstanceService } from 'src/app/services/instance.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { NewProductComponent } from '../modals/new-product/new-product.component';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.scss']
})
export class InstanceComponent implements OnInit {

  selectedInstanceType: string = 'live'
  instances: any = [];
  totalCount: number = 0
  page: number = 1
  isInstanceAvailable: boolean = false
  searchText: string = ''
  bsModalRef!: BsModalRef
  search$ = new Subject<void>()
  searchSubscription$: Subscription
  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  searchForm = new FormGroup({
    expiryFrom: new FormControl(''),
    expiryTo: new FormControl('')
  })

  constructor(
    private instanceService: InstanceService,
    private toasts: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.instanceList();
    this.searchSubscription$ = this.search$.pipe(debounceTime(300)).subscribe(() => {
      this.searchList()
    })
  }

  instanceList() {
    this.instanceService.instance(this.config.currentPage, this.config.itemsPerPage, this.selectedInstanceType).subscribe((res: InstanceRoot) => {
      if (res.success) {
        this.instances = res.instances;
        this.isInstanceAvailable = true
        this.config.currentPage = res?.totalInstance?.pages;
        this.config.totalItems = res?.totalInstance?.total;
      }
    },
      (error: any) => {
        this.instances = []
        this.isInstanceAvailable = false
        this.config.currentPage = 1
        this.config.totalItems = 0
        this.toasts.error(error.error.message);
      })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    if (this.searchForm.value.expiryFrom) {
      this.searchBasedOnExpiryDate()
    }
    else if (this.searchText) {
      this.searchList()
    }
    else {
      this.instanceList()
    }

  }

  searchInstance(event: any) {
    this.searchText = event.target.value
    this.config.currentPage = 1;
    this.search$.next();
  }

  searchList() {
    this.instanceService.instanceSearchList(this.searchText, this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success) {
        if (res.instances.length === 0) {
          this.toasts.error('No Data Found.')
          this.isInstanceAvailable = false
        }
        else {
          this.instances = res.instances;
          this.isInstanceAvailable = true
          this.config.currentPage = res?.totalInstance?.pages;
          this.config.totalItems = res?.totalInstance?.total;
        }
      }
    },
      (error) => {
        this.isInstanceAvailable = false
        this.config.currentPage = 1
        this.config.totalItems = 0
        this.toasts.error(error.error.message)
      })
  }

  searchBasedOnExpiryDate() {
    const expiryDate = moment(this.searchForm.value.expiryTo).diff(this.searchForm.value.expiryFrom, 'days');
    if (expiryDate < 0) {
      this.toasts.warning('The expiry date from is greater than expiry date to.')
    }
    else {
      this.instanceService.instanceSearch(this.searchForm.value.expiryFrom, this.searchForm.value.expiryTo, this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
        if (res.success) {
          if (res.instances.length === 0) {
            this.toasts.error('No Data Found.')
          }
          else {
            this.instances = res.instances;
            this.config.currentPage = res?.totalInstance?.pages;
            this.config.totalItems = res?.totalInstance?.total;
          }

        }
      },
        (error) => {
          this.isInstanceAvailable = false
          this.config.currentPage = 1
          this.config.totalItems = 0
          this.toasts.error(error.error.message)
        })
    }

  }

  getInstanceStatus(instance: any): string {
    if (instance?.noOfUsers === 0) {
      return 'N/A';
    } else if (instance?.subscription?.isDeleted) {
      return 'Deleted';
    } else if (instance?.trialToPaid) {
      return 'Move To Live';
    } else if (instance?.isTrial) {
      return instance?.subscription?.isExpired ? 'Trial Expired' : 'Trial';
    } else if (instance?.subscription?.isExpired) {
      return 'Expired';
    } else {
      return 'Live';
    }
  }

  getInstanceStatusColor(instance: any): string {
    if (instance?.noOfUsers === 0) {
      return '';
    } else if (instance?.subscription?.isDeleted) {
      return 'text-danger';
    } else if (instance?.trialToPaid) {
      return 'text-success';
    } else if (instance?.isTrial) {
      return 'text-danger';
    } else if (instance?.subscription?.isExpired) {
      return 'text-danger';
    } else {
      return 'text-success';
    }
  }

  reset() {
    this.searchForm.reset();
    this.config.currentPage = 1;
    (document.getElementById('searchField') as HTMLInputElement).value = ''
    this.instanceList()
  }
  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
  }
  changeDestination(){
    this.bsModalRef = this.modalService.show(NewProductComponent,{
      class : 'modal-md',
      backdrop : 'static'
    })
    this.bsModalRef.content.serverName = 'serverName'
    // this.bsModalRef.content.drive = drive
    // this.bsModalRef.content.oldInstanceId = instanceId
    this.bsModalRef.content.event.subscribe((res:any)=>{
      if(res == 'ok'){
        this.instanceList()
      }
    })
  }
}
