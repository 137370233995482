import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { AdminRoot } from 'src/app/interfaces/admin';
import { AddPartnerRoot, PartnerDetailRoot } from 'src/app/interfaces/partner';
import { ProductService } from 'src/app/services/product.service';
import { stateList } from '../../../../../state';
import { countryList } from '../../../../../country-code';
import { BillingService } from 'src/app/services/billing.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-add-reseller',
  templateUrl: './add-reseller.component.html',
  styleUrls: ['./add-reseller.component.scss']
})
export class AddResellerComponent implements OnInit {
  @ViewChild('editableDiv') editableDivRef!: ElementRef;
  isEditInput1: boolean = false;
  isEditInput2: boolean = false;
  isEditInput3: boolean = false;
  isEditInput4: boolean = false;
  isEditInput5: boolean = false;
  isEditInput6: boolean = false;
  isEditInput7: boolean = false;
  partnerId = '';
  isEdit = false;
  ZoneList: any[] = []
  accountManagerList: any;
  partners: any = [];
  choosePlan: any = [];
  passType: string = 'password';
  confirmPass: string = 'password';
  show_eye: Boolean = false;
  show_eye1: Boolean = false;
  plans: any;
  isSuperAdmin: any
  isAccountManager: any
  newPlan: any
  selectedPlans: any[] = [];
  noOfSelectedPlan: number = 0
  stateList: any = stateList
  countryList: any = countryList
  isOverseas: boolean = false
  trialPlanIds: any = []
  selectedPlanId: string | null = null;
  isEditCol: boolean = false
  reqPartnerId: any
  reqPartnerDetails: any


  zones: any = [
    { name: "East Zone", value: "eastZone" },
    { name: "West Zone", value: "westZone" },
    { name: "North Zone", value: "northZone" },
    { name: "South Zone", value: "southZone" },
    { name: "Overseas", value: "overseas" }
  ]
  validPinCode: boolean = false
  PartnerCategory: any = [
    { name: "5 Star", value: "5_star" },
    { name: "3 Star", value: "3_star" },
    { name: "Associate Partner", value: "associate_partner" },
    { name: "Marg ", value: "marg " },
    { name: 'Busy', value: 'busy' },
    { name: 'Others', value: 'others' },
  ]
  addResellerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.minLength(8), Validators.required,]),
    partnerType: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.minLength(6), Validators.required]),
    confirmPassword: new FormControl('', [Validators.minLength(6), Validators.required]),
    accountManagerId: new FormControl('', [Validators.required]),
    minOrderValue: new FormControl('50000', [Validators.required]),
    maxTrialLimit: new FormControl('3', [Validators.required]),
    plans: new FormControl<string[]>([], [Validators.required]),
    partnerCustomerLogin: new FormControl(''),
    country: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.maxLength(6), Validators.required]),
    district: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    zone: new FormControl('', [Validators.required]),
    category: new FormControl(''),
    pipeDriveUrl: new FormControl(''),
    proactive: new FormControl(''),
    salesTeamSize: new FormControl(''),
    supportTeamSize: new FormControl(''),
  })


  constructor(
    private partnerService: PartnerService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toasts: ToastrService,
    private adminService: AdminService,
    private productService: ProductService,
    private billingService: BillingService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = localStorage.getItem('role') === 'super admin'
    this.isAccountManager = localStorage.getItem('role') === 'salesManager'
    this.reqPartnerId = this.activeRoute.snapshot.queryParamMap.get('partnerReqId') || ''
    if (this.reqPartnerId) {
      this.getReqPartnerDetails()
    }
    this.addResellerForm.get('country')?.valueChanges.subscribe(value => {
      if (value != 'India') {
        this.isOverseas = true
        this.addResellerForm.controls.zone.setValue('overseas')
        this.addResellerForm.controls.pincode.setValue('')
        this.addResellerForm.controls.district.setValue('')
        this.addResellerForm.controls.state.setValue('')
        this.addResellerForm.controls.pincode.clearValidators()
        this.addResellerForm.controls.pincode.setValidators([Validators.required])
        this.addResellerForm.controls.pincode.updateValueAndValidity()
      } else {
        this.isOverseas = false
        this.addResellerForm.controls.zone.setValue('')
        this.addResellerForm.controls.pincode.setValue('')
        this.addResellerForm.controls.district.setValue('')
        this.addResellerForm.controls.state.setValue('')
        this.addResellerForm.controls.pincode.clearValidators()
        this.addResellerForm.controls.pincode.setValidators([Validators.maxLength(6), Validators.required])
        this.addResellerForm.controls.pincode.updateValueAndValidity()
      }
    });

    this.planList()
    this.accountManagerLists();
    this.activeRoute.queryParams.subscribe(data => {
      this.partnerId = data['partnerId'];
    })
    if (this.partnerId) {
      this.isEdit = true;
      this.isEditInput1 = true;
      this.isEditInput2 = true;
      this.isEditInput3 = true;
      this.isEditInput4 = true;
      this.isEditInput5 = true;
      this.isEditInput6 = true;
      this.isEditInput7 = true;
      this.addResellerForm.get('password')?.clearValidators()
      this.addResellerForm.get('confirmPassword')?.clearValidators()
      this.addResellerForm.controls.password.updateValueAndValidity()
      this.addResellerForm.controls.confirmPassword.updateValueAndValidity()

    }
  }


  toggleEdit(itemId: string) {

    const editableDiv = document.getElementById('editableDiv_' + itemId);
    if (!editableDiv) return;  // Exit if element not found

    const plan = this.plans.find((plan: any) => plan._id === itemId);
    if (!plan || plan.price === 0) return;

    editableDiv.contentEditable = editableDiv.contentEditable = 'true'
    if (editableDiv?.contentEditable === 'true') {
      editableDiv.focus();
    }
    if (this.selectedPlanId === itemId) {
      this.selectedPlanId = null;
    } else {
      this.selectedPlanId = itemId;
    }
  }

  togglePlanSelection(planId: string, price: number) {
    const plansControl = this.addResellerForm.get('plans')
    const selectedPlans = plansControl?.value;

    if (selectedPlans?.includes(planId)) {
      this.noOfSelectedPlan--
      const index = selectedPlans.indexOf(planId);
      const objIndex = this.selectedPlans.findIndex(plan => plan.id === planId);
      this.selectedPlans.splice(objIndex, 1);
      selectedPlans.splice(index, 1);
    } else {
      this.noOfSelectedPlan++
      selectedPlans?.push(planId);
      this.selectedPlans.push({ id: planId, price: price, previousChange: false })
    }
    this.addResellerForm.controls.plans.patchValue(selectedPlans || [])
  }


  isPlanSelected(planId: string) {
    const selectedPlanIds = this.addResellerForm?.get('plans')?.value;
    return selectedPlanIds?.includes(planId);
  }

  onFieldChange(event: any, planId: any, price: number) {
    const plansControl = this.addResellerForm.get('plans')
    const selectedPlans = plansControl?.value
    if (selectedPlans?.includes(planId)) {
      this.selectedPlans.forEach((selectedPlan: any) => {
        if (selectedPlan.id === planId) {
          if (event.target.textContent) {
            const newPrice = Number(event.target.textContent);
            selectedPlan.price = newPrice;
          }
          if (event.target.checked || event.target.checked == false) {
            selectedPlan.previousChange = event.target.checked
          }
        }
      })
    } else {
      event.target.innerText = price.toString()
      this.toasts.warning('please select plan before editing the price')
    }
  }


  accountManagerLists() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accountManagers
      }
    })
  }

  planList() {
    this.productService.productList().subscribe((res: any) => {
      if (res.success) {
        const [firstProduct, secondProduct, thirdProduct] = res.products;
        this.plans = [...firstProduct?.plans, ...secondProduct?.plans]
        this.trialPlanIds = firstProduct?.plans.map((plan: any) => plan._id)
        this.newPlan = thirdProduct ? thirdProduct.plans?.map((plan: any) => { return { id: plan._id, price: plan.price, previousChange: false } }) : []
      }
      if (this.isEdit) {
        this.partnerDetails();
      }
    })
  }

  submit() {
    if (!this.addResellerForm.valid) {
      this.addResellerForm.markAllAsTouched();
    }
    else if (this.noOfSelectedPlan > 0) {
      const selectedPlan = this.selectedPlans
      const selectedPlanIds = selectedPlan.map((plan: any) => plan.id)
      const trialPlanSelected = this.trialPlanIds.some((id: string) => selectedPlanIds.includes(id));
      const verifyPlanPrice = selectedPlan.every((plan: any) => {
        const trialPlan = this.trialPlanIds.some((id: string) => plan.id === id);
        if (trialPlan && plan.price === 0) {
          return true
        } else if (!trialPlan && plan.price > 100) {
          return true
        } else {
          return false
        }
      })
      if (!verifyPlanPrice) {
        this.toasts.warning('Please enter the correct plan price.')
      }
      else if (trialPlanSelected) {
        this.isEdit ? this.updatePartner() : this.addPartner()
      } else {
        this.toasts.warning('Please select atleast one trial plan.')
      }

    } else {
      this.toasts.warning('Please select atleast one plan.')
    }
  }

  addPartner() {
    const matchPassword = (this.addResellerForm.value.password != this.addResellerForm.value.confirmPassword)
    if (matchPassword) {
      this.toasts.warning('Password not matched');
    }
    else {
      let partnerPayload = this.addResellerForm.value
      delete partnerPayload['confirmPassword'];
      partnerPayload.plans = this.selectedPlans
      this.partnerService.createPartner(partnerPayload).subscribe((res: AddPartnerRoot) => {
        if (res.success) {
          this.router.navigate(['reseller']);
          this.toasts.success(res.message);
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

  }

  partnerDetails() {
    this.partnerService.partnerDetails(this.partnerId).subscribe((res: any) => {
      if (res.success) {
        this.addResellerForm.patchValue(res.partner);
        const partnerPlans = res.partner.products.flatMap((product: any) => product.plans);
        const plansCode = partnerPlans.reduce((acc: any, plan: any) => plan.isShow ? [...acc, plan.code] : acc, []);
        this.noOfSelectedPlan = plansCode.length;

        this.plans = this.plans.map((plan: any) => {
          const partnerPlan = partnerPlans.find((p: any) => p.code === plan.code);
          return { ...plan, price: partnerPlan?.price || plan?.price };
        });

        this.selectedPlans = this.plans
          .filter((plan: any) => plansCode.includes(plan.code))
          .map((plan: any) => ({ id: plan._id, price: plan.price, previousChange: false }));

        this.addResellerForm.controls.plans.setValue(this.selectedPlans.map((plan) => plan.id));
      }
    });
  }

  updatePartner() {
    let updatePayload = this.addResellerForm.value
    updatePayload.plans = [...this.selectedPlans]
    updatePayload.plans?.push(...this.newPlan)
    updatePayload = Object.assign(updatePayload, { partnerId: this.partnerId })
    this.partnerService.updatePartner(updatePayload).subscribe((res: AddPartnerRoot) => {
      if (res.success) {
        this.toasts.success(res.message);
        this.router.navigate(['reseller']);
      }
      else {
        this.toasts.warning(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      })
  }

  changePasswordType(type: string) {
    if (type === 'pass') {
      this.show_eye = !this.show_eye;
      this.passType = this.passType === 'password' ? 'text' : 'password';
    }
    if (type === 'confirmPass') {
      this.show_eye1 = !this.show_eye1;
      this.confirmPass = this.confirmPass === 'password' ? 'text' : 'password';
    }
  }
  onPincodeInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length === 6 && !this.isOverseas) {
      event.target.value = inputValue.slice(0, 6);
      this.getPincode(event.target.value)
    }
  }

  getPincode(pincode: any) {
    this.billingService.pincode(pincode).subscribe((res: any) => {
      if (res.success) {
        this.validPinCode = true
        this.addResellerForm.controls.district.setValue(res.pincodeDetail.District)
        this.addResellerForm.controls.state.setValue(res.pincodeDetail.State)
        this.addResellerForm.controls.zone.setValue(res.pincodeDetail.zone)
      }
    })
  }
  cancel() {
    this.addResellerForm.reset();
    this.router.navigate(['reseller']);
  }

  getReqPartnerDetails() {
    this.partnerService.getPartnerDetails(this.reqPartnerId).subscribe((res: any) => {
      if (res.success) {
        this.addResellerForm.patchValue({
          ...res?.partner, confirmPassword: res?.partner.password,
        });

      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
    })
  }

}
