<!-- ---------instance------page----- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row ">
        <h3 class="font-weight-bold heading"><b>Subscription Details</b></h3>
      </div>
      <!-- --------form-------- -->
      <div class="col-md-12">
        <div class="search-form">
          <p class="light mb-0" type="button" aria-expanded="true" aria-controls="collapseExample">
            <i class="bi bi-search"></i>
            <span class="ms-1">Search here</span>
          </p>
          <div class=" mt-3">
            <form class="row g-3" [formGroup]="searchForm">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Sub Id</span>
                  <input type="text" class="form-control" formControlName="subCode" aria-label="Search">
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Admin User Email</span>
                  <input type="text" class="form-control" formControlName="customerEmail" aria-label="Search">
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Cloud User Email</span>
                  <input type="text" class="form-control" formControlName="cloudUserEmail" aria-label="Search">
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Cloud User Name</span>
                  <input type="text" class="form-control" formControlName="cloudUserName" aria-label="Search">
                </div>
              </div>

              <div class="col-md-12 mb-4 text-end">
                <button type="submit" class="comman-btn1 btn-primary text-light me-2" (click)="submit()">
                  Search</button>
                <button type="button" class="comman-btn1 btn-secondary text-light" (click)="resetForm()">Reset</button>
              </div>
            </form>
            <!-- ------ -->
          </div>
        </div>
      </div>
      <!-- -----end----- -->
      <!-- --------table-section---------- -->
      <div class="row table-section mt-4">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="mt-1 img-class mb-4" *ngIf="!showData">
                <div class='d-flex justify-content-center align-items-center'>
                  <img src="../../../assets/images/cloud_not_found.png" class="logo" alt="" />
                </div>
                <p class="error-msg">Too see the data search on any field !</p>
              </div>
              <div class="table-responsive" *ngIf="showData">
                <table class="table  table-borderless">
                  <thead class="roundedTable thead-header">
                    <tr>
                      <th scope="col">Sub ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Stage</th>
                      <th scope="col">Admin Email</th>
                      <th scope="col" *ngIf="userType">Customer Company Name</th>
                      <th scope="col">Partner Company</th>
                      <th scope="col">Partner Email</th>
                      <th scope="col">Number Of User</th>
                      <th scope="col">Instance User Email Id</th>
                      <th scope="col">Instance User Name</th>
                      <th scope="col" *ngIf="userType">Instance Password</th>
                      <th scope="col">Plan Name</th>
                      <th scope="col" *ngIf="userType">Start Date</th>
                      <th scope="col" *ngIf="userType">Next Billing Date</th>
                      <th scope="col" *ngIf="userType">Last Billing Date</th>
                      <th scope="col" *ngIf="!userType">Salesman</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let subdata of instanceUsers">
                      <td><a class="subCode" (click)="instanceUserDetails(subdata?.code)">{{subdata?.code}}</a></td>
                      <td [ngClass]="[subdata.isActive? 'text-success': 'text-danger']">{{subdata.isActive? 'Active':
                        'InActive'}}</td>
                      <td [ngClass]="[subdata.isExpired? 'text-danger': 'text-success']">{{subdata.isExpired? 'Expired':
                        'live'}}</td>
                      <td>{{subdata?.customerEmail}}</td>
                      <td *ngIf="userType">{{subdata?.customer?.companyName}}</td>
                      <td>{{subdata?.partner?.companyName}}</td>
                      <td>{{subdata?.partner?.email}}</td>
                      <td>{{subdata?.instance?.noOfUsers}}</td>
                      <td>{{subdata?.instanceUsers[0]?.email}}</td>
                      <td>{{subdata?.instanceUsers[0]?.userName}}</td>
                      <td *ngIf="userType">{{subdata?.instanceUsers[0]?.userKey}}</td>
                      <td>{{subdata?.plan?.name}}</td>
                      <td *ngIf="userType">{{subdata?.startDate |date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="userType">{{subdata?.endDate |date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="userType">{{(subdata?.lastRenewDate===null)?(subdata?.startDate
                        |date:'dd/MM/yyyy'):(subdata?.lastRenewDate |date:'dd/MM/yyyy')}}</td>
                      <td *ngIf="!userType">{{subdata?.salesPerson?.name}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="mt-4 float-end me-4">
                <pagination-controls></pagination-controls>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- ------end----- -->
    </div>
  </div>
</div>