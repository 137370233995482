import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { InstanceUserService } from 'src/app/services/instance-user.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  subCode: string = ''
  searchField: any = {}
  instanceUser: any = []
  constructor(
    readonly instanceUserService: InstanceUserService,
    private activateRoute: ActivatedRoute,
    private location: Location,
    readonly toasts: ToastrService,
    public authService: AuthService

  ) { }

  ngOnInit(): void {
    console.log(this.authService.userType)
    this.location.subscribe(() => {
      const searchQuery = this.searchField;
      const url = `${window.location.pathname}?search=${searchQuery}`;
      window.open(url, '_self')
    });
    this.activateRoute.queryParams.subscribe(data => {
      this.subCode = data['subCode']
      this.searchField = data['search']
    })
    if (this.subCode && this.searchField) {
      this.instanceUserList()
    }
  }

  instanceUserList() {
    this.instanceUserService.instanceUserList(this.searchField).subscribe((res: any) => {
      if (res.success) {
        this.subCode = this.subCode.toUpperCase()
        this.instanceUser = res.instanceUsers.filter((instanceUser: any) => {
          if (instanceUser.code === this.subCode) {
            return instanceUser
          }
        })
      }
    }, (error: any) => {
      this.instanceUser = []
      this.toasts.error(error.error.message);
    })
  }


  get userType() {
    return this.authService.userType()
  }
}
