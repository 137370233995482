<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 grid-margin">
          <div class="row">
            <div class="col-md-4">
              <h3 class="font-weight-bold">Accounts </h3>
              <span class="text-primary">Manage Label</span>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <span class="input-group-text comman-input" id="basic-addon1"><i class="bi bi-search"></i></span>
                <input type="text" class="form-control comman-input" placeholder="Search here.."
                  [(ngModel)]="labelSearchText" (ngModelChange)="labelSearch()" aria-label="Username"
                  aria-describedby="basic-addon1">
              </div>
            </div>
            <div class="col-md-2 text-end">
              <button type="button" class="comman-btn1 btn-primary" (click)="labelModal()">Add Label</button>
            </div>
          </div>
        </div>
        <!-- ------------------ -->
        <div class="row table-section">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <p class="card-title mb-0">Label Details</p>
                <div class="table-responsive">
                  <table class="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Label Name</th>
                        <th scope="col" class="text-center">Number Of Partners</th>
                        <th scope="col">Label Color</th>
                        <th scope="col" class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let label of labels">
                        <td>{{label?.name}}</td>
                        <td class="text-center">{{label?.count}}</td>
                        <td>
                          <div class="lebel-color-data" [ngStyle]="{'background-color': label?.color}">
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="dropdown">
                            <a class="btn px-0 py-0" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <i class="bi bi-three-dots-vertical"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              <li (click)="editLabel(label?._id)"><a class="dropdown-item"><i
                                    class="bi bi-pencil-fill text-primary"></i>
                                  Edit</a></li>
                              <li (click)="openModal(label?._id)"><a class="dropdown-item"><i
                                    class="bi bi-trash3-fill text-danger"></i> Delete</a></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
  </div>