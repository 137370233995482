import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingRoot } from 'src/app/interfaces/settings';
import { SettingsService } from 'src/app/services/settings.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  logo: string = ''
  imageUploadForm = new FormGroup({
    logo: new FormControl('', Validators.required)
  })

  contactForm = new FormGroup({
    sales: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]),
    support: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required])

  })

  constructor(
    private settingService: SettingsService,
    private toasts: ToastrService,
    private walletService: WalletService

  ) { }

  ngOnInit(): void {
    this.settingDetails();
  }

  submitLogo() {
    if (!this.imageUploadForm.valid) {
      this.imageUploadForm.markAllAsTouched()
    }
    else {
      this.uploadImage();
    }
  }

  saveContactDetails() {
    if (!this.contactForm.valid) {
      this.contactForm.markAllAsTouched()
    }
    else {
      this.uploadImage();
    }
  }

  uploadImage() {
    let settingPayload = {}
    if (this.imageUploadForm.value.logo === this.logo) {
      settingPayload = this.contactForm.value
    }
    else {
      settingPayload = this.imageUploadForm.value
    }
    this.settingService.settings(settingPayload).subscribe((res: SettingRoot) => {
      if (res.success) {
        this.walletService.adminProfileDetails();
        this.toasts.success(res.message)
        this.settingDetails();
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      }
    )
  }

  settingDetails() {
    this.settingService.settingsDetail().subscribe((res: SettingRoot) => {
      if (res.success) {
        this.logo = res.admin.logo
        this.contactForm.patchValue(res.admin.contact)
        this.imageUploadForm.patchValue(res.admin)
      }
    })
  }

}
