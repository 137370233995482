import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MrrService } from 'src/app/services/mrr.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { PartnerService } from 'src/app/services/partner.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import * as Highcharts from 'highcharts';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-mrr-report',
  templateUrl: './mrr-report.component.html',
  styleUrls: ['./mrr-report.component.scss']
})
export class MrrReportComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;
  @ViewChild('multiSelect2') multiSelect2!: MatSelect;
  isShowDetails: boolean = false
  mrrReports: any
  mrrGrowths: any
  allSelected = false;
  allPartnerSelected = false;
  mrrGrowthGraph: any
  mrrDetail: any
  accountManagerList: any
  currentUserRole: string = ""
  userId: string = ""
  partnerList: any;
  filterPartnerList: []
  fileName = 'MRR_Details.xlsx';
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  filterdMonths: any[] = []
  Highcharts: typeof Highcharts = Highcharts;
  mrrReportOption: Highcharts.Options = {}
  subUserData: boolean = true
  updateFromInput: boolean = true
  objFromInput: any = {}
  chartType: string = "area"
  searchForm = new FormGroup(
    {
      accountManagerIds: new FormControl<string[]>([]),
      partnerIds: new FormControl<string[]>([]),
      searchText: new FormControl(''),
      searchText1: new FormControl(''),
    }
  )
  constructor(
    private mrrService: MrrService,
    private adminService: AdminService,
    private partnerService: PartnerService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.allPartnerList()
    this.accountManagerLists()
    this.currentUserRole = localStorage.getItem('role') || ''
    this.userId = localStorage.getItem('userId') || ''
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const accountManagerId: string = params['accountManagerId'];
      const partnerId: string = params['partnerId']
      if (accountManagerId) {
        this.subUserData = false
        this.searchForm.get('accountManagerIds')?.setValue([accountManagerId]);
      }
      else if (partnerId) {
        this.searchForm.get('partnerIds')?.setValue([partnerId]);
      } else {
        this.searchForm.reset()
      }
      this.submitAll()
    })
    this.searchForm.controls.accountManagerIds.valueChanges.subscribe((value: any) => {
      this.filterPartner()
    })
  }

  mrrReport() {
    const accountManagerIds = this.searchForm.get('accountManagerIds')?.value
    const partnerIds = this.searchForm.get('partnerIds')?.value
    const mrrReportPlayload = {
      accountManagerIds: accountManagerIds,
      partnerIds: partnerIds,
      subUserData: this.subUserData
    }
    this.mrrService.mrrMovement(mrrReportPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrReports = res?.mrrMovement
      }
    })
  }

  mrrDetails(month: any, billingType: any) {
    this.isShowDetails = true
    setTimeout(() => {
      if (this.scrollContainer && this.scrollContainer.nativeElement) {
        this.scrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    })

    const accountManagerIds = this.searchForm.get('accountManagerIds')?.value
    const partnerIds = this.searchForm.get('partnerIds')?.value
    const mrrDetailsPlayload = {
      month: month,
      billingType: billingType,
      accountManagerIds: accountManagerIds,
      partnerIds: partnerIds,
      subUserData: this.subUserData
    }
    this.mrrService.mrrDetails(mrrDetailsPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrDetail = res?.mrrDetails
      }
    })
  }


  mrrGrowth() {
    const accountManagerIds = this.searchForm.controls.accountManagerIds.value || []
    const partnerIds = this.searchForm.controls.partnerIds.value || []
    const mrrGrowthPlayload = {
      accountManagerIds: accountManagerIds,
      partnerIds: partnerIds,
      subUserData: this.subUserData
    }
    this.mrrService.mrrGrowth(mrrGrowthPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrGrowths = res?.mrrGrowth
        this.setChartVariableForMrr(this.mrrGrowths);
      }
    })

  }
  setChartVariableForMrr(mrrData: any) {
    const objFromInput: any = {};
    objFromInput['credits'] = false;
    objFromInput['chart'] = { type: this.chartType };
    objFromInput['legend'] = { align: 'center', verticalAlign: 'top', lineHeight: 0, padding: 0 };
    objFromInput['title'] = { text: '' };
    objFromInput['subtitle'] = { text: `<b>Monthly Revenue Report<b/>` };
    objFromInput['yAxis'] = {
      title: {
        text: 'MRR values',
      },
    };

    objFromInput['xAxis'] = {
      type: 'datetime',
      title: {
        text: 'Months',
      },
      labels: {
        format: '{value: %b %Y}'
      },
      tickPositioner: function () {
        return this.series[0].xData;
      },
    };

    objFromInput['tooltip'] = {
      formatter: function (): string {
        const point = this.point;
        return `<br/><b>Month :</b> ${point.month}<br/><b>MRR :</b> ${point.value}<br/> <b>Percentage :</b> ${point.changePercentage}%`;
      }
    };

    objFromInput['plotOptions'] = {
      series: {
        borderRadius: 3
      }
    };

    objFromInput['series'] = [
      {
        name: 'MRR',
        data: mrrData.map((data: any) => {
          const x = Date.UTC(new Date(data.month).getFullYear(), new Date(data.month).getMonth(), 1);
          const y = data.value;
          return {
            x,
            y,
            month: data.month,
            value: data.value,
            changePercentage: data.changePercentage
          };
        }),
      }
    ];
    objFromInput['colors'] = ['#4B49AC'];
    this.mrrReportOption = objFromInput;
    Highcharts.chart('mrrReportChart', this.mrrReportOption);
    this.updateFromInput = true;
  }

  accountManagerLists() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accountManagers
      }
    })

  }

  allPartnerList() {
    this.partnerService.allPartnerList().subscribe((res: any) => {
      if (res.success) {
        this.partnerList = res.partners
        this.filterPartnerList = res.partners
        this.filterPartner()
      }
    })
  }

  filterPartner() {
    const accountManagerIds = this.searchForm.controls.accountManagerIds.value || []
    if (accountManagerIds && accountManagerIds.length == 0) {
      this.partnerList = [...this.filterPartnerList];
    } else {
      this.partnerList = this.filterPartnerList.filter((partner: any) => {
        if (accountManagerIds?.some((id: string) => partner.accountManagerId === id)) {
          return partner
        }
      })
    }
  }


  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }
  get searchText1() {
    return this.searchForm.controls.searchText1.value || '';
  }

  submitAll() {
    this.isShowDetails = false
    this.mrrReport()
    this.mrrGrowth()
  }

  toggleChartType(graph: string) {
    this.chartType = graph
    this.mrrReportOption['chart'] = { type: graph }
    Highcharts.chart('mrrReportChart', this.mrrReportOption)
    this.updateFromInput = true
  }

  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allSelected) {
          item.select();
        } else if (id === 2 && this.allPartnerSelected) {
          item.select();
        } else {
          item.deselect();
        }
      });
    }
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;
    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    if (id === 1) {
      this.allSelected = newStatus;
    } else if (id === 2) {
      this.allPartnerSelected = newStatus;
    }
  }

  deselectAll(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1) {
          item.deselect();
          this.allSelected = false
        } else if (id === 2) {
          item.deselect();
        }
      });
    }
  }

  get userRole() {
    return this.authService.userRole()
  }

  exportexcel(): void {
    const mrrDetail = document.getElementById('mrr-detail-table')
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(mrrDetail);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

}
