import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DiscountService } from 'src/app/services/discount.service';
import { ProductService } from 'src/app/services/product.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {
  selectedType: string = 'static'
  showMonthInput: boolean = false;
  isEdit: boolean = false
  allSelected = false;
  couponDetails: any
  selectedValue: any
  couponId: any
  selectedPlanIds: any[] = [];

  PlanList: any
  listValue = ''
  couponForm = new FormGroup({
    name: new FormControl('', Validators.required),
    couponType: new FormControl('static'),
    value: new FormControl<number>(1, [Validators.pattern('^(?!0)[0-9]+[.]?[0-9]*$'), Validators.required]),
    totalRedemption: new FormControl(''),
    email: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]),
    expirationDate: new FormControl('', Validators.required),
    limit: new FormControl('', Validators.required),
    minUser: new FormControl('', Validators.required),
    plans: new FormControl<string[]>([], [Validators.required]),
    selectedDate: new FormControl('off'),
    selectedLimit: new FormControl('off'),
    selectedEmail: new FormControl('off'),
  })
  constructor(
    private discountService: DiscountService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.planList()
    this.couponForm.controls?.couponType?.valueChanges.subscribe((value: any) => {
      if (value === 'percentage') {
        this.couponForm.controls?.value.setValidators([
          Validators.max(100),
          Validators.pattern('^(?!0)[0-9]+[.]?[0-9]*$'),
          Validators.required
        ]);
      } else {
        this.couponForm.controls?.value.setValidators([
          Validators.pattern('^(?!0)[0-9]+[.]?[0-9]*$'),
          Validators.required
        ]);
      }
      this.couponForm.controls?.value.updateValueAndValidity();
    });
  
    this.initialValidators();
    this.couponForm.get('selectedDate')?.valueChanges.subscribe((value: any) => {
      this.setConditionalValidators('expirationDate', value === 'on');
    });

    this.couponForm.get('selectedLimit')?.valueChanges.subscribe((value: any) => {
      this.setConditionalValidators('limit', value === 'on');
    });

    this.couponForm.get('selectedEmail')?.valueChanges.subscribe((value: any) => {
      this.setConditionalValidators('email', value === 'on');
    });

    this.route.queryParams.subscribe(params => {
      this.couponId = params['couponId'];
      if (this.couponId) {
        this.isEdit = true;
        this.couponDetail();
      }
    });
    this.route.queryParams.subscribe(params => {
      this.listValue = params['type'];
    });
  }
    initialValidators = () => {
    const selectedDate = this.couponForm.get('selectedDate')?.value;
    const selectedLimit = this.couponForm.get('selectedLimit')?.value;
    const selectedEmail = this.couponForm.get('selectedEmail')?.value;

    this.setConditionalValidators('expirationDate', selectedDate === 'on');
    this.setConditionalValidators('limit', selectedLimit === 'on');
    this.setConditionalValidators('email', selectedEmail === 'on');
  };

  setConditionalValidators = (controlName: string, condition: boolean) => {
    const control = this.couponForm.get(controlName);
    if (condition) {
      control?.setValidators(Validators.required);
    } else {
      control?.clearValidators();
      control?.reset();
    }
    control?.updateValueAndValidity();
  };


  submit() {
    const date = this.couponForm.get('selectedDate')?.value
    const limit = this.couponForm.get('selectedLimit')?.value
    if (date == 'off' && limit == 'off') {
      this.toastr.warning('Either expiration date or limit is required.')
    } else if (!this.couponForm.valid) {
      this.couponForm.markAllAsTouched()
    } else {
      this.isEdit ? this.updateCoupon() : this.createCoupon()

    }
  }

  toggle(value: string) {
    this.selectedType = value
    this.couponForm.get('couponType')?.setValue(value)
    this.couponForm.controls.value.setValue(null)
  }

  createCoupon() {
    const payload = this.couponForm.value
    this.discountService.createCoupon(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.router.navigate(['/coupon-list'], {
          relativeTo: this.route,
          queryParams: { type: this.listValue, couponId : null }, 
          queryParamsHandling: 'merge'
        });
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }


  couponDetail() {
    this.discountService.couponDetail(this.couponId).subscribe((res: any) => {
      if (res.success) {
        this.couponDetails = res.coupon;
        this.selectedType = this.couponDetails?.couponType
        this.selectedPlanIds = res?.coupon?.plans

        this.couponForm.patchValue(this.couponDetails);
        if (this.couponDetails?.expirationDate) {
          this.couponForm.get('selectedDate')?.setValue('on');
        }
        if (this.couponDetails?.limit) {
          this.couponForm.get('selectedLimit')?.setValue('on');
        }
        if (this.couponDetails?.email) {
          this.couponForm.get('selectedEmail')?.setValue('on');
        }
        this.allSelected = this.PlanList.plans.every((plan: any) => this.selectedPlanIds.includes(plan._id));
      }
    });
  }


  updateCoupon() {
    let payload = this.couponForm.value
    payload = Object.assign(payload, { couponId: this.couponId })
    this.discountService.couponUpdate(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.router.navigate(['/coupon-list'], {
          relativeTo: this.route,
          queryParams: { type: this.listValue, couponId : null }, 
          queryParamsHandling: 'merge'
        });
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  cancel() {
    this.router.navigate(['/coupon-list'], {
      relativeTo: this.route,
      queryParams: { type: this.listValue, couponId : null }, 
      queryParamsHandling: 'merge'
    });
  }

  planList() {
    this.productService.productList().subscribe((res: any) => {
      if (res.success && res?.products.length > 1) {
        this.PlanList = res?.products[2]

        this.allSelected = this.PlanList.plans.every((plan: any) => this.selectedPlanIds.includes(plan._id));
      }
    })
  }

  selectAll(event: any): void {
    this.allSelected = event.target.checked;
    if (this.allSelected) {
      this.selectedPlanIds = this.PlanList.plans.map((item: any) => item._id);
    } else {
      this.selectedPlanIds = [];
    }
    this.couponForm.controls.plans.setValue(this.selectedPlanIds);
  }

  isPlanSelected(planId: string) {
    const selectedPlanIds = this.couponForm?.get('plans')?.value;
    return selectedPlanIds?.includes(planId);
  }

  togglePlanSelection(planId: string) {
    const plansControl = this.couponForm.get('plans');
    const selectedPlans = plansControl?.value || [];

    if (selectedPlans.includes(planId)) {
      const index = selectedPlans.indexOf(planId);
      selectedPlans.splice(index, 1);
    } else {
      selectedPlans.push(planId);
    }
    this.selectedPlanIds = selectedPlans
    this.allSelected = this.PlanList.plans.every((plan: any) => selectedPlans.includes(plan._id));
    this.couponForm.controls.plans.patchValue(selectedPlans);
  }
  get disablePreviousDate(){
    let date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year= date.getFullYear()
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }
}
