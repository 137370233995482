<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0"><b>AddOn Pricing</b></h4>
</div>

<div class="modal-body pt-0">
    <form action="" [formGroup]="changeAddonPriceForm">
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Select Product</label>
            <div class="col-sm-8">
                <select class="form-select form-control" aria-label="Default select example" id="productId"
                    (change)="addOnList()">
                    <option value="" selected>Open this select menu</option>
                    <option *ngFor="let product of products" [value]="product._id">
                        {{product.name}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Select AddOn</label>
            <div class="col-sm-8">
                <select class="form-select form-control" aria-label="Default select example" id="addOnId"
                    (change)="getAddonPrice()">
                    <option value="" selected>Open this select menu</option>
                    <option *ngFor="let addon of addOns" [value]="addon._id">{{addon.name}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Price</label>
            <div class="col-sm-8 mt-2">
                <input type="number" placeholder="Enter Price" class="form-control" formControlName="price">
            </div>
        </div>
    </form>
</div>

<div class="text-end border-0 modal-footer">
  <button type="submit" class="comman-btn1 btn-primary me-2" (click)="changeAddonPrice()">Change</button>
  <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="closeModal()">Cancel</button>
</div>