export interface BillRoot {
    success: boolean
    message: string
    billings: Billing[]
    totalBillings: TotalBillings
    billingDetails: BillingDetail
  }
  
  export interface Billing {
    _id: number
    adminStartingBalance: number
    credit: number
    debit: number
    createdAt: string
  }
  
  export interface TotalBillings {
    total: number
    pages: number
  }
  
  export interface BillingDetail {
    _id: string
    customerId: any
    partnerId: string
    partnerEmail: string
    action: string
    adminStartingBalance: number
    partnerStartingBalance: number
    credit: number
    debit: number
    description: string
    createdBy: string
    isActive: boolean
    isDeleted: boolean
    createdAt: string
    updatedAt: string
    id: number
    __v: number
    month: number
  }

  