<!-- --------add---addon--page------ -->
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12">
     <div class="row">
      <div class="col-12 col-xl-8 d-flex mb-4 mb-xl-0 heading-section">
       <div><i class="bi bi-arrow-left-short fs-2" (click)="navigateBackToAddProduct()"></i></div>
       <div>
        <h3 class="font-weight-bold">Add-on</h3>
       </div>
      </div>
      <!-- -----form------- -->
      <div class="add-plan px-5">
       <form action="" [formGroup]="addOnForm">
        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Add-on Name</label>
         <div class="col-sm-10 mt-2">
          <input type="text" class="form-control" formControlName="name" placeholder="Enter Add-on Name">
          <div *ngIf="addOnForm.get('name')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="addOnForm.get('name')?.errors?.['required'] && (addOnForm.get('name')?.dirty || addOnForm.get('name')?.touched)">
            <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
            </p>
           </div>
           <div class="error-message" *ngIf="addOnForm.get('name')?.errors?.['pattern']">
            <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
           </div>
          </div>
         </div>
        </div>
        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Add-on Code</label>
         <div class="col-sm-10 mt-2">
          <input type="text" class="form-control" formControlName="code" placeholder="Enter Add-on Code">
          <div *ngIf="addOnForm.get('code')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="addOnForm.get('code')?.errors?.['required'] && (addOnForm.get('code')?.dirty || addOnForm.get('code')?.touched)">
            <p class="text-danger m-0 p-0"><small>Add-on Code is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Description</label>
         <div class="col-sm-10 mt-2">
          <textarea type="textarea" class="form-control description" placeholder="Description"
           formControlName="description"></textarea>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Unit</label>
         <div class="col-sm-10 mt-2">
          <select class="form-select form-control ps-4" aria-label="Default select example" formControlName="unit">
           <option selected value="">Select Unit</option>
           <option *ngFor="let unit of units" value="{{unit}}">{{unit}}</option>
          </select>
          <div *ngIf="addOnForm.get('unit')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="addOnForm.get('unit')?.errors?.['required'] && (addOnForm.get('unit')?.dirty || addOnForm.get('unit')?.touched)">
            <p class="text-danger m-0 p-0"><small>Unit is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>
        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Price</label>
         <div class="col-sm-10 mt-2">
          <input type="number" class="form-control" placeholder="Enter Price" formControlName="price" min="0"
           onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
          <div *ngIf="addOnForm.get('price')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="addOnForm.get('price')?.errors?.['required'] && (addOnForm.get('price')?.dirty || addOnForm.get('price')?.touched)">
            <p class="text-danger m-0 p-0"><small>Price is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Add-on Type</label>
         <div class="col-sm-10 d-flex mt-2">
          <div class="form-check ms-4 d-flex">
           <input class="form-check-input me-2 mouseCursor" type="radio" formControlName="type" name="type"
            id="flexRadioDefault1" value="oneTime">
           <div *ngIf="addOnForm.get('type')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
             *ngIf="addOnForm.get('type')?.errors?.['required'] && (addOnForm.get('type')?.dirty || addOnForm.get('type')?.touched)">
             <p class="text-danger m-0 p-0"><small>Add-on Type is mandatory.</small>
             </p>
            </div>
           </div>
           <label for="flexRadioDefault1" class="mouseCursor">
            One Time
           </label>
          </div>
          <div class="form-check ms-5 d-flex">
           <input class="form-check-input me-2 mouseCursor" type="radio" formControlName="type" name="type"
            id="flexRadioDefault2" value="recurring">
           <div *ngIf="addOnForm.get('type')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
             *ngIf="addOnForm.get('type')?.errors?.['required'] && (addOnForm.get('type')?.dirty || addOnForm.get('type')?.touched)">
             <p class="text-danger m-0 p-0"><small>Add-on Type is mandatory.</small>
             </p>
            </div>
           </div>
           <label for="flexRadioDefault2" class="mouseCursor">
            Recurring
           </label>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Pricing Interval</label>
         <div class="col-sm-5 mb-4">
          <input type="number" class="form-control" placeholder="Enter Pricing Interval" formControlName="pricingEvery"
           min="0"
           onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
          <div *ngIf="addOnForm.get('pricingEvery')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="addOnForm.get('pricingEvery')?.errors?.['required'] && (addOnForm.get('pricingEvery')?.dirty || addOnForm.get('pricingEvery')?.touched)">
            <p class="text-danger m-0 p-0"><small>Pricing Interval is mandatory.</small>
            </p>
           </div>
           <div class="error-message" *ngIf="addOnForm.get('pricingEvery')?.errors?.['pattern']">
            <p class="text-danger m-0 p-0"><small>Invalid Pricing Interval.</small></p>
           </div>
          </div>
         </div>
         <div class="col-sm-5 Select-Duration">
          <select class="form-select form-control ps-4" aria-label="Default select example"
           formControlName="pricingInterval">
           <option selected value="">Select Duration</option>
           <option value="week">Week(s)</option>
           <option value="month">Month(s)</option>
           <option value="year">Year(s)</option>
          </select>
          <div *ngIf="addOnForm.get('pricingInterval')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="addOnForm.get('pricingInterval')?.errors?.['required'] && (addOnForm.get('pricingInterval')?.dirty || addOnForm.get('pricingInterval')?.touched)">
            <p class="text-danger m-0 p-0"><small>Pricing Interval is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>

        <div class="text-end">
         <button type="submit" class="comman-btn1 btn-primary me-2"
          (click)="addOns()">{{!isEdit?'Submit':'Update'}}</button>
         <button type="button" class="comman-btn1 btn-secondary text-light"
          (click)="navigateBackToAddProduct()">Cancel</button>
        </div>
       </form>
      </div>
      <!-- -------end-------- -->
     </div>
    </div>
   </div>
  </div>
 </div>
</div>
<!-- --------------end--------- -->