import {
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddBillsModalComponent } from '../modals/add-bills-modal/add-bills-modal.component';
import { AddLabelModalComponent } from '../modals/add-label-modal/add-label-modal.component';
import { LabelService } from 'src/app/services/label.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ChangeAddonPriceComponent } from '../modals/change-addon-price/change-addon-price.component';
import { ChangePlanPriceComponent } from '../modals/change-plan-price/change-plan-price.component';
import { AdminRoot } from 'src/app/interfaces/admin';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import { AddPartnerRoot, RootPartner } from 'src/app/interfaces/partner';
import {
  labelListRoot,
  manageLabel,
} from 'src/app/interfaces/label';
import { AuthService } from 'src/app/services/auth.service';
import { PartnerRequestModalComponent } from '../modals/partner-request-modal/partner-request-modal.component';
@Component({
  selector: 'app-reseller',
  templateUrl: './reseller.component.html',
  styleUrls: ['./reseller.component.scss'],
})
export class ResellerComponent implements OnInit {
  dtOptions: any = {};
  defaultColDef: any;
  partnerLists: any = [];
  bsModalRef!: BsModalRef;
  bsModalRef1!: BsModalRef;
  labels: any = [];
  selectedPartnerId: any = [];
  accountManagers: any = [];
  labelSearchText: string;
  isShow: boolean = false;
  isResellerAvailable: boolean = false;
  totalCount: number = 0;
  page: number = 1;
  sortValue: any = '{}'
  isSorting: boolean = false
  activation = 'partner'
  isResellerReq: boolean = false
  partnerReqLists: any = []

  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount,
  };

  searchForm = new FormGroup({
    companyName: new FormControl(''),
    email: new FormControl(''),
    mobile: new FormControl(''),
    partnerType: new FormControl(''),
    isActive: new FormControl(''),
    label: new FormControl(''),
    accountManagerId: new FormControl(''),
    accountManagerName: new FormControl(''),
    amountFrom: new FormControl(''),
    amountTo: new FormControl(''),
    registrationFrom: new FormControl(''),
    registrationTo: new FormControl(''),
    noOfAccountsFrom: new FormControl(''),
    noOfAccountsTo: new FormControl(''),
  });

  constructor(
    public authService: AuthService,
    private partnerService: PartnerService,
    private router: Router,
    private toasts: ToastrService,
    private modalService: BsModalService,
    private labelService: LabelService,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.partnerList();
    this.labelList();
    this.adminList();
  }

  toggle(activation: any) {
    this.activation = activation;
    if (activation == 'partnerReq') {
      this.isResellerAvailable = false
      this.isResellerReq = true
      // this.searchForm.controls.accountManagerIds.reset()
      this.partnerReqList()
    }
    else {
      this.isResellerAvailable = true
      this.isResellerReq = false
      this.partnerList()
    }
  }

  adminList() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagers = res.accountManagers;
      }
    },
      (error) => {
        this.accountManagers = [];
        this.toasts.error(error.error.message);
      }
    );
  }

  sortingPartner(sortData: string) {
    if (!this.isSorting) {
      this.sortValue = JSON.stringify({ [sortData]: 1 });
    } else {
      this.sortValue = JSON.stringify({ [sortData]: -1 });
    }

    this.isSorting = !this.isSorting;
    this.partnerList();
  }

  partnerList() {
    this.partnerService
      .partnerList(this.config.currentPage, this.config.itemsPerPage, this.sortValue)
      .subscribe(
        (res: RootPartner) => {
          if (res.success && res?.partners[0]?.products?.length > 0) {
            this.partnerLists = res.partners.map((partner: any) => {
              if (partner?.products?.length > 1) {
                partner.products[1]?.plans.forEach((plan: any) => {
                  if (plan.billingCycle === "year" && plan.billEvery === 1 && plan.name.includes('tally')) {
                    partner.tallyYearlyPrice = plan.price;
                  }
                });
              }
              return partner;
            });
            this.isResellerAvailable = true;
            this.isResellerReq = false;
            this.config.currentPage = res.totalPartners.pages;
            this.config.totalItems = res.totalPartners.total;
            this.partnerLists.forEach((e: any) => {
              e.collapse = true;
            });
          } else {
            this.partnerLists = [];
            this.toasts.warning('No data found.');
          }

        },
        (error) => {
          this.partnerLists = [];
          this.toasts.error(error.error.message);
        }
      );
  }

  partnerReqList() {
    this.partnerService
      .partnerReqList(this.config.currentPage, this.config.itemsPerPage, this.sortValue)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.partnerReqLists = res.requestedPartners.map((partner: any) => {
              return partner;
            });
            this.config.currentPage = res.totalRequestedPartners.pages;
            this.config.totalItems = res.totalRequestedPartners.total;
          } else {
            this.partnerReqLists = [];
            this.toasts.warning('No data found.');
          }
        },
        (error) => {
          this.partnerReqLists = [];
          this.toasts.error(error.error.message);
        }
      );
  }

  partnerDetails(partnerId: string) {
    this.isShow = true;
    this.router.navigate(['partner-details'], {
      queryParams: { partnerId: partnerId },
    });
  }

  editPartner(partnerId: string) {
    this.router.navigate(['reseller/add-reseller'], {
      queryParams: { partnerId: partnerId },
    });
  }

  onToggle(partnerId: string, status: boolean) {
    const body = {
      partnerId: partnerId,
      isActive: !status,
    };
    this.partnerService.updatePartner(body).subscribe((res: AddPartnerRoot) => {
      if (res.success) {
        this.partnerList();
      }
    });
  }

  updateStatus(list: any) {
    this.bsModalRef = this.modalService.show(PartnerRequestModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.partnerId = list?._id
    this.bsModalRef.content.status = list?.status ? list?.status : 'pending'
    this.bsModalRef.content.companyName = list?.companyName
    this.bsModalRef.content.remark = list?.remark
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.partnerReqList();
      }
    })
  }

  openModal(partnerId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.partnerId = partnerId;
    this.bsModalRef.content.title = 'Delete';
    this.bsModalRef.content.message = 'Are you sure you want to delete this?';
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.partnerList();
      } else {
        this.partnerList();
      }
    });
  }

  redirectToPartner(email: string) {
    const payload = { email: email };
    this.partnerService.loginToPartner(payload).subscribe((res: any) => {
      if (res.success) {
        const url = `${environment.url}/loginWithAdmin?token=${res.user.token}`;
        window.open(url, '_blank');
      }
    });
  }

  addBills(partnerId: string) {
    this.bsModalRef1 = this.modalService.show(AddBillsModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef1.content.partnerId = partnerId;
  }

  labelModal() {
    this.bsModalRef = this.modalService.show(AddLabelModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.labelList();
      } else {
        this.labelList();
      }
    });
  }

  selectPartner(partnerId: string) {
    const existingPartnerId = this.selectedPartnerId.filter((id: string) => {
      if (id === partnerId) {
        return id;
      }
    });
    if (existingPartnerId[0] === partnerId) {
      this.selectedPartnerId.pop(partnerId);
    } else {
      this.selectedPartnerId.push(partnerId);
    }
  }

  assignLabel(labelId: string) {
    const assignLabelPayload = {
      partnerId: this.selectedPartnerId,
      labelId: labelId,
    };
    this.labelService.assignLabel(assignLabelPayload).subscribe(
      (res: manageLabel) => {
        if (res.success) {
          this.selectedPartnerId = [];
          this.toasts.success(res.message);
          this.partnerList();
        } else {
          this.toasts.warning(res.message);
        }
      },
      (error) => {
        this.toasts.error(error.error.message);
      }
    );
  }

  labelList() {
    this.labelService.labelList().subscribe(
      (res: labelListRoot) => {
        if (res.success) {
          this.labels = res.labels;
        }
      },
      (error) => {
        this.labels = [];
      }
    );
  }

  removeLabel(partnerId: string, labelId: string) {
    this.labelService.removeLabel(partnerId).subscribe(
      (res: manageLabel) => {
        if (res.success && labelId != null) {
          this.partnerList();
          this.toasts.success(res.message);
        } else {
          this.toasts.warning('No Label assigned to given Partner');
        }
      },
      (error) => {
        this.toasts.error(error.error.message);
      }
    );
  }

  searchList() {
    const searchPayload = this.searchForm.value;
    const amount =
      Number(this.searchForm.value.amountTo) -
      Number(this.searchForm.value.amountFrom);
    const noOfAccounts =
      Number(this.searchForm.value.noOfAccountsTo) -
      Number(this.searchForm.value.noOfAccountsFrom);
    const days = moment(this.searchForm.value.registrationTo).diff(
      this.searchForm.value.registrationFrom,
      'days'
    );
    if (days < 0) {
      this.toasts.warning(
        'The Registration from date is lesser than registration to date.'
      );
    } else if (noOfAccounts < 0) {
      this.toasts.warning(
        'The no. of account from is lesser than no. of accounts to.'
      );
    } else if (amount < 0) {
      this.toasts.warning(
        'The credited from amount is less than credited to amount.'
      );
    } else {
      this.partnerService.searchList(JSON.stringify(searchPayload), this.config.currentPage, this.config.itemsPerPage, this.sortValue).subscribe(
        (res: RootPartner) => {
          if (res.success) {
            if (res.partners.length === 0) {
              this.isResellerAvailable = false
              this.toasts.error('No data found');
            }
            else {
              if (res?.partners[0]?.products?.length > 0) {
                this.partnerLists = res.partners.map((partner: any) => {
                  if (partner?.products?.length > 1) {
                    partner.products[1]?.plans.forEach((plan: any) => {
                      if (plan.billingCycle === "year" && plan.billEvery === 1 && plan.name.includes('tally')) {
                        partner.tallyYearlyPrice = plan.price;
                      }
                    });
                  }
                  return partner;
                });
                this.isResellerAvailable = true;
                this.config.currentPage = res.totalPartners.pages;
                this.config.totalItems = res.totalPartners.total;
                this.partnerLists.forEach((e: any) => {
                  e.collapse = true;
                });
              } else {
                this.partnerLists = [];
                this.toasts.warning('No data found.');
              }

            }

          }
        },
        (error: any) => {
          this.toasts.error(error.error.message);
          this.resetSearchForm();
        }
      );
    }
  }

  resetSearchForm() {
    this.searchForm.reset();
    this.config.currentPage = 1;
    this.partnerList();
  }

  labelSearch() {
    this.labelService
      .searchList(this.labelSearchText)
      .subscribe((res: labelListRoot) => {
        if (res.success) {
          this.labels = res.labels;
        }
      });
  }

  changePlanPrice(partnerId: string) {
    this.bsModalRef1 = this.modalService.show(ChangePlanPriceComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef1.content.partnerId = partnerId;
  }

  changeAddonPrice(partnerId: string) {
    this.bsModalRef1 = this.modalService.show(ChangeAddonPriceComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef1.content.partnerId = partnerId;
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    if (this.searchForm.value) {
      this.searchList()
    }
    else {
      this.partnerList();
    }

  }

  get userType() {
    return this.authService.userType()
  }

  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems);
  }

}
