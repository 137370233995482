import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FilterPipe } from '../customPipe/filter.pipe';
import { PlanService } from 'src/app/services/plan.service';
import { ToastrService } from 'ngx-toastr';
import { DiscountService } from 'src/app/services/discount.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  providers: [FilterPipe]
})
export class DiscountComponent implements OnInit {
  selectedType: string = 'activationValue'
  occuranceType: string = 'non-recurring'
  plans: any
  selectedPlan: any
  priceAfterDiscount: number = 0
  schemeDetailData: any
  isEdit: boolean = false
  showMonthInput: boolean = false;
  allSelected = false;
  selectedValue: any
  couponId: any
  schemeId: any
  selectedPlanIds: any[] = [];
  selectedPartnersIds: any[] = [];
  allPartnerSelected: false
  warningMessage: any
  minToDate: any
  partnerList: any
  listValue = ''
  occurrenceType!:any
  constructor(
    private discountService: DiscountService,
    private planService: PlanService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private partnerService: PartnerService
  ) { }

  schemeForm = new FormGroup({
    name: new FormControl('', Validators.required),
    rewardType: new FormControl('activationValue'),
    occurrence: new FormControl('non-recurring'),
    // noOfMonths: new FormControl<number>(1, [Validators.required, Validators.min(1)]),
    toDate: new FormControl('', Validators.required),
    fromDate: new FormControl('', Validators.required),
    rewardValue: new FormArray<FormGroup>([
      new FormGroup({
        noOfUsers: new FormControl<number | null>(null, [Validators.pattern('^(?!0)[0-9]*$'), Validators.required]),
        value: new FormControl<number | null>(null, [Validators.pattern('^(?!0)[0-9]*$'), Validators.required])
      })
    ]),
    plans: new FormControl<string[]>([], [Validators.required]),
    partnerIds: new FormControl<string[]>([], [Validators.required]),
    searchText1: new FormControl(''),
  })

  ngOnInit(): void {
    this.allPartnerList()
    this.planList()
    this.route.queryParams.subscribe(params => {
      this.schemeId = params['schemeId'];
      if (this.schemeId) {
        this.isEdit = true
        this.schemeDetail()
      }
    })
    this.route.queryParams.subscribe(params => {
      this.listValue = params['type'];
    });

    const initialRecurringTypeValue = this.schemeForm.controls.occurrence.value;
    this.applyValidatorsBasedOnRecurringType(initialRecurringTypeValue);

    this.schemeForm.controls.occurrence.valueChanges.subscribe((value: any) => {
      this.applyValidatorsBasedOnRecurringType(value);
    });

    this.schemeForm.get('fromDate')?.valueChanges.subscribe((fromDate: any) => {
      this.minToDate = this.formatDate(new Date(fromDate));
    });
    // this.schemeForm.get('noOfMonths')?.valueChanges.subscribe((value :any)=>{
    //   if(value<1){
    //     this.schemeForm.get('noOfMonths')?.setValidators([Validators.min(1)])
    //     this.schemeForm.updateValueAndValidity()
    //   }
    // })
  }

  applyValidatorsBasedOnRecurringType(value: any) {
    const valueControl = this.schemeForm.get('noOfMonths');
    const toDateControl = this.schemeForm.get('toDate');

    if (value === 'recurring') {
      valueControl?.setValidators([Validators.required]);
      toDateControl?.clearValidators();
    } else {
      toDateControl?.setValidators([Validators.required]);
      valueControl?.clearValidators();
    }
    valueControl?.updateValueAndValidity();
    toDateControl?.updateValueAndValidity();
  }
  toggle(value: string) {
    this.selectedType = value
    this.schemeForm.get('rewardType')?.setValue(value)
  }
  toggleType(value: string) {
    this.occuranceType = value
    this.schemeForm.get('occurrence')?.setValue(value)
  }
  planList() {
    this.planService.discountPlanList().subscribe((res: any) => {
      if (res.success) {
        this.plans = res.plans
        if (this.schemeId) {
          this.isEdit = true
          this.schemeDetail()
        }
      }
    })
  }
  allPartnerList() {
    this.partnerService.allPartnerList().subscribe((res: any) => {
      if (res.success) {
        this.partnerList = res?.partners
      }
    })
  }
  schemeDetail() {
    this.discountService.schemeDetail(this.schemeId).subscribe((res: any) => {
      if (res.success) {
        this.schemeDetailData = res.scheme;
        this.selectedType = this.schemeDetailData.schemeType;
        this.occuranceType = this.schemeDetailData.occurrence;
        this.selectedPlanIds = res.scheme.plans;
        this.selectedPartnersIds = res?.scheme?.partnerIds
        this.allSelected = this.plans?.every((plan: any) => this.selectedPlanIds.includes(plan.code));
        this.allPartnerSelected = this.partnerList?.every((partnerIds: any) => this.selectedPartnersIds.includes(partnerIds._id));
        const rewardValueArray = this.schemeForm.get('rewardValue') as FormArray;
        rewardValueArray.clear();
        if (this.schemeDetailData.rewardValue && Array.isArray(this.schemeDetailData.rewardValue)) {
          this.schemeDetailData.rewardValue.forEach((reward: any) => {
            const rewardFormGroup = new FormGroup({
              noOfUsers: new FormControl<number | null>(reward.noOfUsers, [Validators.pattern('^(?!0)[0-9]*$'), Validators.required]),
              value: new FormControl<number | null>(reward.value, [Validators.pattern('^(?!0)[0-9]*$'), Validators.required])
            });
            rewardValueArray.push(rewardFormGroup);
          });
        }
        this.schemeForm.patchValue({
          name: this.schemeDetailData.name,
          rewardType: this.schemeDetailData.rewardType,
          toDate: this.schemeDetailData.toDate,
          fromDate: this.schemeDetailData.fromDate,
          plans: this.selectedPlanIds,
          partnerIds: this.selectedPartnersIds,
          occurrence: this.schemeDetailData.occurrence,
          // noOfMonths : this.schemeDetailData.noOfMonths
        });
      }
    });
  }

  submit() {
    if (!this.schemeForm.valid) {
      this.toastr.warning('please fill all the required feilds.')
      this.schemeForm.markAllAsTouched();
    }
    else {
      this.isEdit ? this.updateSheme() : this.addScheme()
    }
  }
  get searchText1() {
    return this.schemeForm.controls.searchText1.value || '';

  }

  addScheme() {
    let payload = this.schemeForm.value
    this.discountService.createScheme(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.router.navigate(['/coupon-list'], {
          relativeTo: this.route,
          queryParams: { type: this.listValue, schemeId: null },
          queryParamsHandling: 'merge'
        });
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  updateSheme() {
    let payload = this.schemeForm.value
    this.discountService.updateScheme(payload, this.schemeId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.router.navigate(['/coupon-list'], {
          relativeTo: this.route,
          queryParams: { type: this.listValue, schemeId: null },
          queryParamsHandling: 'merge'
        });
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }


  selectAll(event: any, type: string): void {
    if (type == 'plans') {
      this.allSelected = event.target.checked;
      if (this.allSelected) {
        this.selectedPlanIds = this.plans.map((item: any) => item.code);
      } else {
        this.selectedPlanIds = [];
      }
      this.schemeForm.controls.plans.setValue(this.selectedPlanIds);
    }
    else if (type == 'partners') {
      this.allPartnerSelected = event.target.checked;
      if (this.allPartnerSelected) {
        this.selectedPartnersIds = this.partnerList.map((item: any) => item._id);
      } else {
        this.selectedPartnersIds = [];
      }
      this.schemeForm.controls.partnerIds.setValue(this.selectedPartnersIds);
    }

  }

  isPlanSelected(planId: string, type: string) {
    const selectedPlanIds = this.schemeForm?.get('plans')?.value;
    const selectedPartnersIds = this.schemeForm?.get('partnerIds')?.value;
    if (type == 'partners') {
      return selectedPartnersIds?.includes(planId);
    } else if (type == 'plans') {
      return selectedPlanIds?.includes(planId);
    }
  }


  togglePlanSelection(planId: string, type: string) {
    if (type == 'plans') {
      const plansControl = this.schemeForm.get('plans');
      const selectedPlans = plansControl?.value || [];
      if (selectedPlans.includes(planId)) {
        const index = selectedPlans.indexOf(planId);
        selectedPlans.splice(index, 1);
      } else {
        selectedPlans.push(planId);
      }
      this.selectedPlanIds = selectedPlans
      this.allSelected = this.plans.every((plan: any) => selectedPlans.includes(plan.code));
      this.schemeForm.controls.plans.patchValue(selectedPlans);
    }
    else if (type == 'partners') {
      const partnersControl = this.schemeForm.get('partnerIds');
      const selectedPartnersIds = partnersControl?.value || [];
      if (selectedPartnersIds.includes(planId)) {
        const index = selectedPartnersIds.indexOf(planId);
        selectedPartnersIds.splice(index, 1);
      } else {
        selectedPartnersIds.push(planId);
      }
      this.selectedPartnersIds = selectedPartnersIds
      this.allPartnerSelected = this.partnerList.every((partner: any) => selectedPartnersIds.includes(partner._id))
      this.schemeForm.controls.partnerIds.patchValue(selectedPartnersIds);
    }

  }


  formatDate(date: Date): string {
    const d = new Date(date);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear()
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }


  cancel() {
    this.router.navigate(['/coupon-list'], {
      relativeTo: this.route,
      queryParams: { type: this.listValue, schemeId: null },
      queryParamsHandling: 'merge'
    });
  }
  get rewardValueControls() {
    return (this.schemeForm.get('rewardValue') as FormArray).controls;
  }

  addRewardValue() {
    const control = this.schemeForm.controls.rewardValue;
    const lastGroup = control.at(control.length - 1);
    const newGroup = new FormGroup({
      noOfUsers: new FormControl<number | null>(null, [Validators.required, Validators.pattern('^(?!0)[0-9]*$')]),
      value: new FormControl<number | null>(null, [Validators.required, Validators.pattern('^(?!0)[0-9]*$')])
    });

    if (lastGroup) {
      const lastNoOfUsers = lastGroup.get('noOfUsers')?.value;
      const lastValue = lastGroup.get('value')?.value;
      newGroup.get('noOfUsers')?.setValidators([Validators.required, Validators.pattern('^(?!0)[0-9]*$'), Validators.min(lastNoOfUsers + 1)]);
      newGroup.get('value')?.setValidators([Validators.required, Validators.pattern('^(?!0)[0-9]*$'), Validators.min(lastValue + 1)]);
    }

    newGroup.valueChanges.subscribe((newValue: any) => {
      if (lastGroup) {
        const noOfUsers = newValue.noOfUsers;
        const value = newValue.value;

        if (noOfUsers <= lastGroup.get('noOfUsers')?.value) {
          this.warningMessage = 'Number of users must be greater than the previous entry.';
        } else if (value <= lastGroup.get('value')?.value) {
          this.warningMessage = 'Value must be greater than the previous entry.';
        } else {
          this.warningMessage = '';
        }
      }
    });

    control.push(newGroup);
  }


  removeRewardValue(index: number) {
    const control = <FormArray>this.schemeForm.controls['rewardValue'];
    control.removeAt(index);
  }

}
