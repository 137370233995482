import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CreateProductRoot } from 'src/app/interfaces/product';
import { InstanceService } from 'src/app/services/instance.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() serverName!: any
  @Input() drive!: any
  isShow: boolean = false
  freeInstanceList !: any
  liveInstanceList !: any
  productForm = new FormGroup({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    description: new FormControl(''),
    source: new FormControl('', [Validators.required]),
    destination: new FormControl('', [Validators.required]),
    searchText: new FormControl(''),
    destinationId: new FormControl(''),
    sourceId: new FormControl(''),
    sourceSearchText: new FormControl('')
  })

  constructor(
    private productService: ProductService,
    private router: Router,
    private toasts: ToastrService,
    public bsModalRef: BsModalRef,
    private instanceService: InstanceService
  ) { }

  ngOnInit(): void {
    this.getAllLiveInstance()
    this.getAllfreeInstance()
  }

  submit() {
    if (!this.productForm.valid) {
      this.productForm.markAllAsTouched();
    }
    else {
      this.addProduct();
    }
  }

  addProduct() {
    const productPayload = this.productForm.value
    this.productService.createProduct(productPayload).subscribe((res: CreateProductRoot) => {
      if (res.success) {
        this.closedModal();
        this.router.navigate(['product/add-product'], { queryParams: { productId: res.product._id } })
        this.event.emit('ok');

        this.productForm.reset();
      }
      else {
        this.toasts.warning(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      }
    )
  }

  closedModal() {
    this.bsModalRef.hide();
  }
  getAllfreeInstance() {
    this.instanceService.getAllfreeInstance().subscribe((res: any) => {
      this.freeInstanceList = res?.freeInstances
    })
  }

  getAllLiveInstance() {
    this.instanceService.getAllLiveInstance().subscribe((res: any) => {
      this.liveInstanceList = res?.liveInstancesList
    })
  }
  handleSubmit() {
    if (!this.productForm.controls.destination.valid) {
      this.productForm.markAllAsTouched()
    } else {
      if (!this.isShow) {
        this.isShow = true;
      } else {
        this.changeDestination();
      }
    }

  }

  changeDestination() {
    const payload = {
      sourceInstanceId: this.productForm.controls.sourceId.value,
      destinationInstanceId: this.productForm.controls.destinationId.value,
    }
    this.instanceService.changeInstance(payload).subscribe((res: any) => {
      if (res.success) {
        this.bsModalRef.hide();
        this.toasts.success(res.message)
        this.event.emit('ok')
      }
    }, (err) => {
      this.bsModalRef.hide();
      this.toasts.error(err.error.message)
    })
  }
  cancel() {
    if (!this.isShow) {
      this.bsModalRef.hide()
    }
    else {
      this.isShow = false
    }
  }
  selectInstance(instanceId: string, event: MouseEvent, serverName: any, type: any) {
    if (type == 'free') {
      this.productForm.controls.destination.setValue(serverName)
      this.productForm.controls.destinationId.setValue(instanceId)
    } else if (type == 'live') {
      this.productForm.controls.source.setValue(serverName)
      this.productForm.controls.sourceId.setValue(instanceId)
    }
  }
  get searchText() {
    return this.productForm.controls.searchText.value || '';

  }

  get sourceSearchText() {
    return this.productForm.controls.sourceSearchText.value || '';
  }
  get freeInstance() {
    return this.productForm.controls.destination.value || '';
  }

  get liveInstance() {
    return this.productForm.controls.source.value || '';
  }
}
