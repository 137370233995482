import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddOnRoot, createAddOn } from 'src/app/interfaces/addOn';
import { ProductRoot } from 'src/app/interfaces/product';
import { AddOnService } from 'src/app/services/add-on.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-change-addon-price',
  templateUrl: './change-addon-price.component.html',
  styleUrls: ['./change-addon-price.component.scss']
})
export class ChangeAddonPriceComponent implements OnInit {

  @Input() partnerId = ''
  products: any = [];
  addOns: any = [];
  productId: string = ''
  addOnId: string = ''

  changeAddonPriceForm = new FormGroup({
    price: new FormControl('')
  })

  constructor(
    private bsModalRef: BsModalRef,
    private productService: ProductService,
    private addonService: AddOnService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.productList();
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  productList() {
    this.productService.SelectProduct(this.partnerId).subscribe((res: ProductRoot) => {
      if (res.success) {
        this.products = res.products;
      }
    })
  }

  addOnList() {
    this.productId = (document.getElementById("productId") as HTMLInputElement).value
    if (this.productId) {
      this.addonService.selectAddonList(this.partnerId, this.productId).subscribe((res: AddOnRoot) => {
        if (res.success) {
          this.addOns = res.addOns;
        }
      })
    }
  }

  getAddonPrice() {
    this.addOnId = (document.getElementById("addOnId") as HTMLInputElement).value
    if (this.addOnId) {
      this.addonService.selectAddonDetail(this.partnerId, this.addOnId).subscribe((res: any) => {
        if (res.success) {
          this.changeAddonPriceForm.patchValue(res.addOn);
        }
      })
    }
  }

  changeAddonPrice() {
    const pricePayload = {
      partnerId: this.partnerId,
      productId: this.productId,
      planId: this.addOnId,
      price: this.changeAddonPriceForm.value.price
    }

    this.addonService.changeAddonPrice(pricePayload).subscribe((res: createAddOn) => {
      if (res.success) {
        this.toasts.success(res.message);
        this.closeModal();
      }
    })
  }
}
