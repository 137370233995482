import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router
  ) { }

  getToken() {
    return localStorage.getItem('token');
  }

  userType() {
    const value = localStorage.getItem('isSuperAdmin');
    return value === 'true';
  }
  
  userRole() {
    const value = localStorage.getItem('role');
    return value === 'manager' || value === 'salesManager';
  }

  logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('isSuperAdmin')
    this.router.navigate(['/login'])
  }
  
  getPaginationText(currentPage: number, itemsPerPage: number, totalItems: number): string {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);
    return `Showing ${startItem} to ${endItem} of ${totalItems} records`;
  }
}
