import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { PartnerService } from 'src/app/services/partner.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlanService } from 'src/app/services/plan.service';
import { LabelService } from 'src/app/services/label.service';
import { ProductService } from 'src/app/services/product.service';
import { AddOnService } from 'src/app/services/add-on.service';
import { AdminService } from 'src/app/services/admin.service';
import { manageLabel } from 'src/app/interfaces/label';
import { DeletePartner } from 'src/app/interfaces/partner';
import { deleteAdmin } from 'src/app/interfaces/admin';
import { PlanCreation } from 'src/app/interfaces/plan';
import { UpdateAndDeleteProduct } from 'src/app/interfaces/product';
import { createAddOn } from 'src/app/interfaces/addOn';
import { RolesPermissionsService } from 'src/app/services/roles-permissions.service';
import { ServerService } from 'src/app/services/server.service';
import { DiscountService } from 'src/app/services/discount.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WalletService } from 'src/app/services/wallet.service';
import * as moment from 'moment';
@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Input() partnerId!: string;
  @Input() planId: string;
  @Input() labelId: string;
  @Input() productId: string;
  @Input() addOnId: string;
  @Input() adminId: string;
  @Input() roleId: string;
  @Input() serverId: number;
  @Input() subId: string;
  @Input() removeAddonsubId: string;
  @Input() returnAmount: number;
  @Input() schemeId!: string;
  @Input() tallyId!: string;
  @Input() subscriptionId!: string;
  title: string = '';
  message: string = '';
  confirmText: string = '';
  cancelText: string = '';
  subscription: any
  event: EventEmitter<any> = new EventEmitter();
  deleteSubForm = new FormGroup({
    remark: new FormControl('', Validators.required)
  })

  constructor(
    private partnerService: PartnerService,
    private toasts: ToastrService,
    public bsModalRef: BsModalRef,
    private planService: PlanService,
    private labelService: LabelService,
    private productService: ProductService,
    private addOnService: AddOnService,
    private adminService: AdminService,
    private rolePermissionService: RolesPermissionsService,
    private serverService: ServerService,
    private discountService: DiscountService,
    private subscriptionService: SubscriptionService,
    private walletService : WalletService,

  ) { }

  removeAddOnForm = new FormGroup({
    remark: new FormControl('', Validators.required)
  })

  ngOnInit(): void {
    setTimeout(() => {
      if(this.subscriptionId){
        this.subscriptionDetail()
      }
    });
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  delete() {
    if (this.schemeId) {
      this.event.emit('ok');
      this.closedModal();
    }
    if (this.tallyId) {
      this.event.emit('ok');
      this.closedModal();
    }
    if (this.partnerId) {
      this.partnerService.delete(this.partnerId).subscribe((res: DeletePartner) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

    if (this.planId && this.productId) {
      this.planService.delete(this.planId, this.productId).subscribe((res: PlanCreation) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

    if (this.labelId) {
      this.labelService.delete(this.labelId).subscribe((res: manageLabel) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

    if (!this.planId && !this.addOnId && this.productId) {
      this.productService.delete(this.productId).subscribe((res: UpdateAndDeleteProduct) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }

      },
        (error) => {
          this.toasts.error(error.error.message);
        }
      )
    }

    if (this.addOnId && this.productId) {
      this.addOnService.delete(this.addOnId, this.productId).subscribe((res: createAddOn) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

    if (this.adminId) {
      this.adminService.delete(this.adminId).subscribe((res: deleteAdmin) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

    if (this.roleId) {
      this.rolePermissionService.deleteRoles(this.roleId).subscribe((res: any) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }
    if (this.serverId) {
      this.serverService.resetServer(this.serverId).subscribe((res: any) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();
        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })
    }

    if (this.subId) {
      if (!this.removeAddOnForm.valid) {
        this.removeAddOnForm.markAllAsTouched()
      }
      else {
        const remark = this.removeAddOnForm.controls.remark.value || ''
        this.discountService.deleteDiscount(this.subId, remark).subscribe((res: any) => {
          if (res.success) {
            this.toasts.success(res.message);
            this.event.emit('ok');
            this.closedModal();
          }

        },
          (error) => {
            this.toasts.error(error.error.message);
          })
      }

    }

    if (this.removeAddonsubId) {
      if (!this.removeAddOnForm.valid) {
        this.removeAddOnForm.markAllAsTouched()
      } else {
        const remark = this.removeAddOnForm.controls.remark.value || ''
        this.subscriptionService.removeAddOn(this.removeAddonsubId, remark).subscribe((res: any) => {
          if (res.success) {
            this.toasts.success(res.message);
            this.event.emit('ok');
            this.closedModal();
          }
        },
          (error) => {
            this.toasts.error(error.error.message);
          }
        )
      }
    }
    
  }



  creditPlanPrice: any = 0;
  creditAddOnPrice: any = 0;

  subscriptionDetail() {
    this.subscriptionService.subscriptionDetails(this.subscriptionId).subscribe((res: any) => {
      if (res.success) {
        const subscription = this.subscription = res.subscription
        const isPaidSub = subscription.plan.unitPrice > 100
        const planDays = (subscription.plan.billingCycle === "week") ? 7 :
          (subscription.plan.billingCycle === "month") ? 30 : 365;
        const currentDate = moment().format('YYYY-MM-DD');
        const assignDate = subscription.activate ? moment((subscription.lastRenewDate === null) ? subscription.startDate : subscription.lastRenewDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        const paidPlanDays = isPaidSub ? moment(currentDate).diff(subscription.paidSubDate, 'days') : 0
        let totalDays = moment(currentDate).diff(moment(assignDate), 'days');

        if (paidPlanDays <= planDays) {
          totalDays = subscription.isTrial ? (totalDays + 7) : totalDays
        }

        if (subscription.plan.price != null) {
          const planOneDayPrice = (subscription.plan.unitPrice / planDays) / subscription.plan.billEvery;
          this.creditPlanPrice = (subscription.plan.noOfUsers * subscription.plan.unitPrice) - (planOneDayPrice * totalDays * subscription.plan.noOfUsers);
        }

        if (subscription.addOn && subscription.addOn.price != null) {
          const addOnOneDayPrice = (subscription.addOn.price / planDays) / subscription.plan.billEvery;
          this.creditAddOnPrice = (subscription.addOn.price * subscription.addOn.quantity) - (addOnOneDayPrice * totalDays * subscription.addOn.quantity);
        }

      }
    })
  }

  removeSubscription() {
    if (this.deleteSubForm.valid) {
      const remarkByUser = this.deleteSubForm.value.remark || ''
      const remark = JSON.stringify(remarkByUser.replace(/[&?]/g, ''));
      this.subscriptionService.deleteSubscription(this.subscription._id, remark).subscribe((res: any) => {
        if (res.success) {
          this.event.emit('ok');
          this.walletService.adminProfileDetails()
          this.closedModal();
          this.toasts.info('Subscription removed')
        } else {
          this.toasts.info(res.message)
          this.closedModal();
        }
      },
        (error:any) => {
          this.toasts.error(error.error.message)
        }
      )
    } else {
      this.deleteSubForm.markAllAsTouched()
    }

  }
}
