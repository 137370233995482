import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PlanCreation, PlanRoot } from 'src/app/interfaces/plan';
import { ProductRoot } from 'src/app/interfaces/product';
import { PlanService } from 'src/app/services/plan.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-change-plan-price',
  templateUrl: './change-plan-price.component.html',
  styleUrls: ['./change-plan-price.component.scss']
})
export class ChangePlanPriceComponent implements OnInit {

  @Input() partnerId = ''
  products: any = [];
  plans: any = [];
  productId: string = ''
  planId: string = ''
  plan: any = []

  changePlanPriceForm = new FormGroup({
    price: new FormControl('', Validators.required),
    remark: new FormControl('', Validators.required),
    isChangePreviousSubPrice: new FormControl(false)
  })

  constructor(
    private bsModalRef: BsModalRef,
    private productService: ProductService,
    private planService: PlanService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.productList();
    });

  }

  closeModal() {
    this.bsModalRef.hide();
  }

  productList() {
    this.productService.SelectProduct(this.partnerId).subscribe((res: ProductRoot) => {
      if (res.success) {
        this.products = res.products.filter((product: any) => !product.name.toLowerCase().includes('trial'));

      }
    })
  }

  planList() {
    this.productId = (document.getElementById("productId") as HTMLInputElement).value
    if (this.productId) {
      this.planService.selectPlanList(this.partnerId, this.productId).subscribe((res: PlanRoot) => {
        if (res.success) {
          this.plans = res.plans

        }
      })
    }
  }

  getPlanPrice() {
    this.planId = (document.getElementById("planId") as HTMLInputElement).value
    if (this.planId) {
      this.planService.selectPlanDetail(this.partnerId, this.planId).subscribe((res: any) => {
        if (res.success) {
          this.plan = res.plan
          this.changePlanPriceForm.patchValue(res.plan);
        }

      })
    }
  }

  changePlanPrice() {
    const pricePayload = {
      partnerId: this.partnerId,
      productId: this.productId,
      planId: this.planId,
      price: this.changePlanPriceForm.value.price,
      isChangePreviousSubPrice: this.changePlanPriceForm.value.isChangePreviousSubPrice,
      remark: this.changePlanPriceForm.value.remark
    }
    if (this.productId === "" || this.planId === "") {
      this.toasts.warning("All fields are required.");
    } else if (Number(pricePayload.price) === Number(this.plan.price)) {
      this.toasts.warning("Current plan price is same as previous plan price.");
    }
    else {
      this.planService.changePlanPrice(pricePayload).subscribe((res: PlanCreation) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.closeModal();
        } else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message)
        }
      )
    }

  }

}
