<!-- --------------------------------- -->
<div class="modal-header border-0 py-0">
  <h4 class="modal-title pull-left border-0"><b>Add Bills</b></h4>
</div>
<div class="modal-body pt-0">
  <form action="" [formGroup]="walletForm">
    <div class="form-group mt-2">
      <label for=""><b>Billing Action:</b></label>
      <select class="form-select form-control ps-4" aria-label="Default select example" formControlName="billingAction"
        [(ngModel)]="selectedValue">
        <option selected value="">Please Select</option>
        <option value="freeCredit">Free Credit</option>
        <option value="creditBought">Credit Bought</option>
        <option value="Adjustment">Adjustment</option>
      </select>
      <div *ngIf="walletForm.get('billingAction')?.invalid" class="alert mb-0 p-0">
        <div class="error-message"
          *ngIf="walletForm.get('billingAction')?.errors?.['required'] && (walletForm.get('billingAction')?.dirty || walletForm.get('billingAction')?.touched)">
          <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
          </p>
        </div>
      </div>
    </div>
    <div class="form-group m-0">
      <div class="d-flex">
        <div>
          <label for="gender"><b>Payment Type:</b></label>
        </div>
        <div class="d-flex">
          <div>
            <input id="male" type="radio" value="credit" name="paymentType" formControlName="paymentType">
            <label for="male" class="mx-2">Credit</label>
          </div>

          <div>
            <input id="female" type="radio" value="debit" name="paymentType" formControlName="paymentType">
            <label for="female" class="mx-2">Debit</label>
          </div>
          <div *ngIf="walletForm.get('paymentType')?.invalid" class="alert mb-0 p-0">
            <div
              *ngIf="walletForm.get('paymentType')?.errors?.['required'] && (walletForm.get('paymentType')?.dirty || walletForm.get('paymentType')?.touched)">
              <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <div class="input-group-text pb-3 bg-danger text-light">&#8377;</div>
        </div>
        <input type="number" class="form-control" id="inlineFormInputGroup" placeholder="Enter amount"
          formControlName="amount" min="0"
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">

      </div>
      <div *ngIf="walletForm.get('amount')?.invalid" class="alert mb-0 p-0">
        <div class="error-message error-message-type-1"
          *ngIf="walletForm.get('amount')?.errors?.['required'] && (walletForm.get('amount')?.dirty || walletForm.get('amount')?.touched)">
          <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
          </p>
        </div>
      </div>
    </div>
    <div class="text-message">
      <textarea class="form-control description" formControlName="descriptionByAdmin" placeholder="Description"></textarea>
    </div>
  </form>
</div>

<div class="text-end border-0">
  <button type="submit" class="comman-btn1 btn-primary me-2" (click)="addWallet()">Save</button>
  <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="closedModal()">Cancel</button>
</div>