import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ManageServerRoot, ServerRoot } from '../interfaces/server';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(
    private http: HttpClient
  ) { }

  createServer(serverPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/server`, serverPayload)
  }

  serverList(page: number, limit: number, serverType: string, statusType: string, sortValue: string) {
    return this.http.get<ServerRoot>(`${environment.apiURL}/admin/server?page=${page}&limit=${limit}&serverType=${serverType}&statusType=${statusType}&sortValue=${sortValue}`)
  }

  serverDetail(serverId: string) {
    return this.http.get<ManageServerRoot>(`${environment.apiURL}/admin/server/details?serverId=${serverId}`)
  }

  editServer(payload: any) {
    return this.http.put(`${environment.apiURL}/admin/server`, payload)
  }

  resetServer(serverId: number) {
    return this.http.put(`${environment.apiURL}/admin/server/reset`, { serverId: serverId })
  }

  refreshInstance() {
    return this.http.get(`${environment.apiURL}/admin/refresh/error/instance`)
  }
  allServerList(filterOutUpdateExe: any) {
    return this.http.get(`${environment.apiURL}/admin/server/all?filterOutUpdateExe=${filterOutUpdateExe}`)
  }

  updateServerExe(updateServerExePayload: any) {
    return this.http.put(`${environment.apiURL}/admin/server/exe/update`, updateServerExePayload)
  }

  refreshServer(serverId: string) {
    return this.http.get(`${environment.apiURL}/admin/server/refresh?serverId=${serverId}`)
  }
  getCdriveSpace(){
    return this.http.get(`${environment.apiURL}/admin/server/CDrive/stats`)
  }
}
