<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
    <div class="main-panel">
     <div class="content-wrapper">
      <div class="row">
       <div class="col-md-12 grid-margin">
        <div class="row">
         <div class="col-md-6 mb-4">
          <h3 class="font-weight-bold">Credit Back Report</h3>         
         </div>
         <div class="col-md-6 text-end">
          <button type="button" class="comman-btn btn-primary"><i class="bi bi-filetype-csv"></i> Export
            to CSV</button>
         </div>
        </div>
       </div>
   <!-- -------table-section----------- -->
   <div class="row table-section">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <p class="card-title mb-0">Report:</p>
          <div class="table-responsive">
            <table class="table table-striped table-borderless">  
              <thead>
                <tr>
                  <th scope="col">Company Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Plan Type</th>
                  <th scope="col">Plan Frequenncy </th>
                  <th scope="col">Type</th>
                  <th scope="col">Credit</th>
                  <th scope="col">Debit</th>
                  <th scope="col">Create Date</th>
                  <th scope="col">Expire Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td>Comhard Technology</td>
                    <td>rohan@algogenix.com</td>
                    <td>Business</td>
                    <td>Trial</td>
                    <td>Change Plan (Old)</td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td>11/12/2022 11:21:01pm</td>
                    <td>11/12/2022 11:21:01pm</td>
                    <td class="text-center">
                      <div class="dropdown">
                      <a class="btn px-0 py-0"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-three-dots-vertical"></i>
                      </a>                 
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><a class="dropdown-item" ><i class="bi bi-pencil-fill text-primary"></i> Edit</a></li>
                          <li><a class="dropdown-item" ><i class="bi bi-trash3-fill text-danger"></i> Delete</a></li>
                      </ul>
                    </div>
                  </td>                                      
                </tr>
                <tr>
                    <td>Comhard Technology</td>
                    <td>rohan@algogenix.com</td>
                    <td>Business</td>
                    <td>Trial</td>
                    <td>Change Plan (Old)</td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td>11/12/2022 11:21:01pm</td>
                    <td>11/12/2022 11:21:01pm</td>
                    <td class="text-center">
                      <div class="dropdown">
                      <a class="btn px-0 py-0"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-three-dots-vertical"></i>
                      </a>                 
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><a class="dropdown-item" ><i class="bi bi-pencil-fill text-primary"></i> Edit</a></li>
                          <li><a class="dropdown-item" ><i class="bi bi-trash3-fill text-danger"></i> Delete</a></li>
                      </ul>
                    </div>
                  </td>                  
                </tr>
                <tr>
                    <td>Comhard Technology</td>
                    <td>rohan@algogenix.com</td>
                    <td>Business</td>
                    <td>Trial</td>
                    <td>Change Plan (Old)</td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td>11/12/2022 11:21:01pm</td>
                    <td>11/12/2022 11:21:01pm</td>
                    <td class="text-center">
                      <div class="dropdown">
                      <a class="btn px-0 py-0"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-three-dots-vertical"></i>
                      </a>                 
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><a class="dropdown-item" ><i class="bi bi-pencil-fill text-primary"></i> Edit</a></li>
                          <li><a class="dropdown-item" ><i class="bi bi-trash3-fill text-danger"></i> Delete</a></li>
                      </ul>
                    </div>
                  </td>                      
                </tr>
              </tbody>
            </table>  
          </div>
        </div>
      </div>
    </div>
   </div>
   <!-- -----end------ -->
    </div>
    </div>
   </div>