import { Component, OnInit,HostListener } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth.service';
import { WalletService } from '../services/wallet.service';
import { LogoutComponent } from '../shared/modals/logout/logout.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  wallet: any;
  bsModalRef!: BsModalRef;
  logo: any;
  name :any;
  isShow = false;
  isSideNavOpen: boolean = false;

  screenWidth = window.innerWidth;
  screenHeight = window.innerHeight;
  showNav: boolean = (this.screenWidth < 920)
  mobileView: boolean = true;
  userName = localStorage.getItem("userName")
  isExpanded: boolean = false;
  isExpanded1: boolean = false;
  expandedMenu: string = '';
  onToggle() {
    this.isShow = !this.isShow;

  }

  hideBtn() {
   
   this.showNav = !this.showNav;
    // this.isSideNavOpen = !this.isSideNavOpen;
  }

  constructor(
    public authService: AuthService,
    private walletService: WalletService,
    private modalService: BsModalService
  ) { 
    this.screenWidth = window.innerWidth;
    this.showNav = !(this.screenWidth < 920); 
    this.sideNavToggle();
  }

  ngOnInit(): void {
    this.profileDetails();
    this.walletService.walletSubject.subscribe((res) => {
      this.wallet = res;
    })
    this.walletService.logoSubject.subscribe((res) => {
      this.logo = res;
    })
    this.walletService.nameSubject.subscribe((res) => {
      this.name = res;
    })
  }


 
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.screenWidth = window.innerWidth;
    this.sideNavToggle();
  }
  sideNavToggle() {
    if(this.screenWidth < 920){
      this.hideBtn()
    }else{
      this.showNav = false
    }
  }
  profileDetails() {
    this.walletService.adminProfileDetails();
  }
  toggleMenu(menu: string) {
    this.expandedMenu = this.expandedMenu === menu ? '' : menu;
  }
  openModal() {
    this.bsModalRef = this.modalService.show(LogoutComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })

  }

  get userType() {
    return this.authService.userType()
  }

  get userRole() {
    return this.authService.userRole()
  }
}
