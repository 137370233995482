import { Component, EventEmitter, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from 'src/app/services/partner.service';
import { SubscriptionService } from 'src/app/services/subscription.service';


@Component({
  selector: 'app-partner-request-modal',
  templateUrl: './partner-request-modal.component.html',
  styleUrls: ['./partner-request-modal.component.scss']
})
export class PartnerRequestModalComponent implements OnInit {

  @Input() partnerId!: any
  @Input() status!: any
  @Input() remark!: any
  @Input() companyName!: any
  @Input() orderData!: any
  event: EventEmitter<any> = new EventEmitter();


  constructor(
    private toasts: ToastrService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private partnerService: PartnerService,
    private subscriptionService: SubscriptionService,
    private modalService: BsModalService
  ) { }

  updateStatusForm = new FormGroup({
    status: new FormControl('pending', Validators.required),
    remark: new FormControl('', Validators.required)
  })

  ngOnInit(): void {
    setTimeout(() => {
      this.updateStatusForm.controls.remark.setValue(this.orderData?.addOn?.remarks)
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  submit() {
    if (!this.updateStatusForm.valid) {
      this.updateStatusForm.markAllAsTouched()
    } else if (this.companyName) {
      this.updateStatus()
    } else {
      this.updateSubManagement()
    }
  }

  updateStatus() {
    const payload: any = this.updateStatusForm.value
    payload['partnerId'] = this.partnerId
    this.partnerService.updateStatus(payload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.closeModal()
        this.event.emit('ok')
      } else {
        this.toasts.warning(res.message)
      }
    }, (err) => {
      this.toasts.error(err.error.message)
    })
    const status = this.updateStatusForm.controls.status.value || ''
    if (status == "approved") {
      this.router.navigate(['reseller/add-reseller'],
        { queryParams: { partnerReqId: this.partnerId } }
      );
    }
  }

  updateSubManagement() {
    let payload: any = this.updateStatusForm.value
    const updateSubManagementPayload = {
      subManagementRequestId: this.orderData._id,
      status: payload.status,
      remarks: payload.remark
    }
    if (payload.status == 'approved') {
      this.closeModal()
      if (this.orderData.reqType == 'addOn') {
        this.event.emit('success')
      } else {
        this.router.navigate(['/new-subscription'],
          { queryParams: { subManagementRequestId: this.orderData._id, subId: this.orderData.code } }
        );
      }
    } else {
      this.subscriptionService.updateStatus(updateSubManagementPayload).subscribe((res: any) => {
        if (res.success) {
          this.event.emit('ok')
          this.closeModal()
          this.toasts.success(res.message)
        }
      }, (err) => {
        this.toasts.error(err.error.message)
      })
    }
  }
}
