import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddTallyReleaseComponent } from '../modals/add-tally-release/add-tally-release.component';
import { TallyReleaseService } from 'src/app/services/tally-release.service';

@Component({
  selector: 'app-tally-release',
  templateUrl: './tally-release.component.html',
  styleUrls: ['./tally-release.component.scss']
})
export class TallyReleaseComponent implements OnInit {

  tallyReleases: any = [];
  isTallyAvailable: boolean = false
  bsModalRef!: BsModalRef;
 
  constructor(
    private modalService: BsModalService,
    private tallyReleaseService: TallyReleaseService,
    private toasts: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tallyReleaseList();
  }

  tallyReleaseList() {
    this.tallyReleaseService.tallyReleaseList().subscribe((res: any) => {
      if (res.success) {
        this.tallyReleases = res.tallyVersions
        this.isTallyAvailable = true
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })

  }

  addTallyReleaseModal(tallyReleaseId:string) {
    this.bsModalRef = this.modalService.show(AddTallyReleaseComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.tallyReleaseId = tallyReleaseId
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.tallyReleaseList();
      }
      else {
        this.tallyReleaseList();
      }
    })
  }

}
