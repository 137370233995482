export interface RootPartner {
  success: boolean
  message: string
  partners: Partner[]
  totalPartners: TotalPartners
}

export interface Partner {
  _id: string
  name: string
  countryCode: string
  mobile: string
  email: string
  roles: any[]
  createdBy: string
  companyName: string
  partnerType: string
  accountManager: string
  activityLogs: any[]
  products: Product[]
  wallet: number
  walletLogs: WalletLog[]
  branding: Branding
  logo?: string
  isActive: boolean
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  id: number
  __v: number
  lastLogin?: string
  label: Label
  accounts: Account[]
  noOfAccounts: number
}

export interface Product {
  _id: string
  name: string
  description: string
  plans: Plan[]
  isActive: boolean
  isDeleted: boolean
  addOns: AddOn[]
  createdAt: string
  updatedAt: string
  id: number
  __v: number
}

export interface Plan {
  name: string
  code: string
  unit: string
  price: number
  description: string
  billEvery: number
  billingCycle: string
  autoRenewal: boolean
  isShow: boolean
  _id: string
}

export interface AddOn {
  name: string
  code: string
  description: string
  unit: string
  price: number
  type: string
  pricingEvery: number
  pricingInterval: string
  _id: string
}

export interface WalletLog {
  description?: string
  billingType: string
  amount: number
  _id: string
  billingAction?: string
}

export interface Branding {
  domainName: string
  brandName: string
  senderName: string
  senderEmail: string
  logo: string
  favicon: string
  contactSupport: string
  onlineHelp: string
  aboutProduct: string
  termsOfService: string
  productAgreement: string
  privacyPolicy: string
  thirdPartyLicense: string
}

export interface Label {
  name: string
}

export interface Account {
  _id: string
  accountId: string
  name: string
  mobile: string
  email: string
  country: string
  customerType: string
  partnerId: string
  password: string
  createdBy: string
  isEmailVerified: boolean
  isMobileVerified: boolean
  isVerified: boolean
  isActive: boolean
  isDeleted: boolean
  isFreeSubscriptionUses: boolean
  activityLogs: ActivityLog[]
  createdAt: string
  updatedAt: string
  id: number
  __v: number
  lastLogin?: string
  companyName: string
  gstIn?: string
  natureOfBusiness?: string
  organizationType?: string
  panNumber?: string
  socialProfile?: string
  website?: string
  label?: Label2
}

export interface ActivityLog {
  updatedBy: string
  updateDate: string
  _id: string
}

export interface Label2 {
  id: string
  name: string
  color: string
}

export interface TotalPartners {
  total: number
  pages: number
}
export interface PartnerDetailRoot {
  success: boolean
  message: string
  partner: Partner
}

export interface AddPartnerRoot {
  success: boolean
  message: string
  partner: Partner
}


export interface DeletePartner {
  success: boolean
  message: string
}