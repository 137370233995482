import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { PartnerRequestModalComponent } from '../modals/partner-request-modal/partner-request-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddOnComponent } from '../modals/add-on/add-on.component';
@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss']
})
export class OrderManagementComponent implements OnInit {
  detailList!: any
  page: number = 1;
  totalCount: number = 0;
  isOrderManagement: boolean = false
  activeSection: string = 'addOn'
  bsModalRef!: BsModalRef
  subDataDetails!: any
  subsDataPrice !: any
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount,
  };
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.activeSection = params['type'] || 'addOn';
      if(this.activeSection == 'addOn'){
        this.getdetailsSubManagement()
      }else{
        this.getdetailsSubManagement()
      }
    });
    
  }
  toggleSection(value: string) {
    this.activeSection = value
    this.detailList = []
    this.config.currentPage = 1;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { type: value },
      queryParamsHandling: 'merge'
    });
  }

  getdetailsSubManagement() {
    this.subscriptionService.getdetailsSubManagement(this.activeSection, this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res?.subManagementRequestedList.length === 0) {
        this.toastr.error('No data found');
        this.isOrderManagement = false;
        this.config.currentPage = 1
        this.config.totalItems = 0
        this.detailList = []
      }
      else {
        this.detailList = res?.subManagementRequestedList
        this.config.currentPage = res.totalSubManagementRequest.pages;
        this.config.totalItems = res.totalSubManagementRequest.total;
        this.isOrderManagement = true;
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.getdetailsSubManagement();
  }
  updateStatus(data: any) {
    if (data.status == 'pending') {
      if(data.reqType=='addOn'){
        this.subsDetails(data.code)
      }
      this.bsModalRef = this.modalService.show(PartnerRequestModalComponent, {
        class: 'modal-md',
        backdrop: 'static'
      })
      this.bsModalRef.content.orderData = data
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res === 'success') {
          this.addOn(data)
        }
        else {
          this.getdetailsSubManagement()
        }
      })
    }


  }

  subsDetails(code :any) {
    this.subscriptionService.subscriptionDetails(code).subscribe((res: any) => {
      if (res.success) {
        this.subsDataPrice = res?.subscription?.addOn?.price
        this.subDataDetails = res?.subscription
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  addOn(subData: any) {
    this.bsModalRef = this.modalService.show(AddOnComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.subDataDetails = subData;
    this.bsModalRef.content.addOnId = subData._id;
    this.bsModalRef.content.subsDataPrice = this.subsDataPrice
    this.bsModalRef.content.subDataDetails = this.subDataDetails
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.router.navigate(['/subscription'])
      }
    });
  }
  updateSupscriptionStatus() {
    this.router.navigate(['/new-subscription'])
  }
  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems);
  }


}
