<!-- -------logout----modal--- -->
<div *ngIf="!subscriptionId">
  <div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0 title"><b>{{title}}</b></h4>
  </div>
  <div class="modal-body text-center  pt-1 pb-1">
    <h4 *ngIf="!schemeId">{{message}}</h4>
    <h4 *ngIf="schemeId"><b>Note: </b>{{message}}</h4>
    <div class="alert alert-warning mt-4" role="alert" *ngIf="removeAddonsubId">
      <span class="fw-bold">Refundable Amount :
        <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
        <span class="text-primary">{{returnAmount | number:'1.0-2'}}
        </span></span>
  
  
    </div>
    <div class="row mb-3" *ngIf="returnAmount || subId">
      <label for="fullName" class="col-sm-3 col-form-label">Remarks</label>
      <div class="col-sm-9 mt-2">
        <form [formGroup]="removeAddOnForm">
          <textarea rows="1" class="form-control " id="fullName" placeholder="Remarks" style="overflow:hidden"
            formControlName="remark"></textarea>
          <div *ngIf="removeAddOnForm.get('remark')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
              *ngIf="removeAddOnForm.get('remark')?.errors?.['required'] && (removeAddOnForm.get('remark')?.dirty || removeAddOnForm.get('remark')?.touched)">
              <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
              </p>
            </div>
          </div>
        </form>
  
      </div>
    </div>
  </div>
  <div class="text-end border-0">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="delete()">{{schemeId ? 'Approve' :
      'Yes'}}</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
  </div>
</div>



<div  *ngIf="subscriptionId">
  <div class="modal-header py-1 border-0">
    <h4 class="modal-title pull-left"><b>Delete Subscription</b></h4>
    <!-- <span (click)="closedModal()"><i class="bi bi-x-lg"></i></span> -->
  </div>
  <div>
    <div class="modal-body pt-0 mt-0">
      <div>
        <h4>Are you sure you want to delete this subscription?</h4>
        <div class="alert alert-warning mt-4" role="alert">
          <span class="fw-bold">Refundable Amount :
            <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
            <span class="text-primary">{{creditPlanPrice+creditAddOnPrice | number:'1.0-2'}}
            </span></span>
        </div>
        <div class="row mb-3">
          <label for="fullName" class="form-label">Remark</label>
          <div class="mt-2" [formGroup]="deleteSubForm">
            <input type="text" placeholder="Enter Remark" class="form-control" formControlName="remark">
            <div *ngIf="deleteSubForm.get('remark')?.invalid" class="alert mb-0 p-0">
              <div class="error-message"
                *ngIf="deleteSubForm.get('remark')?.errors?.['required'] && (deleteSubForm.get('remark')?.dirty || deleteSubForm.get('remark')?.touched)">
                <p class="text-danger m-0 p-0"><small>Remark is mandatory.</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="text-danger">
          <span class="fw-bold">*Note :</span> Deleting this subscription will
          also delete all associated instances and their users
        </p>
      </div>
      <div>
        <h5 class="text-danger">
          <span class="fw-bold">*This step is irreversible</span>
        </h5>
      </div>
    </div>
  
  
    <div class="modal-footer border-0 pe-4">
      <div class="text-end border-0">
        <button type="button" class="comman-btn1 btn-primary me-2" (click)="removeSubscription()">Yes</button>
        <button type="button" class="comman-btn1 btn-secondary text-light" (click)="bsModalRef.hide()">Back</button>
      </div>
    </div>
  </div>
</div>
<!-- --------------end--------- -->