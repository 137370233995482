<!-- ---------dashboard--page------ -->
<div class="main-panel">
  <div class="content-wrapper ">
    <div class="row">
      <div class="col-md-12 content-dash mt-2">
        <p class="sub">Dashboard & Statistics</p>
      </div>
      <div class="sticky-container" >
        <form [formGroup]="searchForm">
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Timeline Filter</mat-label>
                <mat-select formControlName="timeLine" #timeLineSelect >
                  <div class="custom-panel">
                    <mat-option *ngFor="let item of AllWeekMonthYear" [value]="item.value">
                      {{ item.label }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>

         
            <div class="col-md-3">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Custom Date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="From" formControlName="startDate">
                  <input matEndDate placeholder="To" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply
                          (click)="submitAll()">Apply</button>
                  </mat-date-range-picker-actions>
              </mat-date-range-picker>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Account Manager</mat-label>
                <mat-select multiple #multiSelect1 formControlName="accountManagerIds">
                  <div class="custom-panel">
                    <div class="search ">
                      <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                        formControlName="searchText">
                    </div>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                    </div>
                    <mat-option *ngFor="let list of accountManagerList |  filter: searchText: 'email'"
                      [value]="list?._id" (click)="optionClick(multiSelect1,1)">
                      {{list?.email | titlecase}}
                    </mat-option>

                  </div>
                  <footer>
                    <button mat-raised-button class="me-2" (click)="submitAll();multiSelect1.close()">Ok</button>
                    <button mat-button (click)="multiSelect1.close()">Cancel</button>
                  </footer>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Partner</mat-label>
                <mat-select multiple #multiSelect2 formControlName="partnerIds">
                  <div class="custom-panel">
                    <div class="search ">
                      <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                        formControlName="searchText1">
                    </div>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allPartnerSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect2,2)">Select All</mat-checkbox>
                    </div>
                    <mat-option class="text-truncat"
                      *ngFor="let list of partnerList | filter: searchText1: 'companyName'" [value]="list?._id"
                      (click)="optionClick(multiSelect2,2)">
                      {{list?.companyName}}</mat-option>
                  </div>
                  <footer>
                    <button mat-raised-button class="me-2" (click)="submitAll();multiSelect2.close()">Ok</button>
                    <button mat-button (click)="multiSelect2.close()">Cancel</button>
                  </footer>
                </mat-select>
              </mat-form-field>


            </div>

          </div>
        </form>

      </div>
      
      <div class="col-lg-12">
        <div class="row mt-3">
          <div class="col-md-6 m-b">
            <div class="card">
              <div class="card-body card1">
                <p class="text" (click)="clickableData('paid')" [ngClass]="totalPaid?.totalSub>0?'cursor-pointer':''">
                  Total Paid Subscriptions: </p>
                <p class="number">{{totalPaid?.totalAmount | pricePipe}} <i class="bi bi-arrow-up"></i></p>
                <div class="justify-content-between  d-flex">
                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{totalPaid?.totalSub}}</p>
                    <p class="text-center sub-txt">Subscriptions</p>
                  </div>
                  <div class="vr"></div>

                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{totalPaid?.totalUsers}}</p>
                    <p class="text-center sub-txt ">Users</p>
                  </div>
                  <div>
                    <div class="circle d-none d-sm-block">
                      <img src="../../../../assets/images/icons8-resume.png" alt="" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="card">
              <div class="card-body card3">
                <p class="text " (click)="sendDataToSubscription(newPaid?.subIds) "
                  [ngClass]="newPaid?.totalSub>0?'cursor-pointer':''">New Subscriptions Activations:
                </p>
                <p class="number">{{newPaid?.totalAmount | pricePipe}} <i class="bi bi-arrow-up"></i></p>
                <div class="justify-content-between  d-flex">
                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{newPaid?.totalSub}}</p>
                    <p class="text-center sub-txt ">Subscriptions</p>
                  </div>
                  <div class="vr"></div>

                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{newPaid?.totalUsers}}</p>
                    <p class="text-center sub-txt ">Users</p>
                  </div>
                  <div>
                    <div class="circle d-none d-sm-block">
                      <img src="../../../../assets/images/icons8-new.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-12 mt-3">
        <div class="row ">
          <div class="col-lg-8 stretch-card ">
            <div class="card">
              <div class="card-body">
                <div class="sub-heading">Renewal Summary
                </div>
                <div class="table-responsive tables tablerounededCorner mt-4">
                  <table class="table table-borderless roundedTable table-sm table-striped">
                    <thead class="table-head">
                      <tr class="">
                        <th scope="col">#</th>
                        <th scope="col">Renewals</th>
                        <th scope="col">Subscriptions</th>
                        <th scope="col">Users</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td><span (click)="sendDataToSubscription(totalRenewal?.subIds) "
                            [ngClass]="totalRenewal?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Total
                            Renewals</span></td>
                        <td>{{totalRenewal?.totalSub}}</td>
                        <td>{{totalRenewal?.totalUsers}}</td>
                        <td>{{totalRenewal?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-up-arrow text-success ms-2"></i></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td><span (click)="sendDataToSubscription(renew?.subIds) "
                            [ngClass]="renew?.totalAmount  > 0? 'custom-arrow':'custom-arrow2'">Renewed</span></td>
                        <td>{{renew?.totalSub}}</td>
                        <td>{{renew?.totalUsers}}</td>
                        <td>{{renew?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-up-arrow text-success ms-2"></i></td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td><span (click)="sendDataToSubscription(upgrade?.subIds) "
                            [ngClass]="upgrade?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Upgrades</span></td>
                        <td>{{upgrade?.totalSub}}</td>
                        <td>{{upgrade?.totalUsers}}</td>
                        <td>{{upgrade?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-up-arrow text-success ms-2"></i></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td><span (click)="sendDataToSubscription(downgrade?.subIds) "
                            [ngClass]="downgrade?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Downgrade</span></td>
                        <td>{{downgrade?.totalSub}}</td>
                        <td>{{downgrade?.totalUsers}}</td>
                        <td>{{downgrade?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-down-arrow ms-2 text-danger"></i></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td><span (click)="sendDataToSubscription(upcomingRenewal?.subIds) "
                            [ngClass]="upcomingRenewal?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Upcoming
                            Renewals</span></td>
                        <td>{{upcomingRenewal?.totalSub}}</td>
                        <td>{{upcomingRenewal?.totalUsers}}</td>
                        <td>{{upcomingRenewal?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-up-arrow text-success ms-2"></i></td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td><span (click)="sendDataToSubscription(expired?.subIds) "
                            [ngClass]="expired?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Expired</span></td>
                        <td>{{expired?.totalSub}}</td>
                        <td>{{expired?.totalUsers}}</td>
                        <td>{{expired?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-down-arrow ms-2 text-danger"></i></td>
                      </tr>


                      <tr>
                        <td>7</td>
                        <td><span (click)="sendDataToSubscription(deleted?.subIds) "
                            [ngClass]="deleted?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Deleted</span></td>
                        <td>{{deleted?.totalSub}}</td>
                        <td>{{deleted?.totalUsers}}</td>
                        <td>{{deleted?.totalAmount | currency:'INR'}}<i
                            class="bi bi-graph-down-arrow ms-2 text-danger"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card">
              <div class="card-body ">
                <div class="sub-heading">Risky Customers: <i class="bi bi-arrow-right-circle-fill "
                    (click)="sendDataToSubscription(riskyCustomer?.subIds)"
                    [ngClass]="riskyCustomer?.totalAmount > 0? 'custom-arrow':'custom-arrow2'"></i>

                </div>
                <div class="text-end"><i class="bi bi-exclamation-diamond-fill fs-3"
                    ngbTooltip="Not logged-in past 15 days"></i></div>
                <div id="riskyCustomerChart" class="d-flex justify-content-center align-items-center">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="riskyCustomerChartOption"
                    [(update)]="updateFromInput" style="width:100%; height: auto; display: block;"></highcharts-chart>
                </div>
                <div class="reseller">
                  <p class="text-center mb-0 numbers">{{riskyCustomer?.totalAmount| pricePipe}}</p>
                  <p class="text-center ">Amount</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-12">
        <div class="row mt-3">
          <div class="col-lg-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between enterval-data">

                  <div class="mt-2">
                    <!-- <div class="wrapper"> -->
                    <button class="graph me-2" [ngClass]="paidChartType=='line'?'active':''"
                      (click)="toggleChartType('line',2)">Line</button>
                    <button class="graph" [ngClass]="paidChartType=='column'?'active':''"
                      (click)="toggleChartType('column',2)">Bar</button>
                    <!-- </div> -->
                  </div>
                  <div class="float end">
                    <ul class="days">
                      <li (click)="toggleDaysMonthYear('day',2)" [ngClass]="{'activate': activeSectionForPaid==='day'}">
                        <a>Day</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('week',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='week' }">
                        <a>Week</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('month',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='month'}">
                        <a>Month</a>
                      </li>
                      <!-- <li (click)="toggleDaysMonthYear('quarter',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='quarter'}"><a>Quarter</a></li> -->
                      <li (click)="toggleDaysMonthYear('year',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='year'}">
                        <a>Year</a>
                      </li>
                    </ul>

                  </div>
                </div>
                <div id="paidSubTrendChart" class="d-flex justify-content-center align-items-center">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="paidSubTrendOption"
                    [(update)]="updateFromInput" style="width:100%; height: 100%; display: block;"></highcharts-chart>
                </div>

              </div>
            </div>

          </div>


        </div>
      </div>
      <div class="col-lg-12 mt-3">
        <div class="row ">
          <div class="col-xl-12 stretch-card">
            <div class="card">
              <div class="card-body ">
                <div class="d-flex align-items-center justify-content-between enterval-data">
                  <div class="d-flex align-items-center justify-content-between">
                    <!-- <div class="sub-heading">Trial Summary Trend : <i class="bi bi-arrow-right-circle-fill "
                        (click)="sendDataToSubscription(tiralData?.subIds)"
                        [ngClass]="tiralData?.totalSub ? 'custom-arrow':'custom-arrow2'"></i>
                    </div> -->

                    <div class="mt-2 ms-3">
                      <!-- <div class="wrapper"> -->
                      <button class="graph me-2" [ngClass]="chartType=='line'?'active':''"
                        (click)="toggleChartType('line',1)">Line</button>
                      <button class="graph" [ngClass]="chartType=='column'?'active':''"
                        (click)="toggleChartType('column',1)">Bar</button>
                      <!-- </div> -->
                    </div>

                  </div>

                  <div class="float end">
                    <ul class="days">
                      <li (click)="toggleDaysMonthYear('day',1)" [ngClass]="{'activate': activeSection==='day'}">
                        <a>Day</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('week',1)" [ngClass]="{'activate': activeSection==='week'}">
                        <a>Week</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('month',1)" [ngClass]="{'activate': activeSection==='month'}">
                        <a>Month</a>
                      </li>
                      <!-- <li (click)="toggleDaysMonthYear('quarter',1)"
                        [ngClass]="{'activate': activeSection==='quarter'}">
                        <a>Quarter</a>
                      </li> -->
                      <li (click)="toggleDaysMonthYear('year',1)" [ngClass]="{'activate': activeSection==='year'}">
                        <a>Year</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row  p-3">
                  <div class="col-xl-3 col-sm-3 box-1">
                    <p class='card-heading card-heading1 mb-1 ' (click)="sendDataToSubscription(tiralData?.subIds) "
                      [ngClass]="tiralData?.totalSub>0?'cursor-pointer':''">Trial Signups</p>
                    <p class=" mb-0 title"><b>Amount : {{tiralData?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{tiralData?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{tiralData?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-3 box-2">
                    <p class="card-heading card-heading2 mb-1 " (click)="sendDataToSubscription(trialEpired?.subIds) "
                      [ngClass]="trialEpired?.totalSub>0 ? 'cursor-pointer':''">Trial Expired</p>
                    <p class=" mb-0 title"><b>Amount : {{trialEpired?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">

                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{trialEpired?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{trialEpired?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-3 box-3">
                    <p class="card-heading card-heading3 mb-1 " (click)="sendDataToSubscription(trialPaid?.subIds) "
                      [ngClass]="trialPaid?.totalSub>0 ? 'cursor-pointer':''">Trial to Paid</p>
                    <p class="mb-0 title"><b>Amount : {{trialPaid?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{trialPaid?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{trialPaid?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-3 box-4 pe-0">
                    <p class="card-heading card-heading4 mb-1" (click)="sendDataToSubscription(liveTrial?.subIds) "
                      [ngClass]="liveTrial?.totalSub>0 ? 'cursor-pointer':''">Live Trial</p>
                    <p class="mb-0 title"><b>Amount : {{liveTrial?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{liveTrial?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{liveTrial?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="trialReportChart" class="d-flex justify-content-center align-items-center">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="trialReportChartOption"
                    [(update)]="updateFromInput" style="width:100%; height: auto; display: block;"></highcharts-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>