import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-extend-to-expiry',
  templateUrl: './extend-to-expiry.component.html',
  styleUrls: ['./extend-to-expiry.component.scss']
})
export class ExtendToExpiryComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() subCode: string;
  @Input() planName: string;
  @Input() endDate: string;
  minDate: string;

  extendToExpiry = new FormGroup({
    endDate: new FormControl('', Validators.required),
    currentEndDate: new FormControl(''),
    remark: new FormControl('', Validators.required),
    isTempExt: new FormControl(false)
  })

  constructor(
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private datePipe: DatePipe

  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      const formattedEndDate = this.datePipe.transform(this.endDate, 'dd-MM-yyyy');
      this.extendToExpiry.controls.currentEndDate.setValue(formattedEndDate);
      this.calculateMinDate();
    })

  }


  calculateMinDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow
    this.minDate = this.formatDate(tomorrow);
  }

  formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }

  onDateChange(event: any) {
    const formattedDate = this.formatDate(new Date(event.target.value));
    this.extendToExpiry.get('endDate')?.setValue(formattedDate);
  }

  submit() {
    if (!this.extendToExpiry.valid) {
      this.extendToExpiry.markAllAsTouched();
    } else {
      const expiryDatePayload = this.extendToExpiry.value
      const currentEndDate = new Date(this.endDate)
      const newEndDate = new Date(expiryDatePayload.endDate || '')
      if (expiryDatePayload.isTempExt && currentEndDate > newEndDate) {
        this.toastr.warning('For temprory end date extension new end date must be greater then current end date.')
      } else {
        const payload = {
          subCode: this.subCode,
          endDate: expiryDatePayload.endDate,
          remark: expiryDatePayload.remark,
          isTempExt: expiryDatePayload.isTempExt
        }

        this.subscriptionService.extendExpiryDate(payload).subscribe(
          (res: any) => {
            if (res.success) {
              this.toastr.success(res.message);
              this.closedModal();
              this.event.emit('ok')
            } else {
              this.closedModal();
              this.toastr.warning(res.message);
            }
          },
          (err) => {
            this.closedModal();
            this.toastr.error(err.error.message);
          }
        );
      }

    }
  }

  closedModal() {
    this.bsModalRef.hide();
  }
}
