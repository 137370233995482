<!-- -------logout----modal--- -->
<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0"><b>Add Label</b></h4>
</div>
<form action="" [formGroup]="labelForm">
    <div class="modal-body">
        <div class="row mb-3">
            <label for="inputLabel" class="col-sm-3 col-form-label">Label Name</label>
            <div class="col-sm-9">
                <input type="text" class="form-control mt-1" id="inputLabel" formControlName="name">
            </div>
        </div>
        <div class="row mb-3">
            <label for="inputLabel" class="col-sm-3 col-form-label">Color</label>
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-1 mb-2 mt-1" *ngFor="let number of selectedColor">
                        <div class="label-color" [ngStyle]="{'background-color':number}" (click)="selectColor(number)">
                        </div>
                    </div>
                    <div class="col-4 mt-2">
                        <div class="color-pick" [ngStyle]="{'background-color':pickedColor}"></div>
                    </div>
                    <div class="col-8 mt-2">
                        <div class="custome-color d-flex">
                            <label for="exampleColorInput" class="form-label mb-0 me-2">Pick Custom Color</label>
                            <input type="color" class="form-control border-0 p-0 mt-1 form-control-color"
                                formControlName='color' id="exampleColorInput" value="Pick Custom Color"
                                title="Choose your color" (change)="selectCustomColor()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="text-end border-0">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">{{isEdit?'Update':'Submit'}}</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="closedModal()">Cancel</button>
</div>
<!-- --------------end--------- -->