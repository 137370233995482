import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InstanceRoot } from '../interfaces/instance';
import { VMLogRoot } from '../interfaces/vmLogs';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  constructor(
    private http: HttpClient
  ) { }


  instance(page: number, limit: number, instanceType: string) {
    return this.http.get<InstanceRoot>(`${environment.apiURL}/admin/instance?page=${page}&limit=${limit}&instanceType=${instanceType}`);
  }

  virtualMachine(page: number, limit: number, searchText: string) {
    return this.http.get<VMLogRoot>(`${environment.apiURL}/admin/virtualmachinelog?page=${page}&limit=${limit}&search=${searchText}`);
  }

  instanceSearchList(search: string, page: number, limit: number) {
    return this.http.get<InstanceRoot>(`${environment.apiURL}/admin/instance?search=${search}&page=${page}&limit=${limit}`);
  }

  instanceSearch(expireFrom: any, expireTo: any, page: number, limit: number) {
    return this.http.get<InstanceRoot>(`${environment.apiURL}/admin/instance?expireFrom=${expireFrom}&expireTo=${expireTo}&page=${page}&limit=${limit}`);
  }

  getAllfreeInstance(){
    return this.http.get(`${environment.apiURL}/admin/freeInstance`);
  }
  getAllLiveInstance(){
    return this.http.get(`${environment.apiURL}/admin/liveInstance`);
  }
  changeInstance(payload : any){
    return this.http.post(`${environment.apiURL}/admin/changeInstance`, payload)
  }
}
