<!-- ------reseller---page---- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-4">
        <h3 class="font-weight-bold heading"><b>Reseller</b></h3>
      </div>
      <div class="col-8 d-flex justify-content-end mb-4" *ngIf="userType">
        <button type="button" class="comman-btn btn-primary" [routerLink]="['/reseller/add-reseller']">+
          Reseller</button>
        <div class="dropdown" *ngIf="userType">
          <button class="comman-btn btn-primary ms-2 dropdown-toggle" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false" aria-label="Search">
            <i class="bi bi-tags-fill me-2"></i>
          </button>
          <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
            <div class="mb-3 row">
              <div class="col-sm-12">
                <p>Search here</p>
                <p><input type="text" class="form-control" [(ngModel)]="labelSearchText" id="inputText"
                    (ngModelChange)="labelSearch()"></p>
                <div *ngFor="let label of labels">
                  <p [ngStyle]="{'background-color': label?.color, 'text-align': 'center'}"
                    (click)="assignLabel(label._id)">
                    {{label?.name}}</p>
                </div>
                <p class="mt-3 label-section" (click)="labelModal()"><b>Add Label</b></p>
                <p class="label-section" [routerLink]="['/manage-label']"><b>Manage Labels</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ---------search--form--------------- -->
      <div class="col-md-12" *ngIf="!isResellerReq && isResellerAvailable">
        <div class="search-form">
          <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
            aria-expanded="false" aria-controls="collapseExample">
            <i class="bi bi-search"></i>
            <span class="ms-5">Search here</span>
          </p>
          <div class="collapse" id="collapseExample">
            <form class="row g-3 mt-2" [formGroup]="searchForm">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Company Name</span>
                  <input type="text" class="form-control" placeholder="Company Name" formControlName="companyName"
                    aria-label="Username" aria-describedby="basic-addon1">
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Email</span>
                  <input type="email" class="form-control" placeholder="Email" formControlName="email"
                    aria-label="Username" aria-describedby="basic-addon1">
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Mobile</span>
                  <input type="number" class="form-control" formControlName="mobile" placeholder="Mobile"
                    aria-label="Username" aria-describedby="basic-addon1">
                </div>
              </div>

              <div class="col-md-6 d-flex">
                <span class="input-group-text custom-label reseller-type" id="basic-addon1">Reseller Type</span>
                <select class="form-select custom-select reseller-type" formControlName="partnerType"
                  aria-label="Default select example">
                  <option selected>Please Select</option>
                  <option value="partner">Partner</option>
                  <option value="white label">White Label</option>
                </select>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-7 pe-0">
                    <div class="input-group mb-2 responsive-field">
                      <span class="input-group-text" id="basic-addon1">Registration Date</span>
                      <input type="date" class="form-control custom-label registrationFrom"
                        formControlName="registrationFrom" placeholder="From" aria-label="Username"
                        aria-describedby="basic-addon1">
                    </div>
                  </div>

                  <div class="col-md-5 ps-0">
                    <div class="input-group mb-2 responsive-field-1">
                      <span class="input-group-text" id="basic-addon1">To</span>
                      <input type="date" class="form-control custom-label" formControlName="registrationTo"
                        placeholder="To" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-7 pe-0">
                    <div class="input-group mb-2 responsive-field">
                      <span class="input-group-text" id="basic-addon1">No. of Accounts</span>
                      <input type="number" class="form-control custom-label" formControlName="noOfAccountsFrom"
                        placeholder="From" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                  </div>

                  <div class="col-md-5 ps-0">
                    <div class="input-group mb-2 responsive-field-1">
                      <span class="input-group-text " id="basic-addon1">To</span>
                      <input type="number" class="form-control custom-label" formControlName="noOfAccountsTo"
                        placeholder="To" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-7 pe-0">
                    <div class="input-group mb-2 responsive-field">
                      <span class="input-group-text" id="basic-addon1">Credit </span>
                      <input type="number" class="form-control custom-label" formControlName="amountFrom"
                        placeholder="From" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                  </div>

                  <div class="col-md-5 ps-0">
                    <div class="input-group mb-2 responsive-field-1">
                      <span class="input-group-text" id="basic-addon1">To</span>
                      <input type="number" class="form-control custom-label" formControlName="amountTo" placeholder="To"
                        aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 d-flex">
                <span class="input-group-text custom-label" id="basic-addon1">Label</span>
                <select class="form-select custom-select " aria-label="Default select example" formControlName="label">
                  <option value="" selected>Please Select</option>
                  <option *ngFor="let label of labels" id="labelOption"
                    [ngStyle]="{'background-color': label?.color, 'text-align': 'center', 'border-radius':'5px', 'font-size':'0.8rem'}"
                    value="{{label.name}}">{{label.name|titlecase}}</option>
                </select>
              </div>

              <div class="col-md-6 d-flex">
                <span class="input-group-text custom-label" id="basic-addon1">Account Manager</span>
                <select class="form-select custom-select" aria-label="Default select example"
                  formControlName="accountManagerId">
                  <option selected value="">Please Select</option>
                  <option *ngFor="let accountManager of accountManagers" value="{{accountManager._id}}">
                    {{accountManager.name}}</option>
                </select>
              </div>

              <div class="col-md-6 d-flex">
                <span class="input-group-text custom-label" id="basic-addon1">Status</span>
                <select class="form-select custom-select" aria-label="Default select example"
                  formControlName="isActive">
                  <option value="" selected>All</option>
                  <option [ngValue]="false">Suspend</option>
                  <option [ngValue]="true">Active</option>
                </select>
              </div>

              <div class="col-md-12 text-end">
                <button type="submit" class="comman-btn1 btn-primary me-2" (click)="searchList()">Search</button>
                <button type="search" class="comman-btn1 btn-secondary text-light"
                  (click)="resetSearchForm()">Reset</button>
              </div>
            </form>
            <!-- ------ -->
          </div>
        </div>
      </div>
      <!-- -------end-------------- -->
      <!-- --------reseller-table------- -->
      <div class="row table-section mt-3 pe-0">
        <div class="col-md-12  stretch-card pe-0">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="mb-3">
                  <ul class="d-flex service ps-0 mb-0">
                    <li (click)="toggle('partner')" [ngClass]="activation==='partner'?'activate':''">
                      <a>Partners</a>
                    </li>
                    <li (click)="toggle('partnerReq')" [ngClass]="activation==='partnerReq'?'activate':''">
                      <a>Partners Requests</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mb-4 records">{{updatePaginationText}}</div>
              <div *ngIf="!isResellerAvailable && !isResellerReq" class="text-center">
                <h4>There is no Products added yet. To add Products click</h4>
                <button type="button" class="comman-btn btn-primary" [routerLink]="['/reseller/add-reseller']"
                  *ngIf="userType">+
                  Reseller</button>
              </div>
              <div class="table-responsive" *ngIf="isResellerAvailable && !isResellerReq">
                <table id="partnerTable" class="table table-hover table-borderless">
                  <thead class="roundedTable thead-header">
                    <tr>
                      <th></th>
                      <th>#</th>
                      <th class="custom-pointer" (click)="sortingPartner('id')">Reseller ID<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th>Account Manager </th>
                      <th>Label</th>
                      <th>Company Name</th>
                      <th>Reseller Type</th>
                      <th>Tally Yearly Price</th>
                      <th class="custom-pointer" (click)="sortingPartner('wallet')">Wallet<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th>Paid Sub</th>
                      <th>Trial Sub</th>
                      <th>Total Users</th>
                      <th>Active Customer</th>
                      <th>Status</th>
                      <th>SSL</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody class="resellerTable">
                    <ng-container *ngFor="let list of partnerLists | paginate : config">
                      <tr>
                        <td (click)="list.collapse = !list.collapse" class="collapse-data">
                          <i *ngIf="list.collapse" [ngStyle]="{'color': 'green'}" class="bi bi-plus-circle"></i>
                          <i *ngIf="!list.collapse" [ngStyle]="{'color': 'red'}" class="bi bi-dash-circle"></i>
                        </td>
                        <td>
                          <input type="checkbox" (click)="selectPartner(list._id)" aria-label="Search" />
                        </td>
                        <td class="detailsCursor">{{list?.id}}</td>
                        <td>{{list?.accountManager | titlecase}}</td>
                        <td>
                          <div class="p-1"
                            [ngStyle]="{'background-color': list?.label?.color, 'text-align': 'center', 'border-radius':'5px', 'font-size':'0.8rem'}">
                            {{list?.label?.name?list?.label?.name: '-'}}</div>
                        </td>
                        <td>
                          <div class="d-inline-block text-truncate" style="max-width: 120px;"
                            ngbTooltip="{{list?.companyName |titlecase}}" placement="top"> <a class="sub-link"
                              (click)="redirectToPartner(list.email)">{{list?.companyName | titlecase}}</a></div>

                        </td>
                        <td>{{list?.partnerType | titlecase}}</td>
                        <td>{{list?.yearlyPlanPrice }}</td>
                        <td>{{list?.wallet |number : '1.2-2'}}</td>
                        <td>{{list?.paidSub}}</td>
                        <td>{{list?.trialSub}}</td>
                        <td>{{list?.instanceUser + list?.customer}}</td>
                        <td>{{list?.paidSub + list?.trialSub}}</td>
                        <td>
                          <button class="comman-btn btn-outline-success"
                            [ngClass]="[list?.isActive? 'active': 'inActive']">{{list.isActive ?
                            'Active' : 'InActive'}}</button>
                        </td>
                        <td>{{list?.isSSL ? 'Yes':'No'}}</td>
                        <td>
                          <div class="dropdown">
                            <button class="comman-btn1 btn-primary dropdown-toggle" type="button"
                              id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
                              Action
                            </button>
                            <ul class="dropdown-menu menu-item-1 mouseCursor" aria-labelledby="dropdownMenuButton">
                              <li *ngIf="userType ">
                                <a class="dropdown-item">
                                  Manage &raquo;
                                </a>
                                <ul class="dropdown-menu dropdown-submenu menu-item-2" *ngIf="userType">
                                  <li>
                                    <a class="dropdown-item" (click)="addBills(list._id)">Add Bills</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" (click)="removeLabel(list?._id, list?.label?.id)">Remove
                                      Label</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" (click)="changePlanPrice(list._id)">Change Plan Pricing</a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="onToggle(list._id, list.isActive)"
                                  [(ngModel)]="list.isActive" ngDefaultControl>
                                  {{list.isActive?'Suspend':'Activate'}}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="editPartner(list._id)">Edit</a>
                              </li>
                              <li *ngIf="userType">
                                <a class="dropdown-item" (click)="openModal(list._id)">Delete</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="redirectToPartner(list.email)">Manage
                                  Reseller</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr class="collapse" [class.show]="list.collapse == false">
                        <td colspan="12">
                          <p>
                            <label for="">Name:</label>
                            <span>{{list?.name | titlecase}}</span>
                          </p>
                          <p>
                            <label for="">Mobile No:</label>
                            <span>{{list?.mobile}}</span>
                          </p>
                          <p>
                            <label for="">Email Id:</label>
                            <span>{{list?.email}}</span>
                          </p>
                          <p>
                            <label for="">Wallet Amount:</label>
                            <span>{{list?.wallet}}</span>
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="mt-4 float-end me-4" *ngIf="isResellerAvailable && !isResellerReq">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
              <div class="table-responsive" *ngIf="isResellerReq && !isResellerAvailable">
                <table id="partnerTable" class="table table-hover table-borderless">
                  <thead class="roundedTable thead-header">
                    <tr>
                      <th>Partner Name </th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Company Name</th>
                      <th>Account Manager</th>
                      <th>Country</th>
                      <th>State</th>
                      <th>Sales Team</th>
                      <th>Support Team</th>
                      <th>Created At</th>
                      <th>Category</th>
                      <th>Approved By</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody class="resellerTable">
                    <ng-container *ngFor="let list of partnerReqLists | paginate : config">
                      <tr>
                        <td>{{list?.name | titlecase}}</td>
                        <td>{{list?.mobile }}</td>
                        <td>
                          <div class="d-inline-block text-truncate" style="max-width: 120px;"
                            ngbTooltip="{{list?.email |titlecase}}" placement="top"> {{list?.email | titlecase}}
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-block text-truncate" style="max-width: 120px;"
                            ngbTooltip="{{list?.companyName |titlecase}}" placement="top"> {{list?.companyName |
                            titlecase}}
                          </div>
                        </td>
                        <td>
                          {{list?.accountManager | titlecase}}
                        </td>
                        <td>{{list?.country}}</td>
                        <td>{{list?.state}}</td>
                        <td class="text-center">{{list?.salesTeamSize}}</td>
                        <td class="text-center">{{list?.supportTeamSize}}</td>
                        <td>{{list?.createdAt | date:'MMM d, y, H:mm'}}</td>
                        <td>{{list?.category ? list?.category :'N/A'}}</td>
                        <td>{{list?.approvedBy ? (list?.approvedBy | titlecase) :'N/A' }}</td>
                        <td [ngClass]="{
                          'status-pending': list?.status === 'pending',
                          'status-rejected': list?.status === 'rejected',
                          'status-approved': list?.status === 'approved'
                        }">
                          <div [ngbTooltip]="list?.remark | titlecase"> {{list?.status | titlecase}}</div>
                        </td>
                        <td [ngClass]="{ 'disabled': list?.status === 'rejected' ||  list?.status === 'approved'}"
                          class="text-center"> <i class="bi bi-pencil-square pencil" ngbTooltip="Update Status"
                            placement="top" (click)="updateStatus(list)"></i>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="mt-4 float-end me-4" *ngIf="isResellerReq  && !isResellerAvailable">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ---------------------end-------------------------- -->