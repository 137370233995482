import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SettingRoot } from '../interfaces/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient
  ) { }

  settings(settingPayload: any) {
    return this.http.post<SettingRoot>(`${environment.apiURL}/admin/setting`, settingPayload)
  }

  settingsDetail(){
    return this.http.get<SettingRoot>(`${environment.apiURL}/admin/setting`)
  }
}
