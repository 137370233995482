<!-- -------add---admin----page----- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 col-xl-8 mb-2 mb-xl-0 heading-section">
        <h3 class="font-weight-bold mb-0 mt-0"><i class="bi bi-arrow-left-short fs-2"
            [routerLink]="['/account-manager']"></i>
          Account
          Manager</h3>
      </div>
    </div>
    <div class="row mt-4 mx-1">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

            <!-- ----------------- -->
            <form [formGroup]="adminForm">
              <div class="row mb-3">
                <div class="col-md-6">
                  <p class=" heading">Account Manager Details</p>
                  <hr />
                  <div class="row mb-3">
                    <label for="fullName" class="mb-2">Full Name</label>
                    <div class="mt-2">
                      <input type="text" class="form-control" formControlName="name">
                      <div *ngIf="adminForm.get('name')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="adminForm.get('name')?.errors?.['required'] && (adminForm.get('name')?.dirty || adminForm.get('name')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message" *ngIf="adminForm.get('name')?.errors?.['pattern']">
                          <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="mobile" class="mb-2">Mobile Number</label>
                    <div class="mt-2">
                      <input type="text" class="form-control" formControlName="mobile" maxlength="10">
                      <div *ngIf="adminForm.get('mobile')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="adminForm.get('mobile')?.errors?.['required'] && (adminForm.get('mobile')?.dirty || adminForm.get('mobile')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Mobile number is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message"
                          *ngIf="adminForm.get('mobile')?.errors?.['pattern'] || adminForm.get('mobile')?.errors?.['maxlength']">
                          <p class="text-danger m-0 p-0"><small>Please enter valid mobile number.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="email" class=" mb-2">Email Id</label>
                    <div class="mt-2">
                      <input type="email" class="form-control" formControlName="email">
                      <div *ngIf="adminForm.get('email')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="adminForm.get('email')?.errors?.['required'] && (adminForm.get('email')?.dirty || adminForm.get('email')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Email Id is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message" *ngIf="adminForm.get('email')?.errors?.['pattern']">
                          <p class="text-danger m-0 p-0"><small>Please enter valid Email Id.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3" [hidden]="isEdit">
                    <label for="password" class="mb-2">Password</label>
                    <div class="mt-2 hide-icon">
                      <input [type]="passType" class="form-control" formControlName="password" minlength="8">
                      <div class="hideshow-password"><i [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'"
                          (click)="changePasswordType()"> </i></div>
                      <div *ngIf="adminForm.get('password')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="adminForm.get('password')?.errors?.['required'] && (adminForm.get('password')?.dirty || adminForm.get('password')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message"
                          *ngIf="adminForm.get('password')?.errors?.['pattern'] || adminForm.get('password')?.errors?.['minlength']">
                          <p class="text-danger m-0 p-0"><small>Password must be at least 8 digit.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="role" class=" mb-2">Role</label>
                    <div class="mt-2 ">
                      <select class="custom-select form-control" formControlName="role">
                        <option value="manager">Manager</option>
                        <option value="salesManager">Sales Manager</option>
                        <option value="support">Support</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" [hidden]="!isEdit" *ngIf="!adminDetail?.isSuperAdmin">
                  <p class=" heading">Account manager Access</p>
                  <hr />
                  <input type="checkbox" class="me-3" (change)="allowAll($event)" [checked]="allSelected" />
                  <label class="lbl mb-0"><b>Allow All</b></label>
                  <p class="mb-0 mt-0 position-relative"> <small class="text-muted ms-4 access">Allow to access all
                      users</small></p>
                  <div class="dotted-line"></div>

                  <div class="position-relative user-class" *ngFor="let list of accountManagerList; let i = index">
                    <input type="checkbox" class="me-3" (change)="toggleUserAccess($event, i)"
                      [checked]="list.isSelected" [disabled]="list._id == this.adminId" />
                    <label class="lbl mb-0"><b>{{list.name}}</b></label>
                    <p class="mb-0 mt-0 position-relative"> <small class="text-muted ms-4 access">{{list.email}}</small>
                    </p>
                  </div>

                </div>

              </div>


              <div class="row mb-3">
                <div class="col-sm-12 mt-2 text-end">
                  <button type="submit" class="comman-btn1 btn-primary" (click)="addAdmin()">{{isEdit? 'Update':
                    'Submit'}}</button>
                  <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                    [routerLink]="['/account-manager']">Cancel</button>
                </div>
              </div>
            </form>
            <!-- ------------------ -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>