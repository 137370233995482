import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { WalletRoot } from 'src/app/interfaces/wallet';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-add-bills-modal',
  templateUrl: './add-bills-modal.component.html',
  styleUrls: ['./add-bills-modal.component.scss']
})
export class AddBillsModalComponent implements OnInit {

  @Input() partnerId: string;
  selectedValue: string = '';

  walletForm = new FormGroup({
    billingAction: new FormControl('', Validators.required),
    paymentType: new FormControl('', Validators.required),
    amount: new FormControl('', Validators.required),
    descriptionByAdmin: new FormControl('')
  })
  wallet: any;

  constructor(
    private walletService: WalletService,
    public bsModalRef: BsModalRef,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
  }

  addWallet() {
    if (!this.walletForm.valid) {
      this.walletForm.markAllAsTouched();
    }
    else {
      this.addWalletAmount();
    }
  }

  addWalletAmount() {
    let walletPayload = {}
    const formData = this.walletForm.value
    const wallet = formData.paymentType === 'credit' ? { credit: formData.amount } : { debit: formData.amount }
    walletPayload = Object.assign(formData, wallet, { partnerId: this.partnerId });
    this.walletService.walletAmount(walletPayload).subscribe((res: WalletRoot) => {
      if (res.success) {
        this.walletService.adminProfileDetails();
        this.toasts.success(res.message);
        this.closedModal();
      }
      else {
        this.toasts.warning(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      })
  }

  closedModal() {
    this.bsModalRef.hide();
  }

}
