<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 mb-3">
     <div class="row">
      <div class="col-6">
       <h3 class="font-weight-bold heading"><b>All Product</b></h3>
      </div>
      <div class="col-6 text-end">
       <button type="button" class="comman-btn1 btn-primary me-2" (click)="addProductModal()">+ Product</button>
      </div>
     </div>
    </div>
    <!-- ---------table-section--------- -->
    <div class="row table-section pe-0">
     <div class="col-md-12 grid-margin stretch-card pe-0">
      <div class="card">
       <div class="card-body">
        <div *ngIf="!isProductAvailable" class="text-center">
         <h4>There is no Products added yet. To add Products click</h4>
         <button type="button" class="comman-btn1 btn-primary me-2" (click)="addProductModal()">+
          Product</button>
        </div>
        <div class="table-responsive" *ngIf="isProductAvailable">
         <table class="table table-striped table-borderless">
          <thead class="roundedTable thead-header">
           <tr>
            <th scope="col">Name</th>
            <th scope="col">No. of Plans</th>
            <th scope="col">No. of Add-Ons</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">Action</th>
           </tr>
          </thead>
          <tbody>
           <tr *ngFor="let product of products">
            <td class="productNameCursor" (click)="editProduct(product._id)">{{product.name}}</td>
            <td>{{product.plans.length}}</td>
            <td>{{product.addOns.length}}</td>
            <td [ngClass]="[product.isActive? 'activeStatus': 'inactiveStatus']">{{product.isActive?
             'Active':
             'Inactive'}}</td>
            <td class="text-center">
             <i class="bi bi-pencil-fill text-primary productNameCursor me-3" (click)="editProduct(product._id)"></i>
             <i class="bi bi-trash3-fill text-danger productNameCursor" (click)="deleteModal(product._id)"></i>
            </td>
           </tr>
          </tbody>
         </table>
        </div>
       </div>
      </div>
     </div>
    </div>
    <!-- ----end------- -->
   </div>
  </div>
 </div>