<div class="main-panel">
    <div class="content-wrapper">
        <div class="col-md-6">
            <h3 class="font-weight-bold heading"><b> Scheme Details</b></h3>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12">
                            <div class="row mb-4">
                                <div>
                                    <p class="mt-2 "><span class="header">{{schemeDetailData?.name}} </span><span
                                            class="float-end"><a (click)=" updateScheme()" class="edit">Edit</a></span>
                                    </p>
                                    <p><span class="me-3">Start Date : {{schemeDetailData?.fromDate}}</span> <span
                                            *ngIf="occuranceType !=='recurring'" class="ms-3">End Date :
                                            {{schemeDetailData?.toDate}}</span> </p>
                                </div>
                                <div>
                                    <p class="heading">Selected Plans</p>
                                    <div class="col-sm-12 table-responsive">
                                        <table class="table">
                                            <thead class="roundedTable thead-header">
                                                <tr>
                                                    <th>#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let plans of schemePlans,let i=index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{plans.name}}</td>
                                                    <td>{{plans?.price}}</td>
                                                    <td>{{plans?.code}}</td>

                                                    <td>{{plans?.description}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <h4 class="mt-2 heading">Selected Users & Value</h4>
                                    <div class="col-sm-12 table-responsive mt-3">

                                        <table class="table table-sm">
                                            <thead class="roundedTable thead-header">
                                                <tr>
                                                    <th scope="col">S.no</th>
                                                    <th scope="col" class="text-center">No. of Users</th>
                                                    <th scope="col" class="text-center">Value(%)</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of schemeData">
                                                    <td>{{ data.index }}</td>
                                                    <td class="text-center">{{ data.noOfUsers }}</td>
                                                    <td class="text-center">{{ data.valueRange }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="row mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <p class="heading">Eligible Partners</p>
                                    <div class="records mt-2 mb-3">{{paginationList}}</div>
                                </div>
                                <div *ngIf="occuranceType =='recurring'">
                                   <form [formGroup]="searchForm">
                                    <mat-form-field appearance="fill" class="custom-date-picker w-100">
                                        <mat-label>Choose a month and year</mat-label>
                                        <input matInput type="month" formControlName="date" [min]="minDate" />
                                    </mat-form-field>
                                   </form>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 table-responsive">
                            <table class="table" *ngIf="isSchemepartnerListAvailable">
                                <thead class="roundedTable thead-header">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Company Name</th>
                                        <th scope="col">Total Users</th>
                                        <th scope="col">Activation Value</th>
                                        <th scope="col">Reward Value</th>
                                        <th scope="col">Reward Amount</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of schemePartnerList | paginate : config">

                                        <td>{{list.name}}</td>
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 120px;"
                                                ngbTooltip="{{list?.email }}" placement="top"> {{list?.email}}</div>

                                        </td>
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 120px;"
                                                ngbTooltip="{{list?.companyName | titlecase}}" placement="top">
                                                {{list?.companyName | titlecase}}</div>

                                        </td>
                                        <td>{{list?.totalUsers}}</td>
                                        <td>{{list?.values | number:'0.2-2'}}</td>
                                        <td>{{list?.discountSlab?.value>0 ? list?.discountSlab?.value+'%' : '0%'}}</td>
                                        <td>{{list?.discountValue >0? (list?.discountValue | number:'0.2-2'): 0 }}</td>
                                        <td>
                                            <button class=" me-3"
                                                (click)="restrictPartner(list?.partnerId,list?.discountValue,list?.month,list?.year)"
                                                [ngClass]="list?.isRewarded?'approved':'approve'"
                                                [disabled]="list?.isRewarded ">{{list?.isRewarded ?
                                                'Approved' : 'Approve' }}</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-4 float-end me-4" *ngIf="isSchemepartnerListAvailable">
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>