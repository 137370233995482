<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0"><b>Update Infra Exe</b></h4>
</div>
<form action="" [formGroup]="serverExeForm">
    <div class="modal-body pt-0 pb-0 py-0">
        <div class="row mb-3">
            <div class="col-sm-12">
                <label for="fullName" class="mb-2"><b>Select Servers</b></label>
                <div class="dropdown-field custom-select custom-select1">
                    <div class="dropdown psd">
                        <p class="down-btn mt-0 mb-0 form-select psd form- w-100 d-flex align-items-center"
                            type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ (selectedServersIds.length==0)?"Select server":
                            selectedServersIds.length + " server selected" }}
                        </p>
                        <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-4 pe-4"
                            aria-labelledby="dropdownMenuButton">
                            <div class="row search ps-0 pe-0 pt-2 pb-3 align-items-center">
                                <div class="col-7">
                                    <input type="text" class="form-control" formControlName="searchText1"
                                        placeholder="Search here" />
                                </div>
                                <div class="col-5">
                                    <input type="checkbox" formControlName="filterUpdateExe" class="me-2" />
                                    <label><b>Filter Out</b> <i class="bi bi-info-circle ms-2"
                                            ngbTooltip="Filter out those servers which were updated today."></i></label>
                                </div>

                            </div>
                            <div class=" customerList ">
                                <input type="checkbox" class="me-4" (change)="selectAll($event)"
                                    [checked]="allServersSelected" />
                                <label>Select All</label>
                            </div>

                            <div class="customerList" *ngFor="let server of serverLists  | filter: searchText1:'name'">
                                <li (click)="togglePlanSelection(server._id,$event)">
                                    <input type="checkbox" [checked]="isServerSelected(server._id)" class="me-4" />
                                    {{ server?.name }}
                                </li>
                            </div>
                        </ul>
                        <div
                            *ngIf="serverExeForm.get('serverIds')?.errors?.['required'] && (serverExeForm.get('serverIds')?.dirty || serverExeForm.get('serverIds')?.touched)">
                            <p class="text-danger m-0 p-0">
                                <small>It is mandatory field.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3">
                <div>
                    <label for="file" class="mb-2"><b>Choose file to upload</b></label>
                    <input type="file" id="file" name="file" class="form-control form-control1"
                        formControlName="fileSelect" #fileInput />
                </div>
                <div
                    *ngIf="serverExeForm.get('fileSelect')?.errors?.['required'] && (serverExeForm.get('fileSelect')?.dirty || serverExeForm.get('fileSelect')?.touched)">
                    <p class="text-danger m-0 p-0">
                        <small>It is mandatory field.</small>
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="modal-footer rating-bg border-0 p-0">
        <div class="row mb-3 pe-2">
            <div class="col-sm-12 mt-2 text-end">
                <button type="submit" class="comman-btn1 btn-primary" (click)="submit()">Submit</button>
                <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                    (click)="closedModal()">Cancel</button>
            </div>
        </div>
    </div>
</form>