<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0 mb-1 p-0"><b>New Tally Release:</b></h4>
</div>
<form action="" [formGroup]="tallyReleaseForm">
    <div class="modal-body pt-0 pb-0">
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Full Name</label>
            <div class="col-sm-8 mt-2">
                <input type="text" class="form-control" id="fullName" placeholder="Enter Version Name" formControlName="versionName">
                <div *ngIf="tallyReleaseForm.get('versionName')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="tallyReleaseForm.get('versionName')?.errors?.['required'] && (tallyReleaseForm.get('versionName')?.dirty || tallyReleaseForm.get('versionName')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Full Name is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message" *ngIf="tallyReleaseForm.get('versionName')?.errors?.['pattern']">
                        <p class="text-danger m-0 p-0"><small>Please enter Full Name</small></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Download Link</label>
            <div class="col-sm-8 mt-2">
                <input type="text" class="form-control" id="fullName" placeholder="Enter download link"
                    formControlName="link">
                <div *ngIf="tallyReleaseForm.get('link')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="tallyReleaseForm.get('link')?.errors?.['required'] && (tallyReleaseForm.get('link')?.dirty || tallyReleaseForm.get('link')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Download Link is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message" *ngIf="tallyReleaseForm.get('link')?.errors?.['pattern']">
                        <p class="text-danger m-0 p-0"><small>Please enter valid Download Link.</small></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Update Link</label>
            <div class="col-sm-8 mt-2">
                <input type="text" class="form-control" id="fullName" placeholder="Enter Update Link"
                    formControlName="updateLink">
                <div *ngIf="tallyReleaseForm.get('updateLink')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="tallyReleaseForm.get('updateLink')?.errors?.['required'] && (tallyReleaseForm.get('updateLink')?.dirty || tallyReleaseForm.get('updateLink')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Update Link is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message" *ngIf="tallyReleaseForm.get('updateLink')?.errors?.['pattern']">
                        <p class="text-danger m-0 p-0"><small>Please enter valid Update Link.</small></p>
                    </div>
                </div>
            </div>
        </div>

    <div class="modal-footer border-0 p-0 m-0">
        <div class="row">
            <div class="col-sm-12 mt-2 text-end">
                <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">{{isEdit? 'Update':
                    'Submit'}}</button>
                <button type="button" class="comman-btn1 btn-secondary text-light me-2"
                (click)="closedModal()" >Cancel</button>
            </div>
        </div>
    </div>
    </div>
</form>
