import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TallyReleaseService {

  constructor(
    private http:HttpClient
  ) { }

  tallyReleaseList(){
   return this.http.get(`${environment.apiURL}/admin/tally`)
  }

  addTallyRelease(tallyReleasePayload:any){
    return this.http.post(`${environment.apiURL}/admin/tally`,tallyReleasePayload)
  }

  tallyReleaseDetails(tallyReleaseId:string){
    return this.http.get(`${environment.apiURL}/admin/tally/details?tallyVersionId=${tallyReleaseId}`)
   }
 
   updateTallyRelease(tallyReleasePayload:any){
     return this.http.put(`${environment.apiURL}/admin/tally`,tallyReleasePayload)
   }
}
