import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DiscountService } from 'src/app/services/discount.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-scheme-deatils',
  templateUrl: './scheme-deatils.component.html',

  styleUrls: ['./scheme-deatils.component.scss']
})
export class SchemeDeatilsComponent implements OnInit {

  schemeId: any
  schemeDetailData: any
  isSchemepartnerListAvailable: boolean = false
  schemePlans: any
  schemeData: any[] = [];
  schemePartnerList: any[] = []
  totalCount: number = 0
  page: number = 1
  listvalue: ''
  bsModalRef!: BsModalRef;
  occuranceType!: any
  minDate !: any
  config = {
    itemsPerPage: 15,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  searchForm = new FormGroup({
    date: new FormControl('')
  })
  constructor(

    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private discountService: DiscountService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substring(0, 7);
    this.searchForm.get('date')?.patchValue(formattedDate);
    this.route.queryParams.subscribe(params => {
      this.schemeId = params['schemeId'];
      this.occuranceType = params['occuranceType']
      if (this.schemeId) {
        this.schemeDetail()
      }
    })

    this.route.queryParams.subscribe((param: any) => {
      this.listvalue = param['type']
    })
    this.schemeAppliedPartnerList()
    this.searchForm.get('date')?.valueChanges.subscribe((value: any) => {
      this.schemeAppliedPartnerList()
    })


  }
  updateScheme() {
    this.router.navigate(['scheme/add'], { queryParams: { schemeId: this.schemeId, type: this.listvalue } });
  }
  schemeDetail() {
    this.discountService.schemeDetail(this.schemeId).subscribe((res: any) => {
      if (res.success) {
        this.schemePlans = res?.selectedPlanDetails
        this.schemeDetailData = res.scheme;
        this.minDate = moment(this.schemeDetailData?.fromDate).format("YYYY-MM")
        this.schemeData = res.scheme?.rewardValue?.map((data: any, index: any, arr: any[]) => {
          let nextNoOfUsers = arr[index + 1] ? arr[index + 1].noOfUsers - 1 : 'Above';
          return {
            index: index + 1,
            noOfUsers: `${data.noOfUsers}-${nextNoOfUsers}`,
            valueRange: data.value
          };
        });

      }
    }, (error) => {
      this.toastr.error(error.error.message);
    });
  }

  schemeAppliedPartnerList() {
    const payload = {
      schemeId: this.schemeId,
      occurrenceType: this.occuranceType,
      page: this.config.currentPage,
      limit: this.config.itemsPerPage,
      date: this.searchForm.get('date')?.value
    }
    this.discountService.schemeAppliedPartnerList(payload).subscribe((res: any) => {
      if (res.success) {
        if (res?.partnerDetails.length == 0) {
          this.toastr.error('No data found');
          this.config.currentPage = 1
          this.config.totalItems = 0
          this.isSchemepartnerListAvailable = false
          this.schemePartnerList = []
        } else {
          this.isSchemepartnerListAvailable = true
          this.schemePartnerList = res?.partnerDetails
          this.config.currentPage = res?.totalSchemes?.pages;
          this.config.totalItems = res?.totalSchemes?.total;
        }
      }
    }, (error) => {
      this.toastr.error(error.error.message);
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event
    this.schemeAppliedPartnerList()
  }
  get paginationList() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
  }

  rewardTriggred(partnerId: string, rewardValue: number,month :any, year:any) {
    const payload = {
      schemeId: this.schemeId,
      partnerId: partnerId,
      month:month,
      year:year,
      occurrence: this.occuranceType,
      rewardValue: rewardValue
    }
    this.discountService.rewardPartner(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message);
        this.schemeAppliedPartnerList()
      }
    },
      (error) => {
        this.toastr.error(error.error.message);
      })
  }

  restrictPartner(partnerId: string, rewardValue: number, month:any,year:any) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.schemeId = this.schemeId
    this.bsModalRef.content.title = 'Partner Scheme Eligibility Approval';
    this.bsModalRef.content.message = 'Subscriptions under this scheme will be restricted from deletion according to the defined billing cycle.';
    this.bsModalRef.content.confirmText = 'Approve';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.rewardTriggred(partnerId, rewardValue,month,year)
      }
    });
  }
}
