<!-- ---------------Audit Logs---------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <h3 class="font-weight-bold heading"><b>{{isEdit ? 'Update Coupon':'Add Coupon'}} </b></h3>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card border-class">
                <div class="card-body pb-1 position-relative">
                  <div class="row">
                    <form [formGroup]="couponForm">
                      <div class="col-sm-12">
                        <div class="row p-2">
                          <div class="col-5">
                            <h3 class="mb-2"><b>Coupon</b></h3>
                            <p class="text-gray">
                              Set up the main details of the coupon.
                            </p>
                          </div>
                          <div class="col-7">
                            <div class="row">
                              <label for="fullName" class=""><b>Coupon name</b></label>
                              <div class="mt-2">
                                <input type="text" class="form-control" id="fullName"
                                  placeholder="E.g First purchase discount" formControlName="name" />
                                <div *ngIf="couponForm.get('name')?.invalid" class="alert mb-0 p-0">
                                  <div class="error-message"
                                    *ngIf="couponForm.get('name')?.errors?.['required'] && (couponForm.get('name')?.dirty || couponForm.get('name')?.touched)">
                                    <p class="text-danger m-0 p-0">
                                      <small>It is mandatory.</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p class="text-gray mt-3">
                                The name of the coupon can be displayed to your
                                customers through your application.
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>

                      <div class="col-sm-12">
                        <div class="row p-2">
                          <div class="col-5">
                            <h3 class="mb-2"><b>Plan And User</b></h3>
                            <p class="text-gray">Set plan and User</p>
                          </div>
                          <div class="col-7">
                            <div class="row">
                              <div class="col-sm-6">
                                <label for="fullName" class="mb-2"><b>Min User</b></label>
                                <input type="number" formControlName="minUser" class="form-control" placeholder="e.g 10"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <div
                                  *ngIf="couponForm.get('minUser')?.errors?.['required'] && (couponForm.get('minUser')?.dirty || couponForm.get('minUser')?.touched)">
                                  <p class="text-danger m-0 p-0">
                                    <small>It is mandatory field.</small>
                                  </p>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <label for="fullName" class="mb-2"><b>Select Plans</b></label>
                                <div class="dropdown-field custom-select custom-select1">
                                  <div class="dropdown psd">
                                    <p class="down-btn mt-0 mb-0 form-select psd form- w-100 d-flex align-items-center"
                                      type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      {{ (selectedPlanIds.length==0)?"Select plan":
                                      selectedPlanIds.length + " plan selected" }}
                                    </p>
                                    <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-2 pe-2"
                                      aria-labelledby="dropdownMenuButton">
                                      <div class="search customerList pt-3 pb-2">
                                        <input type="checkbox" class="me-2" (change)="selectAll($event)"
                                          [checked]="allSelected" />
                                        <label>Select All</label>
                                      </div>

                                      <div class="customerList" *ngFor="let plans of PlanList?.plans;let i = index">
                                        <li (click)="togglePlanSelection(plans._id)">
                                          <input type="checkbox" [checked]="isPlanSelected(plans._id)" class="me-2" />
                                          {{ plans.name }}
                                        </li>
                                      </div>
                                    </ul>
                                    <div
                                      *ngIf="couponForm.get('plans')?.errors?.['required'] && (couponForm.get('plans')?.dirty || couponForm.get('plans')?.touched)">
                                      <p class="text-danger m-0 p-0">
                                        <small>It is mandatory field.</small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                      <div class="col-sm-12">
                        <div class="row p-2">
                          <div class="col-5">
                            <h3 class="mb-2"><b>Coupon type</b></h3>
                            <p class="text-gray">
                              Determine whether the coupon provides a <b>fixed amount/user </b>or<b> percentage discount/user</b> or fixed month/s.
                            </p>
                          </div>
                          <div class="col-7">
                            <div class="row mb-3">
                              <label for="fullName" class=""><b>Coupon type</b></label>
                              <div class="mt-2">
                                <div class="d-flex couponstype">
                                  <div class="box" (click)="toggle('months')"
                                    [ngClass]="selectedType === 'months'? 'activated': ''">
                                    <label>
                                      <div class="box1 col">
                                        <input type="radio" class="me-2" (change)="toggle('months')"
                                          formControlName="couponType" value="months" />
                                        <b>Month
                                          <i class="bi bi-info-circle"
                                            ngbTooltip="Add coupon discount with an extra months."></i>
                                        </b>
                                      </div>
                                    </label>
                                  </div>

                                  <div class="box" [ngClass]="
                                      selectedType === 'static'? 'activated': ''" (click)="toggle('static')">
                                    <label>
                                      <div class="box1 col">
                                        <input type="radio" (change)="toggle('static')" formControlName="couponType"
                                          value="static" class="me-2" />
                                        <b>Static
                                          <i class="bi bi-info-circle"
                                            ngbTooltip="Add coupon discount with a fixed amount/user."></i>
                                        </b>
                                      </div>
                                    </label>
                                  </div>
                                  <div class="box" (click)="toggle('percentage')"
                                    [ngClass]="selectedType === 'percentage'? 'activated': ''">
                                    <label>
                                      <div class="box1 col">
                                        <input type="radio" class="me-2" (change)="toggle('percentage')"
                                          formControlName="couponType" value="percentage" />
                                        <b>Percentage
                                          <i class="bi bi-info-circle"
                                            ngbTooltip="Add coupon discount with a percentage amount/user."></i>
                                        </b>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div class="row mt-3">
                                  <label class="mb-2"><b>{{selectedType == "months"? "No of Months": selectedType=='static' ?"Discount amount" :'Discount value'
                                      }}</b></label>
                                  <div class="col-sm-6">
                                    <div *ngIf="selectedType == 'months'">
                                      <input type="text" class="form-control" formControlName="value"
                                        placeholder="e.g 10" aria-label="Username" aria-describedby="basic-addon1"
                                         />
                                      <div
                                        *ngIf="couponForm.get('value')?.errors?.['required'] && (couponForm.get('value')?.dirty || couponForm.get('value')?.touched)">
                                        <p class="text-danger m-0 p-0">
                                          <small>It is mandatory field.</small>
                                        </p>
                                      </div>
                                      <div *ngIf="couponForm.get('value')?.errors?.['pattern']">
                                        <p class="text-danger m-0 p-0">
                                          <small>It is not valid.</small>
                                        </p>
                                      </div>
                                    </div>
                                    <div *ngIf="selectedType == 'percentage'">
                                      <div class="input-group " >
                                        <span class="input-group-text" id="basic-addon1">%</span>
                                        <input type="text" class="form-control border-remove" formControlName="value"
                                          placeholder="e.g 10" aria-label="Username" aria-describedby="basic-addon1" />
  
                                        
                                      </div>
                                      <div class=""
                                          *ngIf="couponForm.get('value')?.errors?.['required'] && (couponForm.get('value')?.dirty || couponForm.get('value')?.touched)">
                                          <p class="text-danger m-0 p-0">
                                            <small>It is mandatory field.</small>
                                          </p>
                                        </div>
                                        <div *ngIf="couponForm.get('value')?.errors?.['pattern'] || couponForm.get('value')?.errors?.['max']" >
                                          <p class="text-danger m-0 p-0">
                                            <small>It is valid for 1% to
                                              100%</small>
                                          </p>
                                        </div>
                                    </div >
                                    
                                    <div *ngIf="selectedType == 'static'">
                                      <input type="text" class="form-control" formControlName="value"
                                        placeholder="e.g 10.00" aria-label="Username" aria-describedby="basic-addon1" />
                                      <div class="w-100"
                                        *ngIf="couponForm.get('value')?.errors?.['required'] && (couponForm.get('value')?.dirty || couponForm.get('value')?.touched)">
                                        <p class="text-danger m-0 p-0">
                                          <small>It is mandatory field.</small>
                                        </p>
                                      </div>
                                      <div *ngIf="couponForm.get('value')?.errors?.['pattern']">
                                        <p class="text-danger m-0 p-0">
                                          <small>Amount is not
                                            valid.</small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>

                        <div class="col-sm-12">
                          <div class="row p-2">
                            <div class="col-5">
                              <h3 class="mb-2"><b>Redemption limits</b></h3>
                              <p class="text-gray">
                                Fine-tune the availability of the coupon.
                              </p>
                            </div>
                            <div class="col-7">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="row">
                                        <label for="fullName" class="mb-2"><b>Specific date</b></label>
                                        <div [ngClass]="
                                            couponForm.get('selectedDate')?.value=='on'
                                              ? 'col-sm-6 pe-0'
                                              : 'col-sm-12'
                                          ">
                                          <select class="form-select text-gray" formControlName="selectedDate"
                                            aria-label="Default select example">
                                            <option value="off">OFF</option>
                                            <option value="on">On</option>
                                          </select>
                                        </div>
                                        <div class="col-sm-6 ps-1" *ngIf="couponForm.get('selectedDate')?.value=='on'">
                                          <input type="date" class="form-control form-control1"
                                            formControlName="expirationDate" [min]="disablePreviousDate"/>
                                          <div class="w-100"
                                            *ngIf="couponForm.get('expirationDate')?.errors?.['required'] && (couponForm.get('expirationDate')?.dirty || couponForm.get('expirationDate')?.touched)">
                                            <p class="text-danger m-0 p-0">
                                              <small>It is mandatory field.</small>
                                            </p>
                                          </div>
                                        </div>
                                        <p class="text-gray mt-1">
                                          Limit the date range when customers
                                          can redeem this coupon.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="row">
                                        <label for="fullName" class="mb-2"><b>Limit</b></label>
                                        <div [ngClass]="
                                            couponForm.get('selectedLimit')?.value=='on'
                                              ? 'col-sm-6 pe-0'
                                              : 'col-sm-12'
                                          ">
                                          <select class="form-select text-gray" formControlName="selectedLimit"
                                            aria-label="Default select example">
                                            <option value="off">OFF</option>
                                            <option value="on">On</option>
                                          </select>
                                        </div>
                                        <div class="col-sm-6 ps-1" *ngIf="couponForm.get('selectedLimit')?.value=='on'">
                                          <input type="number" class="form-control" formControlName="limit"
                                            onkeypress="return event.charCode >= 49 && event.charCode <= 57" />
                                          <div class="w-100"
                                            *ngIf="couponForm.get('limit')?.errors?.['required'] && (couponForm.get('limit')?.dirty || couponForm.get('limit')?.touched)">
                                            <p class="text-danger m-0 p-0">
                                              <small>It is mandatory field.</small>
                                            </p>
                                          </div>
                                        </div>
                                        <p class="text-gray mt-1">
                                          Limit the total number of times this
                                          coupon can be redeemed.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                        <div class="col-sm-12">
                          <div class="row p-2">
                            <div class="col-5">
                              <h3 class="mb-2"><b>Email</b></h3>
                              <p class="text-gray">
                                Set up an email for a particular code.
                              </p>
                            </div>
                            <div class="col-7">
                              <div class="row mb-3">
                                <label for="fullName" class="mb-2"><b>Email</b></label>
                                <div [ngClass]="
                                    couponForm.get('selectedEmail')?.value=='on' ? 'col-sm-6 pe-0' : 'col-sm-12'">
                                  <select class="form-select text-gray" formControlName="selectedEmail"
                                    aria-label="Default select example">
                                    <option value="off">OFF</option>
                                    <option value="on">On</option>
                                  </select>
                                </div>
                                <div class="col-sm-6 ps-1" *ngIf="couponForm.get('selectedEmail')?.value=='on'">
                                  <input type="email" class="form-control" formControlName="email" />
                                  <div *ngIf="couponForm.get('email')?.invalid" class="alert mb-0 p-0">
                                    <div
                                      *ngIf="couponForm.get('email')?.errors?.['required'] && (couponForm.get('email')?.dirty || couponForm.get('email')?.touched)">
                                      <p class="text-danger m-0 p-0">
                                        <small>It's mandatory field</small>
                                      </p>
                                    </div>
                                    <div *ngIf="couponForm.get('email')?.errors?.['pattern']">
                                      <p class="text-danger m-0 p-0">
                                        <small>Please enter valid email id.</small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <p class="text-gray mt-1">
                                  Enter the email address of the user you want
                                  to apply this coupon to.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="row bg-color">
                              <div class="col-sm-12">
                                <div class="float-end">
                                  <button class="create-btn" (click)="submit()">
                                    {{ isEdit ? "Update" : "Create" }}
                                  </button>
                                  <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                                    (click)="cancel()">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>