<div class="container-fluid ps-0 pe-0">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="sub-heading ">Inactive subscription user report</div>
        <div class="col-sm-12">
          <form [formGroup]="searchForm">
            <div class="row mt-3 align-items-center">
              <div class="col-md-3">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Custom Date</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="From" formControlName="startDate">
                    <input matEndDate placeholder="To" formControlName="endDate">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply
                        (click)="getListOfInActiveSubUser()">Apply</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>
                </mat-form-field>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div>
                    <h4 class="font-weight-bold sub-heading">Schedular Log List <Button
                            class="exl-btn float-end ms-2 excel " (click)="excelInActiveUserLogsData()"
                            *ngIf="inActiveLogs.length>0">Export To Excel</Button>
                    </h4>
                </div>
                  <div class=" mb-4 records">{{updatePaginationText}}</div>
                  <div *ngIf="!(inActiveLogs.length>0)" class="text-center">
                    <h4>No data found!</h4>
                  </div>
                  <div class="table-responsive" *ngIf="inActiveLogs.length>0">
                    <table class="table table-striped table-borderless">
                      <thead class="roundedTable thead-header">
                        <tr>
                          <th scope="col" class="arrow-down-up">Sub ID</th>
                          <th scope="col">Customer Company Name</th>
                          <th scope="col">Cutomer Email</th>
                          <th scope="col">Cutomer Name</th>
                          <th scope="col">Partner Company Name</th>
                          <th scope="col">Partner Email</th>
                          <th scope="col">Partner Name</th>
                          <th scope="col">Plan Name</th>
                          <th scope="col">No of users</th>
                          <th scope="col">Last Login</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let subdata of inActiveLogs | paginate : config">
                          <td>
                            <a class="sub-link" [routerLink]="['/subscriptions-details']"
                              [queryParams]="{ subId: subdata?.code }"> {{subdata?.code |uppercase}}</a><br />
                          </td>
                          <td>
                            <span class="d-inline-block text-truncate" style="max-width: 100px;"
                              [ngbTooltip]="subdata?.customerCompanyName | titlecase" placement="top">
                              {{subdata?.customerCompanyName| titlecase}}</span>
                          </td>
                          <td>
                            <div class="d-inline-block text-truncate" style="max-width: 120px;"
                              ngbTooltip="{{subdata?.customerEmail}}" placement="top">
                              {{subdata?.customerEmail}}
                            </div>

                          </td>
                          <td>
                            <span class="d-inline-block text-truncate" style="max-width: 100px;"
                            [ngbTooltip]="subdata?.customerName | titlecase" placement="top">
                            {{subdata?.customerName| titlecase}}</span>
                         
                          </td>
                          <td>
                            <span class="d-inline-block text-truncate" style="max-width: 100px;"
                              [ngbTooltip]="subdata?.partnerCompanyName | titlecase" placement="top">
                              {{subdata?.partnerCompanyName| titlecase}}</span>
                          </td>
                          <td>
                            <div class="d-inline-block text-truncate" style="max-width: 120px;"
                              ngbTooltip="{{subdata?.partnerEmail}}" placement="top">
                              {{subdata?.partnerEmail}}
                            </div>
                          </td>
                          <td>
                            <span class="d-inline-block text-truncate" style="max-width: 100px;"
                            [ngbTooltip]="subdata?.partnerName| titlecase" placement="top">
                            {{subdata?.partnerName| titlecase}}</span>
                      
                          </td>
                          <td>{{subdata?.plan?.name}}</td>
                          <td>{{subdata?.plan?.noOfUsers}}</td>
                          <td>{{subdata?.lastLogin | date:"dd/MM/yy"}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-4 float-end me-4" *ngIf="inActiveLogs.length>0">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>