<!-- ---------------Audit Logs---------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-12">
              <h3 class="font-weight-bold heading"><b>{{isEdit ? 'Update Scheme':'Add Scheme'}} </b></h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card border-class">
                <div class="card-body pb-1 position-relative">
                  <div class="row">
                    <form [formGroup]="schemeForm">
                      <div class="col-sm-12">
                        <div class="row p-2">
                          <div class="col-4">
                            <h3 class="mb-2"><b>Scheme <i class="bi bi-info-circle ms-1"
                                  ngbTooltip="Under this schemes, the activated subscription cannot be deleted in 1st billing cycle by partner."></i></b>
                            </h3>
                            <p class="text-gray">
                              Set up the main details of the Scheme.
                            </p>
                          </div>
                          <div class="col-8">
                            <div class="row">
                              <label for="fullName" class=""><b>Scheme name</b></label>
                              <div class="mt-2">
                                <input type="text" class="form-control" id="fullName" placeholder="Enter scheme name"
                                  formControlName="name" />
                                <div *ngIf="schemeForm.get('name')?.invalid" class="alert mb-0 p-0">
                                  <div class="error-message"
                                    *ngIf="schemeForm.get('name')?.errors?.['required'] && (schemeForm.get('name')?.dirty || schemeForm.get('name')?.touched)">
                                    <p class="text-danger m-0 p-0">
                                      <small>It is mandatory.</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p class="text-gray mt-3">
                                The name of the scheme can be displayed to your
                                customers through your application.
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>

                      <div class="col-sm-12">
                        <div class="row p-2">
                          <div class="col-4">
                            <h3 class="mb-2"><b>Plan</b></h3>
                            <p class="text-gray">Set plan & Partners</p>
                          </div>
                          <div class="col-8">
                            <div class="row">
                              <div class="col-sm-6">
                                <label for="fullName" class="mb-2"><b>Select Partners</b></label>
                                <div class="dropdown-field custom-select custom-select1">
                                  <div class="dropdown psd">
                                    <p class="down-btn mt-0 mb-0 form-select psd form- w-100 d-flex align-items-center"
                                      type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      {{ (selectedPartnersIds.length==0)?"Select partner":
                                      selectedPartnersIds.length + " partner selected" }}
                                    </p>
                                    <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-2 pe-2"
                                      aria-labelledby="dropdownMenuButton">
                                      <div class="search p-2">
                                        <input type="text" class="form-control  p-3" formControlName="searchText1"
                                          placeholder="Search here">
                                      </div>
                                      <div class=" customerList ">
                                        <input type="checkbox" class="me-2" (change)="selectAll($event,'partners')"
                                          [checked]="allPartnerSelected" />
                                        <label>Select All</label>
                                      </div>

                                      <div class="customerList"
                                        *ngFor="let partner of partnerList  | filter: searchText1:'companyName'">
                                        <li (click)="togglePlanSelection(partner._id,'partners')">
                                          <input type="checkbox" [checked]="isPlanSelected(partner._id,'partners')"
                                            class="me-2" />
                                          {{ partner?.companyName }}
                                        </li>
                                      </div>
                                    </ul>
                                    <div
                                      *ngIf="schemeForm.get('partnerIds')?.errors?.['required'] && (schemeForm.get('partnerIds')?.dirty || schemeForm.get('partnerIds')?.touched)">
                                      <p class="text-danger m-0 p-0">
                                        <small>It is mandatory field.</small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <label for="fullName" class="mb-2"><b>Select Plans</b></label>
                                <div class="dropdown-field custom-select custom-select1">
                                  <div class="dropdown psd">
                                    <p class="down-btn mt-0 mb-0 form-select psd form- w-100 d-flex align-items-center"
                                      type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      {{ (selectedPlanIds.length==0)?"Select plan":
                                      selectedPlanIds.length + " plan selected" }}
                                    </p>
                                    <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-2 pe-2"
                                      aria-labelledby="dropdownMenuButton">
                                      <div class="search customerList pt-3 pb-2">
                                        <input type="checkbox" class="me-2" (change)="selectAll($event,'plans')"
                                          [checked]="allSelected" />
                                        <label>Select All</label>
                                      </div>

                                      <div class="customerList" *ngFor="let plans of plans;let i = index">
                                        <li (click)="togglePlanSelection(plans.code,'plans')">
                                          <input type="checkbox" [checked]="isPlanSelected(plans.code,'plans')"
                                            class="me-2" />
                                          {{ plans.name }}
                                        </li>
                                      </div>
                                    </ul>
                                    <div
                                      *ngIf="schemeForm.get('plans')?.errors?.['required'] && (schemeForm.get('plans')?.dirty || schemeForm.get('plans')?.touched)">
                                      <p class="text-danger m-0 p-0">
                                        <small>It is mandatory field.</small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>

                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-4">
                            <h3 class="mb-2"><b>Scheme Type</b></h3>
                            <p class="text-gray">Set recurring or non-recurring.</p>
                          </div>
                          <div class="col-8">
                            <div class="d-flex couponstype">
                              <div class="box" (click)="toggleType('non-recurring')"
                                [ngClass]="occuranceType === 'non-recurring'? 'activated': ''">
                                <label>
                                  <div class="box1 col">
                                    <input type="radio" class="me-2" (change)="toggleType('non-recurring')" formControlName="occurrence"
                                    value="non-recurring" />
                                    <b>Non-Recurring</b>
                                  </div>
                                </label>
                              </div>

                              <div class="box" [ngClass]="
                              occuranceType === 'recurring'? 'activated': ''" (click)="toggleType('recurring')">
                                <label>
                                  <div class="box1 col">
                                    <input type="radio" class="me-2" (change)="toggleType('recurring')"
                                      formControlName="occurrence" value="recurring" />
                                    <b>Recurring</b>
                                  </div>
                                </label>
                              </div>

                            </div>

                          </div>
                        </div>
                        <hr />
                      </div>
                      <div class="col-sm-12">
                        <div class="row p-2">
                          <div class="col-4">
                            
                            <!-- <label class="mb-2 me-4"><b>{{occuranceType == 'non-recurring' ? 'Duration': 'Period'}}</b></label>
                            <p class="text-gray">{{occuranceType == 'non-recurring' ? 'Fine-tune the availability of the scheme.' : ' Add no. of days.'}}</p> -->
                            <label class="mb-2 me-4"><b>Duration</b></label>
                            <p class="text-gray">Fine-tune the availability of the scheme.</p>
                          </div>
                          <div class="col-8">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="row">

                                  <div class="col-sm-6">
                                    <label for="fullName" class="mb-2"><b>Start Date</b></label>
                                    <div class="input-group ">
                                      <span class="input-group-text"
                                        id="basic-addon1">{{occuranceType=='non-recurring'?'From':'Date'}}</span>
                                      <input type="date" class="form-control form-control1"
                                        formControlName="fromDate" />
                                      <div class="w-100"
                                        *ngIf="schemeForm.get('fromDate')?.errors?.['required'] && (schemeForm.get('fromDate')?.dirty || schemeForm.get('fromDate')?.touched)">
                                        <p class="text-danger m-0 p-0">
                                          <small>It is mandatory field.</small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6 dateformat" *ngIf="occuranceType=='non-recurring'">
                                    <label class="mb-2"><b>End Date</b></label>
                                    <div class="input-group ">
                                      <span class="input-group-text" id="basic-addon1">To</span>
                                      <input type="date" class="form-control form-control1" formControlName="toDate"
                                        [min]="minToDate" />
                                      <div class="w-100"
                                        *ngIf="schemeForm.get('toDate')?.errors?.['required'] && (schemeForm.get('toDate')?.dirty || schemeForm.get('toDate')?.touched)">
                                        <p class="text-danger m-0 p-0">
                                          <small>It is mandatory field.</small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div class="col-sm-6" *ngIf="occuranceType =='recurring'">
                                    <label class="mb-2"><b>No of months</b></label>
                                    <input type="number" class="form-control" formControlName="noOfMonths" 
                                      placeholder="e.g 10" aria-label="Username" aria-describedby="basic-addon1"  onkeypress="return !(event.charCode == 46)" />
                                    <div
                                      *ngIf="(schemeForm.get('noOfMonths')?.dirty || schemeForm.get('noOfMonths')?.touched)">
                                      <p class="text-danger m-0 p-0" *ngIf="schemeForm.get('noOfMonths')?.errors?.['required']">
                                        <small>It is mandatory field.</small>
                                      </p>
                                      <p class="text-danger m-0 p-0" *ngIf="schemeForm.get('noOfMonths')?.errors?.['min']">
                                        <small>The value must be at least 1.</small>
                                      </p>
                                    </div>
                                    
                                  </div> -->
                                  <p class="text-gray mt-1" *ngIf="occuranceType=='non-recurring'">
                                    Limit the date range when partners
                                    can redeem this scheme.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-4">
                            <h3 class="mb-2"><b>Reward Trigger</b></h3>
                            <p class="text-gray">
                              Set minimum no. of users and value.
                            </p>
                          </div>
                          <div class="col-8 table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">No. of Users</th>
                                  <th scope="col">Reward Value</th>
                                  <th scope="col">Action</th>
                                </tr>
                                <tr>
                                </tr>
                              </thead>
                              <tbody formArrayName="rewardValue">
                                <tr *ngFor="let child of rewardValueControls; let i = index" [formGroupName]="i">
                                  <td>
                                    <input type="number" class="form-control" placeholder="Enter number of users"
                                      formControlName="noOfUsers">
                                  </td>
                                  <td>
                                    <div class="input-group ">
                                      <span class="input-group-text" id="basic-addon1">%</span>
                                      <input type="number" class="form-control border-remove" formControlName="value"
                                        placeholder="e.g 10" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                  </td>
                                  <td>
                                    <span (click)="addRewardValue()" *ngIf="i === rewardValueControls.length - 1">
                                      <i class="bi bi-plus-circle-fill blue-icon"></i>
                                    </span>
                                    <span (click)="removeRewardValue(i)" *ngIf="i !== rewardValueControls.length - 1">
                                      <i class="bi bi-dash-circle-fill red-icon"></i>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <div *ngIf="warningMessage" class="col-sm-12 text-center text-danger">
                                <small>{{ warningMessage }}</small>
                              </div>
                            </table>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="row p-2">
                          </div>
                          <div>
                            <div class="row bg-color">
                              <div class="col-sm-12">
                                <div class="float-end">
                                  <button class="create-btn" (click)="submit()">
                                    {{ isEdit ? "Update" : "Create" }}
                                  </button>
                                  <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                                    (click)="cancel()">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>