<!-- --------------------------------- -->
<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0"><b>{{isUpdate ? 'Update' : 'Add'}} Banner</b></h4>
</div>
<div class="modal-body pt-0">
    <form action="" [formGroup]="bannerForm">
        <div class="row mt-2 mb-3">
            <label class="mb-2"><b>Heading</b></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="heading" placeholder="Add heading" />
                <div *ngIf="bannerForm.get('heading')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="bannerForm.get('heading')?.errors?.['required'] && (bannerForm.get('heading')?.dirty || bannerForm.get('heading')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 mb-3">
            <label class="mb-2"><b>Subheading</b></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="subHeading" placeholder="Add subheading" />
            </div>
        </div>
        <div class="row mb-3">
            <label class="mb-2"><b>Description</b></label>
            <div class="col-sm-12">
                <textarea  class="w-100 textarea-form-control" id="exampleFormControlTextarea1" rows="5"  formControlName="description"
                    placeholder="Add description"></textarea>
            </div>
        </div>
        <!-- <div>
            <label for="file" class="mb-2"><b>Choose file to upload</b></label>
            <input type="file" id="file" name="file" class="form-control form-control1"
                formControlName="fileSelect" #fileInput />
        </div> -->
        <div class="row mt-2">
            <label><b>Activate</b></label>
            <div class="col-sm-12 ">
                <select class="form-select mt-2" formControlName="isActive">
                    <option value="false">Inactive</option>
                    <option value="true">Active</option>
                </select>
            </div>
        </div>
        <div class="row mt-3">
            <label><b>Allow to show banner</b></label>
            <div class="d-flex mt-2 gap">
                <div>
                    <input type="checkbox" formControlName="showCustomer">
                    <label class="mx-2"><b>Direct Customer</b></label>
                </div>

                <div>
                    <input type="checkbox" formControlName="showPartner">
                    <label class="mx-2"><b>Partner Customer</b></label>
                </div>
              
            </div>
        </div>
    </form>
</div>

<div class="text-end border-0">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="isUpdate ? updateBanner() : createBanner()">{{isUpdate ? 'Update' : 'Add'}}</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
</div>