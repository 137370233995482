<!-- -----add--reseller--page-- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row  px-2">
      <div class="card">
        <div class="card-body">
          <!-- ------add-reseller--form----------- -->
          <form [formGroup]="addResellerForm">
            <div>
              <h3 class="font-weight-bold"><strong>{{isEdit?'Edit Reseller:':'Add Reseller:'}}</strong></h3>
            </div>
            <div class="row " *ngIf="isSuperAdmin || isAccountManager">

              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="fullName" class="pt-2 pb-2">Name<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <input type="text" class="form-control" id="fullName" formControlName="name" placeholder="Enter Name"
                    [readonly]="isEditInput1">
                  <span *ngIf="isEdit" class="pencil-icon bi bi-pencil-fill" [ngClass]="{ active: !isEditInput1 }"
                    (click)="isEditInput1=!isEditInput1"></span>
                </div>

                <div *ngIf="addResellerForm.get('name')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('name')?.errors?.['required'] && (addResellerForm.get('name')?.dirty || addResellerForm.get('name')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                    </p>
                  </div>
                  <div class="error-message" *ngIf="addResellerForm.get('name')?.errors?.['pattern']">
                    <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                  </div>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="companyName" class="pt-2 pb-2">Company Name<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <input type="text" class="form-control" id="companyName" formControlName="companyName"
                    placeholder="Enter Company name" [readonly]="isEditInput2">
                  <span *ngIf="isEdit" class="pencil-icon bi bi-pencil-fill" [ngClass]="{ active: !isEditInput2 }"
                    (click)="isEditInput2=!isEditInput2"></span>
                </div>

                <div *ngIf="addResellerForm.get('companyName')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('companyName')?.errors?.['required'] && (addResellerForm.get('companyName')?.dirty || addResellerForm.get('companyName')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Company Name is mandatory.</small>
                    </p>
                  </div>
                  <div class="error-message" *ngIf="addResellerForm.get('companyName')?.errors?.['pattern']">
                    <p class="text-danger m-0 p-0"><small>Please enter valid Company Name.</small></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row  " *ngIf="isSuperAdmin">
              <div class="col-sm-12 col-md-6 mb-2">
                <label for="mobile" class="pt-2 pb-2">Mobile<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <span *ngIf="isEdit" class="pencil-icon bi bi-pencil-fill" [ngClass]="{ active: !isEditInput3 }"
                    (click)="isEditInput3=!isEditInput3"></span>

                  <input type="tel" class="form-control" id="mobile" formControlName="mobile" maxlength="20"
                    placeholder="Enter Mobile Number" [readonly]="isEditInput3">
                </div>

                <div *ngIf="addResellerForm.get('mobile')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('mobile')?.errors?.['required'] && (addResellerForm.get('mobile')?.dirty || addResellerForm.get('mobile')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Mobile number is mandatory.</small>
                    </p>
                  </div>
                  <div class="error-message" *ngIf="addResellerForm.get('mobile')?.errors?.['maxlength']">
                    <p class="text-danger m-0 p-0"><small>Please enter valid mobile number.</small></p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mb-2">
                <label for="email" class="pt-2 pb-2">Email<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <span *ngIf="isEdit" class="pencil-icon bi bi-pencil-fill" [ngClass]="{ active: !isEditInput4 }"
                    (click)="isEditInput4=!isEditInput4"></span>
                  <input type="email" class="form-control" id="email" formControlName="email"
                    placeholder="Enter Email Id" [readonly]="isEditInput4">
                </div>

                <div *ngIf="addResellerForm.get('email')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('email')?.errors?.['required'] && (addResellerForm.get('email')?.dirty || addResellerForm.get('email')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Email Id is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row  " [hidden]="isEdit">
              <div class="col-sm-12 col-md-6 password mb-2">
                <label for="password" class="pt-2 pb-2">Password<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <input [type]="passType" class="form-control" id="password" formControlName="password">
                  <div class="hideshow-password"><i [class]="show_eye ? 'bi bi-eye' : 'bi bi-eye-slash'"
                      (click)="changePasswordType('pass')"> </i></div>
                </div>

                <div *ngIf="addResellerForm.get('password')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('password')?.errors?.['required'] && (addResellerForm.get('password')?.dirty || addResellerForm.get('password')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                    </p>
                  </div>
                  <div class="error-message"
                    *ngIf="addResellerForm.get('password')?.errors?.['pattern'] || addResellerForm.get('password')?.errors?.['minlength']">
                    <p class="text-danger m-0 p-0"><small>Please enter minimum 6 digit Password.</small></p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 password mb-2">
                <label for="password" class="pt-2 pb-2">Confirm Password<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <input [type]="confirmPass" class="form-control password" id="confirmPassword"
                    formControlName="confirmPassword" placeholder="Enter confirm password">
                  <div class="hideshow-password"><i [class]="show_eye1 ? 'bi bi-eye' : 'bi bi-eye-slash'"
                      (click)="changePasswordType('confirmPass')"> </i></div>
                </div>

                <div *ngIf="addResellerForm.get('confirmPassword')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('confirmPassword')?.errors?.['required'] && (addResellerForm.get('confirmPassword')?.dirty || addResellerForm.get('confirmPassword')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                    </p>
                  </div>
                  <div class="error-message"
                    *ngIf="addResellerForm.get('confirmPassword')?.errors?.['pattern'] || addResellerForm.get('confirmPassword')?.errors?.['minlength']">
                    <p class="text-danger m-0 p-0"><small>Please enter minimum 6 digit Password.</small></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isSuperAdmin">
              <div class="col-sm-12 col-md-6  mb-2 ">
                <label for="email" class="pt-2 pb-2">Partner Types<span class="text-danger">*</span></label>
                <select class="form-control form-select" aria-label="Default select example"
                  formControlName="partnerType">
                  <option selected value="">Please Select</option>
                  <option value="partner" selected>Partner</option>
                  <option value="white label">White Label</option>
                </select>
                <div *ngIf="addResellerForm.get('partnerType')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('partnerType')?.errors?.['required'] && (addResellerForm.get('partnerType')?.dirty || addResellerForm.get('partnerType')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Partner Types is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Account Manager<span class="text-danger">*</span></label>

                <select class="form-control form-select" aria-label="Default select example"
                  formControlName="accountManagerId">
                  <option value="">Please Select account Manager</option>
                  <option *ngFor="let list of accountManagerList" value="{{list._id}}">
                    {{ list.name }}
                  </option>
                </select>
                <div *ngIf="addResellerForm.get('accountManagerId')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('accountManagerId')?.errors?.['required'] && (addResellerForm.get('accountManagerId')?.dirty || addResellerForm.get('accountManagerId')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Account Manager is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isSuperAdmin ">
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="minOrderValue" class="pt-2 pb-2">Min Order Limit<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <span *ngIf="isEdit" class="pencil-icon bi bi-pencil-fill" [ngClass]="{ active: !isEditInput5 }"
                    (click)="isEditInput5=!isEditInput5"></span>
                  <input type="number" class="form-control" id="minOrderValue" formControlName="minOrderValue"
                    placeholder="Enter Min Order Value" [readonly]="isEditInput5">
                </div>

                <div *ngIf="addResellerForm.get('minOrderValue')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('minOrderValue')?.errors?.['required'] && (addResellerForm.get('minOrderValue')?.dirty || addResellerForm.get('minOrderValue')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Min Order Value is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mb-2 ">

                <label for="maxTrialLimit" class="pt-2 pb-2">Max Trial Limit<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <span *ngIf="isEdit" class="pencil-icon bi bi-pencil-fill" [ngClass]="{ active: !isEditInput6 }"
                    (click)="isEditInput6=!isEditInput6"></span>
                  <input type="number" class="form-control" id="maxTrialLimit" formControlName="maxTrialLimit"
                    placeholder="Enter Max Trial Limit" [readonly]="isEditInput6">
                </div>

                <div *ngIf="addResellerForm.get('maxTrialLimit')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('maxTrialLimit')?.errors?.['required'] && (addResellerForm.get('maxTrialLimit')?.dirty || addResellerForm.get('maxTrialLimit')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Max Trial Limit is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isSuperAdmin || isAccountManager">
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Country<span class="text-danger">*</span></label>
                <select class="form-control form-select" aria-label="Default select example" formControlName="country">
                  <option value="">Please Select Country</option>
                  <option *ngFor="let list of countryList" value="{{list.name}}">
                    {{ list.name | titlecase}}
                  </option>
                </select>
                <div *ngIf="addResellerForm.get('stateList')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('stateList')?.errors?.['required'] && (addResellerForm.get('stateList')?.dirty || addResellerForm.get('stateList')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Pincode<span class="text-danger">*</span></label>
                <input type="number" class="form-control" (input)="onPincodeInput($event)" name="pinCode"
                  formControlName="pincode" />
                <div *ngIf="addResellerForm.get('pincode')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('pincode')?.errors?.['required'] && (addResellerForm.get('pincode')?.dirty || addResellerForm.get('pincode')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small></p>
                  </div>
                  <div class="error-message"
                    *ngIf="addResellerForm.get('pincode')?.errors?.['maxlength'] && (addResellerForm.get('pincode')?.dirty || addResellerForm.get('pincode')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Maximum length is 6 digits.</small></p>
                  </div>
                </div>
              </div>


            </div>

            <div class="row " *ngIf="isSuperAdmin || isAccountManager">
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">City<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter your city" formControlName="district"
                  required id="city" [readonly]="validPinCode" />
                <div *ngIf="addResellerForm.get('district')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('district')?.errors?.['required'] && (addResellerForm.get('district')?.dirty || addResellerForm.get('district')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">State<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter your state" formControlName="state" required
                  *ngIf="isOverseas" />
                <select class="form-control form-select" aria-label="Default select example" formControlName="state"
                  *ngIf="!isOverseas">
                  <option value="">Please Select State</option>
                  <option *ngFor="let list of stateList" value="{{list.name}}" [disabled]="validPinCode">
                    {{ list.name | titlecase}}
                  </option>
                </select>
                <div *ngIf="addResellerForm.get('state')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('state')?.errors?.['required'] && (addResellerForm.get('state')?.dirty || addResellerForm.get('state')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Zone<span class="text-danger">*</span></label>
                <select class="form-control form-select" aria-label="Default select example" formControlName="zone">
                  <option *ngFor="let zone of zones" [value]="zone.value" disabled>
                    {{ zone.name }}</option>
                </select>
                <div *ngIf="addResellerForm.get('zone')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('zone')?.errors?.['required'] && (addResellerForm.get('zone')?.dirty || addResellerForm.get('zone')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Category</label>
                <select class="form-control form-select" aria-label="Default select example" formControlName="category">
                  <option value="">Select Category</option>
                  <option *ngFor="let category of PartnerCategory" value="{{category.value}}">
                    {{ category.name | titlecase}}
                  </option>
                </select>
                <!-- <div *ngIf="addResellerForm.get('stateList')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('stateList')?.errors?.['required'] && (addResellerForm.get('stateList')?.dirty || addResellerForm.get('stateList')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                    </p>
                  </div>
                </div> -->
              </div>



            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Pipedrive Deal URL</label>
                <!-- <select class="form-control form-select" aria-label="Default select example" formControlName="pipedriveUrl">
                  <option *ngFor="let zone of zones" [value]="zone.value" disabled>
                    {{ zone.name }}</option>
                </select> -->
                <input class="form-control " type="text" formControlName="pipeDriveUrl"
                  aria-label="Default select example" />
                <!-- <div *ngIf="addResellerForm.get('zone')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="addResellerForm.get('zone')?.errors?.['required'] && (addResellerForm.get('zone')?.dirty || addResellerForm.get('zone')?.touched)">
                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                    </p>
                  </div>
                </div> -->
              </div>

              <div class="col-sm-12 col-md-6 mb-2 ">
                <label for="email" class="pt-2 pb-2">Proactive</label>
                <select class="form-control form-select" aria-label="Default select example"
                  formControlName="proactive">
                  <option value="" selected>No</option>
                  <option value="">Yes</option>
                </select>
              </div>

            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 mb-2 ">
                <label class="pt-2 pb-2">Team Size Sales</label>

                <input type="number" class="form-control" placeholder="Enter Sales Team Size "
                  formControlName="salesTeamSize" />
              </div>
              <div class="col-sm-12 col-md-6  mb-2 ">
                <label class="pt-2 pb-2">Team Size Support</label>

                <input type="number" class="form-control" placeholder="Enter Support Team Size"
                  formControlName="supportTeamSize" />
              </div>
            </div>
          </form>
          <div class="row mb-3" *ngIf="isSuperAdmin ">
            <div class="col-sm-12 col-md-12">
              <label for="addLabel" class="pt-2 pb-2">Select Plan<span class="text-danger">*</span></label>
              <div class="dropdown-field  ">
                <div class="dropdown ">
                  <p class="light form-control plan-table pt-3" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    {{(noOfSelectedPlan==0)?"Select plan":noOfSelectedPlan+" plan selected"}}
                    <span class="float-end"><i class="bi bi-chevron-down"></i></span>
                  </p>
                  <ul class="collapse" id="collapseExample">
                    <div>
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Duration</th>
                            <th scope="col" [hidden]="!isEdit">Revise All Previous</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr *ngFor="let plan of plans;">
                            <td scope="row">
                              <input type="checkbox" [checked]="isPlanSelected(plan._id)"
                                (click)="$event.stopPropagation(); togglePlanSelection(plan._id,plan.price)">
                            </td>
                            <td>{{ plan.name | titlecase }}</td>

                            <td #editableDiv [attr.id]="'editableDiv_' + plan._id" contenteditable="false"
                              (click)="toggleEdit(plan._id)" [ngClass]="{ active: selectedPlanId === plan._id }"
                              (input)="onFieldChange($event, plan._id, plan.price)">
                              <span>{{ plan.price }}</span>
                              <i class="bi bi-pencil-fill text-primary"></i>
                            </td>
                            <td>{{ plan.billEvery }}&nbsp;{{ plan.billingCycle }}</td>
                            <td scope="row" [hidden]="!isEdit">
                              <input type="checkbox" (change)="onFieldChange($event, plan._id, plan.price)">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ul>
                </div>
              </div>
              <div *ngIf="addResellerForm.get('plans')?.invalid" class="alert mb-0 p-0">
                <div class="error-message"
                  *ngIf="addResellerForm.get('plans')?.errors?.['required'] && (addResellerForm.get('plans')?.dirty || addResellerForm.get('plans')?.touched)">
                  <p class="text-danger m-0 p-0"><small>plan is mendatory.</small>
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div class="row mb-3">
            <div class="col-sm-12 mt-2 text-end">
              <button type="submit" class="comman-btn1 btn-primary"
                (click)="submit()">{{isEdit?'Update':'Submit'}}</button>
              <button type="button" class="comman-btn1 btn-secondary text-light ms-2" (click)="cancel()">Cancel</button>
            </div>
          </div>
          <!-- --------end------------- -->
        </div>
      </div>
    </div>
  </div>
</div>