import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ServerService } from 'src/app/services/server.service';
@Component({
  selector: 'app-server-exe',
  templateUrl: './server-exe.component.html',
  styleUrls: ['./server-exe.component.scss']
})
export class ServerExeComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  serverLists: any[] = []
  selectedServersIds: any[] = [];
  allServersSelected: boolean = false;
  file !: any
  serverExeForm = new FormGroup({
    serverIds: new FormControl<string[]>([], [Validators.required]),
    fileSelect: new FormControl('', [Validators.required]),
    version: new FormControl('', Validators.required),
    searchText1: new FormControl(''),
    filterUpdateExe: new FormControl<boolean>(false),
  })
  constructor(
    private toasts: ToastrService,
    public bsModalRef: BsModalRef,
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.allServerList()
    this.serverExeForm.controls.filterUpdateExe.valueChanges.subscribe((value: any) => {
      this.allServerList()
    })
  }
  closedModal() {
    this.bsModalRef.hide();
  }
  allServerList() {
    const filterOutUpdateExe = this.serverExeForm.controls.filterUpdateExe.value
    this.serverService.allServerList(filterOutUpdateExe).subscribe((res: any) => {
      if (res.success) {
        this.serverLists = res?.servers
      }
    }, (err) => {
      this.toasts.error(err.error.message)
      this.serverLists = []
    })
  }
 
  submit() {
    const payload = this.serverExeForm.value;
    if (!payload.serverIds || !this.fileInput.nativeElement.files || this.fileInput.nativeElement.files.length === 0) {
      this.serverExeForm.markAllAsTouched();
      this.toasts.error('Please fill all the required fields');
      return;
    }
    const inputElement: HTMLInputElement = this.fileInput.nativeElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      const formData = new FormData();
      formData.append('serverIds', JSON.stringify(payload.serverIds))
      formData.append('file', file);

      this.serverService.updateServerExe(formData).subscribe((res: any) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.bsModalRef.hide()
        }
      }, (err) => {
        this.toasts.error(err.error.message);
      });
    } else {
      console.error('No file selected');
    }
  }


  selectAll(event: any): void {
    this.allServersSelected = event.target.checked;
    if (this.allServersSelected) {
      this.selectedServersIds = this.serverLists.map((item: any) => item._id);
    } else {
      this.selectedServersIds = [];
    }
    this.serverExeForm.controls.serverIds.setValue(this.selectedServersIds);
  }

  isServerSelected(serverId: string) {
    const selectedServersIds = this.serverExeForm?.get('serverIds')?.value;
    return selectedServersIds?.includes(serverId);
  }


  togglePlanSelection(serverId: string, event: MouseEvent) {
    event.stopPropagation();
    const serversControl = this.serverExeForm.get('serverIds');
    const selectedServersIds = serversControl?.value || [];
    if (selectedServersIds.includes(serverId)) {
      const index = selectedServersIds.indexOf(serverId);
      selectedServersIds.splice(index, 1);
    } else {
      selectedServersIds.push(serverId);
    }
    this.selectedServersIds = selectedServersIds
    this.allServersSelected = this.serverLists.every((server: any) => selectedServersIds.includes(server._id))
    this.serverExeForm.controls.serverIds.patchValue(selectedServersIds);
  }

  get searchText1() {
    return this.serverExeForm.controls.searchText1.value || '';

  }
}
