<!-- --------------new---plan-------- -->
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 grid-margin">
     <div class="row">
      <div class="col-12 col-xl-8 d-flex mb-4 mb-xl-0 heading-section">
       <div><i class="bi bi-arrow-left-short fs-2" (click)="navigateToAddProduct()"></i></div>
       <div>
        <h3 class="font-weight-bold">Plan</h3>
       </div>
      </div>
<!-- -------form-------- -->
      <form action="" [formGroup]="planForm">
       <div class="add-plan">
        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Plan Name</label>
         <div class="col-sm-10 mt-2">
          <input type="text" class="form-control" placeholder="Enter Plan Name" formControlName="name">
          <div *ngIf="planForm.get('name')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="planForm.get('name')?.errors?.['required'] && (planForm.get('name')?.dirty || planForm.get('name')?.touched)">
            <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
            </p>
           </div>
           <div class="error-message" *ngIf="planForm.get('name')?.errors?.['pattern']">
            <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
           </div>
          </div>
         </div>
        </div>
        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Plan Code</label>
         <div class="col-sm-10 mt-2">
          <input type="text" placeholder="Enter Code" class="form-control" formControlName="code">
          <div *ngIf="planForm.get('code')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="planForm.get('code')?.errors?.['required'] && (planForm.get('code')?.dirty || planForm.get('code')?.touched)">
            <p class="text-danger m-0 p-0"><small>Plan Code is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Unit</label>
         <div class="col-sm-10 mt-2">
          <select class="form-select form-control" aria-label="Default select example" formControlName="unit">
           <option selected value="">Select Unit</option>
           <option *ngFor="let unit of units" value="{{unit}}">{{unit}}</option>
          </select>
          <div *ngIf="planForm.get('unit')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="planForm.get('unit')?.errors?.['required'] && (planForm.get('unit')?.dirty || planForm.get('unit')?.touched)">
            <p class="text-danger m-0 p-0"><small>Unit is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>
        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Price</label>
         <div class="col-sm-10 mt-2">
          <input type="number" placeholder="Enter Price" class="form-control" formControlName="price" min="0"
           onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
          <div *ngIf="planForm.get('price')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="planForm.get('price')?.errors?.['required'] && (planForm.get('price')?.dirty || planForm.get('price')?.touched)">
            <p class="text-danger m-0 p-0"><small>Price is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Bill Every</label>
         <div class="col-sm-5 mt-2">
          <input type="number" placeholder="0" class="form-control" formControlName="billEvery" min="0"
           onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
          <div *ngIf="planForm.get('billEvery')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="planForm.get('billEvery')?.errors?.['required'] && (planForm.get('billEvery')?.dirty || planForm.get('billEvery')?.touched)">
            <p class="text-danger m-0 p-0"><small>Bill Every is mandatory.</small>
            </p>
           </div>
           <div class="error-message" *ngIf="planForm.get('billEvery')?.errors?.['pattern']">
            <p class="text-danger m-0 p-0"><small>Invalid Bill Every.</small></p>
           </div>
          </div>
         </div>
         <div class="col-sm-5 mt-2">
          <select class="form-select form-control" aria-label="Default select example" formControlName="billingCycle">
           <option value="" selected>Select Duration</option>
           <option value="week">Week(s)</option>
           <option value="month">Month(s)</option>
           <option value="year">Year(s)</option>
          </select>
          <div *ngIf="planForm.get('billingCycle')?.invalid" class="alert mb-0 p-0">
           <div class="error-message"
            *ngIf="planForm.get('billingCycle')?.errors?.['required'] && (planForm.get('billingCycle')?.dirty || planForm.get('billingCycle')?.touched)">
            <p class="text-danger m-0 p-0"><small>Bill Every duration is mandatory.</small>
            </p>
           </div>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Billing Cycle</label>
         <div class="col-sm-10 d-flex mt-2">
          <div class="form-check ms-4 d-flex">
           <input class="form-check-input me-2 mouseCursor" type="radio" name="autoRenewal"
            formControlName="autoRenewal" id="flexRadioDefault1" [value]="true">
           <div *ngIf="planForm.get('autoRenewal')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
             *ngIf="planForm.get('autoRenewal')?.errors?.['required'] && (planForm.get('autoRenewal')?.dirty || planForm.get('autoRenewal')?.touched)">
             <p class="text-danger m-0 p-0"><small>Auto Renewal is mandatory.</small>
             </p>
            </div>
           </div>
           <label for="flexRadioDefault1" class="mouseCursor">
            Auto
           </label>
          </div>
          <div class="form-check ms-5 d-flex">
           <input class="form-check-input me-2 mouseCursor" type="radio" formControlName="autoRenewal"
            name="autoRenewal" id="flexRadioDefault2" [value]="false">
           <div *ngIf="planForm.get('autoRenewal')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
             *ngIf="planForm.get('autoRenewal')?.errors?.['required'] && (planForm.get('autoRenewal')?.dirty || planForm.get('autoRenewal')?.touched)">
             <p class="text-danger m-0 p-0"><small>Auto Renewal is mandatory.</small>
             </p>
            </div>
           </div>
           <label for="flexRadioDefault2" class="mouseCursor">
            Expire at
           </label>
          </div>
         </div>
        </div>

        <div class="row mb-3">
         <label for="fullName" class="col-sm-2 col-form-label">Plan Description</label>
         <div class="col-sm-10 mt-2">
          <textarea type="textarea" class=" description" formControlName="description"
           placeholder="Description"></textarea>
         </div>

         <div class="d-flex mb-3">
          <div class="mt-2">
           <input class="form-check mouseCursor" type="checkbox" value="" id="allPartners" formControlName="isShow">
          </div>
          <label for="allPartners" class="col-form-label ms-2 mt-1 mouseCursor">Show to All Partners</label>
         </div>

         <div class="text-end">
          <button type="submit" class="comman-btn1 btn-primary text-light me-2" (click)="newPlan()">{{!isEdit?'Submit':
           'Update'}}</button>
          <button type="button" class="comman-btn1 btn-secondary text-light"
           (click)="navigateToAddProduct()">Cancel</button>
         </div>
        </div>
       </div>
      </form>
      <!-- -----end------- -->
     </div>
    </div>
   </div>
  </div>
 </div>
</div>
<!-- --------------end--------- -->