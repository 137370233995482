import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient
  ) { }


  subscriptionList(searchPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/sub/list`, searchPayload)
  }

  subPriceChange(priceChangePayload: any) {
    return this.http.put(`${environment.apiURL}/admin/sub/price`, priceChangePayload)
  }

  addUpdateAddOn(addonPayload: any, isEdit: boolean) {
    if (isEdit) {
      return this.http.put(`${environment.apiURL}/admin/sub/addon`, addonPayload)
    } else {
      return this.http.post(`${environment.apiURL}/admin/sub/addon`, addonPayload)
    }
  }

  removeAddOn(removeAddonsubId: string, remark: string) {
    const remarks = JSON.stringify(remark)
    return this.http.delete(`${environment.apiURL}/admin/sub/addon?subId=${removeAddonsubId}&remark=${remarks}`)
  }

  trialSubList() {
    return this.http.get(`${environment.apiURL}/admin/sub/list/trial`)
  }

  extendExpiryDate(extendExpiryPayload: any) {
    return this.http.put(`${environment.apiURL}/admin/sub/extend/expiry`, extendExpiryPayload)
  }

  allPlanList() {
    return this.http.get(`${environment.apiURL}/admin/plan/all`)
  }
  subscriptionDetails(subId: string) {
    return this.http.get(
      `${environment.apiURL}/admin/sub/details?subId=${subId}`
    );
  }
  deleteSubscription(id: string, remark: string) {
    return this.http.delete(
      `${environment.apiURL}/admin//sub/delete?subscriptionId=${id}&remark=${remark}`
    );
  }
  subBillingInfo(subId: string) {
    return this.http.get(`${environment.apiURL}/admin/sub/order?subCode=${subId}`)
  }
  subRecentActivityLog(subId: string) {
    return this.http.get(`${environment.apiURL}/admin/sub/activity?subId=${subId}`)
  }
  subExcelData(subIdPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/sub/excel`, subIdPayload)
  }

  loginAsCustomer(subId: any) {
    return this.http.post(`${environment.apiURL}/admin/customer/login`, subId)
  }
  restrictDelition(restrictDelitionPayload: any) {
    const payload = {
      subscriptionIds: restrictDelitionPayload
    }
    return this.http.put(`${environment.apiURL}/admin/sub/restrict`, payload)
  }

  removeTallySerialNumber(subId: string) {
    const payload = {
      subId: subId
    }
    return this.http.put(`${environment.apiURL}/admin/sub/removeSerialNo`, payload);
  }

  subscriptionSalesPersonList(subId: string) {
    return this.http.get(`${environment.apiURL}/admin/subscriptionSalesPersonList?subscriptionId=${subId}`);
  }

  getdetailsSubManagement(type: string, page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/admin/subManagementRequest?type=${type}&page=${page}&limit=${limit}`)
  }

  updateStatus(payload: any) {
    return this.http.put(`${environment.apiURL}/admin/subManagementRequest`, payload)
  }

  getNewSubscriptionRequestDetails(subManagementRequestId: any, subId: any) {
    return this.http.get(`${environment.apiURL}/admin/subManagementRequest/details?subManagementRequestId=${subManagementRequestId}&subId=${subId}`)
  }

  getListOfInActiveSubUser(search: string, page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/admin/inActiveSubUserReport?search=${search}&page=${page}&limit=${limit}`)
  }

  exportToExcelInActiveUserLog(search: string) {
    return this.http.get(`${environment.apiURL}/admin/exportInActiveSubUserReport?search=${search}`)
  }

}
