import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddOnService } from 'src/app/services/add-on.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductService } from 'src/app/services/product.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-new-subscription',
  templateUrl: './new-subscription.component.html',
  styleUrls: ['./new-subscription.component.scss']
})
export class NewSubscriptionComponent implements OnInit {
  customerList!: any[];
  productList!: any[];
  customerLists!: any[]
  salesPersonLists!: any[]
  addOndetails!: any
  partnerList !: any[]
  planList!: any[];
  allPlans: any[] = []
  addOnList!: any[];
  isEdit: boolean = false
  productName!: any;
  planId!: any;
  addOnId!: any;
  customerId: any
  addOnName!: string;
  addOnprice!: any
  addOnToggle: boolean = true;
  addOnPriceEvery!: any;
  addOnPriceInterval!: any;
  unitAddON!: any;
  showAddOn: boolean = false;
  partnerId!: any
  planToggle: boolean = true;
  code !: any
  subId: any
  subsDetails: any
  products!: any
  planCode!: any
  orderId!: any
  subsRequestDetails!:any
  subscriptionForm = new FormGroup({
    customerId: new FormControl('', Validators.required),
    customerEmail: new FormControl('', Validators.required),
    partnerId: new FormControl('', Validators.required),
    partnerEmail: new FormControl('', Validators.required),
    productName: new FormControl('', Validators.required),
    planName: new FormControl('', Validators.required),
    planDescription: new FormControl('', Validators.required),
    planQty: new FormControl('', Validators.required),
    planUnitPrice: new FormControl('', Validators.required),
    planAmount: new FormControl('', Validators.required),
    // addOnName: new FormControl(''),
    // addOnDescription: new FormControl(''),
    // addOnQty: new FormControl(''),
    // addOnUnitPrice: new FormControl(''),
    // addOnAmount: new FormControl(),
    ram: new FormControl(''),
    cpu: new FormControl(''),
    storage: new FormControl(''),
    refrenceId: new FormControl(''),
    salesmanId: new FormControl(''),
    searchText: new FormControl(''),
    searchText1: new FormControl('')
  });
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private partnerService: PartnerService,
    private productService: ProductService,
    private addOnService: AddOnService,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.allPartnerList()
    this.showProductList()
    this.subId = this.activeRoute.snapshot.queryParamMap.get('subId') || ''
    this.orderId = this.activeRoute.snapshot.queryParamMap.get('subManagementRequestId') || ''
    if (this.subId && this.orderId) {
      this.isEdit = true
      this.getNewRequestSubscriptionDetails(this.orderId,this.subId)
    }else if(this.subId) {
      this.isEdit = true
      this.getSubscriptionDetails()
    }else if(this.orderId) {
      this.isEdit = false
      this.getNewRequestSubscriptionDetails(this.orderId,'')
    }
    this.subscriptionForm.get('planUnitPrice')?.valueChanges.subscribe((value) => {
      this.subscriptionForm.get('planAmount')?.setValue(value)
    })
    this.subscriptionForm.get('partnerId')?.valueChanges.subscribe((value: any) => {
      this.subscriptionForm.get('customerEmail')?.reset()
      this.subscriptionForm.get('customerId')?.reset('')
      this.subscriptionForm.get('salesmanId')?.reset('')
      this.subscriptionForm.get('searchText')?.reset()
      this.selectedPartner()
    })
    this.subscriptionForm.get('customerId')?.valueChanges.subscribe((value: any) => {
      this.subscriptionForm.get('searchText1')?.reset()
    })

    this.subscriptionForm.get('productName')?.valueChanges.subscribe((value: any) => {
      this.subscriptionForm.get('planName')?.reset('')
      this.subscriptionForm.get('planDescription')?.reset()
      this.subscriptionForm.get('planQty')?.reset()
      this.subscriptionForm.get('planUnitPrice')?.reset()
      this.subscriptionForm.get('planAmount')?.reset()
    })
  }

  allPartnerList() {
    this.partnerService.allPartnerList().subscribe((res: any) => {
      if (res.success) {
        this.partnerList = res.partners
      }
    })
  }

  showProductList() {
    this.productService.productList().subscribe((res: any) => {
      if (res.success) {
        this.productList = res.products.filter((item: any) =>
          item.name.toLowerCase().includes('virtual machine')
        );
      }
    })
  }

  selectedPartner() {
    this.partnerId = this.subscriptionForm.get('partnerId')?.value
    this.coustomerList(this.partnerId)
    this.salesPersonList(this.partnerId)
  }

  coustomerList(partnerId: any) {
    this.productService.customerList(partnerId).subscribe((res: any) => {
      if (res.success) {
        this.customerLists = res?.customerList
      }
    }, (err) => {
      this.customerLists = []
    })
  }
  salesPersonList(partnerId: any) {
    this.productService.salesPersonLists(partnerId).subscribe((res: any) => {
      if (res.success) {
        this.salesPersonLists = res?.salesPersonList
      }
    }, (err) => {
      this.salesPersonLists = []
      this.subscriptionForm.get('salesmanId')?.reset('')

    })
  }
  selectedPlan() {
    let defaultQuantity: any = 1;
    let unitPrice: any;
    let amount: any;
    this.showAddOn = true;
    this.planToggle = false;
    const planName = this.subscriptionForm.get('planName')?.value
    const planDetails = this.planList.find((item: any) => planName == item.name)
    unitPrice = planDetails?.price;
    amount = unitPrice * defaultQuantity;
    this.subscriptionForm.controls.planName.setValue(planDetails?.name)
    this.subscriptionForm.controls.planDescription.setValue(planDetails?.description)
    this.subscriptionForm.controls.planQty.setValue(defaultQuantity)
    this.subscriptionForm.controls.planUnitPrice.setValue((planDetails?.price).toFixed(2))
    this.subscriptionForm.controls.planAmount.setValue(amount.toFixed(2))
  }

  planAmount() {
    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.subscriptionForm.controls.planUnitPrice.value;
    quantity = this.subscriptionForm.controls.planQty.value;
    amount = unitPrice * quantity;
    this.subscriptionForm.controls.planAmount.setValue(amount.toFixed(2));
  }

  createSubscription() {
    const formValues = this.subscriptionForm.value
    if (
      !formValues.planName ||
      !formValues.planUnitPrice ||
      !(formValues.customerEmail && formValues.customerId) ||
      !(formValues.partnerEmail && formValues.partnerId) ||
      !formValues.planDescription || !formValues.salesmanId || !formValues.cpu || !formValues.storage || !formValues.ram
    ) {
      this.subscriptionForm.markAllAsTouched();
      this.toastr.error("Please fill all the required fields")
      return
    }
    const planName = this.subscriptionForm.get('planName')?.value
    const planDetails = this.planList.find((item: any) => planName == item.name)
    const payload = {
      customerId: formValues.customerId,
      partnerId: formValues.partnerId,
      refrenceId: formValues.refrenceId,
      salesmanId: formValues.salesmanId,
      productName: formValues.productName,
      plan: {
        name: planDetails.name,
        description: formValues.planDescription,
        billingCycle: planDetails?.billingCycle,
        price: formValues.planAmount,
        unitPrice: formValues.planUnitPrice,
        noOfUsers: formValues.planQty,
        billEvery: planDetails?.billEvery,
        code: planDetails.code,
        cpu: formValues.cpu,
        storage: formValues.storage,
        ram: formValues.ram,
      },
      Id: this.orderId
    }
    this.productService.addNewSubscriptionCreate(payload).subscribe((res: any) => {
      if (res.success) {
        this.code = res?.subscription?.code
        this.router.navigate(['subscriptions-details'],
          { queryParams: { subId: this.code } }
        );
        this.toastr.success("Subscription assigned to customer")
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      }
    )
  }

  getSubscriptionDetails() {
    this.productService.getDetailsSubscription(this.subId).subscribe((res: any) => {
      if (res.success) {
        this.subsDetails = res?.subscription;
        this.subscriptionForm.patchValue({
          partnerEmail: this.subsDetails?.partnerEmail,
          partnerId: this.subsDetails?.partnerId,
          customerId: this.subsDetails?.customerId,
          customerEmail: this.subsDetails?.customerEmail,
          productName: this.subsDetails?.productName,
          planName: this.subsDetails?.plan?.name,
          planDescription: this.subsDetails?.plan?.description,
          planUnitPrice: this.subsDetails?.plan?.unitPrice,
          planQty: this.subsDetails?.plan?.noOfUsers,
          salesmanId: this.subsDetails?.salesPerson?.id,
          planAmount: this.subsDetails?.plan?.price,
          cpu: this.subsDetails?.plan?.cpu,
          storage: this.subsDetails?.plan?.storage,
          ram: this.subsDetails?.plan?.ram

        });
        this.selectedProduct()
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
    });
  }

  updateSubscriptionDetails() {
    const formValues = this.subscriptionForm.value
    if (formValues.planName == null || formValues.planUnitPrice == null || (formValues.customerEmail && formValues.customerId) == null || formValues.planDescription == null || formValues.salesmanId == null) {
      this.toastr.error("Please fill all the required fields.")
      return
    }
    const planName = this.subscriptionForm.get('planName')?.value
    const planDetails = this.planList.find((item: any) => planName == item.name)
    const payload = {
      customerId: formValues.customerId,
      partnerId: formValues.partnerId,
      refrenceId: formValues.refrenceId,
      salesmanId: formValues.salesmanId,
      productName: formValues.productName,
      plan: {
        name: formValues.planName,
        description: formValues.planDescription,
        billingCycle: planDetails.billingCycle,
        price: formValues.planAmount,
        unitPrice: formValues.planUnitPrice,
        noOfUsers: formValues.planQty,
        billEvery: planDetails.billEvery,
        code: planDetails.code,
        cpu: formValues.cpu,
        storage: formValues.storage,
        ram: formValues.cpu,
      },
      subscriptionId: this.subsDetails?._id || this.subsRequestDetails?.subscriptionId,
      subManagementRequestId : this.orderId
    }
    this.productService.updateSubscription(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Subscription updated successfully.")
        this.router.navigate(['/subscription'])
      }
    }, (err) => {
      this.toastr.error(err?.error?.message)
    })
  }

  submit() {
    if (this.isEdit) {
      this.updateSubscriptionDetails()
    } else {
      this.createSubscription()
    }
  }

  selectEmail(email: string, Id: string, type: string) {
    if (type == 'parners') {
      this.subscriptionForm.controls.partnerEmail.setValue(email)
      this.subscriptionForm.controls.partnerId.setValue(Id)
    } else {
      this.subscriptionForm.controls.customerEmail.setValue(email)
      this.subscriptionForm.controls.customerId.setValue(Id)
    }
  }

  getNewRequestSubscriptionDetails(value: any,subId : any) {
    this.subscriptionService.getNewSubscriptionRequestDetails(value,subId).subscribe((res: any) => {
      if (res.success) {
        this.subsRequestDetails = res?.subManagementDetails;
        this.subscriptionForm.patchValue({
          ...this.subsRequestDetails,
          planName: this.subsRequestDetails?.plan?.name,
          planDescription: this.subsRequestDetails?.plan?.description,
          planUnitPrice: this.subsRequestDetails?.plan?.unitPrice,
          planQty: this.subsRequestDetails?.plan?.noOfUsers,
          salesmanId: this.subsRequestDetails?.salesPersonId,
          planAmount: this.subsRequestDetails?.plan?.price,
          cpu: this.subsRequestDetails?.plan?.cpu,
          storage: this.subsRequestDetails?.plan?.storage,
          ram: this.subsRequestDetails?.plan?.ram

        });
        setTimeout(()=>{
          this.selectedProduct()
        },0)
      }
    })
  }

  selectedProduct(): void {
    const productName = this.subscriptionForm.get('productName')?.value;
    this.planList = this.productList?.find((product: any) => product.name === productName)?.plans;
  }
  get searchtxt() {
    return this.subscriptionForm.controls.searchText.value || ''
  }
  get searchtxt1() {
    return this.subscriptionForm.controls.searchText1.value || ''
  }
  get selectedName() {
    return this.subscriptionForm.controls.partnerEmail.value || '';
  }
  get selectCustomer() {
    return this.subscriptionForm.controls.customerEmail.value || '';
  }

  goBack() {
    this.router.navigate(['/subscription'],);
  }
  // selectAddOn() {
  //   let defaultQuantity: any = 1;
  //   let unitPrice: any;
  //   let amount: any;
  //   this.addOnToggle = false;
  //   this.addOnId = this.subscriptionForm.get('addOnName')?.value
  //   this.addOndetails = this.addOnList.find((item: any) => this.addOnId == item._id)
  //   this.addOnName = this.addOndetails.name;
  //   this.addOnprice = this.addOndetails.price;
  //   this.addOnPriceEvery = this.addOndetails.pricingEvery;
  //   const addOnPrice = this.calculateAddOnPrice()
  //   this.addOnPriceInterval = this.addOndetails?.pricingInterval;
  //   this.unitAddON = this.addOndetails?.unit;
  //   this.subscriptionForm.controls.addOnDescription.patchValue(this.addOndetails?.description)
  //   this.subscriptionForm.controls.addOnQty.patchValue(defaultQuantity)
  //   unitPrice = Number(addOnPrice)
  //   this.subscriptionForm.controls.addOnUnitPrice.patchValue(unitPrice.toFixed(2))
  //   amount = Number(addOnPrice) * defaultQuantity;
  //   this.subscriptionForm.controls.addOnAmount.patchValue(amount.toFixed(2))

  // }
  // addOnAmount() {
  //   let unitPrice: any;
  //   let quantity: any;
  //   let amount: any;
  //   unitPrice = this.subscriptionForm.controls.addOnUnitPrice.value;
  //   quantity = this.subscriptionForm.controls.addOnQty.value;
  //   amount = unitPrice * quantity;
  //   this.subscriptionForm.controls.addOnAmount.patchValue(amount.toFixed(2))
  // }

  // if (this.addOnId !== undefined) {
  //   const addOnPrice = this.calculateAddOnPrice()
  //   const unitPrice = Number(addOnPrice)
  //   this.subscriptionForm.controls.addOnUnitPrice.patchValue(unitPrice.toFixed(2))
  //   const amount = Number(addOnPrice) * Number(this.subscriptionForm.controls.addOnQty.value);
  //   this.subscriptionForm.controls.addOnAmount.patchValue(amount.toFixed(2))
  // }
  // this.planName = planDetails?.name;
  // this.planCode = planDetails?.code
  // this.planBillCycle = planDetails?.billingCycle;
  // this.planBillEvery = planDetails?.billEvery


  // calculateAddOnPrice() {
  //   const planDays = (this.planBillCycle === "week") ? 7 : (this.planBillCycle === "month") ? 30 : 365
  //   const planTotalDays = planDays * this.planBillEvery
  //   const addOnDays = (this.addOnPriceInterval === "week") ? 7 : (this.addOnPriceInterval === "month") ? 30 : 365
  //   const addOnTotalDays = addOnDays * this.addOnPriceEvery
  //   const addOnOneDayPrice = this.addOnprice / addOnTotalDays
  //   const addOnPrice = addOnOneDayPrice * planTotalDays
  //   return addOnPrice
  // }

  // addOn: {
  //   name: this.addOnName,
  //   description: this.subscriptionForm.controls.addOnDescription.value,
  //   quantity: this.subscriptionForm.controls.addOnQty.value,
  //   price: this.subscriptionForm.controls.addOnAmount.value,
  //   unitPrice: this.subscriptionForm.controls.addOnUnitPrice.value,
  //   unit: this.unitAddON,
  //   pricingEvery: this.addOnPriceEvery,
  //   pricingInterval: this.addOnPriceInterval
  // }

  // showAddOnList(productId: any) {
  //   this.addOnService.addOnList(productId).subscribe((res: any) => {
  //     if (res.success) {
  //       this.addOnList = res.addOns;
  //     }
  //   },
  //     (error) => {
  //       this.addOnList = [];
  //     })
  // }
}








