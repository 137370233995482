<div class="content-wrapper">
    <div class="row">
        <div class="sub-heading ">Partner Report</div>
        <div class="col-sm-12">
            <form [formGroup]="searchForm">
                <div class="row mt-3 align-items-center">
                    <div class="col-md-3" *ngIf="!hideSubDateRange">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Choose a month and year</mat-label>
                            <input matInput type="month" formControlName="date" min="2024-03" />
                        </mat-form-field>
                    </div>

                    <div class="col-md-3" *ngIf="hideSubDateRange">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Custom Date</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="From" formControlName="startDate">
                                <input matEndDate placeholder="To" formControlName="endDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker>
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply
                                        (click)="onButtonClick()">Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3" *ngIf="hideSubDateRange">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>State</mat-label>
                            <mat-select multiple #multiSelect2 formControlName="state">
                                <div class="custom-panel">
                                    <div class="search ">
                                        <input type="text" class="custom-input-style  p-2" placeholder="Enter state"
                                            formControlName="searchText">
                                    </div>

                                    <mat-option *ngFor="let list of stateList |  filter: searchText: 'name'"
                                        [value]="list?.name" (click)="optionClick(multiSelect1,1)">
                                        {{list?.name | titlecase}}
                                    </mat-option>

                                </div>
                                <footer>
                                    <button mat-raised-button class="me-2"
                                        (click)="onButtonClick();multiSelect2.close()">Ok</button>
                                    <button mat-button (click)="multiSelect2.close()">Cancel</button>
                                </footer>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3" *ngIf="hideSubDateRange">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Zone</mat-label>

                            <mat-select multiple #multiSelect3 formControlName="zone">
                                <div class="custom-panel">
                                    <mat-option *ngFor="let zone of zones"
                                        [value]="zone.value">{{zone.name}}</mat-option>
                                </div>
                                <footer>
                                    <button mat-raised-button class="me-2"
                                        (click)="onButtonClick();multiSelect3.close()">Ok</button>
                                    <button mat-button (click)="multiSelect3.close()">Cancel</button>
                                </footer>
                            </mat-select>

                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Account Manager</mat-label>
                            <mat-select multiple #multiSelect1 formControlName="accountManagerIds">
                                <div class="custom-panel">
                                    <div class="search ">
                                        <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                                            formControlName="searchText1">
                                    </div>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                                    </div>
                                    <mat-option *ngFor="let list of accountManagerList |  filter: searchText1: 'email'"
                                        [value]="list?._id" (click)="optionClick(multiSelect1,1)">
                                        {{list?.email | titlecase}}
                                    </mat-option>

                                </div>
                                <footer>
                                    <button mat-raised-button class="me-2"
                                        (click)="onButtonClick();multiSelect1.close()">Ok</button>
                                    <button mat-button (click)="multiSelect1.close()">Cancel</button>
                                </footer>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="col-lg-12 mt-2">
                <div class="card ">
                    <div class="card-body ">
                        <div class="mb-4 d-flex justify-content-between align-items-center">
                            <div class="wrapper">
                                <button class="graph me-2" [ngClass]="activation=='mrr'?'active':''"
                                    (click)="toggle('mrr')">MRR</button>

                                <button class="graph" [ngClass]="activation=='subcription'?'active':''"
                                    (click)="toggle('subcription')">Subscription</button>
                            </div>
                            <div clas="col-12" *ngIf="hideSubDateRange">
                                <div class="text-center mb-2">
                                    <span class="me-3"><i class="bi bi-circle-fill me-3  subs"></i>Subscriptions</span>
                                    <span><i class="bi bi-circle-fill me-3  users"></i>Users</span>
                                </div>
                            </div>
                            <span class="float-end excel" (click)="exportexcel()" *ngIf="userType">EXPORT (EXCEL)</span>
                        </div>
                        <div class="row ">
                            <!-- <div clas="col-12" *ngIf="hideSubDateRange">
                                <div class="text-center mb-2">
                                    <span class="me-3"><i class="bi bi-circle-fill me-3  subs"></i>Subscriptions</span>
                                    <span><i class="bi bi-circle-fill me-3  users"></i>Users</span>
                                </div>
                            </div> -->
                            <div class="col-12 table-responsive   ps-0 pe-0" *ngIf="!hideSubDateRange">
                                <table class="table table-bordered  fixed-columns-table">
                                    <thead class=" thead-header">
                                        <tr>
                                            <th scope="col ">S.no</th>
                                            <th scope="col ">Company Name</th>
                                            <th scope="col ">Account Manager</th>

                                            <th scope="col " class="pointer" (click)="sorting('netNewBiz')">New Business
                                                MRR <i class="bi bi-question-circle me-1  "
                                                    ngbTooltip="Revenue increases when customers convert to paid subscriptions."></i>
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netNewBiz' && sortAsc, 'bi-arrow-up': sortColumn === 'netNewBiz' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('netUpgrade')">Expansion
                                                MRR <i class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Revenue increases upon upgrades from subscribers."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netUpgrade' && sortAsc, 'bi-arrow-up': sortColumn === 'netUpgrade' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('netDowngrade')">
                                                Contraction MRR <i class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Revenue decreases upon downgrade from subscribers."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netDowngrade' && sortAsc, 'bi-arrow-up': sortColumn === 'netDowngrade' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col" class="pointer" (click)="sorting('netChurn')">Churn MRR <i
                                                    class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Revenue lost from subscribers cancelling all their subscriptions."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netChurn' && sortAsc, 'bi-arrow-up': sortColumn === 'netChurn' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('netReactivation')">
                                                Reactivation MRR <i class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Revenue added when customers renew their subscriptions after expired"></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netReactivation' && sortAsc, 'bi-arrow-up': sortColumn === 'netReactivation' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('netNetChurn')">Net Churn
                                                MRR <i class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Revenue lost from subscribers cancelling all their subscriptions."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netNetChurn' && sortAsc, 'bi-arrow-up': sortColumn === 'netNetChurn' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('netMrrMovement')">Net MRR
                                                Movement <i class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Net change in recurring revenue from the current month."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'netMrrMovement' && sortAsc, 'bi-arrow-up': sortColumn === 'netMrrMovement' && !sortAsc}"></i>
                                            </th>

                                            <th scope="col " class="pointer"
                                                (click)="sorting('lastMonthNetMrrMovement')">Net Previous MRR <i
                                                    class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Net change in recurring revenue from the previous month."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'lastMonthNetMrrMovement' && sortAsc, 'bi-arrow-up': sortColumn === 'lastMonthNetMrrMovement' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('currentMrr')">Current MRR
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'currentMrr' && sortAsc, 'bi-arrow-up': sortColumn === 'currentMrr' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('prevMonthMrr')">Previous
                                                MRR <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'prevMonthMrr' && sortAsc, 'bi-arrow-up': sortColumn === 'prevMonthMrr' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('percentageChange')">
                                                Change <i class="bi bi-question-circle  me-1"
                                                    ngbTooltip="Compared to the previous interval."></i><i
                                                    class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'percentageChange' && sortAsc, 'bi-arrow-up': sortColumn === 'percentageChange' && !sortAsc}"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let list of partnerReportData;let i=index">
                                            <td class="fixed-col fixed-col-width"><b>{{i+1}}</b></td>
                                            <td class="fixed-col">
                                                <div class="text-center text-truncate" style="max-width: 100px;"
                                                    ngbTooltip="{{list?.companyName| titlecase}}" placement="top"
                                                    tooltipClass="tooltip-custom">
                                                    <b><a class="sub-link" [routerLink]="['/mrr-report']"
                                                            [queryParams]="{ partnerId: list?._id}">{{list?.companyName
                                                            |titlecase}}</a></b>
                                                </div>
                                            </td>
                                            <td class="fixed-col text-center">{{list?.accountManager}}</td>
                                            <td class="fixed-col"
                                                (click)="list?.newBizCount>0?mrrDetails(list?._id,'new subscription'):''"
                                                [ngClass]="list?.newBizCount>0?'pointer':''">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="list?.newBizCount>0?'col-6 text-end':'col-12 text-center'">
                                                        {{list?.newBizCount>0 ? (list?.netNewBiz|
                                                        currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="list?.newBizCount>0" class="col-6"><span
                                                            class="text-success ms-2">{{list?.newBizCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col"
                                                (click)="list?.upgradeCount>0?mrrDetails(list?._id,'upgrade'):''"
                                                [ngClass]="list?.upgradeCount>0?'pointer':''">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="list?.upgradeCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{list?.upgradeCount>0?(list?.netUpgrade |currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="list?.upgradeCount>0" class="col-5"><span
                                                            class="text-success ms-2">{{list?.upgradeCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col"
                                                (click)="list?.downgradeCount>0?mrrDetails(list?._id,'downgrade'):''"
                                                [ngClass]="list?.downgradeCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="list?.downgradeCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{list?.downgradeCount>0?(list?.netDowngrade |
                                                        currency:"INR"):'-'}}</div>
                                                    <div *ngIf="list?.downgradeCount>0" class="col-5"><span
                                                            class="text-danger ms-2">{{list?.downgradeCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col"
                                                (click)="list?.churnCount>0?mrrDetails(list?._id,'churn'):''"
                                                [ngClass]="list?.churnCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="list?.churnCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{list?.churnCount?(list?.netChurn | currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="list?.churnCount>0" class="col-5"><span
                                                            class="text-danger ms-2">{{list?.churnCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col"
                                                (click)="list?.reActivationCount>0?mrrDetails(list?._id,'reactivation'):''"
                                                [ngClass]="list?.reActivationCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="list?.reActivationCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{list?.reActivationCount>0?(list?.netReactivation |
                                                        currency:"INR"):"-"}}</div>
                                                    <div *ngIf="list?.reActivationCount>0" class="col-5"><span
                                                            class="text-success ms-2">{{list?.reActivationCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col"
                                                (click)="list?.netChurnCount>0?mrrDetails(list?._id,'netchurn'):''"
                                                [ngClass]="list?.netChurnCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="list?.netChurnCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{list?.netChurnCount?(list?.netNetChurn | currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="list?.netChurnCount>0" class="col-5"><span
                                                            class="text-danger ms-2">{{list?.netChurnCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div>{{list?.netMrrMovement | currency:"INR"}}</div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div>{{list?.lastMonthNetMrrMovement | currency:"INR"}}</div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div>{{list?.currentMrr | currency:"INR"}}</div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div>{{list?.prevMonthMrr | currency:"INR"}}</div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                            <td class="fixed-col">

                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div> <span
                                                            [ngClass]="list?.percentageChange>0?'text-success':'text-danger'">
                                                            {{list?.percentageChange ? list?.percentageChange+'%' : '0%'
                                                            }}</span></div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td><strong>Total</strong></td>
                                            <td>&nbsp;</td>
                                            <td class="text-center"><strong>{{totals?.totalNetNewBiz |
                                                    currency:"INR"}}</strong></td>
                                            <td class="text-center"><strong>{{totals?.totalNetUpgrade |
                                                    currency:"INR"}}</strong></td>
                                            <td class="text-center"><strong>{{totals?.totalNetDowngrade |
                                                    currency:"INR"}}</strong></td>
                                            <td class="text-center"><strong>{{totals?.totalChurn |
                                                    currency:"INR"}}</strong></td>
                                            <td class="text-center"><strong>{{totals?.totalNetReactivation |
                                                    currency:"INR"}}</strong>
                                            </td>
                                            <td class="text-center"><strong>{{totals?.totalNetChurn |
                                                    currency:"INR"}}</strong></td>
                                            <td class="text-center"><strong>{{totals?.totalNetMrrMovement |
                                                    currency:"INR"}}</strong>
                                            </td>
                                            <td class="text-center"><strong>{{totals?.totalLastMonthNetMrrMovement |
                                                    currency:"INR"}}</strong>
                                            <td class="text-center"><strong>{{totals?.totalNetCurrentMrr |
                                                    currency:"INR"}}</strong>
                                            <td class="text-center"><strong>{{totals?.totalNetPrevMonthMrr |
                                                    currency:"INR"}}</strong>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="col-12 table-responsive position-relative  ps-0 pe-0" *ngIf="hideSubDateRange">
                                <table class="table table-bordered  fixed-columns-table">
                                    <thead class=" thead-header">
                                        <tr>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col " class="text-center" colspan="7">Renewal Summary</th>
                                            <th scope="col ">&nbsp;</th>
                                            <th scope="col " class="text-center" colspan="4">Trial Summary</th>
                                            <th scope="col " class="text-center" colspan="3">Wallet Summary</th>

                                        </tr>
                                        <tr>
                                            <th scope="col ">S.no</th>
                                            <th scope="col ">Account Managers</th>
                                            <th scope="col ">Company Name</th>
                                            <th scope="col " class="text-center">State</th>
                                            <th scope="col " class="text-center">Partner Since(Months)</th>
                                            <th scope="col " class="text-center pointer"
                                                (click)="sorting('totalPaidSub.totalAmount')">Total Subscriptions
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'totalPaidSub.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'totalPaidSub.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="text-center pointer"
                                                (click)="sorting('newPaidSubs.totalAmount')">New Subscriptions
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'newPaidSubs.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'newPaidSubs.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('totalRenewalsReport.totalAmount')">Total
                                                Renewals
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'totalRenewalsReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'totalRenewalsReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('renewedReport.totalAmount')">Renewed
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'renewedReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'renewedReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('upgradeReport.totalAmount')">Upgrades
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'upgradeReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'upgradeReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('downgradeReport.totalAmount')">Downgrade
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'downgradeReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'downgradeReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('upcomingRenewalsReport.totalAmount')">
                                                Upcoming Renewals
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'upcomingRenewalsReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'upcomingRenewalsReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('expiredReport.totalAmount')">Expired
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'expiredReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'expiredReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('deletedReport.totalAmount')">Deleted
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'deletedReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'deletedReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="text-center pointer"
                                                (click)="sorting('riskySubsReport.totalAmount')">Risky Customers Value
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'riskySubsReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'riskySubsReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('totalTrialReport.totalAmount')">Trial
                                                Signups
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'totalTrialReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'totalTrialReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('expiredTrialReport.totalAmount')">Trial
                                                Expired
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'expiredTrialReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'expiredTrialReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('trialToPaidReport.totalAmount')">Trial to
                                                Paid
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'trialToPaidReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'trialToPaidReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer"
                                                (click)="sorting('liveTrialReport.totalAmount')">Live Trial
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'liveTrialReport.totalAmount' && sortAsc, 'bi-arrow-up': sortColumn === 'liveTrialReport.totalAmount' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('totalCurrentWallet')">
                                                Current Wallet
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'totalCurrentWallet' && sortAsc, 'bi-arrow-up': sortColumn === 'totalCurrentWallet' && !sortAsc}"></i>
                                            </th>
                                            <th scope="col " class="pointer" (click)="sorting('totalWalletPurchased')">
                                                Wallet Purchased
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'totalWalletPurchased' && sortAsc, 'bi-arrow-up': sortColumn === 'totalWalletPurchased' && !sortAsc}"></i>
                                            </th>

                                            <th scope="col " class="pointer" (click)="sorting('totalWalletConsumed')">
                                                Wallet Consumed
                                                <i class="bi bi-arrow-down-up"
                                                    [ngClass]="{'bi-arrow-down': sortColumn === 'totalWalletConsumed' && sortAsc, 'bi-arrow-up': sortColumn === 'totalWalletConsumed' && !sortAsc}"></i>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let list of partnerSupscriptionReport;let i= index">
                                            <td class="fixed-col"><b>{{i+1}}</b></td>
                                            <td class="fixed-col"><b>{{list?.accountManager |titlecase}}</b></td>
                                            <td class="fixed-col">
                                                <span class="d-inline-block text-truncate" style="max-width: 100px;"
                                                    ngbTooltip="{{list?.companyName| titlecase}}" placement="top"
                                                    tooltipClass="tooltip-custom">
                                                    <b>{{list?.companyName| titlecase}}</b>
                                                </span>
                                            </td>
                                            <td class="fixed-col">{{ list?.state ? list?.state +'(' + list?.zone+ ')'
                                                :'N/A' }}</td>
                                            <td class="fixed-col">{{ list?.totalMonthsSincePartner ?
                                                (list?.totalMonthsSincePartner) :'N/A' }}</td>

                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.totalPaidSub?.totalAmount | currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.totalPaidSub?.totalSub}}

                                                        </span>
                                                        <span class="users">({{list?.totalPaidSub?.totalUsers}})</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.newPaidSubs?.totalAmount | currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.newPaidSubs?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.newPaidSubs?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.totalRenewalsReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.totalRenewalsReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.totalRenewalsReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.renewedReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.renewedReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.renewedReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.upgradeReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.upgradeReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.upgradeReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.downgradeReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.downgradeReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.downgradeReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.upcomingRenewalsReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.upcomingRenewalsReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.upcomingRenewalsReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.expiredReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.expiredReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.expiredReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.deletedReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.deletedReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.deletedReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>
                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.riskySubsReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.riskySubsReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.riskySubsReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.totalTrialReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.totalTrialReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.totalTrialReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.expiredTrialReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.expiredTrialReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.expiredTrialReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.trialToPaidReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.trialToPaidReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.trialToPaidReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>
                                            <td class="fixed-col">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(list?.liveTrialReport?.totalAmount| currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{list?.liveTrialReport?.totalSub}}
                                                        </span>
                                                        <span class="users">
                                                            ({{list?.liveTrialReport?.totalUsers}})
                                                        </span>

                                                    </div>

                                                </div>

                                            </td>

                                            <td class="fixed-col">{{(list?.totalCurrentWallet | currency:"INR")
                                                }}</td>
                                            <td class="fixed-col">{{(list?.totalWalletPurchased |
                                                currency:"INR") }}</td>
                                            <td class="fixed-col">{{(list?.totalWalletConsumed |
                                                currency:"INR") }}</td>


                                        </tr>
                                        <tr class="fixed-col1">
                                            <td>&nbsp;</td>
                                            <td><b>Total</b></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.totalPaidSub?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.totalPaidSub?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.totalPaidSub?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.newPaidSubs?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.newPaidSubs?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.newPaidSubs?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.totalRenewalsReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.totalRenewalsReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.totalRenewalsReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.renewedReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.renewedReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.renewedReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.upgradeReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.upgradeReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.upgradeReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.downgradeReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.downgradeReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.downgradeReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.upcomingRenewalsReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.upcomingRenewalsReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.upcomingRenewalsReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.expiredReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.expiredReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.expiredReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.deletedReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.deletedReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.deletedReport?.totalUsers}})</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.riskySubsReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.riskySubsReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.riskySubsReport?.totalUsers}})</span>
                                                    </div>
                                                </div>

                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.totalTrialReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.totalTrialReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.totalTrialReport?.totalUsers}})</span>
                                                    </div>
                                                </div>

                                            </td>
                                            <td class="bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.expiredTrialReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.expiredTrialReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.expiredTrialReport?.totalUsers}})</span>
                                                    </div>
                                                </div>

                                            </td>
                                            <td class=" bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.trialToPaidReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.trialToPaidReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.trialToPaidReport?.totalUsers}})</span>
                                                    </div>
                                                </div>

                                            </td>
                                            <td class="bold">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div class="col-6">
                                                        {{(totalPartnerSubs?.liveTrialReport?.totalAmount |
                                                        currency:"INR")}}
                                                    </div>
                                                    <div class="col-6"><span class="text-success ms-2">
                                                            {{totalPartnerSubs?.liveTrialReport?.totalSub}}

                                                        </span>
                                                        <span
                                                            class="users">({{totalPartnerSubs?.liveTrialReport?.totalUsers}})</span>
                                                    </div>
                                                </div>

                                            </td>
                                            <td class=" bold">{{totalPartnerSubs?.totalCurrentWallet |
                                                currency:"INR"}}</td>
                                            <td class=" bold">{{totalPartnerSubs?.totalWalletPurchased
                                                |currency:"INR"}}</td>
                                            <td class="bold">{{totalPartnerSubs?.totalWalletConsumed
                                                |currency:"INR"}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="row mt-3" *ngIf="isShowDetails" #scrollContainer>
            <div class="sub-heading ">Activity <span class="float-end excel" (click)="exportExcelMrrDetails()"
                    *ngIf="userType">EXPORT (EXCEL)</span></div>
            <div class="card  mt-3">
                <div class="card-body">
                    <div class="col-lg-12 table-responsive table-responsive1">
                        <table class="table table-bordered" id="mrr-detail-table">
                            <thead class="roundedTable thead-header">
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Sub Id</th>
                                    <th scope="col">Partner Email</th>
                                    <th scope="col">Account Manager</th>
                                    <th scope="col">Salesperson</th>
                                    <th scope="col">Customer Email</th>
                                    <th scope="col">MRR Movement</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Date</th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let details of mrrDetail">
                                    <td>
                                        <div class="d-inline-block text-truncate" style="max-width: 200px;"
                                            ngbTooltip="{{details?.description}}">{{details?.description}}</div>
                                    </td>
                                    <td>
                                        <a class="sub-link" [routerLink]="['/subscriptions-details']"
                                            [queryParams]="{ subId: details?.subId }">
                                            {{details?.subId}}</a>

                                    </td>
                                    <td>{{details?.partnerEmail?details?.partnerEmail:'NA'}}</td>
                                    <td>{{details?.accountManager?details?.accountManager:'NA'}}</td>
                                    <td>{{details?.salesPerson?details?.salesPerson:'NA'}}</td>
                                    <td>{{details?.customerEmail?details?.customerEmail:'NA'}}</td>
                                    <td>{{details?.mrrMovement ? (details?.mrrMovement| currency:"INR"):'NA'}}</td>
                                    <td>{{details?.billingType? (details?.billingType | titlecase):'NA'}}</td>
                                    <td>{{details?.createdAt ? (details?.createdAt| date):''}}</td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>