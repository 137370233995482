import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginRoot } from '../interfaces/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  login(loginPayload: any) {
    return this.http.post<LoginRoot>(`${environment.apiURL}/admin/login`, loginPayload)
  }

  adminLogo() {
    return this.http.get(`${environment.apiURL}/admin/logo`)
  }

  forgotPassword(resetPasswordPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/forgotPassword`, resetPasswordPayload)
  }

  changePassword(changePasswordPayload: any) {
    return this.http.post(`${environment.apiURL}/admin/changePassword`, changePasswordPayload)
  }

  get logo() {
    return localStorage.getItem('logo');
  }

}
