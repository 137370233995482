<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left mb-4"><b>{{isEdit?'Update':'Add'}} AddOn ({{(subData?.code || subDataDetails?.code) |uppercase}})</b></h4>
</div>
<div class="modal-body  mt-0 pt-0 pb-3">
    <form [formGroup]="addonInSub">
        <div class="row mb-3">
            <label for="name" class="col-sm-4 col-form-label text"><b>Name</b></label>
            <div class="col-sm-8 mt-2">
                <div class="select-section">
                    <select class="form-select form-control" id="selected-addOn-field" formControlName="name">
                        <option selected disabled value="">Select Name</option>
                        <option value="{{addon?.name}}" *ngFor="let addon of addOns">{{addon?.name}}</option>
                    </select>
                    <div *ngIf="addonInSub.get('name')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="addonInSub.get('name')?.errors?.['required'] && (addonInSub.get('name')?.dirty || addonInSub.get('name')?.touched)">
                            <p class="text-danger m-0 p-0"><small>name is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="unit" class="col-sm-4 col-form-label"><b>Unit</b></label>
            <div class="col-sm-8 mt-2">
                <div class="select-section">
                    <select class="form-select form-control" formControlName="unit">
                        <option selected disabled value="">Select Unit</option>
                        <option value="mb">MB</option>
                        <option value="gb">GB</option>
                        <option value="nos">Nos</option>
                    </select>
                    <div *ngIf="addonInSub.get('unit')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="addonInSub.get('unit')?.errors?.['required'] && (addonInSub.get('unit')?.dirty || addonInSub.get('unit')?.touched)">
                            <p class="text-danger m-0 p-0"><small>unit is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="price" class="col-sm-4 col-form-label"><b>Price</b></label>
            <div class="col-sm-8 mt-2">
                <input type="number" placeholder="Enter Price" class="form-control" formControlName="price" min="0"
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                <div *ngIf="addonInSub.get('price')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="addonInSub.get('price')?.errors?.['required'] && (addonInSub.get('price')?.dirty || addonInSub.get('price')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Price is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="quantity" class="col-sm-4 col-form-label"><b>Quantity</b></label>
            <div class="col-sm-8 mt-2">
                <input type="number" placeholder="Enter Quantity" class="form-control" formControlName="quantity"
                    min="0" 
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                <div *ngIf="addonInSub.get('quantity')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="addonInSub.get('quantity')?.errors?.['required'] && (addonInSub.get('quantity')?.dirty || addonInSub.get('quantity')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Quantity is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="description" class="col-sm-4 col-form-label"><b>Description</b></label>
            <div class="col-sm-8 mt-2">
                <textarea type="text" class="form-control description" aria-describedby="emailHelp"
                    placeholder="Description..." formControlName="description"></textarea>
                <div *ngIf="addonInSub.get('description')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="addonInSub.get('description')?.errors?.['required'] && (addonInSub.get('description')?.dirty || addonInSub.get('description')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label"><b>Remarks</b></label>
            <div class="col-sm-8 mt-2">
                <textarea rows="1" class="form-control " id="fullName" placeholder="Remarks" style="overflow:hidden"
                    formControlName="remarks"></textarea>
                <div *ngIf="addonInSub.get('remarks')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="addonInSub.get('remarks')?.errors?.['required'] && (addonInSub.get('remarks')?.dirty || addonInSub.get('remarks')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label"><b>Select User</b></label>
            <div class="col-sm-8 mt-2">
                <select class="form-select form-control mt-2" id="salesPersonName" formControlName="salesPersonName">
                    <option value="direct" selected (click)="salesPersons('direct')">Direct</option>
                    <option value="support" (click)="salesPersons('support')">Support</option>
                    <option *ngFor="let salesPerson of salesPersonList" (click)="salesPersons(salesPerson.name)" [value]="salesPerson.name">
                        {{salesPerson?.name}}
                    </option>
                </select>
            </div>
        </div>
    </form>
    <div class="col-sm-12" *ngIf="isEdit">
        <div class="alert alert-warning mt-4" role="alert">
            <p class="mb-0">
                <span class="fw-bold">Current Price :
                    <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
                    <span class="text-primary">
                        {{currentPrice | number:'1.0-2'}}
                    </span>
                </span>

                <span class="fw-bold float-end" *ngIf="totalprice<=0">Refundable Amount :
                    <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
                    <span class="text-primary">
                        {{-(totalprice) | number:'1.0-2'}}
                    </span>
                </span>
                <span class="fw-bold float-end" *ngIf="totalprice>0">Extra Charges :
                    <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
                    <span class="text-primary">
                        {{totalprice | number:'1.0-2'}}
                    </span>
                </span>
            </p>
        </div>
        <p class="text-info ms-2 mt-0" *ngIf="isEdit">
            *Charges for the next time when plan gets renewed :
            <i class="bi bi-currency-rupee text-primary m-0"></i>
            <span class="text-primary fw-bold">{{nextAmount | number:'1.0-2'}}</span>
        </p>

    </div>
</div>
<div class="text-end border-0 mt-0 pt-0">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">{{isEdit?'Update':'Submit'}}</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
</div>