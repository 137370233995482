import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlanCreation, PlanDetail, PlanRoot } from '../interfaces/plan';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private http: HttpClient
  ) { }

  createPlan(payload: any) {
    return this.http.post<PlanCreation>(`${environment.apiURL}/admin/plan`, payload)
  }

  planList(productId: string) {
    return this.http.get<PlanRoot>(`${environment.apiURL}/admin/plan?productId=${productId}`)
  }
  discountPlanList() {
    return this.http.get<PlanRoot>(`${environment.apiURL}/admin/discount/plan`)
  }
  planDetail(productId: string, planId: string) {
    return this.http.get<PlanDetail>(`${environment.apiURL}/admin/plan/details?productId=${productId}&planId=${planId}`)
  }

  editPlan(payload: any) {
    return this.http.put<PlanCreation>(`${environment.apiURL}/admin/plan`, payload)
  }

  delete(planId: string, productId: string) {
    return this.http.delete<PlanCreation>(`${environment.apiURL}/admin/plan?planId=${planId}&productId=${productId}`)
  }

  selectPlanList(partnerId: string, productId: string) {
    return this.http.get<PlanRoot>(`${environment.apiURL}/admin/partner/plan?partnerId=${partnerId}&productId=${productId}`)
  }

  selectPlanDetail(partnerId: string, planId: string) {
    return this.http.get<PlanDetail>(`${environment.apiURL}/admin/partner/plan/details?partnerId=${partnerId}&planId=${planId}`)
  }

  changePlanPrice(pricePayload: any) {
    return this.http.post<PlanCreation>(`${environment.apiURL}/admin/plan/price`, pricePayload)
  }

}
