<div class="main-panel ">
    <div class="content-wrapper ">
        <div class="row">
            <div>
                <h3 class="font-weight-bold heading"><b>Order Management</b></h3>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="mb-3">
                                <ul class="d-flex service ps-0 mb-0">
                                    <li (click)="toggleSection('addOn')"
                                        [ngClass]="(activeSection==='addOn') ? 'activate': ''">
                                        <a>Request for Addon</a>
                                    </li>
                                    <li (click)="toggleSection('subscription')"
                                        [ngClass]="(activeSection==='subscription') ? 'activate': ''">
                                        <a>Request for Subscription</a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div class="records mb-3">{{updatePaginationText}}</div>
                        <div *ngIf="!isOrderManagement" class="text-center text-muted">No data found!</div>
                        <div class=" table-responsive table-responsive1" *ngIf="isOrderManagement">
                            <table class="table table-bordered fixed-columns-table">
                                <thead class="thead-header">
                                    <tr>
                                        <th scope="col">Sub Id</th>
                                        <th scope="col">Customer Email</th>
                                        <th scope="col" *ngIf="activeSection=='subscription'">salesperson Name</th>
                                        <th scope="col">{{activeSection=='addOn'? 'Addon Name': 'Plan Name'}}</th>
                                        <th scope="col">Price</th>
                                        <th scope="col" *ngIf="activeSection=='subscription'">RAM</th>
                                        <th scope="col" *ngIf="activeSection=='subscription'">CPU</th>
                                        <th scope="col" *ngIf="activeSection=='subscription'">Storage</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" *ngIf="activeSection=='addOn'">Remarks</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let data of detailList | paginate : config">
                                        <td>{{data?.code ? data?.code :'N/A'}}</td>
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                ngbTooltip=" {{data?.customerEmail}}" placement="top">
                                                {{data?.customerEmail}}</div>

                                        </td>
                                        <td *ngIf="activeSection=='subscription'">{{data?.salesPersonName}}</td>
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                ngbTooltip=" {{activeSection=='addOn'? (data?.addOn?.name | titlecase) : (data?.plan?.name | titlecase) }}"
                                                placement="top">
                                                {{activeSection=='addOn'? (data?.addOn?.name | titlecase):
                                                (data?.plan?.name | titlecase)}}</div>

                                        </td>
                                        <td>{{activeSection=='addOn'? data?.addOn?.price : data?.plan?.price}}</td>
                                        <td *ngIf="activeSection=='subscription'">{{data?.plan?.ram}}</td>
                                        <td *ngIf="activeSection=='subscription'">{{data?.plan?.cpu}}</td>
                                        <td *ngIf="activeSection=='subscription'">{{data?.plan?.storage}}</td>
                                        <td [ngClass]="{
                                                'status-pending': data?.status === 'pending',
                                                'status-rejected': data?.status === 'rejected',
                                                'status-approved': data?.status === 'approved'
                                              }">{{data?.status | titlecase}}</td>
                                        <td *ngIf="activeSection=='addOn'">{{data?.addOn?.remarks | titlecase}}</td>
                                        <td [ngClass]="data?.status != 'pending'?'disabled':'active'">
                                            <i class="bi bi-pencil-square pencil text-center" ngbTooltip="Update Status"
                                                placement="top" (click)="updateStatus(data)">
                                            </i>
                                        </td>

                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="mt-3 float-end me-4" *ngIf="isOrderManagement">
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>