import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlanCreation } from 'src/app/interfaces/plan';
import { UnitRoot } from 'src/app/interfaces/unit';
import { PlanService } from 'src/app/services/plan.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-new-plan',
  templateUrl: './new-plan.component.html',
  styleUrls: ['./new-plan.component.scss']
})
export class NewPlanComponent implements OnInit {

  productId: string = ''
  planId: string = ''
  isEdit: boolean = false
  units: any = []

  planForm = new FormGroup({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    code: new FormControl('', Validators.required),
    unit: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    billEvery: new FormControl('', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]),
    billingCycle: new FormControl('', Validators.required),
    autoRenewal: new FormControl(true, Validators.required),
    isShow: new FormControl(false),
    description: new FormControl('')
  })

  constructor(
    private planService: PlanService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toasts: ToastrService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.unitList()
    this.activateRoute.queryParams.subscribe(data => {
      this.productId = data['productId'];
    })
    this.activateRoute.queryParams.subscribe(data => {
      this.planId = data['planId'];
    })
    if (this.productId && this.planId) {
      this.isEdit = true;
      this.planDetails();
    }
  }

  newPlan() {
    if (!this.planForm.valid) {
      this.planForm.markAllAsTouched();
    }
    else {
      this.submit();
    }
  }

  submit() {
    if (!this.isEdit) {
      this.addPlan();
    }
    else {
      this.updatePlan();
    }
  }

  unitList() {
    this.productService.unitList().subscribe((res: UnitRoot) => {
      if (res.success) {
        this.units = res.units;
      }
    })
  }

  addPlan() {
    let planPayload = {}
    planPayload = Object.assign(this.planForm.value, { productId: this.productId })
    this.planService.createPlan(planPayload).subscribe((res: PlanCreation) => {
      if (res.success) {
        this.navigateToAddProduct();
        this.toasts.success(res.message);
        this.planForm.reset();
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      })
  }

  planDetails() {
    this.planService.planDetail(this.productId, this.planId).subscribe((res: any) => {
      if (res.success) {
        this.planForm.patchValue(res.plan);
      }
    })
  }

  navigateToAddProduct() {
    this.router.navigate(['product/add-product'], { queryParams: { productId: this.productId } })
  }

  updatePlan() {
    let updatePayload = {}
    updatePayload = Object.assign(this.planForm.value, { productId: this.productId, planId: this.planId })
    this.planService.editPlan(updatePayload).subscribe((res: PlanCreation) => {
      if (res.success) {
        this.navigateToAddProduct();
        this.toasts.success(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      })
  }


}
