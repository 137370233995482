<!-- --------Settings-------- -->
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 mb-3">
     <div class="row">
      <div class="col-12">
       <h3 class="font-weight-bold heading"><b>Settings</b></h3>
      </div>
     </div>
    </div>
    <!-- -------image--upload--section---------- -->
    <div class="col-md-12 grid-margin stretch-card">
     <div class="card">
      <div class="card-body py-0 px-0">
       <p class="fs-5 setting-logo p-1 text-light"><i class="bi bi-card-image"></i> Logo</p>
       <p class="text-secondary sub-text">This email will be used in the reseller system. Your logo
        should be PNG Transparent Preferred and Dimensions of: 170px*50px.</p>
       <div class="contact-email">
        <div class="choose-file row">
         <h4 class="col-sm-8">To upload/ Change your company Logo, Please click on this: </h4>
         <a class="col-sm-4" href="https://imgbb.com/" target="_blank">Upload Image</a>
        </div>
        <div>
         <h4>Please Follow these instructions to upload / change company logo:</h4>
         <ol>
          <li>Click on the given Link to upload/change Company Logo.</li>
          <li>After uploading image, Please select HTML full linked and copy the src link. </li>
          <li>Paste that copied link to the given input Box.</li>
         </ol>
        </div>
        <form action="" [formGroup]="imageUploadForm">
         <div class="row mb-3">
          <label for="text" class="col-sm-5 col-form-label">Please Paste your Logo Url Here:</label>
          <div class="col-sm-7">
           <input type="text" class="form-control" id="mobile" placeholder="Paste image link" formControlName="logo">
           <div *ngIf="imageUploadForm.get('logo')?.invalid" class="alert mb-0 p-0">
            <div
             *ngIf="imageUploadForm.get('logo')?.errors?.['required'] && (imageUploadForm.get('logo')?.dirty || imageUploadForm.get('logo')?.touched)">
             <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
             </p>
            </div>
           </div>
          </div>
         </div>
         <div class="text-end mb-4">
          <button type="submit" class="comman-btn1 btn-primary text-light" (click)="submitLogo()">
           Submit</button>
         </div>
        </form>
       </div>
       <!-- --- -->
      </div>
     </div>
    </div>
    <!-- ----end------ -->
    <div class="col-md-12 grid-margin stretch-card">
     <div class="card">
      <div class="card-body py-0 px-0">
       <p class="fs-5 setting-logo p-1 text-light"><i class="bi bi-person-badge"></i>
        Contact/Email:</p>
       <p class="text-secondary sub-text">This email will be used by your users to contact you.</p>
       <form action="" [formGroup]="contactForm">
        <div class="contact-email">
         <div class="row mb-3">
          <label for="text" class="col-sm-3 col-form-label">Contact Sales</label>
          <div class="col-sm-9 mt-2">
           <input type="email" class="form-control" id="mobile" formControlName="sales">
           <div *ngIf="contactForm.get('sales')?.invalid" class="alert mb-0 p-0">
            <div
             *ngIf="contactForm.get('sales')?.errors?.['required'] && (contactForm.get('sales')?.dirty || contactForm.get('sales')?.touched)">
             <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
             </p>
            </div>
            <div *ngIf="contactForm.get('sales')?.errors?.['pattern']">
             <p class="text-danger m-0 p-0"><small>Please enter valid email id.</small>
             </p>
            </div>
           </div>
          </div>
         </div>
         <!--  -->
         <div class="row mb-3">
          <label for="text" class="col-sm-3 col-form-label">Contact Support</label>
          <div class="col-sm-9 mt-2">
           <input type="email" class="form-control" id="mobile" formControlName="support">
           <div *ngIf="contactForm.get('support')?.invalid" class="alert mb-0 p-0">
            <div
             *ngIf="contactForm.get('support')?.errors?.['required'] && (contactForm.get('support')?.dirty || contactForm.get('support')?.touched)">
             <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
             </p>
            </div>
            <div *ngIf="contactForm.get('support')?.errors?.['pattern']">
             <p class="text-danger m-0 p-0"><small>Please enter valid email id.</small>
             </p>
            </div>
           </div>
          </div>
         </div>
         <!-- --- -->
         <div class="text-end mb-4">
          <button type="submit" class="comman-btn1 btn-primary" (click)="saveContactDetails()">Save</button>
         </div>
        </div>
       </form>
      </div>
     </div>
    </div>
    <!-- ------------------ -->
   </div>
  </div>
 </div>
</div>