<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="main-panel">
 <div class="content-wrapper">
  <div class="row">
   <div class="col-md-12 grid-margin">
    <div class="row">
     <div class="col-6">
      <h3 class="font-weight-bold">Roles & Permissions</h3>
     </div>
     <div class="col-6 text-end">
      <button type="button" class="comman-btn1 btn-primary me-2"
       [routerLink]="['/roles-permissions/add-roles-permissions']">+ Roles</button>
     </div>
    </div>
   </div>
   <!-- -------table-section----------- -->
   <div class="row table-section pe-0">
    <div class="col-md-12 grid-margin stretch-card pe-0">
     <div class="card">
      <div class="card-body">
       <div *ngIf="!isRolesAndPermissionAvailable" class="text-center">
        <h4>There is no Roles added yet. To add Roles click</h4>
        <button type="button" class="comman-btn1 btn-primary me-2"
         [routerLink]="['/roles-permissions/add-roles-permissions']">+ Roles</button>
       </div>
       <div class="table-responsive" *ngIf="isRolesAndPermissionAvailable">
        <table class="table table-striped table-borderless">
         <thead>
          <tr>
           <th scope="col">Administration Roles</th>
           <th scope="col" class="text-center">Action</th>
          </tr>
         </thead>
         <tbody>
          <tr *ngFor="let role of roles">
           <td class="productNameCursor" (click)="rolesDetails(role?._id)">
            {{role?.roles | titlecase}}</td>
           <td class="text-center">
            <i class="bi bi-pencil-fill text-primary productNameCursor me-3" (click)="rolesDetails(role?._id)"></i>
            <i class="bi bi-trash3-fill text-danger mouseCursor" (click)="deleteRoles(role?._id)"></i>
           </td>
          </tr>
         </tbody>
        </table>
       </div>
       <!-- <div class="mt-4 float-end me-4">
                                    <pagination-controls></pagination-controls>
                                </div> -->
      </div>
     </div>
    </div>
   </div>
   <!-- -----end-------- -->
  </div>
 </div>
</div>