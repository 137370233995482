<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 grid-margin">
     <div class="row">
      <div class="col-md-4">
       <h3 class="font-weight-bold">Add Roles & Permissions</h3>
      </div>
     </div>
    </div>
    <!-- ---------form--------- -->
    <form action="" [formGroup]="roleForm">
     <div class="row mb-3 pe-0">
      <label for="fullName" class="col-sm-2 col-form-label">Role Name</label>
      <div class="col-sm-10 mt-2">
       <input type="text" class="form-control" id="fullName" formControlName="roles">
       <div *ngIf="roleForm.get('roles')?.invalid" class="alert mb-0 p-0">
        <div class="error-message"
         *ngIf="roleForm.get('roles')?.errors?.['required'] && (roleForm.get('roles')?.dirty || roleForm.get('roles')?.touched)">
         <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
         </p>
        </div>
       </div>
      </div>
     </div>
    </form>
    <!-- -----end----- -->
    <!-- -----table-section---- -->
    <div class="row table-section pe-0">
     <div class="col-md-12 grid-margin stretch-card pe-0">
      <div class="card">
       <div class="card-body">
        <div class="table-responsive">
         <table class="table table-striped table-borderless">
          <thead>
           <tr>
            <th>Name</th>
            <th class="text-center">All</th>
            <th class="text-center">View</th>
            <th class="text-center">Create</th>
            <th class="text-center">Delete</th>
            <th class="text-center">Edit</th>
           </tr>
          </thead>
          <tbody>
           <tr *ngFor="let role of permissions">
            <td class="productNameCursor">{{role?.resources | titlecase}}</td>
            <td class="text-center">
             <input type="checkbox" [checked]="role.checked" [indeterminate]="someComplete(role)"
              (change)="setAll(role, $event)">
            </td>
            <td class="text-center" *ngFor="let permission of role.permissions">
             <input type="checkbox" [(ngModel)]="permission.checked" (ngModelChange)="updateAllComplete(role)">
            </td>
           </tr>
          </tbody>
         </table>
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="col-md-12 text-end">
     <button type="submit" class="comman-btn1 btn-primary me-2" (click)="rolesAndPermissions()">{{isEdit?
      'Update': 'Submit'}}</button>
     <button type="button" class="comman-btn1 btn-secondary me-2" [routerLink]="['/roles-permissions']">Cancel</button>
    </div>
    <!-- -----end------ -->
   </div>
  </div>
 </div>