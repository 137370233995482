import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddOnDetail, AddOnRoot, createAddOn } from '../interfaces/addOn';

@Injectable({
  providedIn: 'root'
})
export class AddOnService {

  constructor(
    private http: HttpClient
  ) { }

  createAddOn(payload: any) {
    return this.http.post<createAddOn>(`${environment.apiURL}/admin/addon`, payload)
  }

  addOnList(productId: string) {
    return this.http.get<AddOnRoot>(`${environment.apiURL}/admin/addon?productId=${productId}`)
  }

  addOnDetail(productId: string, addonId: string) {
    return this.http.get<AddOnDetail>(`${environment.apiURL}/admin/addon/details?productId=${productId}&addOnId=${addonId}`)
  }

  editAddOn(payload: any) {
    return this.http.put<createAddOn>(`${environment.apiURL}/admin/addon`, payload)
  }

  delete(addonId: string, productId: string) {
    return this.http.delete<createAddOn>(`${environment.apiURL}/admin/addon?addOnId=${addonId}&productId=${productId}`)
  }

  selectAddonList(partnerId: string, productId: string) {
    return this.http.get<AddOnRoot>(`${environment.apiURL}/admin/partner/addon?partnerId=${partnerId}&productId=${productId}`)
  }

  selectAddonDetail(partnerId: string, addonId: string) {
    return this.http.get<AddOnDetail>(`${environment.apiURL}/admin/partner/addon/details?partnerId=${partnerId}&addOnId=${addonId}`)
  }

  changeAddonPrice(pricePayload: any) {
    return this.http.post<createAddOn>(`${environment.apiURL}/admin/addon/price`, pricePayload)
  }
}
