<!-- ----------header---section--start---------- -->

<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
    <a class="navbar-brand brand-logo me-5" href="index.html"><img
        [src]="logo? logo:'./../assets/images/cocloud-logo.jpg'" class="me-2" alt="logo" class="logo d-block" /></a>
    <a class="navbar-brand brand-logo-mini" href="index.html"><img
        [src]="logo? logo: './../assets/images/cocloud-logo.jpg'" alt="logo" class="logo d-block" /></a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
    <span class='icon-menu d-sm-none d-md-none d-lg-block hamburger_icon' (click)="onToggle()"></span>
    <ul class="navbar-nav navbar-nav-right">
      <li class="text-center mt-3 me-4 ">
        <div class="wallet">
          <p class="mb-0 text-center ">Wallet: &nbsp; <b>{{wallet |
              currency:'INR'}}</b></p>
        </div>
      </li>
      <li class="text-center mt-3 me-2">
        <p class="name mb-0 "><i class="bi bi-person-circle me-2 fs-5"></i><b>{{name | titlecase}}</b></p>
      </li>
      <li class="nav-item nav-profile dropdown">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="profileDropdown">
          <i class="bi bi-three-dots-vertical"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
          <span class="dropdown-item" [routerLink]="['setting']" *ngIf="userType">
            <i class="ti-settings text-primary"></i>
            Settings
          </span>
          <span class="dropdown-item" (click)="openModal()">
            <i class="ti-power-off text-primary"></i>
            Logout
          </span>
        </div>
      </li>

    </ul>
    <button class="hide-menu d-lg-none align-self-center" type="button" (click)="hideBtn()">
      <i class="bi bi-list fs-"></i>
    </button>
  </div>
</nav>
<!-- -------------------sidebar---------------- -->
<!-- partial:partials/_sidebar.html -->
<div class="container-fluid page-body-wrapper custome-sidebar">
  <nav class="sidebar min-menu" id="sidebar "
    [ngClass]="showNav ? (showNav ? 'show-nav' : 'sidebar-width'): (isShow ? 'show-nav': 'hide-nav')">

    <ul class="nav  ">
      <li class="nav-item" *ngIf="userType || userRole">
        <a class="nav-link1" [routerLink]="['']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
        true}" (click)="sideNavToggle()">
          <i class="bi bi-grid-fill menu-icon"></i>
          <span class="menu-title">Dashboard</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['instance']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:true}" (click)="sideNavToggle()">
          <i class="bi bi-person-fill menu-icon"></i>
          <span class="menu-title">Instance</span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link1" [routerLink]="['cloud-user']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:true}" (click)="sideNavToggle()">
          <i class="bi bi-people-fill menu-icon"></i>
          <span class="menu-title">Cloud User</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="userType || userRole">
        <a class="nav-link1" data-bs-toggle="collapse" [routerLink]="['reseller']" [routerLinkActive]="['active-link']"
          aria-expanded="false" aria-controls="e-commerce" [routerLinkActiveOptions]="{exact:
            false}" (click)="sideNavToggle()">
          <i class="bi bi-tag-fill menu-icon"></i>
          <span class="menu-title">Reseller</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['server']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{ exact: true }" (click)="sideNavToggle()">
          <i class="bi bi-database-fill-gear menu-icon"></i>
          <span class="menu-title">Server</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['tally-release']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{ exact: true }" (click)="sideNavToggle()">
          <i class="bi bi-x-diamond-fill menu-icon"></i>
          <span class="menu-title">Tally Release</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="userType || userRole">
        <a class="nav-link1" [routerLink]="['subscription']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{ exact: true }" (click)="sideNavToggle()">
          <i class="bi bi-sliders2-vertical menu-icon"></i>
          <span class="menu-title">Subscription</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['billing']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
          true}" (click)="sideNavToggle()">
          <i class="bi bi-receipt menu-icon"></i>
          <span class="menu-title">Billing</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['coupon-list']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:false}" (click)="sideNavToggle()">
          <i class="bi bi-boxes menu-icon"></i>
          <span class="menu-title">Coupons & Schemes</span>
        </a>
      </li>
      <!-- <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['banner']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:false}" (click)="sideNavToggle()">
          <i class="bi bi-badge-ad-fill menu-icon"></i>
          <span class="menu-title">Banner</span>
        </a>
      </li> -->
      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['product']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
          false}" (click)="sideNavToggle()">
          <i class="bi bi-p-square-fill menu-icon"></i>
          <span class="menu-title">Product</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['google-drive']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
          false}" (click)="sideNavToggle()">
          <i class="mdi mdi-google-drive menu-icon"></i>
          <span class="menu-title">Google Drive</span>
        </a>
      </li>
      <!-- <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['order-management']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
          false}" (click)="sideNavToggle()">
          <i class="bi bi-card-text menu-icon"></i>
          <span class="menu-title">Order Management</span>
        </a>
      </li> -->
      <li class="nav-item" *ngIf="userType || userRole">
        <a class="nav-link log-link" data-bs-toggle="collapse" href="#reports" aria-controls="reports"
          (click)="toggleMenu('reports')" [attr.aria-expanded]="expandedMenu === 'reports' ? 'true' : 'false'">
          <i class="bi bi-graph-up-arrow menu-icon"
            [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}"></i>
          <span class="menu-title" [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}">Reports</span>
          <i class="menu-arrow" [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : '#130F26'}"></i>
        </a>
        <div class="collapse" [ngClass]="{'show': expandedMenu === 'reports'}" id="reports">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['mrr-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}"></i>
                <span class="menu-titled">MRR Report</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['account-manager-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}"></i>
                <span class="menu-titled">Account Manager</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['partner-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}"></i>
                <span class="menu-titled">Partner Report</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="userType">
              <a class="nav-link" [routerLink]="['inactive-sub-report']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'reports' ? 'white' : ''}"></i>
                <span class="menu-titled">Inactive Sub User</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['payment-report']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
          true}" (click)="sideNavToggle()">

          <i class="bi bi-credit-card menu-icon"></i>
          <span class="menu-title">Payment Report</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['setting']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:
          true}" (click)="sideNavToggle()">
          <i class="bi bi-gear-wide-connected menu-icon"></i>
          <span class="menu-title">Settings</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['account-manager']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
          false}" (click)="sideNavToggle()">
          <i class="bi bi-person-fill-gear menu-icon"></i>
          <span class="menu-title">Account Manager</span>
        </a>
      </li>


      <li class="nav-item" *ngIf="userType">
        <a class="nav-link log-link" data-bs-toggle="collapse" href="#ui-advanced" aria-controls="ui-advanced"
          (click)="toggleMenu('ui-advanced')" [attr.aria-expanded]="expandedMenu === 'ui-advanced' ? 'true' : 'false'">
          <i class="bi bi-list-ol menu-icon" [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}"></i>
          <span class="menu-title" [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}">Logs</span>
          <i class="menu-arrow" [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : '#130F26'}"></i>
        </a>
        <div class="collapse" [ngClass]="{'show': expandedMenu === 'ui-advanced'}" id="ui-advanced">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['audit-log']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}"></i>
                <span class="menu-titled">Audit Logs</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['instance-log']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}"></i>
                <span class="menu-titled">Instance Logs</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="userType">
              <a class="nav-link" [routerLink]="['virtual-machine']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact: true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon"
                  [ngStyle]="{'color': expandedMenu === 'ui-advanced' ? 'white' : ''}"></i>
                <span class="menu-titled">VM Logs</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- Second Menu -->

      <!-- <li class="nav-item" *ngIf="userType">
        <a class="nav-link log-link" data-bs-toggle="collapse" fragment="ui-advanced" href="#ui-advanced"
          aria-controls="ui-advanced" (click)="toggleMenu()" [attr.aria-expanded]="isExpanded ? 'true' : 'false'">
          <i class="bi bi-list-ol menu-icon" [ngStyle]="{'color': isExpanded ? 'white' : ''}"></i>
          <span class="menu-title" [ngStyle]="{'color': isExpanded ? 'white' : ''}">Logs</span>
          <i class="menu-arrow" [ngStyle]="{'color': isExpanded ? 'white' : '#130F26'}"></i>
        </a>
        <div class="collapse" id="ui-advanced">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['audit-log']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
                true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon" [ngStyle]="{'color': isExpanded ? 'white' : ''}"></i>
                <span class="menu-titled">Audit Logs</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['instance-log']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
                true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon" [ngStyle]="{'color': isExpanded ? 'white' : ''}"></i>
                <span class="menu-titled">Instance Logs</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="userType">
              <a class="nav-link" [routerLink]="['virtual-machine']" [routerLinkActive]="['active-link']"
                [routerLinkActiveOptions]="{exact:
                true}" (click)="sideNavToggle()">
                <i class="bi bi-info-square-fill menu-icon" [ngStyle]="{'color': isExpanded ? 'white' : ''}"></i>
                <span class="menu-titled">VM Logs</span>
              </a>
            </li>
          </ul>
        </div>
      </li> -->
      <!-- <li class="nav-item" *ngIf="userType">
        <a class="nav-link1" [routerLink]="['roles-permissions']" [routerLinkActive]="['active-link']"
          [routerLinkActiveOptions]="{exact:
        false}" (click)="sideNavToggle()">
          <i class="bi bi-person-fill-slash menu-icon"></i>
          <span class="menu-title">Roles & Permissions</span>
        </a>
      </li> -->
    </ul>
  </nav>
  <!-- - -->
  <div [ngClass]="isShow ? 'show-right-section' : 'hide-right-section' " class="side-section">
    <router-outlet></router-outlet>
  </div>
  <!-- - -->
</div>