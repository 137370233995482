import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { labelDetailRoot, labelRoot } from 'src/app/interfaces/label';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'app-add-label-modal',
  templateUrl: './add-label-modal.component.html',
  styleUrls: ['./add-label-modal.component.scss']
})
export class AddLabelModalComponent implements OnInit {

  @Input() labelId: string;
  event: EventEmitter<any> = new EventEmitter();
  isEdit: boolean = false;
  isColorSelected: boolean = false;

  pickedColor: any;


  labelForm = new FormGroup({
    name: new FormControl(''),
    color: new FormControl(''),
    customColor: new FormControl(''),
  })

  selectedColor = ["#f0f8ff", "#a52a2a", "#faebd7", "#00ffff", "#7fffd4", "#f0ffff", "#f5f5dc",
    "#ffe4c4", "#000000", "#ffebcd", "#0000ff", "#8a2be2", '#ff69b4', '#800080', '#2e8b57', '#6a5acd', '#4682b4',
    '#9acd32', '#40e0d0', '#008080', '#ff6347', '#87ceeb']

  constructor(
    private labelService: LabelService,
    public bsModalRef: BsModalRef,
    private toasts: ToastrService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.labelDetails();
    });
  }

  submit() {
    if (!this.isEdit) {
      this.addLabel();
    }
    else {
      this.updateLabel();
    }
  }

  addLabel() {
    let labelPayload = this.labelForm.value
    if (labelPayload.color === '' || labelPayload.name === '') {
      this.toasts.warning("Please select color and enter label name")
    }
    else {
      this.labelService.addLabel(labelPayload).subscribe((res: labelRoot) => {
        if (res.success) {
          this.toasts.success(res.message);
          this.event.emit('ok');
          this.closedModal();

        }
        else {
          this.toasts.warning(res.message);
        }
      },
        (error) => {
          this.toasts.error(error.error.message);
        })

    }

  }

  selectColor(color: string) {
    this.pickedColor = color;
    this.isColorSelected = true;
    this.labelForm.value.color = this.pickedColor
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  labelDetails() {
    this.labelService.labelDetails(this.labelId).subscribe((res: labelDetailRoot) => {
      if (res.success) {
        this.pickedColor = res.label.color
        this.isEdit = true;
        this.labelForm.patchValue(res.label);
      }
    })
  }

  updateLabel() {
    let updatePayload = {};
    updatePayload = Object.assign(this.labelForm.value, { labelId: this.labelId })
    this.labelService.updateLabel(updatePayload).subscribe((res: labelRoot) => {
      if (res.success) {
        this.toasts.success(res.message);
        this.event.emit('ok');
        this.closedModal();
      }
      else {
        this.toasts.warning(res.message);
      }
    }, (error) => {
      this.toasts.error(error.error.message);
    })
  }

  selectCustomColor() {
    this.pickedColor = this.labelForm.value.color
  }

}
