import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  passType: string = 'password';
  show_eye: Boolean = false;
  showPassword: boolean = false;
  showPassword1: boolean = false;
  changePassword: boolean = false;
  otpVerify: boolean = false;
  resetHash: string;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]),
    password: new FormControl('', [Validators.minLength(6), Validators.required])
  })

  resetPasswordForm = new FormGroup({
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email,
    ])),

    password: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(6),
    ])),

    confirmPassword: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(6),
    ])),

    otp: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]{6,6}$'),
      Validators.maxLength(6),
    ])),
  })

  constructor(
    public loginService: LoginService,
    private router: Router,
    private toasts: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loginService.adminLogo().subscribe((res: any) => {
      localStorage.setItem('logo', res.logo)
    })
  }

  login() {
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
    }
    else {
      this.adminLogin();
    }
  }
  resetPassword() {
    this.changePassword = true
  }
  validationsReset = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter valid email' },
    ],

    'otp': [
      { type: 'required', message: 'Otp is required' },
      { type: 'pattern', message: 'Please enter valid 6 digit OTP' },
    ],

    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password is min 6 digit' },
    ],


  };

  adminLogin() {
    const loginPayload = this.loginForm.value
    this.loginService.login(loginPayload).subscribe((res: any) => {
      if (res.success) {
        localStorage.setItem('token', res.user.token);
        localStorage.setItem('walletAmount', res.user.walletAmount)
        localStorage.setItem('isSuperAdmin', res.user.isSuperAdmin)
        localStorage.setItem('role', res.user.role),
        localStorage.setItem('userId', res.user._id)
        this.router.navigate(['']);
        this.toasts.success(res.message);
      }
      else {
        this.toasts.warning(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message);
      })
  }

  getOtp() {

    if (this.resetPasswordForm.controls.email.invalid) {
      this.resetPasswordForm.controls.email.markAsTouched();
    }
    else {

      const resetPasswordPayload = {
        email: this.resetPasswordForm?.value?.email || ''
      }
      this.loginService.forgotPassword(resetPasswordPayload).subscribe((res: any) => {
        if (res.success) {
          this.otpVerify = true;
          this.resetHash = res.hash;
          this.toasts.info("Otp send to registered email")
        }
        if (!res.success) {
          this.toasts.warning(res.message)
        }
      },
        (error) => {
          this.toasts.error(error.error.message)
        }
      )
    }

  }

  confirmChange() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
    }
    else if (this.resetPasswordForm.value.password != this.resetPasswordForm.value.confirmPassword) {
      this.toasts.warning("Password does not match with re-entered password")
    }
    else {
      const payload = {
        hash: this.resetHash
      }
      const { email, password, otp } = this.resetPasswordForm.value;
      Object.assign(payload, { email, password, otp })

      this.loginService.changePassword(payload).subscribe((res: any) => {
        if (res.success) {
          this.changePassword = false;
          this.resetPasswordForm.reset()
          this.toasts.info("Password updated")
        }
      },
        (error) => {
          this.toasts.error(error.error.message)
        }
      )
    }
  }

  changePasswordType() {
    this.show_eye = !this.show_eye;
    if (this.passType == 'password') {
      this.passType = 'text'
    } else {
      this.passType = 'password'
    }
  }

  hidePassword(section: string) {

    if (section === 'reset') {
      this.showPassword = !this.showPassword;
      (document.getElementById("reset1") as HTMLInputElement).type = this.showPassword ? 'text' : 'password';
    }

    if (section === 'confirm') {
      this.showPassword1 = !this.showPassword1;
      (document.getElementById("reset2") as HTMLInputElement).type = this.showPassword1 ? 'text' : 'password';
    }

  }

}
