import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BillingDetail, BillRoot } from 'src/app/interfaces/billing';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  billing: any = [];
  monthBilling: any = [];
  isBillingAvailable: boolean = false
  totalCount: number = 0
  page: number = 1
  month: number = 0
  year: number = 0
  currentView: 'main' | 'details' = 'main';
  currentExpandedBill: any = null;
  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  searchForm = new FormGroup({
    action: new FormControl(''),
    createFrom: new FormControl(''),
    createTo: new FormControl(''),
    partnerEmail: new FormControl('')
  })

  constructor(
    private billingService: BillingService,
    private toasts: ToastrService,
    private authService: AuthService
  ) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1
    this.month = Number(currentMonth)
    this.year = Number(currentDate.getFullYear())
  }

  ngOnInit(): void {
    this.billingList();
    // this.billingDetail();
  }

  billingList() {
    const searchPayload = this.searchForm.value
    const days = moment(this.searchForm.value.createTo).diff(this.searchForm.value.createFrom, 'days')
    if (days < 0) {
      this.toasts.warning('The Bill Create from date is lesser than Bill Create to date.')
    }
    else {
      this.billingService.billList(JSON.stringify(searchPayload), this.config.currentPage, this.config.itemsPerPage).subscribe((res: BillRoot) => {
        if (res.success) {
          if (res.billings.length === 0) {
            this.toasts.error('No data found');
            this.isBillingAvailable = false;
            this.config.currentPage = 1
            this.config.totalItems = 0
            this.billing = []
          }
          else {
            this.billing = res.billings;
            this.isBillingAvailable = true
            this.config.currentPage = res?.totalBillings?.pages;
            this.config.totalItems = res?.totalBillings?.total;
            this.billing.map((e: any) => {
              e.collapse = true;
            })
          }

        }
      },
        (error) => {
          this.billing = []
          this.toasts.error(error.error.message);
        })
    }
  }

  toggleTableExpend(bill: any) {
    if (this.currentExpandedBill === bill) {
      this.currentExpandedBill.collapse = true;
      this.currentExpandedBill = null;
      this.currentView = 'main';
      this.config.currentPage = 1
      this.config.itemsPerPage = 25;
      this.config.totalItems = this.billing.length;
    } else {
      if (this.currentExpandedBill) {
        this.currentExpandedBill.collapse = true;
      }
      bill.collapse = false;
      this.currentExpandedBill = bill;
     
      this.currentView = 'details';
      this.loadMonthBilling(bill._id.month, bill._id.year);
    }
  }
  loadMonthBilling(month: number, year: number) {
    this.month = month;
    this.year = year;
    this.billingDetail();
  }

  billingDetail() {
    const searchPayload = this.searchForm.value
    const days = moment(this.searchForm.value.createTo).diff(this.searchForm.value.createFrom, 'days')
    if (days < 0) {
      this.toasts.warning('The Bill Create from date is lesser than Bill Create to date.')
    }
    else {
      this.billingService.billDetails(JSON.stringify(searchPayload), this.config.currentPage, this.config.itemsPerPage, this.month, this.year).subscribe((res: BillRoot) => {
        if (res.success) {
          if(res?.billings.length==0){
            this.toasts.error('No Data Found.')
            this.isBillingAvailable = false;
            this.config.currentPage = 1
            this.config.totalItems = 0
            this.monthBilling = []
          }else{
            this.monthBilling = res?.billings;
            this.isBillingAvailable = true
            this.config.currentPage = res.totalBillings.pages;
            this.config.totalItems = res.totalBillings?.total;

          }
            
        }
      },
        (error) => {
          this.monthBilling = []
          this.toasts.error(error.error.message);
        })
    }
  }


  searchList() {
    this.billingList()
    this.billingDetail()
  }

   pageChanged(event: any) {
    this.config.currentPage = event;
    if (this.currentView === 'main') {
      this.billingList();
    } else {
      this.billingDetail();
    }
  }

  resetSearchForm() {
    this.searchForm.reset()
    this.config.currentPage = 1
    this.searchList()
   
  }
  
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
