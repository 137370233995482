<!-- ---------instance------page----- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <h3 class="font-weight-bold heading"><b>Instance</b></h3>
      </div>
      <!-- --------form-------- -->

      <div class="row mt-2">
        <div class="col-md-3 col-sm-12 position-relative mb-2">
          <i class="bi bi-search"></i>
          <input type="text" class="form-control" id="searchField" [(ngModel)]="searchText"
            [ngModelOptions]="{standalone: true}" placeholder="Search Here" (keyup)="searchInstance($event)">
        </div>

        <div class="col-md-3 col-sm-12 mb-2">
          <select class="custom-select1 " [(ngModel)]="selectedInstanceType" (ngModelChange)="instanceList()">
            <option selected>Select Instance Type</option>
            <option value="trial">Trial Instance</option>
            <option value="live">Live Instance</option>
            <option value="marg">Marg Instance</option>
            <option value="partnerInstance">Partner Instance</option>
          </select>
        </div>
        <div class="col-md-6 col-sm-12 mb-2">
          <div class="row" [formGroup]="searchForm">
            <div class="col-md-7 col-sm-12">
              <div class="input-group mb-2 ">
                <span class="input-group-text" id="basic-addon1">Next Bill From</span>
                <input type="date" class="form-control custom-label registrationFrom" placeholder="From"
                  aria-label="Username" formControlName="expiryFrom" aria-describedby="basic-addon1">
              </div>
            </div>
            <div class="col-md-5 col-sm-12 ">
              <div class="input-group ml-1">
                <span class="input-group-text" id="basic-addon1">to</span>
                <input type="date" class="form-control" placeholder="To" formControlName="expiryTo"
                  aria-label="Username" aria-describedby="basic-addon1">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 text-end">
          <button type="submit" class="comman-btn1 btn-primary me-2" (click)="searchBasedOnExpiryDate()">Search</button>
          <button type="search" class="comman-btn1 btn-secondary text-light" (click)="reset()">Reset</button>
        </div>
      </div>
      <!-- -----end----- -->
      <!-- --------table-section---------- -->
      <div class="row table-section">
        <div class="col-md-12 ">
          <div class="card">
            <div class="card-body">
              <div *ngIf="!isInstanceAvailable" class="text-center">
                <h4>There is no Instance created yet</h4>
              </div>
              <div class=" mb-4 records d-flex align-items-center justify-content-between" *ngIf="isInstanceAvailable"><span>{{updatePaginationText}}</span>
                <button class="btn btn-primary " (click)="changeDestination()">Change Instance</button>
              </div>

              <div class="table-responsive" *ngIf="isInstanceAvailable">
                <table class="table table-striped table-borderless">
                  <thead class="roundedTable thead-header">
                    <tr>
                      <th scope="col">Server Name</th>
                      <th scope="col">Drive Name</th>
                      <th scope="col">Subscription ID</th>
                      <th scope="col">Customer Email</th>
                      <th scope="col">No. Of Users</th>
                      <th scope="col">Stage</th>
                      <th scope="col">Status</th>
                      <th scope="col">Partner Company</th>
                      <th scope="col">Sales Person</th>
                      <th scope="col">Plan Name</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Next Billing Date</th>
                      <th scope="col">Company Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let instance of instances | paginate: config">
                      <td>
                        <span [ngbTooltip]="instance?.server?.description | titlecase" placement="top" >
                          {{instance?.serverName |titlecase}}</span>
                      </td>
                      <td>{{instance?.drive}}</td>
                      <td>{{instance?.subId}}</td>
                      <td>{{instance?.customerEmail}}</td>
                      <td>{{instance?.noOfUsers}}</td>
                      <td [ngClass]="[getInstanceStatusColor(instance)]">
                        {{getInstanceStatus(instance)}}
                      </td>
                      <td [ngClass]="[instance.isActive? 'text-success': 'text-danger']">
                        {{instance.isActive?'Active':'InActive'}}</td>
                      <td>{{instance?.partnerName | titlecase}}</td>
                      <td>{{instance?.subscription?.salesPerson ?.name| titlecase}}</td>
                      <td>{{instance?.planName | titlecase}}</td>
                      <td>{{instance?.subscription?.startDate | date : 'dd-MMM-yyyy'}}</td>
                      <td>{{instance?.subscription?.endDate| date : 'dd-MMM-yyyy'}}</td>
                      <td>{{instance?.companyName | titlecase}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-4 float-end me-4" *ngIf="isInstanceAvailable">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------end----- -->
    </div>
  </div>
</div>