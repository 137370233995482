<!-- ------new--Subscription--page-- -->
<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-12 col-xl-8 d-flex  mb-xl-0 heading-section">
                        <div>
                            <h3 class="font-weight-bold">{{isEdit ? 'Update' : 'New'}} Subscription</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ------form--------- -->
        <form [formGroup]="subscriptionForm">
            <div class="row ">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-4 ">
                                    <label for="addLabel" class="col-form-label">Select Partner<span
                                            class="text-danger">*</span></label>
                                    <div class="dropdown-field ">
                                        <div class="dropdown psd">
                                            <p class="down-btn mt-0 mb-0 form-select text-muted psd form- w-100 d-flex align-items-center"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                {{selectedName? selectedName:'Select Partner'}}
                                            </p>
                                            <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-2 pe-2"
                                                aria-labelledby="dropdownMenuButton">
                                                <div class="search p-2">
                                                    <input type="text" class="form-control  p-3"
                                                        formControlName="searchText" placeholder="Search here">
                                                </div>
                                                <div class="customerList">
                                                    <li *ngFor="let list of partnerList | filter : searchtxt : 'email'"
                                                        (click)="selectEmail(list?.email,list._id,'parners')"
                                                        class="dropdown-item">
                                                        <label class="d-flex list-item">
                                                            {{list?.email}}
                                                        </label>
                                                    </li>
                                                </div>
                                            </ul>

                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-4">
                                    <label for="addLabel" class="col-form-label">Customer Name<span
                                            class="text-danger">*</span></label>

                                    <div class="dropdown-field ">
                                        <div class="dropdown psd">
                                            <p class="down-btn mt-0 mb-0 form-select text-muted psd form- w-100 d-flex align-items-center"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                {{selectCustomer? selectCustomer:'Select customer'}}
                                            </p>
                                            <ul class="dropdown-menu menu-item-1 col-12 pt-0 ps-2 pe-2"
                                                aria-labelledby="dropdownMenuButton">
                                                <div class="search p-2">
                                                    <input type="text" class="form-control  p-3"
                                                        formControlName="searchText1" placeholder="Search here">
                                                </div>
                                                <div class="customerList">
                                                    <li *ngFor="let list of customerLists | filter : searchtxt1:'email'"
                                                        (click)="selectEmail(list?.email,list._id,'customers')"
                                                        class="dropdown-item">
                                                        <label class="d-flex list-item">
                                                            {{list?.email}}
                                                        </label>
                                                    </li>
                                                </div>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <label for="addLabel" class=" col-form-label">Select Product<span
                                            class="text-danger">*</span></label>
                                    <select class="form-select  down-btn text-muted" formControlName="productName"
                                        id="selected-field" (change)="selectedProduct()">
                                        <option selected disabled value="">Select Product</option>
                                        <option [value]="list?.name" *ngFor="let list of productList">{{list?.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div class="mt-3">
                                    <h4>Plan</h4>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Plan Name<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <select class="form-select down-btn text-muted"
                                                        formControlName="planName" id="selected-plan-field"
                                                        (change)="selectedPlan()">
                                                        <option selected disabled value="">Select Plan</option>
                                                        <option [value]="list?.name" *ngFor="let list of planList">
                                                            {{list?.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Quantity<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp" formControlName="planQty"
                                                        pattern="[1-9][0-9]*" required (input)="planAmount()" readonly>

                                                    <div *ngIf="subscriptionForm.controls.planQty.invalid && (subscriptionForm.controls.planQty.dirty || subscriptionForm.controls.planQty.touched)"
                                                        class="error-message">
                                                        <div
                                                            *ngIf="subscriptionForm.controls.planQty.errors?.['required']">
                                                            <p class="text-danger m-0 p-0"><small>It is mandatory
                                                                    field</small></p>
                                                        </div>
                                                        <div
                                                            *ngIf="subscriptionForm.controls.planQty.errors?.['pattern']">
                                                            <p class="text-danger m-0 p-0"><small>Not a valid
                                                                    quantity</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="addLabel"
                                                    class="col-sm-5 col-form-label">Ram(GB)<span
                                                    class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp"
                                                        formControlName="ram"  onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="addLabel"
                                                    class="col-sm-5 col-form-label">Storage(GB)<span
                                                    class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp"
                                                        formControlName="storage"  onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Unit Price<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp" formControlName="planUnitPrice">
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Amount</label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp" formControlName="planAmount"
                                                        readonly>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="addLabel"
                                                    class="col-sm-5 col-form-label">CPU(Core)<span
                                                    class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp"
                                                        formControlName="cpu"  onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Description<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-sm-7 mt-2">
                                                    <textarea type="text" class="form-control description"
                                                        aria-describedby="emailHelp"
                                                        formControlName="planDescription"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div *ngIf="showAddOn">
                                    <h4>Add-on</h4>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Add-on
                                                    Name</label>
                                                <div class="col-sm-7 mt-2">
                                                    <div class="select-section">
                                                        <select class="form-select form-control"
                                                            formControlName="addOnName" id="selected-addOn-field" (change)=" selectAddOn()">
                                                            <option selected disabled value="">Select Add-on</option>
                                                            <option [value]="list?._id" *ngFor="let list of addOnList">
                                                                {{list?.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Quantity</label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp" formControlName="addOnQty"
                                                        pattern="[1-9][0-9]*" required (input)="addOnAmount()">

                                                        <div *ngIf="subscriptionForm.get('addOn.quantity')?.invalid && (subscriptionForm.get('addOn.quantity')?.dirty || subscriptionForm.get('addOn.quantity')?.touched)" class="error-message">
                                                            <div *ngIf="subscriptionForm.get('addOn.quantity')?.errors?.['required']">
                                                                <p class="text-danger m-0 p-0"><small>It is a mandatory field</small></p>
                                                            </div>
                                                            <div *ngIf="subscriptionForm.get('addOn.quantity')?.errors?.['pattern']">
                                                                <p class="text-danger m-0 p-0"><small>Not a valid quantity</small></p>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="addLabel"
                                                    class="col-sm-5 col-form-label">Description</label>
                                                <div class="col-sm-7 mt-2 d-flex">
                                                    <textarea type="text" class="form-control description"
                                                        aria-describedby="emailHelp"
                                                        formControlName="addOnDescription"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Unit Price</label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp" formControlName="addOnUnitPrice">
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="addLabel" class="col-sm-5 col-form-label">Amount</label>
                                                <div class="col-sm-7 mt-2">
                                                    <input type="number" class="form-control"
                                                        aria-describedby="emailHelp" formControlName="addOnAmount">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- ------section--------------- -->
                                <div class="row">
                                    <h4>Subscription Term</h4>
                                    <hr>
                                    <div class="col-md-6">

                                        <div class="row mb-3">
                                            <label for="addLabel" class="col-sm-5 col-form-label">Reference Id</label>
                                            <div class="col-sm-7 mt-2">
                                                <input type="text" class="form-control" aria-describedby="emailHelp"
                                                    formControlName="refrenceId">
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-md-6">
                                        <div class="row mb-3">
                                            <label for="addLabel" class="col-sm-5 col-form-label">Sales Person<span
                                                    class="text-danger">*</span></label>
                                            <div class="col-sm-7 mt-2">
                                                <select class="form-select down-btn text-muted"
                                                    formControlName="salesmanId" required>
                                                    <option selected disabled value="">Select a salesperson</option>
                                                    <option value="self">Self</option>
                                                    <option *ngFor="let salesman of salesPersonLists"
                                                        value="{{salesman._id}}">{{salesman.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="subscriptionForm.controls.salesmanId.invalid && (subscriptionForm.controls.salesmanId.dirty|| subscriptionForm.controls.salesmanId.touched)"
                                                    class="error-message">
                                                    <div
                                                        *ngIf="subscriptionForm.controls.salesmanId.errors?.['required']">
                                                        <p class="text-danger m-0 p-0"><small>It is mandatory
                                                                field</small></p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- ---------end------------ -->
                            <div class="row mb-3">
                                <!-- <label for="number" class="col-sm-3 col-form-label"><a >+Create New Plan</a></label> -->
                                <div class="col-sm-12 mt-2 text-end">
                                    <button type="button" class="comman-btn1 btn-primary me-2"
                                        (click)="submit()">{{isEdit? 'Update':'Create'}}
                                    </button>
                                    <button type="button" class="comman-btn1 btn-secondary text-light"
                                        (click)="goBack()">Cancel</button>
                                </div>
                            </div>
                            <!-- -->
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- -----form----end---------- -->
    </div>
</div>