import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/services/billing.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManualPaymentStatusComponent } from '../modals/manual-payment-status/manual-payment-status.component';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {
  bsModalRef!: BsModalRef;
  paymentDetails: any
  activeSection: string = 'manual';
  onlinePaymentMode: any
  manualPaymentMode: any
  totalCount: number = 0
  page: number = 1
  ispaymentAvailable: boolean = false
  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  searchForm = new FormGroup({
    subId: new FormControl(''),
    email: new FormControl(''),
    userType: new FormControl(''),
    status: new FormControl('pending'),
    transactionId: new FormControl(''),
    orderId: new FormControl(''),
    amount: new FormControl(''),
    invoice: new FormControl('')
  })
  constructor(
    private billingService: BillingService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.paymentListData()
  }

  paymentListData() {
    const searchPayload = this.searchForm.value
    this.billingService.paymentList(JSON.stringify(searchPayload), this.activeSection, this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success) {
        if (res.payments.length === 0) {
          this.toastr.error('No Data Found.')
          this.config.currentPage = 1
          this.config.totalItems = 0
          this.paymentDetails = []
          this.ispaymentAvailable = false;
        } else {
          this.paymentDetails = res?.payments;
          this.ispaymentAvailable = true
          this.config.currentPage = res?.totalPayment?.pages;
          this.config.totalItems = res?.totalPayment?.total;
        }
      }
    },(error)=>{
      this.toastr.error(error.error.message)
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event
    this.paymentListData()
  }

  resetSearchForm() {
    this.config.currentPage = 1;
    this.searchForm.reset()
    this.paymentListData()
  }

  toggleSection(value: string) {
    this.activeSection = value
    this.paymentDetails = []
    this.config.currentPage = 1; 
    if (value == 'online') {
      this.searchForm.controls.status.setValue('')
    } else {
      this.searchForm.controls.status.setValue('pending')
    }
    this.paymentListData()
  }

  updateStatus(paymentId: string, orderId: string) {
    this.bsModalRef = this.modalService.show(ManualPaymentStatusComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.orderId = orderId
    this.bsModalRef.content.paymentId = paymentId
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.paymentListData();
      }
    })
  }

  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
