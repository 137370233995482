import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { createAddOn } from 'src/app/interfaces/addOn';
import { PlanRoot } from 'src/app/interfaces/plan';
import { UnitRoot } from 'src/app/interfaces/unit';
import { AddOnService } from 'src/app/services/add-on.service';
import { PlanService } from 'src/app/services/plan.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-add-addon',
  templateUrl: './add-addon.component.html',
  styleUrls: ['./add-addon.component.scss']
})
export class AddAddonComponent implements OnInit {

  productId: string = ''
  plans: any = [];
  units: any = [];
  addOnId: string = ''
  isEdit: boolean = false;

  addOnForm = new FormGroup({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    description: new FormControl(''),
    code: new FormControl('', Validators.required),
    unit: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    type: new FormControl('oneTime', Validators.required),
    pricingInterval: new FormControl('', Validators.required),
    pricingEvery: new FormControl('',  [Validators.required, Validators.pattern('^[1-9][0-9]*$')]),
  })

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private planService: PlanService,
    private addOnService: AddOnService,
    private productService: ProductService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
    this.planList()
    this.unitList()
    this.activateRoute.queryParams.subscribe(data => {
      this.productId = data['productId'];
    })
    this.activateRoute.queryParams.subscribe(data => {
      this.addOnId = data['addOnId'];
    })
    if (this.productId && this.addOnId) {
      this.isEdit = true;
      this.addOnDetails()
    }
  }

  addOns() {
    if (!this.addOnForm.valid) {
      this.addOnForm.markAllAsTouched();
    }
    else {
      this.submit();
    }
  }

  submit() {
    if (this.isEdit) {
      this.updateAddOn()
    }
    else {
      this.createAddOn();
    }
  }

  navigateBackToAddProduct() {
    this.router.navigate(['product/add-product'], { queryParams: { productId: this.productId } })
  }

  unitList() {
    this.productService.unitList().subscribe((res: UnitRoot) => {
      if (res.success) {
        this.units = res.units;
      }
    })
  }

  planList() {
    this.planService.planList(this.productId).subscribe((res: PlanRoot) => {
      if (res.success) {
        this.plans = res.plans;
      }
    })
  }

  createAddOn() {
    let addOnPayload = {}
    addOnPayload = Object.assign(this.addOnForm.value, { productId: this.productId })
    this.addOnService.createAddOn(addOnPayload).subscribe((res: createAddOn) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.navigateBackToAddProduct();
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  addOnDetails() {
    this.addOnService.addOnDetail(this.productId, this.addOnId).subscribe((res: any) => {
      if (res.success) {
        this.addOnForm.patchValue(res.addOn);
      }
    })
  }

  updateAddOn() {
    let updatePayload = {}
    updatePayload = Object.assign(this.addOnForm.value, { productId: this.productId, addOnId: this.addOnId })
    this.addOnService.editAddOn(updatePayload).subscribe((res: createAddOn) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.navigateBackToAddProduct();
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

}
