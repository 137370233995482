import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProductRoot } from 'src/app/interfaces/product';
import { ProductService } from 'src/app/services/product.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { NewProductComponent } from '../modals/new-product/new-product.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  products: any = [];
  bsModalRef!: BsModalRef;
  isProductAvailable: boolean = false

  constructor(
    private productService: ProductService,
    private router: Router,
    private modalService: BsModalService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
    this.productList();
  }

  productList() {
    this.productService.productList().subscribe((res: ProductRoot) => {
      if (res.success) {
        this.products = res.products;
        this.isProductAvailable = true
      }
    },
      (error) => {
        this.products = [];
        this.toasts.error(error.error.message);
      })
  }

  editProduct(productId: string) {
    this.router.navigate(['product/add-product'], { queryParams: { productId: productId } });
  }

  addProductModal() {
    this.bsModalRef = this.modalService.show(NewProductComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.productList();
      }
      else {
        this.productList();
      }
    })
  }

  deleteModal(productId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.productId = productId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.productList();
      }
      else {
        this.productList();
      }
    })
  }

}
