<div class="common-modal">
    <div class="modal-header border-0 py-0">
        <p class="modal-title pull-left border-0 title">Update Status({{orderId}})</p>
    </div>
    <div>
        <div class="modal-body border-0 py-0">
            <form [formGroup]="updateStatusForm">

                <div class="col-sm-12">
                    <p class="" id="basic-addon3">Status</p>
                    <select class="form-select form-control" formControlName="status">
                        <option value='' selected disabled>Select status</option>
                        <option value="Successful">Successful</option>
                        <option value="On Hold">On Hold</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                    <div *ngIf="updateStatusForm.get('status')?.invalid" class="alert mb-0 p-0">
                        <div
                            *ngIf="updateStatusForm.get('status')?.errors?.['required'] && (updateStatusForm.get('status')?.dirty || updateStatusForm.get('status')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm 12 mt-3">
                    <p>Received Amount</p>
                    <input type="text" class="form-control" formControlName="recievedAmount" placeholder="Enter received amount" />
                    <div *ngIf="updateStatusForm.get('recievedAmount')?.invalid" class="alert mb-0 p-0">
                        <div
                            *ngIf="updateStatusForm.get('recievedAmount')?.errors?.['required'] && (updateStatusForm.get('recievedAmount')?.dirty || updateStatusForm.get('recievedAmount')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                            </p>
                        </div>
                        <div *ngIf="updateStatusForm.get('recievedAmount')?.errors?.['pattern'] ">
                            <p class="text-danger m-0 p-0"><small>Enter valid amount</small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm 12 mt-3">
                    <p>Remark</p>
                    <input type="text" class="form-control" formControlName="remark" placeholder="Remark..."/>
                    <div *ngIf="updateStatusForm.get('remark')?.invalid" class="alert mb-0 p-0">
                        <div
                            *ngIf="updateStatusForm.get('remark')?.errors?.['required'] && (updateStatusForm.get('remark')?.dirty || updateStatusForm.get('remark')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <div class="float-end border-0 p-3 custome-btn">
        <button type="button" class="me-3 no-btn" (click)="closeModal()">Cancel</button>
        <button type="button" class="text-light yes-btn" (click)="submit()">Submit</button>
    </div>
</div>