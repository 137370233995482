import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TechnicalDashboardService {
  constructor(private http: HttpClient) { }

  getInstanceReport(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/instanceStatsReport`)
  }

  getInstanceDetails(page: number, limit: number, payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/admin/technicalDashboard/cloudServerDetails?page=${page}&limit=${limit}`, payload)
  }

  getServerSummaryReport(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/serverSummaryReport`)
  }

  getServerDetail(cloudId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/serverDetails?cloudId=${cloudId}`)
  }

  getLoginTrendGraphData(cloudId: any, type: any): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/loginTrendGraph?cloudId=${cloudId}&type=${type}`)
  }

}


