import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {RootAdminDetail } from '../interfaces/adminDetails';
import { WalletRoot } from '../interfaces/wallet';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  wallet: any = 0;
  logo: any = '';
  name:any ='';

  walletSubject = new BehaviorSubject(this.adminProfileDetails());
  logoSubject = new BehaviorSubject(this.adminProfileDetails());
  nameSubject = new BehaviorSubject(this.adminProfileDetails());
  
  constructor(
    private http: HttpClient
  ) { }


  walletAmount(payload: any) {
    return this.http.post<WalletRoot>(`${environment.apiURL}/admin/wallet`, payload)
  }

  profileDetails() {
    return this.http.get<RootAdminDetail>(`${environment.apiURL}/admin/user/details`);
  }

  adminProfileDetails() {
    this.profileDetails().subscribe((res: RootAdminDetail) => {
      if (res.success) {
        this.wallet = res.admin.wallet;
        this.name=res.admin.name;
        this.logo = res.admin.logo;
        this.walletSubject.next(this.wallet);
        this.logoSubject.next(this.logo);
        this.nameSubject.next(this.name)
      }
    })
  }
  
}
