<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="col-md-12 ">
                <div class="row ">
                    <div class="col-8">
                        <h3 class="font-weight-bold heading"><b>Banner</b></h3>
                    </div>
                    <div class="col-4 text-end mb-2">
                        <button type="button" class="comman-btn1 btn-primary" (click)="createUpdateBanner('')">+ Banner</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="font-weight-bold sub-heading">Banner List</h4>
                        </div>
                        <div>
                            <h4 class="font-weight-bold sub-heading text-center" *ngIf="bannerList.length==0">No data
                                found!</h4>
                        </div>
                        <div class="table-responsive" *ngIf="bannerList.length>0">
                            <table class="table table-bordered fixed-columns-table">
                                <thead class="thead-header">
                                    <tr>
                                        <th scope="col">Heading</th>
                                        <th scope="col">Subheading</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Allow</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of bannerList | paginate:config let i=index;">
                                        <td>{{list.heading}}</td>
                                        <td>{{list.subHeading}}</td>
                                        <td>{{list.description}}</td>
                                        <td>{{list.showCustomer ? 
                                            (list.showPartner ? 'All' : 'Customer') : 
                                            (list.showPartner ? 'Partner' : 'None')}}</td>
                                        <td>{{list.isActive ? 'Active' :'Inactive'}}</td>
                                        <td>
                                            <i class="bi bi-pencil-fill text-primary me-2"
                                                (click)="createUpdateBanner(list?._id)"></i>
                                            <i class="bi bi-trash3-fill text-danger"
                                                (click)="deleteBanner(list?._id)"></i>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-4 float-end me-4" *ngIf="bannerList.length>0">
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>