import { Component, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { VMLogRoot } from 'src/app/interfaces/vmLogs';
import { AuthService } from 'src/app/services/auth.service';
import { InstanceService } from 'src/app/services/instance.service';

@Component({
  selector: 'app-virtual-machine',
  templateUrl: './virtual-machine.component.html',
  styleUrls: ['./virtual-machine.component.scss']
})
export class VirtualMachineComponent implements OnInit {

  searchText: string = ''
  search$ = new Subject<void>()
  searchSubscription$: Subscription
  virtualMachines: any = []
  totalCount: number = 0
  page: number = 1
  isVMAvailable: boolean = false

  config = {
    itemsPerPage: 10,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  constructor(
    private instanceService: InstanceService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.virtualMachineLog()
    this.searchSubscription$ = this.search$.pipe(debounceTime(600)).subscribe(() => {
      this.virtualMachineLog()
    })
  }

  searchVMLogs(event: any) {
    this.searchText = event.target.value
    this.config.currentPage = 1;
    this.search$.next();
  }

  virtualMachineLog() {
    this.instanceService.virtualMachine(this.config.currentPage, this.config.itemsPerPage, this.searchText).subscribe((res: any) => {
      if (res.success) {
        this.isVMAvailable = true
        this.config.currentPage = res.total.pages;
        this.config.totalItems = res.total.total;
        this.virtualMachines = res.virtualMachineLogs
      }
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.virtualMachineLog()
  }

  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  } 
}
