<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="row">
              <div class="col-6">
                <h3 class="font-weight-bold heading"><b>Tally Release</b></h3>
              </div>
              <div class="col-6 text-end">
                <button type="button" class="comman-btn1 btn-primary me-2" (click)="addTallyReleaseModal('')">+ Tally Release</button>
              </div>
            </div>
          </div>
          <!-- ---------table-section--------- -->
          <div class="row table-section pe-0">
            <div class="col-md-12 grid-margin stretch-card pe-0">
              <div class="card">
                <div class="card-body">
                  
                  <div *ngIf="!isTallyAvailable" class="text-center">
                    <h4>There is no Tally Release added yet. To add Tally Release click</h4>
                    <button type="button" class="comman-btn1 btn-primary me-2" (click)="addTallyReleaseModal('')">+ Tally Release</button>
                  </div>
                  <div class="table-responsive" *ngIf="isTallyAvailable">
                    <table class="table table-striped table-borderless">
                      <thead class="roundedTable thead-header">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Version Name</th>
                          <th scope="col">Download Link</th>
                          <th scope="col">Update Link</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let tallyRelease of tallyReleases let i=index">

                          <td >{{i+1}}</td>
                          <td >{{tallyRelease?.versionName | titlecase}}</td>
                          <td class="textWrap">{{tallyRelease?.link}}</td>
                          <td class="textWrap">{{tallyRelease?.updateLink}}</td>
                          
                          <td>
                            <i class="bi bi-pencil-fill text-primary productNameCursor me-3"
                              (click)="addTallyReleaseModal(tallyRelease._id)"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -----end-------- -->
        </div>
      </div>
    </div>
