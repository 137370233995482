import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {AdminRoot, deleteAdmin, ManageAdminRoot } from '../interfaces/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient
  ) { }

  createAdmin(adminPayload: any) {
    return this.http.post<ManageAdminRoot>(`${environment.apiURL}/admin/user`, adminPayload)
  }

  adminList() {
    return this.http.get<AdminRoot>(`${environment.apiURL}/admin/user`);
  }
  
  accountManagerList() {
    return this.http.get<AdminRoot>(`${environment.apiURL}/admin/accountmanager`);
    
  }

  adminDetails(adminId: string) {
    return this.http.get<ManageAdminRoot>(`${environment.apiURL}/admin/user/details?adminId=${adminId}`)
  }

  updateAdmin(updatePayload: any) {
    return this.http.put<ManageAdminRoot>(`${environment.apiURL}/admin/user`, updatePayload)
  }

  delete(adminId: string) {
    return this.http.delete<deleteAdmin>(`${environment.apiURL}/admin/user?adminId=${adminId}`)
  }
}
