<!-- ---------------Audit Logs---------------- -->
<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12 mb-2">
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="font-weight-bold heading"><b> Coupons & Schemes</b></h3>
                        </div>
                        <div class="col-md-6 text-end">
                            <button type="button" class="comman-btn1 btn-primary me-2" (click)="routeToAdd()">
                                + {{activeSection=='coupon'?'Coupon':'Scheme'}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ------------------ -->
                <div class="row table-section pe-0">
                    <div class="col-md-12 grid-margin stretch-card pe-0">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mt-4">
                                    <div class="mb-3">
                                        <ul class="d-flex service ps-0 mb-0">
                                            <li (click)="toggleSection('coupon')"
                                                [ngClass]="(activeSection==='coupon') ? 'activate': ''">
                                                <a>Coupon</a>
                                            </li>
                                            <li (click)="toggleSection('scheme')"
                                                [ngClass]="(activeSection==='scheme') ? 'activate': ''">
                                                <a>Scheme</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="activeSection == 'coupon'">
                                    <div class=" mb-4 records">{{updatePaginationText}}</div>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead class="roundedTable thead-header">
                                                <tr>
                                                    <th scope="col">Coupon Name</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Discount</th>
                                                    <th scope="col">Expire Date</th>
                                                    <th scope="col">Limit</th>
                                                    <th scope="col">Total Redemption</th>
                                                    <th scope="col" class="text-center">Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let coupon of coupons | paginate : config">
                                                    <td>
                                                        {{coupon?.name}}
                                                    </td>
                                                    <td>{{coupon?.couponType | titlecase}}</td>
                                                    <td>{{coupon?.couponType
                                                        =='percentage'?coupon?.value+'%':coupon?.couponType ==
                                                        'months'?(coupon?.value>1?coupon?.value+'(months)':coupon?.value+'(month)'):(coupon?.value
                                                        |currency:'INR')}}</td>

                                                    <td>{{coupon?.expirationDate ? (coupon?.expirationDate |
                                                        date:"dd/MM/yyyy") : 'N/A'}}</td>
                                                    <td>{{coupon?.limit ? coupon?.limit : 'N/A'}}</td>
                                                    <td>{{coupon?.totalRedemption}}</td>
                                                    <td class="text-center">
                                                        <i class="bi bi-pencil-fill text-primary productNameCursor me-3 fs-6 "
                                                            (click)="updateCoupon(coupon?._id)"></i>
                                                        <i class="bi bi-trash text-danger productNameCursor fs-6"
                                                            (click)="deleteCoupon(coupon?._id)"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 float-end me-4">
                                        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                                    </div>
                                </div>
                                <div *ngIf="activeSection =='scheme'">
                                    <div class=" mb-4 records">{{updatePaginationText}}</div>
                                    <div class="table-responsive">
                                        <table class="table ">
                                            <thead class="roundedTable thead-header">
                                                <tr>
                                                    <th scope="col">Scheme Name</th>
                                                    <th scope="col">From Date</th>
                                                    <th scope="col">To Date</th>
                                                    <th scope="col">Occurance Type</th>
                                                    <th scope="col" class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let scheme of schemes | paginate : config">
                                                    <tr>

                                                        <td><a [routerLink]="['/scheme-details']" class="scheme"
                                                            [queryParams]="{ schemeId: scheme?._id, type : activeSection, occuranceType:scheme?.occurrence}">{{scheme?.name}}</a>
                                                            
                                                        </td>

                                                        <td>{{scheme?.fromDate | date : "MMM d, y"}}</td>
                                                        <td>{{scheme?.toDate ?(scheme?.toDate | date : "MMM d, y") :'N/A'}}</td>
                                                        <td>{{scheme?.occurrence | titlecase}}</td>
                                                        <td class="text-center">
                                                            <i class="bi bi-pencil-fill text-primary productNameCursor me-3 fs-6 "
                                                                (click)="updateScheme(scheme?._id)"></i>
                                                            <i class="bi bi-trash text-danger productNameCursor fs-6"
                                                                (click)="deleteScheme(scheme?._id)"></i>
                                                        </td>
                                                    </tr>

                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 float-end me-4">
                                        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- ----------- -->
            </div>
        </div>
    </div>