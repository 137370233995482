<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="row">
            <div class="col-6">
              <h3 class="font-weight-bold heading"><b>Account Manager</b></h3>
            </div>

            <div class="col-6 text-end">
              <button type="button" class="comman-btn1 btn-primary" [routerLink]="['/account-manager/add-admin']">+
                Admin</button>
            </div>
          </div>
        </div>
        <!-- ------------------ -->
        <div class="row table-section pe-0">
          <div class="col-md-12 grid-margin stretch-card pe-0">
            <div class="card">
              <div class="card-body">

                <div *ngIf="!isAdminAvailable" class="text-center">
                  <h4>There is no Products added yet. To add Products click</h4>
                  <button type="button" class="comman-btn1 btn-primary" [routerLink]="['/account-manager/add-admin']">+
                    Admin</button>
                </div>
                <div class="table-responsive" *ngIf="isAdminAvailable">
                  <table class="table table-striped table-borderless">
                    <thead class="roundedTable thead-header">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status </th>
                        <th scope="col" class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let admin of admins">
                        <td class="mouseHover" (click)="navigateToAddAdmin(admin._id)">{{admin.name | titlecase}}</td>
                        <td>{{admin.email}}</td>
                        <td>{{admin.mobile}}</td>
                        <td>{{admin?.isSuperAdmin?'SuperAdmin':admin?.role |titlecase}}</td>
                        <td [ngClass]="admin.isActive? 'text-success': 'text-danger'">{{admin.isActive? 'Active':
                          'InActive'}}</td>
                        <td class="dropdown text-center">
                          <button class="comman-btn1 btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-mdb-toggle="dropdown" aria-expanded="false">
                            Action
                          </button>
                          <ul class="dropdown-menu menu-item-1" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item"
                                (click)="toggleStatus(admin?.isActive, admin?._id)">{{admin?.isActive?
                                'Suspend': 'Active'}}</a></li>
                            <li><a class="dropdown-item" (click)="navigateToAddAdmin(admin._id)">Edit User</a></li>
                            <li><a class="dropdown-item" (click)="deleteModal(admin._id)">Delete User</a></li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ----------- -->
      </div>
    </div>
  </div>