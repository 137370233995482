<!-- -------Instance Logs--------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="row">
            <div class="col-12 col-xl-8 mb-4 mb-xl-0">
              <h3 class="font-weight-bold heading"><b>Instance Logs</b></h3>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <div class="search-form">
            <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              <i class="bi bi-search"></i>
              <span class="ms-5">Search here</span>
            </p>

            <div class="collapse" id="collapseExample">
              <form  [formGroup]="searchForm">
                <div class="row mt-2">
                  <div class="col-md-4">
                    <mat-form-field appearance="fill" class="example-form-field w-100">
                      <mat-label>Date</mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="From" formControlName="startDate">
                        <input matEndDate placeholder="To" formControlName="endDate" [max]="minDate">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker>
                    </mat-date-range-picker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="fill" class="example-form-field w-100">
                      <mat-label>User Name</mat-label>
                      <input type="text" matInput formControlName="userName" class="form-input-field" placeholder="Enter username"/>
                    </mat-form-field>
                  </div>

                  <div class="col-md-4">
                    <mat-form-field appearance="fill" class="example-form-field w-100">
                      <mat-label>Email</mat-label>
                      <input type="email" matInput formControlName="email" class="form-input-field" placeholder="Enter email"/>
                    </mat-form-field>
                  </div>
  
                  <div class="col-md-4">
                    <mat-form-field appearance="fill" class="example-form-field w-100">
                      <mat-label>Sub Id</mat-label>
                      <input type="text" matInput formControlName="subId" class="form-input-field"/>
                    </mat-form-field>
                  </div>

                  <div class="col-md-4">
                    <mat-form-field appearance="fill" class="example-form-field w-100">
                      <mat-label>Action Type</mat-label>
                      <mat-select formControlName="actionType">
                        <mat-option value="" selected>All</mat-option>
                        <mat-option value="login">Login</mat-option>
                        <mat-option value="logout">Logout</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-12 text-end">
                  <button type="submit" class="comman-btn1 btn-primary me-2"
                    (click)=" instanceLogList()">Search</button>
                  <button type="search" class="comman-btn1 btn-secondary text-light"
                    (click)="resetSearchForm()">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- ----------table-section---------- -->
      <div class="row table-section">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class=" mb-4 records">{{updatePaginationText}}</div>
              <div *ngIf="!isInstanceLogAvailable" class="text-center">
                <h4>There is no Instance Logs created yet</h4>
              </div>
              <div class="table-responsive" *ngIf="isInstanceLogAvailable">
                <table class="table table-striped table-borderless">
                  <thead class="roundedTable thead-header">
                    <tr>
                      <th scope="col">#Id</th>
                      <th scope="col">Action Type</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">HostName</th>
                      <th scope="col">UserName</th>
                      <th scope="col">Email</th>
                      <th scope="col">SubId</th>
                      <th scope="col">SessionType</th>
                      <th scope="col">SessionId</th>
                      <th scope="col">SessionClientName</th>
                      <th scope="col">SessionIPAddress</th>
                      <th scope="col">State</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let instance of instance | paginate : config">
                      <td>{{instance?.id}}</td>
                      <td [ngClass]="(instance?.actionType==='login' )? 'text-success': 'text-danger'">
                        {{instance?.actionType | titlecase}}</td>
                      <td>{{(instance?.loginDate)? (instance?.loginDate+" "+instance?.loginTime):
                        (instance?.logoutDate+" "+instance?.logoutTime)}}</td>
                      <td class='text-success'>{{instance?.status | titlecase}}</td>
                      <td>{{instance?.hostName | titlecase}}</td>
                      <td>{{instance?.userName}}</td>
                      <td>{{instance?.email}}</td>
                      <td>{{instance?.subId}}</td>
                      <td>{{instance?.sessionType}}</td>
                      <td>{{instance?.sessionId}}</td>
                      <td>{{instance?.sessionClientName}}</td>
                      <td>{{instance?.sessionIPAddress}}</td>
                      <td [ngClass]="(instance?.state==='Active' )? 'text-success': 'text-danger'">
                        {{instance?.state}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-4 float-end me-4" *ngIf="isInstanceLogAvailable">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ----end------- -->
    </div>
  </div>
</div>