import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateProductRoot, ProductRoot, UpdateAndDeleteProduct } from '../interfaces/product';
import { UnitRoot } from '../interfaces/unit'; 

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient
  ) { }

  createProduct(payload: any) {
    return this.http.post<CreateProductRoot>(`${environment.apiURL}/admin/product`, payload)
  }

  productList() {
    return this.http.get<ProductRoot>(`${environment.apiURL}/admin/product`)
  }

  productDetail(productId: string) {
    return this.http.get<CreateProductRoot>(`${environment.apiURL}/admin/product/details?productId=${productId}`)
  }

  editProduct(payload: any) {
    return this.http.put<UpdateAndDeleteProduct>(`${environment.apiURL}/admin/product`, payload)
  }

  delete(productId: string) {
    return this.http.delete<UpdateAndDeleteProduct>(`${environment.apiURL}/admin/product?productId=${productId}`)
  }

  unitList() {
    return this.http.get<UnitRoot>(`${environment.apiURL}/admin/unit`)
  }

  SelectProduct(partnerId: string) {
    return this.http.get<ProductRoot>(`${environment.apiURL}/admin/partner/product?partnerId=${partnerId}`)
  }
  customerList(partnerId:any){
    return this.http.get(`${environment.apiURL}/admin/sub/customers?partnerId=${partnerId}`)
  }
  salesPersonLists(partnerId:any){
    return this.http.get(`${environment.apiURL}/admin/sub/salesPersons?partnerId=${partnerId}`)
  }

  addNewSubscriptionCreate(payload:any){
    return this.http.post(`${environment.apiURL}/admin/sub/create `, payload)
  }
  getDetailsSubscription(subId:any){
    return this.http.get(`${environment.apiURL}/admin/sub/subDetails?code=${subId}`)
  }
  updateSubscription(payload:any){
    return this.http.post(`${environment.apiURL}/admin/sub/update`, payload)
  }
}
