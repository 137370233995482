import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  sharedData: any;

  constructor(
    private http: HttpClient
  ) { }

  subscriptionRenew(search: string) {
    const searchPayload = {
      search: search
    }
    return this.http.post(`${environment.apiURL}/admin/dashboard/renewal`, searchPayload)
  }

  subscriptionReport(search: string) {
    const searchPayload = {
      search: search
    }
    return this.http.post(`${environment.apiURL}/admin/dashboard/paid`, searchPayload)
  }

  subscriptionRisky(search: string) {
    const searchPayload = {
      search: search
    }
    return this.http.post(`${environment.apiURL}/admin/dashboard/risky`, searchPayload)
  }

  trialReport(search: string) {
    const searchPayload = {
      search: search
    }
    return this.http.post(`${environment.apiURL}/admin/dashboard/trial`, searchPayload)
  }

  liveSubscriptionsSummary(search: string) {
    const searchPayload = {
      search: search
    }
    return this.http.post(`${environment.apiURL}/admin/dashboard/sub/trend`, searchPayload)
  }

  setData(data: any): void {
    this.sharedData = data;
  }

  getData(): any {
    return this.sharedData;
  }
  releaseData(): any {
    this.sharedData = []
  }
  getDashboardEmbedUrl() {
    return this.http.get(`${environment.apiURL}/admin/dashboard/embed`)
  }
}
