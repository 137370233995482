import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(
    private http: HttpClient
  ) { }

  createBanner(payload:any){
    return this.http.post(`${environment.apiURL}/admin/banner`, payload)
  }

  updateBanner(payload:any) {
    return this.http.put(`${environment.apiURL}/admin/banner`, payload)
  }

  getListOfBanner(page: number, limit: number,){
    return this.http.get(`${environment.apiURL}/admin/banner?page=${page}&limit=${limit}`)
  }

  getDetailsBanner(bannerId:any){
    return this.http.get(`${environment.apiURL}/admin/banner/details?id=${bannerId}`)
  }
  deleteBanner(bannerId:any){
    return this.http.delete(`${environment.apiURL}/admin/banner?id=${bannerId}`)
  }
}
