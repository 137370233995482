import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DiscountService } from 'src/app/services/discount.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-discount-list',
  templateUrl: './discount-list.component.html',
  styleUrls: ['./discount-list.component.scss']
})
export class DiscountListComponent implements OnInit {
  activeSection: string = 'coupon';
  schemes: any
  coupons: any
  totalCount: number = 0
  page: number = 1
  bsModalRef!: BsModalRef;
  config = {
    itemsPerPage: 15,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  constructor(
    private discountService: DiscountService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.activeSection = params['type'] || 'coupon';
      if(this.activeSection == 'coupon'){
        this.couponList()
      }else{
        this.schemeList()
      }
    });
  }

  schemeList() {
    this.discountService.schemeList(this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success) {
        if (res.schemes.length == 0) {
          this.toastr.error('No data found!')
          this.config.currentPage = 1;
          this.config.totalItems = 0;
          this.schemes = []
        } else {
          this.schemes = res.schemes
          this.config.currentPage = res?.totalSchemes?.pages;
          this.config.totalItems = res?.totalSchemes?.total;
        }
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  couponList() {
    this.discountService.couponList(this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success) {
        if (res.coupons.length == 0) {
          this.toastr.error('No coupons found');
          this.config.currentPage = 1;
          this.config.totalItems = 0;
          this.coupons = []
        }
        this.coupons = res.coupons
        this.config.currentPage = res?.totalCoupons?.pages;
        this.config.totalItems = res?.totalCoupons?.total;
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  routeToAdd() {
    if (this.activeSection == 'coupon') {
      this.router.navigate(['coupon'], { queryParams: {type: this.activeSection } });
    } else {
      this.router.navigate(['scheme/add'], { queryParams: {type: this.activeSection } });
    }
  }

  updateScheme(Id: string) {
    this.router.navigate(['scheme/add'], { queryParams: { schemeId: Id, type: this.activeSection } });
  }

  updateCoupon(couponId: any) {
    this.router.navigate(['coupon'], { queryParams: { couponId: couponId, type: this.activeSection } });
  }

  deleteCoupon(couponId: any) {
    this.discountService.deleteCoupon(couponId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message);
        this.couponList();
      }
    }, (err) => {
      this.toastr.error(err.error.message);
      this.couponList();
    })
  }

  deleteScheme(id: any) {
    this.discountService.deleteScheme(id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message);
        this.schemeList();
      }
    }, (err) => {
      this.toastr.error(err.error.message);
      this.schemeList();
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event
    if (this.activeSection == 'coupon') {
      this.couponList()
    } else if (this.activeSection == 'scheme') {
      this.schemeList()
    }
  }

  toggleSection(value: string) {
    this.activeSection = value
    this.config.currentPage = 1;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { type: value },
      queryParamsHandling: 'merge'
    });
  }
  
  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
  }
}
