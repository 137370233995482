import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-inactive-sub-report',
  templateUrl: './inactive-sub-report.component.html',
  styleUrls: ['./inactive-sub-report.component.scss']
})
export class InactiveSubReportComponent implements OnInit {

  totalCount: number = 0
  page: number = 1
  fileName = 'InActiveUsersLogList.xlsx';
  inActiveLogs: any[] = []
  minDate !: any
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  searchForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  })

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    this.getListOfInActiveSubUser()
  }

  getListOfInActiveSubUser() {
    let payload = this.searchForm.value
    this.subscriptionService.getListOfInActiveSubUser(JSON.stringify(payload), this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success && res.inActiveLogs.length > 0) {
        this.inActiveLogs = res?.inActiveLogs
        this.config.currentPage = res.totalInActiveLogs.pages;
        this.config.totalItems = res.totalInActiveLogs.total;
      } else {
        this.inActiveLogs = []
        this.config.currentPage = 1;
        this.config.totalItems = 0;
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.getListOfInActiveSubUser()
  }

  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems);
  }

  excelInActiveUserLogsData() {
    let payload = this.searchForm.value
    this.subscriptionService.exportToExcelInActiveUserLog(JSON.stringify(payload)).subscribe((res: any) => {
      if (res.success) {
        this.exportexcel(res.inActiveLogs)
      }
    })
  }

  exportexcel(schedulerLogs: any): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(schedulerLogs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }
}
