<!-- ---------change--plan--price--modal------------------ -->
<div class="px-4">
    <h4 class="modal-title pull-left mb-4"><b>Plan Pricing</b></h4>
    <div>
        <form action="" [formGroup]="changePlanPriceForm">
            <div class="row mb-2">
                <label for="fullName" class="col-sm-4 col-form-label">Select Product</label>
                <div class="col-sm-8 mt-2">
                    <select class="form-select form-control mt-2" aria-label="Default select example" id="productId"
                        (change)="planList()">
                        <option value="" selected>Open this select menu</option>
                        <option *ngFor="let product of products" [value]="product._id">
                            {{product.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-2">
                <label for="fullName" class="col-sm-4 col-form-label">Select Plan</label>
                <div class="col-sm-8 mt-2">
                    <select class="form-select form-control mt-2" aria-label="Default select example" id="planId"
                        (change)="getPlanPrice()">
                        <option value="" selected>Open this select menu</option>
                        <option *ngFor="let plan of plans" [value]="plan._id">{{plan.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <label for="fullName" class="col-sm-4 col-form-label">Price</label>
                <div class="col-sm-8 mt-2">
                    <input type="number" placeholder="Enter Price" class="form-control" formControlName="price" min="0"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                    <div *ngIf="changePlanPriceForm.get('price')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="changePlanPriceForm.get('price')?.errors?.['required'] && (changePlanPriceForm.get('price')?.dirty || changePlanPriceForm.get('price')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Price is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="fullName" class="col-sm-4 col-form-label">Remark</label>
                <div class="col-sm-8 mt-2">
                    <input type="text" placeholder="Enter Remark" class="form-control" formControlName="remark">
                    <div *ngIf="changePlanPriceForm.get('remark')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="changePlanPriceForm.get('remark')?.errors?.['required'] && (changePlanPriceForm.get('remark')?.dirty || changePlanPriceForm.get('remark')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Remark is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-1">
                <div class=" col-sm-12 mt-1  mb-2">
                    <input class="" type="checkbox" id="flexSwitchCheckDefault"
                        formControlName="isChangePreviousSubPrice">
                    <label class="form-check-label ms-3" for="flexSwitchCheckDefault">Change price for all previous
                        Subscription.</label>
                </div>
            </div>
        </form>
    </div>
    <div class="text-end">
        <button type="submit" class="comman-btn1 btn-primary me-2" [disabled]="!changePlanPriceForm.valid"
            (click)="changePlanPrice()">Change</button>
        <button type="button" class="comman-btn1 btn-secondary text-light" (click)="closeModal()">Cancel</button>
    </div>
</div>