import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleDriveService } from 'src/app/services/google-drive.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-google-drive',
  templateUrl: './google-drive.component.html',
  styleUrls: ['./google-drive.component.scss']
})
export class GoogleDriveComponent implements OnInit {

  searchText: string = ''
  schedulerLogs: any[] = []
  fileName = 'GoogleDriveLogList.xlsx';
  totalCount: number = 0
  page: number = 1
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  googleDriveSlabs = [
    { name: 'Auth Attempt', label: 'authAttempt', count: 0, icon: 'bi bi-dropbox', searchText: '' },
    { name: 'Auth Completed', label: 'authCompleted', count: 0, icon: 'bi bi-bag-check-fill', searchText: '' },
    { name: 'Schedular Add', label: 'noOfScheduler', count: 0, icon: 'bi bi-patch-plus-fill', searchText: '' },
    { name: 'Success Schedular Log ', label: 'noOfSuccessLog', count: 0, icon: 'bi bi-check-square-fill', searchText: 'success' },
    { name: 'Failed Schedular Log ', label: 'noOfFailedLog', count: 0, icon: 'bi bi-bag-x-fill', searchText: 'failed' },
    { name: 'Delete Schedular Log ', label: 'noOfDeletedLog', count: 0, icon: 'bi bi-trash3-fill', searchText: 'deleted' },
  ]
  searchForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  })
  constructor(
    private authService: AuthService,
    private googleDriveService: GoogleDriveService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    this.googleDriveReport()
  }

  get pagination() {
    const paginationData = this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
    return paginationData
  }

  googleDriveReport() {
    const payload = {
      startDate : this.searchForm.controls.startDate.value,
      endDate : this.searchForm.controls.endDate.value,
      status : this.searchText
    }
  
    this.googleDriveService.googleDriveReport(this.config.currentPage, this.config.itemsPerPage, JSON.stringify(payload)).subscribe((res: any) => {
      if (res.success) {
        this.schedulerLogs = res.schedulerLogs
        this.config.currentPage = res?.totalSchedulerLogs?.pages || 1;
        this.config.totalItems = res?.totalSchedulerLogs?.total || 0;
        this.googleDriveSlabs.map((obj: any) => {
          obj.count = res[obj.label]
        })
      }
    }, (err) => {
      this.schedulerLogs = []
      this.config.currentPage = 1
      this.config.totalItems = 0
      this.toastrService.error(err.error.message)
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.googleDriveReport()
  }

  filterData(search: string) {
    this.config.currentPage = 1
    this.searchText = search
    this.googleDriveReport()
  }

  excelGoogleLogsData() {
    const payload = {
      startDate : this.searchForm.controls.startDate.value,
      endDate : this.searchForm.controls.endDate.value,
      status : this.searchText
    }
    this.googleDriveService.exportToExcelDriveLog(JSON.stringify(payload)).subscribe((res: any) => {
      if (res.success) {
        this.exportexcel(res.schedulerLogs)
      }
    })
  }

  exportexcel(schedulerLogs: any): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(schedulerLogs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

}
