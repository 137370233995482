import { Component, OnInit } from '@angular/core';
import { BannerModalComponent } from '../modals/banner-modal/banner-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BannerService } from 'src/app/services/banner.service';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  bsModalRef!: BsModalRef
  bannerList : any[] =[]
  totalCount: number = 0
  page: number = 1
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  constructor(
    private modalService: BsModalService,
    private toasts: ToastrService,
    private authService: AuthService,
    private bannerService: BannerService
  ) { }

  ngOnInit(): void {
    this.getListOfBanner()
  }

  createUpdateBanner(bannerId :any) {
    this.bsModalRef = this.modalService.show(BannerModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.bannerId = bannerId
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.getListOfBanner();
      }
    })
  }
  getListOfBanner() {
    this.bannerService.getListOfBanner(this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success) {
        this.bannerList = res?.banners
        this.config.currentPage = res?.totalbanners?.pages || 1;
        this.config.totalItems = res?.totalbanners?.total || 0;
      }
    }, (err) => {
      this.bannerList = []
      this.config.currentPage = 1
      this.config.totalItems = 0
      this.toasts.error(err.error.message)
    })
  }
  pageChanged(event: any) {
    this.config.currentPage = event;
    this.getListOfBanner()
  }
  get pagination() {
    const paginationData = this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
    return paginationData
  }
  deleteBanner(bannerId :any){
    this.bannerService.deleteBanner(bannerId).subscribe((res:any)=>{
      if(res.success){
        this.toasts.success(res.message)
        this.getListOfBanner()
      }
    })
  }
}
