import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { labelDetailRoot, labelListRoot, labelRoot, manageLabel } from '../interfaces/label';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(
    private http: HttpClient
  ) { }

  addLabel(labelPayload: any) {
    return this.http.post<labelRoot>(`${environment.apiURL}/admin/label`, labelPayload)
  }

  assignLabel(assignLabelPayload: any) {
    return this.http.post<manageLabel>(`${environment.apiURL}/admin/label/assign`, assignLabelPayload)
  }

  labelList() {
    return this.http.get<labelListRoot>(`${environment.apiURL}/admin/label`);
  }

  searchList(search: any) {
    return this.http.get<labelListRoot>(`${environment.apiURL}/admin/label?search=${search}`);
  }


  removeLabel(partnerId: string) {
    return this.http.get<manageLabel>(`${environment.apiURL}/admin/label/remove?partnerId=${partnerId}`);
  }

  labelDetails(labelId: any) {
    return this.http.get<labelDetailRoot>(`${environment.apiURL}/admin/label/details?labelId=${labelId}`)
  }

  updateLabel(updatePayload: any) {
    return this.http.put<labelRoot>(`${environment.apiURL}/admin/label`, updatePayload)
  }

  delete(labelId: string) {
    return this.http.delete<manageLabel>(`${environment.apiURL}/admin/label?labelId=${labelId}`)
  }
}
