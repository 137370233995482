import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstanceLogService {

  constructor(
    private http: HttpClient
  ) { }

  instanceLog(page: number, limit: number, searchField: string) {
    return this.http.get(`${environment.apiURL}/admin/instancelog?page=${page}&limit=${limit}&search=${searchField}`);
  }

}
