import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { error } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ServerService } from 'src/app/services/server.service';
import { TechnicalDashboardService } from 'src/app/services/technical-dashboard.service';

@Component({
  selector: 'app-technical-dashboard',
  templateUrl: './technical-dashboard.component.html',
  styleUrls: ['./technical-dashboard.component.scss']
})
export class TechnicalDashboardComponent implements OnInit {

  @ViewChild('serverTypeSelect') serverTypeSelect!: MatSelect
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;

  allServerListSelected = false
  isServerDataAvailable: boolean = false
  serverLists!: []
  cloud: any
  serverListData: any
  cloudSummaryData: any
  serverType = [
    { value: 'trial', label: 'Trial Server' },
    { value: 'live', label: 'Live Server' },
    { value: 'marg', label: 'Marg Server' },
    { value: 'partnerCloud', label: 'Partner Server' },
  ]
  serverData: any
  totalCount: number = 0
  page: number = 1
  serverForm = new FormGroup({
    serverName: new FormControl(''),
    serverType: new FormControl(''),
    searchText: new FormControl(''),
    filterUpdateExe: new FormControl<boolean>(false),
    serverIds: new FormControl('')
  })

  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  constructor(
    private technicalDashboardService: TechnicalDashboardService,
    private toastr: ToastrService,
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.cloudServerReport()
    this.instanceDetailsData()
    this.cloudSummaryDetails()
    this.allServerList()
    this.serverForm.controls.serverType.valueChanges.subscribe((value: any) => {
      this.instanceDetailsData()
    })
  }

  cloudServerReport() {
    this.technicalDashboardService.getInstanceReport().subscribe((res: any) => {
      if (res.success) {
        this.serverData = [
          { title: 'Total Instance', count: res?.instanceStats[0]?.totalInstances, free: res?.instanceStats[0]?.freeInstances, occupied: res?.instanceStats[0]?.usedInstances, color: '#4B49AC' },
          { title: 'Live Instance', count: res?.instanceStats[0]?.liveInstances, free: res?.instanceStats[0]?.freeLiveInstances, occupied: res?.instanceStats[0]?.usedLiveInstances, color: '#00ab41' },
          { title: 'Trial Instance', count: res?.instanceStats[0]?.trialInstances, free: res?.instanceStats[0]?.freeTrialInstances, occupied: res?.instanceStats[0]?.usedTrialInstances, color: '#FA7070' },
          { title: 'Marg Instance', count: res?.instanceStats[0]?.margInstances, free: res?.instanceStats[0]?.freeMargInstances, occupied: res?.instanceStats[0]?.usedMargInstances, color: '#03AED2' },
          { title: 'Partner Instance', count: res?.instanceStats[0]?.partnerInstances, free: res?.instanceStats[0]?.freePartnerInstances, occupied: res?.instanceStats[0]?.usedPartnerInstances, color: '#4389F9' },
          {
            title: 'Offline Servers ', color: '#FFAD60', subItems: [
              { title: 'Live', count: res?.instanceStats[0]?.offlineLiveInstances, last: true },
              { title: 'Trial', count: res?.instanceStats[0]?.offlineTrialInstances, last: true },
              { title: 'Partner', count: res?.instanceStats[0]?.offlinePartnerInstances, last: true },
              { title: 'Marg', count: res?.instanceStats[0]?.offlineMargInstances, last: false },
            ]
          }]
      }
      this.toastr.success(res.message)
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }

  instanceDetailsData() {
    const payload = {
      serverIds: this.serverForm.controls.serverIds.value,
      serverType: this.serverForm.controls.serverType.value
    }

    this.technicalDashboardService.getInstanceDetails(this.config.currentPage, this.config.itemsPerPage, payload).subscribe((res: any) => {
      if (res.success) {
        this.serverListData = res?.serverStats[0].data
        this.isServerDataAvailable = true
        this.config.currentPage = res?.serverStats[0].total[0].pages
        this.config.totalItems = res?.serverStats[0].total[0].total
      }
    }, (error) => {
      this.isServerDataAvailable = false
      this.config.currentPage = 1
      this.config.totalItems = 0
      this.toastr.error(error.error.message)
    })
  }

  cloudSummaryDetails() {
    this.technicalDashboardService.getServerSummaryReport().subscribe((res: any) => {
      if (res.success) {
        this.cloudSummaryData = res?.cloudSummaryReport
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }


  submitAll() {
    this.instanceDetailsData()
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allServerListSelected) {
          item.select();
        } else {
          item.deselect();
        }
      });
    }
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;
    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allServerListSelected = newStatus;
    }
  }

  deselectAll(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1) {
          item.deselect();
          this.allServerListSelected = false
        }
      });
    }
  }

  allServerList() {
    const filterOutUpdateExe = this.serverForm.controls.filterUpdateExe.value
    this.serverService.allServerList(filterOutUpdateExe).subscribe((res: any) => {
      if (res.success) {
        this.serverLists = res?.servers
      }
    }, (err) => {
      this.toastr.error(err.error.message)
      this.serverLists = []
    })
  }

  get searchText() {
    return this.serverForm.controls.searchText.value || ''
  }
}
