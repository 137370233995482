import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RolesPermissionsService } from 'src/app/services/roles-permissions.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-roles-permissions',
  templateUrl: './roles-permissions.component.html',
  styleUrls: ['./roles-permissions.component.scss']
})
export class RolesPermissionsComponent implements OnInit {
  roles: any = []
  bsModalRef!: BsModalRef
  isRolesAndPermissionAvailable: boolean = false

  constructor(
    private rolesPermissionService: RolesPermissionsService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.roleList()
  }

  roleList() {
    this.rolesPermissionService.rolesList().subscribe((res: any) => {
      if (res.success && res.roles.length > 0) {
        this.roles = res.roles;
        this.isRolesAndPermissionAvailable = true
      }
    })
  }

  rolesDetails(roleId: string) {
    this.router.navigate(['roles-permissions/add-roles-permissions'], { queryParams: { roleId: roleId } })
  }

  deleteRoles(roleId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.roleId = roleId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.roleList();
      }
      else {
        this.roleList();
      }
    })
  }

}
