<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 grid-margin">
          <div class="row mb-4">
            <div class="col-md-4">
              <h3 class="font-weight-bold">Product Details</h3>
            </div>

            <div class="col-md-8 text-end">
              <button type="button" class="comman-btn1 btn-primary me-2" (click)="plan()">+ Plan</button>
              <button type="button" class="comman-btn1 btn-primary" (click)="navigateToAddOn()">+ Add-on</button>
            </div>
          </div>
          <form action="" [formGroup]="productForm">
            <div class="row mb-3">
              <label for="fullName" class="col-sm-2 col-form-label">Full Name</label>
              <div class="col-sm-10 mt-2">
                <input type="text" class="form-control" id="fullName" formControlName="name">
                <div *ngIf="productForm.get('name')?.invalid" class="alert mb-0 p-0">
                  <div class="error-message"
                    *ngIf="productForm.get('name')?.errors?.['required'] && (productForm.get('name')?.dirty || productForm.get('name')?.touched)">
                    <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                    </p>
                  </div>
                  <div class="error-message" *ngIf="productForm.get('name')?.errors?.['pattern']">
                    <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <label for="fullName" class="col-sm-2 col-form-label">Description</label>
              <div class="col-sm-10 mt-2">
                <textarea class="form-control description" id="fullName" formControlName="description"></textarea>
              </div>
            </div>
            <!-- ------------------ -->
            <div class="row table-section">
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <p class="card-title mb-0">Plan</p>
                      </div>
                    </div>

                    <div *ngIf="!isPlanAvailable" class="text-center">
                      <h4>There is no plan added yet. To add plan click</h4>
                      <button type="button" class="comman-btn1 btn-primary me-2" (click)="plan()">+ Plan</button>
                    </div>

                    <div class="table-responsive" *ngIf="isPlanAvailable">
                      <table class="table table-striped table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Code</th>
                            <th scope="col">Unit</th>
                            <th scope="col">Price</th>
                            <th scope="col" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let plan of plans">
                            <td>{{plan.name| titlecase}}</td>
                            <td>{{plan.code}}</td>
                            <td>{{plan.unit}}</td>
                            <td>{{plan.price}}</td>
                            <td class="text-center">
                              <i class="bi bi-pencil-fill text-primary me-3 mouseCursor"
                                (click)="editPlan(plan._id)"></i>
                              <i class="bi bi-trash3-fill text-danger mouseCursor"
                                (click)="deletePlanModal(plan._id)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- --------------------Add On Table----------------------- -->
            <div class="row table-section">
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <p class="card-title mb-0">Add-on</p>
                      </div>
                    </div>

                    <div *ngIf="!isAddonAvailable" class="text-center">
                      <h4>There is no Add-on added yet. To add Add-on click</h4>
                      <button type="button" class="comman-btn1 btn-primary" (click)="navigateToAddOn()">+
                        Add-on</button>
                    </div>
                    <div class="table-responsive" *ngIf="isAddonAvailable">
                      <table class="table table-striped table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Code</th>
                            <th scope="col">Unit</th>
                            <th scope="col">Price</th>
                            <th scope="col" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let addon of addOns" class="col-2">
                            <td>{{addon.name | titlecase}}</td>
                            <td>{{addon.code}}</td>
                            <td>{{addon.unit}}</td>
                            <td>{{addon.price}}</td>
                            <td class="text-center">
                              <i class="bi bi-pencil-fill text-primary mouseCursor me-3"
                                (click)="editAddOns(addon._id)"></i>
                              <i class="bi bi-trash3-fill text-danger mouseCursor"
                                (click)="deleteAddOnModal(addon._id)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 mt-2 text-end">
                <button type="submit" class="comman-btn1 btn-primary text-light" (click)="submit()">Update</button>
                <button type="submit" class="comman-btn1 btn-secondary text-light ms-2"
                  [routerLink]="['/product']">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ----------- -->