import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RolesPermissionsService } from 'src/app/services/roles-permissions.service';

@Component({
  selector: 'app-add-roles-permissions',
  templateUrl: './add-roles-permissions.component.html',
  styleUrls: ['./add-roles-permissions.component.scss']
})
export class AddRolesPermissionsComponent implements OnInit {
  permissions: any = []
  roleId: string = ''
  tempRoleId: string = ''
  isEdit: boolean = false;

  roleForm = new FormGroup({
    roles: new FormControl('', Validators.required)
  })

  constructor(
    private rolesPermissionService: RolesPermissionsService,
    private router: Router,
    private toasts: ToastrService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((data) => {
      this.roleId = data['roleId']
    })
    if (this.roleId) {
      this.isEdit = true
      this.rolesDetails();
    }

    this.permissionList()
  }

  rolesAndPermissions() {
    if (!this.roleForm.valid) {
      this.roleForm.markAllAsTouched()
    }
    else {
      this.submit()
    }
  }

  submit() {
    if (this.isEdit) {
      this.updateRoles()
    }
    else {
      this.createRoles()
    }
  }

  permissionList() {
    this.rolesPermissionService.permissionList().subscribe((res: any) => {
      if (res.success) {
        this.permissions = res.allows;
      }
    })
  }

  createRoles() {
    const rolePayload = {
      roles: this.roleForm.value.roles,
      allows: this.permissions
    }
    this.rolesPermissionService.createRole(rolePayload).subscribe((res: any) => {
      if (res.success) {
        this.router.navigate(['roles-permissions'])
        this.toasts.success(res.message)
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  rolesDetails() {
    this.rolesPermissionService.details(this.roleId).subscribe((res: any) => {
      if (res.success) {
        this.permissions = res.tempRoleObject[0].allows;
        this.roleForm.patchValue(res.tempRoleObject[0]);
        this.tempRoleId = res.tempRoleObject[0]._id;
      }
    })
  }

  updateRoles() {
    const rolePayload = {
      roles: this.roleForm.value.roles,
      allows: this.permissions,
      roleId: this.roleId,
      tempRoleId: this.tempRoleId,
    };
    this.rolesPermissionService.updateRole(rolePayload).subscribe((res: any) => {
      this.router.navigate(['roles-permissions'])
      this.toasts.success(res.message)
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  someComplete(resource: any): boolean {
    if (resource.permissions == null) {
      return false;
    }
    return (
      resource.permissions.filter((t: any) => t.checked).length > 0 &&
      !resource.checked
    );
  }
  setAll(resource: any, completed: Event) {
    const target = completed.target as HTMLInputElement;
    resource.checked = target.checked;
    if (resource.permissions == null) {
      return;
    }
    resource.permissions.forEach((p: any) => (p.checked = target.checked));
  }

  updateAllComplete(resource: any) {
    resource.checked =
      resource.permissions != null &&
      resource.permissions.every((t: any) => t.checked);
  }

}
