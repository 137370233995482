<!-- ---------change--plan--price--modal------------------ -->
<div class="px-4">
    <h4 class="modal-title pull-left mb-4"><b>Change Sub Pricing({{subCode |uppercase}})</b></h4>
    <div>
        <form action="" [formGroup]="changeSubPriceForm">
            <div class="row mb-3">
                <label for="fullName" class="col-sm-4 col-form-label">Plan Name</label>
                <div class="col-sm-8 mt-2">
                    <input type="text" placeholder="Enter Price" class="form-control" formControlName="name" readonly>
                </div>
            </div>
            <div class="row mb-3">
                <label for="fullName" class="col-sm-4 col-form-label">Current Price</label>
                <div class="col-sm-8 mt-2">
                    <input type="number" placeholder="Enter Price" class="form-control" formControlName="currentPrice"
                        readonly>
                </div>
            </div>
            <div class="row mb-3">
                <label for="price" class="col-sm-4 col-form-label">Price</label>
                <div class="col-sm-8 mt-2">
                    <input type="number" placeholder="Enter Price" class="form-control" formControlName="price" min="0"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                    <div *ngIf="changeSubPriceForm.get('price')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="changeSubPriceForm.get('price')?.errors?.['required'] && (changeSubPriceForm.get('price')?.dirty || changeSubPriceForm.get('price')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Price is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="fullName" class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8 mt-2">
                    <textarea rows="1" class="form-control " id="fullName" placeholder="Remarks" style="overflow:hidden"
                        formControlName="remark"></textarea>
                    <div *ngIf="changeSubPriceForm.get('remark')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="changeSubPriceForm.get('remark')?.errors?.['required'] && (changeSubPriceForm.get('remark')?.dirty || changeSubPriceForm.get('remark')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="fullName" class="col-sm-4 col-form-label">Select User</label>
                <div class="col-sm-8 mt-2">
                    <select class="form-select form-control mt-2" id="salesPersonName" formControlName="salesPersonName">
                        <option value="direct" selected (click)="salesPersons('direct')">Direct</option>
                        <option value="support" (click)="salesPersons('support')">Support</option>
                        <option *ngFor="let salesPerson of salesPersonList" (click)="salesPersons(salesPerson.name)" [value]="salesPerson.name">
                            {{salesPerson?.name}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="text-end">
        <button type="submit" class="comman-btn1 btn-primary me-2" (click)="changeSubPrice()">Change</button>
        <button type="button" class="comman-btn1 btn-secondary text-light" (click)="closeModal()">Cancel</button>
    </div>
</div>