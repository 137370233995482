import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BillRoot } from '../interfaces/billing';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private http: HttpClient) { }

  billList(searchPayload: any, page: number, limit: number) {
    return this.http.get<BillRoot>(`${environment.apiURL}/admin/bill?search=${searchPayload}&page=${page}&limit=${limit}`);
  }
  billDetails(searchPayload: any, page: number, limit: number, month: number, year: number) {
    return this.http.get<BillRoot>(`${environment.apiURL}/admin/bill/details?search=${searchPayload}&page=${page}&limit=${limit}&month=${month}&year=${year}`);
  }

  pincode(pinCode: any) {
    return this.http.get(`${environment.apiURL}/state?pincode=${pinCode}`)
  }

  paymentList(searchPayload: any, paymentMode: string, page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/admin/payment?search=${searchPayload}&paymentMode=${paymentMode}&page=${page}&limit=${limit}`);
  }

  updatePaymentStatus(payload: any) {
    return this.http.put(`${environment.apiURL}/admin/payment`, payload)
  }
}
