import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsService {

  constructor(
    private http: HttpClient
  ) { }

  permissionList() {
    return this.http.get(`${environment.apiURL}/admin/permissions`);
  }

  rolesList() {
    return this.http.get(`${environment.apiURL}/admin/role`);
  }

  createRole(rolePayload: any) {
    return this.http.post(`${environment.apiURL}/admin/role`, rolePayload);
  }

  details(roleId: string) {
    return this.http.get(`${environment.apiURL}/admin/role/details?roleId=${roleId}`);
  }

  updateRole(rolePayload: any) {
    return this.http.put(`${environment.apiURL}/admin/role`, rolePayload);
  }

  deleteRoles(roleId: string) {
    return this.http.delete(`${environment.apiURL}/admin/role?roleId=${roleId}`);
  }
}
