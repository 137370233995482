<!-- ---------------Virtual Machine Logs---------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-weight-bold heading"><b>Virtual Machine Logs</b></h3>
            </div>
            <!-- <div class="col-md-8 text-end">
                            <button type="button" class="comman-btn1 btn-primary me-2"
                                [routerLink]="['/add-virtual-machine']">Add Virtual Machine</button>
                        </div> -->
            <!-- --------form-------- -->
            <div class="row ">
              <div class="col-md-3 col-sm-12 mb-3">
                <input type="text" class="form-control search-item" id="searchField" placeholder="search here"
                  [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (keyup)="searchVMLogs($event)">
              </div>
            </div>
            <!-- <form action="">
              <div class="row mt-4">

                <div class="col-md-6 col-sm-12 text-center">
                  <div class="row">
                    <div class="col-md-7 pe-0">
                      <div class="input-group mb-2 responsive-field">
                        <span class="input-group-text" id="basic-addon1">Created date from</span>
                        <input type="date" class="form-control custom-label registrationFrom"
                          formControlName="registrationFrom" placeholder="From" aria-label="Username"
                          aria-describedby="basic-addon1">
                      </div>
                    </div>

                    <div class="col-md-5 ps-0">
                      <div class="input-group mb-2 responsive-field-1">
                        <span class="input-group-text custom-select" id="basic-addon1">to</span>
                        <input type="date" class="form-control" formControlName="registrationTo" placeholder="To"
                          aria-label="Username" aria-describedby="basic-addon1">
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-3 col-sm-12 text-end">
                  <button type="submit" class="comman-btn1 btn-primary me-2">Search</button>
                  <button type="search" class="comman-btn1 btn-secondary text-light">Reset</button>
                </div>
              </div>
            </form> -->
            <!-- -----end----- -->
          </div>
        </div>
        <!-- ------------------ -->
        <div class="row table-section pe-0">
          <div class="col-md-12 grid-margin stretch-card pe-0">
            <div class="card">
              <div class="card-body">
                <div class=" mb-4 records">{{updatePaginationText}}</div>
                <div *ngIf="!isVMAvailable" class="text-center">
                  <h4>There is no Logs created yet.</h4>
                </div>
                <div class="table-responsive" *ngIf="isVMAvailable">
                  <table class="table table-striped table-borderless">
                    <thead class="roundedTable thead-header">
                      <tr>
                        <th scope="col">#Id</th>
                        <th scope="col">Date & Time</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">UserType</th>
                        <th scope="col" class="textWrap">API Request Payload</th>
                        <th scope="col">API Request URL</th>
                        <th scope="col">API Response</th>
                        <!-- <th scope="col" class="text-center">Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let virtualMachine of virtualMachines| paginate: config">
                        <td class="productNameCursor textWrap">
                          {{(virtualMachine?.createdBy==='customer')?virtualMachine?.customer?.id:(virtualMachine?.createdBy==='partner')?virtualMachine?.partner?.id:''}}
                        </td>
                        <td>{{virtualMachine?.createdAt| date : "dd/MM/yyyy, hh:mm a":'UTC + 05:30'}}</td>
                        <td>
                          {{(virtualMachine?.createdBy==='customer')?virtualMachine?.customer?.name:(virtualMachine?.createdBy==='partner')?virtualMachine?.partner?.name:''}}
                        </td>
                        <td>
                          {{(virtualMachine?.createdBy==='customer')?virtualMachine?.customer?.email:(virtualMachine?.createdBy==='partner')?virtualMachine?.partner?.email:''}}
                        </td>
                        <td>{{virtualMachine?.createdBy}}</td>
                        <td class="textWrap">{{virtualMachine?.apiRequestPayload}}</td>
                        <td class="textWrap">{{virtualMachine?.apiRequestUrl}}</td>
                        <td class="textWrap">{{virtualMachine?.apiResponse}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-4 float-end me-4" *ngIf="isVMAvailable">
                  <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ----------- -->
      </div>
    </div>
  </div>