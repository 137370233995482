import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { error } from 'jquery';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TallyReleaseService } from 'src/app/services/tally-release.service';

@Component({
  selector: 'app-add-tally-release',
  templateUrl: './add-tally-release.component.html',
  styleUrls: ['./add-tally-release.component.scss']
})
export class AddTallyReleaseComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() tallyReleaseId: string;
  isEdit: boolean=false

  constructor(
    private tallyReleaseService: TallyReleaseService,
    private toasts: ToastrService,
    public bsModalRef: BsModalRef,
  ) { }

  tallyReleaseForm = new FormGroup({
    versionName: new FormControl('', Validators.required),
    link: new FormControl('', Validators.required),
    updateLink: new FormControl('', Validators.required),
  })

  ngOnInit(): void {
    setTimeout(() => {
      if (this.tallyReleaseId != "") {
        this.isEdit = true
        this.tallyReleaseDetails()
      }
    });
  }



  submit() {
    if (!this.tallyReleaseForm.valid) {
      this.tallyReleaseForm.markAllAsTouched()
    } else {
      if(this.isEdit){
        this.updateTallyReleaseData()
      }else{
        this.saveTallyReleaseData()
      }
     
    }
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  saveTallyReleaseData() {
    const tallyReleasePayload = this.tallyReleaseForm.value
    this.tallyReleaseService.addTallyRelease(tallyReleasePayload).subscribe((res: any) => {
      if (res.success) {
        this.closedModal()
        this.event.emit('ok')
        this.tallyReleaseForm.reset();
        this.toasts.success(res.message)
      }
    }, (error) => {
      this.toasts.error(error.error.message)
    })
  }

  updateTallyReleaseData() {
    const tallyReleasePayload =Object.assign(this.tallyReleaseForm.value,{tallyVersionId:this.tallyReleaseId}) 
    this.tallyReleaseService.updateTallyRelease(tallyReleasePayload).subscribe((res: any) => {
      if (res.success) {
        this.closedModal()
        this.event.emit('ok')
        this.tallyReleaseForm.reset();
        this.toasts.success(res.message)
      }
    }, (error) => {
      this.toasts.error(error.error.message)
    })
  }


  tallyReleaseDetails() {
    this.tallyReleaseService.tallyReleaseDetails(this.tallyReleaseId).subscribe((res: any) => {
      if (res.success) {
        this.tallyReleaseForm.patchValue(res.tallyVersion)
      }
    }, (error) => {
      this.toasts.error(error.error.message)
    })
  }
}
