import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ServerRoot } from 'src/app/interfaces/server';
import { ServerService } from 'src/app/services/server.service';
import { AddServerComponent } from '../modals/add-server/add-server.component';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { ServerExeComponent } from '../modals/server-exe/server-exe.component';

@Component({
  selector: 'app-server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.scss']
})
export class ServerComponent implements OnInit {

  selectedServerType: string = 'live'
  selectedStatusType: string = 'all'
  servers: any = [];
  isServerAvailable: boolean = false
  bsModalRef!: BsModalRef;
  totalCount: number = 0
  page: number = 1
  isSorting: boolean = false
  cDriveDetails: any
  sortValue: string = '{"id":-1}'

  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  constructor(
    private modalService: BsModalService,
    private serverService: ServerService,
    private toasts: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.serverList();
  }

  serverList() {
    this.serverService.serverList(this.config.currentPage, this.config.itemsPerPage,
      this.selectedServerType, this.selectedStatusType, this.sortValue).subscribe((res: any) => {
        if (res.success) {
          this.servers = res.servers;
          this.config.currentPage = res?.totalServers?.pages;
          this.config.totalItems = res?.totalServers?.total;
          this.isServerAvailable = true
        }
      },
        (error) => {
          this.servers = []
          this.isServerAvailable = false
          this.config.currentPage = 1;
          this.config.totalItems = 0;
          this.toasts.error(error.error.message)
        })

  }
  serverListChange() {
    this.config.currentPage = 1
    this.serverList()
  }
  addUpdateServer(serverId: string) {
    this.bsModalRef = this.modalService.show(AddServerComponent, {
      class: 'modal-lg',
      backdrop: 'static'
    })
    this.bsModalRef.content.serverId = serverId
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.serverList();
      }
      else {
        this.serverList();
      }
    })
  }

  resetServerModal(serverId: number) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.serverId = serverId;
    this.bsModalRef.content.title = 'Reset';
    this.bsModalRef.content.message = 'Are you sure you want to reset this server?';
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.serverList();
      } else {
        this.serverList();
      }
    });
  }

  sortingServer(sortData: string) {
    if (!this.isSorting) {
      this.sortValue = JSON.stringify({ [sortData]: 1 });
    } else {
      this.sortValue = JSON.stringify({ [sortData]: -1 });
    }

    this.isSorting = !this.isSorting;
    this.serverList();
  }

  pageChanged(event: any) {
    this.config.currentPage = event
    this.serverList()
  }

  get updatePaginationText() {
    return this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems);
  }

  serverExe() {
    this.bsModalRef = this.modalService.show(ServerExeComponent, {
      class: 'modal-md',
      backdrop: 'static',
    })
  }

  refreshInstance() {
    this.serverService.refreshInstance().subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
      }
      else {
        this.toasts.warning(res.message)
      }
    })
  }

  refreshServer(serverId: string) {
    this.serverService.refreshServer(serverId).subscribe((res: any) => {
      if (res.success) {
        this.serverList()
      }
    }, (err: any) => {
      this.serverList()
      this.toasts.error(err.error.message)
    })
  }
  cDriveSpace() {
    this.serverService.getCdriveSpace().subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
      }
    })
  }
}
