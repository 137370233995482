<!-- ------user---management-------- -->
<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row ">
                <div class="col-md-12 ">
                    <h3 class="font-weight-bold heading"><b>Payment Report</b></h3>
                </div>

                <div class="col-md-12 mb-2 mt-2 ">
                    <div class="search p-3 bg-white">
                        <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                            aria-expanded="false" aria-controls="collapseExample">
                            <i class="bi bi-search"></i>
                            <span class="ms-5">Search here</span>
                        </p>
                        <div class="collapse" id="collapseExample">
                            <form class="row g-3 mt-2" [formGroup]="searchForm">
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon1">Sub Id</span>
                                        <input type="text" class="form-control" placeholder="Sub Id"
                                            formControlName="subId" aria-label="Username"
                                            aria-describedby="basic-addon1">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">Email</span>
                                        <input type="email" class="form-control" placeholder="Email"
                                            formControlName="email" aria-label="Username"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">User Type</span>
                                        <select class="form-select form-control" formControlName="userType">
                                            <option value="">All</option>
                                            <option value="partner">Partner</option>
                                            <option value="customer">Customer</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text " id="basic-addon3">Status</span>
                                        <select class="form-select form-control" formControlName="status">
                                            <option value="" selected>All</option>
                                            <option value="success">Success</option>
                                            <option value="pending">Pending</option>
                                            <option value="failed">Failed</option>
                                            <option value="onHold">On Hold</option>
                                            <option value="rejected">Rejected</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">Transaction ID</span>
                                        <input type="text" class="form-control" placeholder="Transaction ID"
                                            formControlName="transactionId" aria-label="Username"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">Order ID</span>
                                        <input type="text" class="form-control" placeholder="Order ID"
                                            formControlName="orderId" aria-label="Username"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">Amount</span>
                                        <input type="number" class="form-control" placeholder="Amount"
                                            formControlName="amount" aria-label="Username"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">Invoice</span>
                                        <select class="form-select form-control" formControlName="invoice">
                                            <option value="">All</option>
                                            <option value="created">Created</option>
                                            <option value="notCreated">Not Created</option>

                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12 text-end mt-4">
                                    <button type="submit" class="comman-btn1 btn-primary me-2"
                                        (click)="paymentListData()">Search</button>
                                    <button type="search" class="comman-btn1 btn-secondary text-light"
                                        (click)="resetSearchForm()">Reset</button>
                                </div>
                            </form>
                            <!-- ------ -->
                        </div>
                    </div>
                </div>
                <!----- ONLINE PAYMENT ----->


                <div class="col-md-12  mb-2 mt-2  stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="mb-3">
                                    <ul class="d-flex service ps-0 mb-0">
                                        <li (click)="toggleSection('manual')"
                                            [ngClass]="(activeSection==='manual') ? 'activate': ''">
                                            <a>Manual Payment</a>
                                        </li>
                                        <li (click)="toggleSection('online')"
                                            [ngClass]="(activeSection==='online') ? 'activate': ''">
                                            <a>Online Payment</a>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                            <div class=" mb-4 records">{{updatePaginationText}} </div>


                            <div class="table-responsive">
                                <table class="table  table-borderless  table-sm">
                                    <thead class="table-header">
                                        <tr>
                                            <th scope="col">Date & Time</th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col" *ngIf="activeSection==='manual'">Paid Amount</th>
                                            <th scope="col" *ngIf="activeSection==='manual'">Received Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Payment Mode</th>
                                            <th scope="col">Transaction ID</th>
                                            <th scope="col" *ngIf="activeSection==='manual'">Estimated Date & Time</th>
                                            <th scope="col" *ngIf="activeSection==='manual'">Remark</th>
                                            <th scope="col">Sub Id</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">User Type</th>
                                            <th scope="col" *ngIf="activeSection==='manual'">Approved By</th>
                                            <th scope="col">Invoice</th>
                                            <th scope="col" *ngIf="activeSection==='manual'">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let payment of paymentDetails | paginate : config">
                                        <tr>
                                            <td>{{payment?.createdAt? (payment?.createdAt | date:'MMM d, y, H:mm')
                                                :'N/A'}}</td>
                                            <td>{{payment.orderId}}</td>
                                            <td>{{payment.amount ? (payment.amount | currency:"INR") :'N/A'}}</td>
                                            <td *ngIf="activeSection==='manual'">{{payment.paidAmount ?
                                                (payment.paidAmount | currency:"INR") :'N/A'}}</td>
                                            <td *ngIf="activeSection==='manual'">{{payment.recievedAmount ?
                                                (payment.recievedAmount | currency:"INR") :'N/A'}}</td>
                                            <td>{{payment.orderStatus ? payment.orderStatus :'N/A'}}</td>
                                            <td>{{payment?.paymentMode? (payment?.paymentMode | titlecase) :'N/A'}}</td>
                                            <td>
                                                <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                    ngbTooltip="{{payment.trackingId ? payment.trackingId :'N/A'}}"
                                                    placement="top">{{payment.trackingId ? payment.trackingId :'N/A'}}
                                                </div>
                                            </td>
                                            <td *ngIf="activeSection==='manual'">{{payment?.dateTime? (payment?.dateTime
                                                | date:'MMM d, y, H:mm')
                                                :'N/A'}}</td>
                                            <td *ngIf="activeSection==='manual'">
                                                <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                    ngbTooltip="{{payment.remark ? payment.remark :'N/A'}}"
                                                    placement="top">{{payment.remark ? payment.remark :'N/A'}}
                                                </div>
                                            </td>
                                            <td><a class="sub-link1" [routerLink]="['/subscriptions-details']"
                                                    [queryParams]="{subId : payment.subscription?.code }"
                                                    *ngIf="payment.subscription?.code">{{payment.subscription?.code
                                                    }}</a>
                                                <span *ngIf="!payment.subscription?.code">N/A</span>
                                            </td>


                                            <td>
                                                <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                    ngbTooltip="{{payment.email? payment.email : 'N/A'}}"
                                                    placement="top">{{payment.email? payment.email : 'N/A'}}</div>
                                            </td>
                                            <td>{{payment.userType? (payment.userType | titlecase) :'N/A'}}</td>
                                            <td *ngIf="activeSection==='manual'">
                                                <div ngbTooltip="{{payment.remark ?  payment.remark :'N/A'}}">
                                                    {{payment.statusUpdatedBy ? payment.statusUpdatedBy :'N/A'}}</div>
                                            </td>

                                            <td>
                                                <a [href]="payment?.invoiceUrl" target="_blank" class="sub-link1"
                                                    *ngIf="payment?.invoiceCreated">Show
                                                    Invoice</a>
                                                <span *ngIf="!payment?.invoiceCreated">Not Created</span>
                                            </td>
                                            <td *ngIf="activeSection==='manual'" class="text-center"> <i
                                                    class="bi bi-pencil-square pencil" ngbTooltip="Update Status"
                                                    placement="top"
                                                    (click)="updateStatus(payment._id,payment.orderId)"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-4 float-end me-4" *ngIf="ispaymentAvailable">
                                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                            </div>
                        </div>


                    </div>
                </div>







            </div>

        </div>
        <!-- content-wrapper ends -->
    </div>
</div>