import { Component, ElementRef, EventEmitter, Input, OnInit ,ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BannerService } from 'src/app/services/banner.service';
@Component({
  selector: 'app-banner-modal',
  templateUrl: './banner-modal.component.html',
  styleUrls: ['./banner-modal.component.scss']
})
export class BannerModalComponent implements OnInit {
  @Input() bannerId !: any
  @ViewChild('fileInput') fileInput: ElementRef;
  event: EventEmitter<any> = new EventEmitter();
  file !: any
  isUpdate: boolean = false
  bannerForm = new FormGroup({
    heading: new FormControl('', Validators.required),
    subHeading: new FormControl('', Validators.required),
    description: new FormControl(''),
    fileSelect : new FormControl(''),
    isActive: new FormControl<boolean>(false),
    showCustomer: new FormControl<boolean>(false),
    showPartner: new FormControl<boolean>(false)
  })
  constructor(
    public bsModalRef: BsModalRef,
    private toasts: ToastrService,
    private bannerService: BannerService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.bannerId) {
        this.isUpdate = true
        this.getDetailsBanner()
      }
    });
  }
  
  createBanner() {
    const payload = this.bannerForm.value
    if(!this.bannerForm.get('heading')?.valid){
     this.bannerForm.markAllAsTouched()
     return
    }
    // const inputElement: HTMLInputElement = this.fileInput.nativeElement;
    // if (inputElement.files && inputElement.files.length > 0) {
    //   const file = inputElement.files[0];
    //   const formData = new FormData();
    //   formData.append('heading', JSON.stringify(payload.heading))
    //   formData.append('subHeading', JSON.stringify(payload.subHeading))
    //   formData.append('description', JSON.stringify(payload.description))
    //   formData.append('fileSelect', JSON.stringify(payload.heading))
    //   formData.append('isActive', JSON.stringify(payload.isActive))
    //   formData.append('showCustomer', JSON.stringify(payload.showCustomer))
    //   formData.append('file', file);
    //   this.bannerService.createBanner(formData).subscribe((res: any) => {
    //     if (res.success) {
    //       this.bsModalRef.hide()
    //       this.toasts.success(res.message)
    //       this.event.emit('ok')
    //     }
    //   })
    // } else {
    //   console.error('No file selected');
    // }
    this.bannerService.createBanner(payload).subscribe((res: any) => {
      if (res.success) {
        this.bsModalRef.hide()
        this.toasts.success(res.message)
        this.event.emit('ok')
      }
    })
  }
  updateBanner() {
    let bannerPayload = this.bannerForm.value
    bannerPayload = Object.assign(bannerPayload, { id: this.bannerId })
    this.bannerService.updateBanner(bannerPayload).subscribe((res: any) => {
      if (res.success) {
        this.bsModalRef.hide()
        this.toasts.success(res.message);
        this.event.emit('ok')
      }
    }, (error) => {
      this.toasts.error(error.error.message)
    })
  }
  getDetailsBanner(){
    this.bannerService.getDetailsBanner(this.bannerId).subscribe((res:any)=>{
      if(res.success){
        this.bannerForm.patchValue(res?.banner)
      }
    })
  }
}
