<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="col-md-12 ">
        <div class="row ">
          <div class="col-8">
            <h3 class="heading">{{subTypeTitle}}</h3>
          </div>
          <div class="col-4 text-end mb-2" *ngIf="userType">
            <button type="button" class="comman-btn1 btn-primary" (click)="createNew()">+ New</button>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-2 mb-2"><button class="btn-color btn-color5 w-100" (click)="clickableData('')"
              [ngClass]="subTypeTitle==='All Subscriptions'?'active-btn-color5':''">All Subscription</button></div>
          <div class="col-md-2 mb-2"><button class="btn-color btn-color1 w-100" (click)="clickableData('paid')"
              [ngClass]="subType==='paid'?'active-btn-color1':''">Paid Subscription</button></div>
          <div class="col-md-2 mb-2"><button class="btn-color btn-color2 w-100" (click)="clickableData('trial')"
              [ngClass]="subType==='trial'?'active-btn-color2':''">Trial Subscription</button></div>
          <div class="col-md-3 mb-2"><button class="btn-color btn-color3 w-100" (click)="clickableData('renewal')"
              [ngClass]="subType==='renewal'?'active-btn-color3':''">Upcoming Renewal</button></div>
          <div class="col-md-3"><button class="btn-color btn-color4 w-100" (click)="clickableData('expired')"
              [ngClass]="subType==='expired'?'active-btn-color4':''">Expired Subscription</button></div>

        </div>

        <div class="row mt-4">
          <div class="col-sm-6  grid-margin">
            <div class="card card1">
              <div class="card-body ps-3 pe-3">
                <p class="txt">Total Subscriptions</p>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <p class="number text-center">{{ subscriptionData?.total }}</p>
                    <p class="text-center sub-txt">Subscriptions</p>
                  </div>
                  <div class="vr"></div>
                  <div>
                    <p class="number text-center">{{subscriptionData?.totalUsers}}</p>
                    <p class="text-center sub-txt">Users</p>
                  </div>
                  <div class="vr"></div>
                  <div>
                    <p class="number text-center">{{subscriptionData?.totalAmount | pricePipe}}</p>
                    <p class="text-center sub-txt">Amount</p>
                  </div>
                  <div>
                    <div class="circle d-none d-sm-block">
                      <img src="../../../../assets/images/icons8-resume.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class=" col-sm-6 grid-margin">
            <div class="card card2">
              <div class="card-body ps-3 pe-3 ">
                <p class="txt">New Paid Subscriptions
                  <select class="dropdown select-dropdown float-end selected" #selectedReportTime
                    (change)="subscriptionList()">
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                    <option value="year">This Year</option>
                  </select>
                </p>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <p class="number text-center">{{subReportData?.totalSub}}</p>
                    <p class="text-center sub-txt">Subscriptions</p>
                  </div>
                  <div class="vr"></div>
                  <div>
                    <p class="number text-center">{{subReportData?.totalUsers}}</p>
                    <p class="text-center sub-txt">Users</p>
                  </div>
                  <div class="vr"></div>
                  <div>
                    <p class="number text-center">{{subReportData?.amount | pricePipe}}</p>
                    <p class="text-center sub-txt">Amount</p>
                  </div>
                  <div>
                    <div class="circle d-none d-sm-block">
                      <img src="../../../../assets/images/icons8-new.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="search-form">
          <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
            aria-expanded="false" aria-controls="collapseExample">
            <i class="bi bi-search"></i>
            <span class="ms-5">Search here</span>
          </p>
          <div class="collapse" id="collapseExample">
            <form class="row g-3 mt-2" [formGroup]="searchForm">

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Start Date</mat-label>
                  <mat-date-range-input [rangePicker]="startPicker">
                    <input matStartDate formControlName="startDateFrom" placeholder="Start date from">
                    <input matEndDate formControlName="startDateTo" placeholder="Start date to">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #startPicker></mat-date-range-picker>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Next Billing Date</mat-label>
                  <mat-date-range-input [rangePicker]="billingPicker">
                    <input matStartDate placeholder="Start date" formControlName="expiryDateFrom">
                    <input matEndDate placeholder="End date" formControlName="expiryDateTo">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="billingPicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #billingPicker></mat-date-range-picker>
                </mat-form-field>
              </div>


              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Last Billing Date</mat-label>
                  <mat-date-range-input [rangePicker]="lastbillingPicker">
                    <input matStartDate placeholder="Start date" formControlName="lastDateFrom">
                    <input matEndDate placeholder="End date" formControlName="lastDateTo">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="lastbillingPicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #lastbillingPicker></mat-date-range-picker>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Company/Email Address:</mat-label>
                  <input type="text" matInput formControlName="userDetail" class="form-input-field" />
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>SubCode:</mat-label>
                  <input type="text" matInput formControlName="subCode" class="form-input-field" />
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option value="option1" selected>All </mat-option>
                    <mat-option [value]="true">Active</mat-option>
                    <mat-option [value]="false">Suspend</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Stage</mat-label>
                  <mat-select multiple #multiSelect formControlName="stage">
                    <mat-select-trigger>
                      {{ multiSelect.value ? multiSelect.value[0] : '' }}
                      <span *ngIf="multiSelect.value?.length > 1" class="example-additional-selection">
                        (+{{ multiSelect.value.length - 1 }} {{ multiSelect.value?.length === 2 ? 'other' : 'others'
                        }})
                      </span>
                    </mat-select-trigger>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect,0)">Select All</mat-checkbox>
                    </div>

                    <mat-option *ngFor="let stage of stageOptions" [value]="stage.value"
                      (click)="optionClick(multiSelect, 0)">
                      {{ stage.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Plan Name</mat-label>
                  <mat-select multiple #multiSelect1 formControlName="planName">
                    <mat-select-trigger>
                      {{ multiSelect1.value ? multiSelect1.value[0] : '' }}
                      <span *ngIf="multiSelect1.value?.length > 1" class="example-additional-selection">
                        (+{{ multiSelect1.value.length - 1 }} {{ multiSelect1.value?.length === 2 ? 'other' : 'others'
                        }})
                      </span>
                    </mat-select-trigger>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allPlanSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                    </div>
                    <mat-option *ngFor="let list of PlanList" [value]="list?.name"
                      (click)="optionClick(multiSelect1, 1)">{{list?.name | titlecase}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Partner</mat-label>
                  <mat-select multiple #multiSelect2 formControlName="partnerIds">
                    <div class="search ">
                      <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                        formControlName="searchText1">
                    </div>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allPartnerSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect2,2)">Select All</mat-checkbox>
                    </div>
                    <mat-option *ngFor="let list of partnerList | filter: searchText1:'companyName'" [value]="list?._id"
                      (click)="optionClick(multiSelect2,2)">
                      {{list?.companyName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Account Manager</mat-label>
                  <mat-select multiple #multiSelect3 formControlName="accountManagerIds">
                    <div class="search ">
                      <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                        formControlName="searchText">
                    </div>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allAccountManagerSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect3,3)">Select All</mat-checkbox>
                    </div>
                    <mat-option *ngFor="let list of accountManagerList | filter: searchText:'email'" [value]="list?._id"
                      (click)="optionClick(multiSelect3,3)">
                      {{list?.email |
                      titlecase}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Activate</mat-label>
                  <mat-select formControlName="activate">
                    <mat-option value="option1" selected>All </mat-option>
                    <mat-option [value]="true">Activate</mat-option>
                    <mat-option [value]="false">Not Activate</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Tally Serial No</mat-label>
                  <input type="text" matInput formControlName="tallySerialNo" class="form-input-field" />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="example-form-field w-100">
                  <mat-label>Schedular</mat-label>
                  <mat-select formControlName="isSchedular">
                    <mat-option value="" selected>All </mat-option>
                    <mat-option [value]="true">Added</mat-option>
                    <mat-option [value]="false">Not Added</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 text-end">
                <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">Search</button>
                <button type="search" class="comman-btn1 btn-secondary text-light" (click)="resetForm()">Reset</button>
              </div>

            </form>
            <!-- ------ -->
          </div>
        </div>
      </div>
      <!--  --------table-section---------- -->

      <div class="row table-section mt-4 pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="mb-3">
                  <ul class="d-flex service ps-0 mb-0">
                    <li (click)="toggle('subscripton')" [ngClass]="activation==='subscripton'?'activate':''">
                      <a>Subscriptions</a>
                    </li>
                    <li (click)="toggle('misMatchedSub')" [ngClass]="activation==='misMatchedSub'?'activate':''">
                      <a>Serial No. Mismatch </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class=" mb-4 records mb-3">{{updatePaginationText}}
                <button (click)="performBulkAction()" class=" float-end exl-btn ms-2 me-2"
                  *ngIf="countSelected()">Restrict Deletion({{countSelected()}})</button>
                <button class="float-end exl-btn ms-2 me-2" [disabled]="this.config.totalItems === 0"
                  (click)="excelSubsData()" *ngIf="!userRole">
                  Export To Excel
                </button>
              </div>

              <div *ngIf="!isSubscriptionAvailable" class="text-center">
                <h4>There is no Subscription found.</h4>
              </div>
              <div class="table-responsive" *ngIf="isSubscriptionAvailable">
                <table class="table table-striped table-borderless table-sm">
                  <thead class="table-header">
                    <tr>
                      <th scope="col">
                        <input type="checkbox" (change)="selectAll($event)" [checked]="isAllSelected()">
                      </th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('code')">Sub ID<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col">Company Name</th>
                      <th scope="col">Partner Email</th>
                      <th scope="col">Account Manager</th>
                      <th scope="col">Set For</th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('name')">Plan Name<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('unitPrice')">Unit Price<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('noOfUsers')">No Of Users<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('price')">Amount<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('currentMRR')">Current MRR<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col">Tally Serial No</th>
                      <th scope="col">Tally Serial No By Customer</th>
                      <th scope="col">Start Date</th>
                      <th scope="col" class="arrow-down-up" (click)="sortingPartner('endDate')">Next Billing date<i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col">Last billing date</th>
                      <th scope="col">Stage</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let subdata of subsList | paginate : config">
                      <td>
                        <input type="checkbox" [checked]="selectedSubs[subdata._id]"
                          (change)="toggleSelection(subdata._id)">
                      </td>
                      <td>
                        <a class="sub-link" [routerLink]="['/subscriptions-details']"
                          [queryParams]="{ subId: subdata?.code }"
                          [ngClass]="subdata.isDeleted ? 'text-danger' : 'text-primary'"> {{subdata?.code |
                          uppercase}}</a><br />
                        <small *ngIf="subdata?.restricted" class="text-muted">(Restricted)</small>
                      </td>
                      <td>
                        <span class="d-inline-block text-truncate" style="max-width: 100px;"
                          [ngbTooltip]="subdata?.customer?.companyName | titlecase" placement="top">
                          {{subdata?.customer?.companyName | titlecase}}</span>
                      </td>
                      <td>
                        <div class="d-inline-block text-truncate" style="max-width: 120px;"
                          ngbTooltip="{{subdata?.partner?.email |titlecase}}" placement="top">
                          {{subdata?.partner?.email|titlecase}}
                        </div>

                      </td>
                      <td>{{subdata?.partner?.accountManagerName}}</td>
                      <td [ngClass]="subdata?.isAutoRenew ?'text-success' :''">
                        {{subdata?.isAutoRenew?'Auto Renew':'Auto Expire'}}</td>
                      <td>
                        <div class="d-inline-block text-truncate" style="max-width: 120px;"
                          ngbTooltip="{{subdata?.plan?.name |titlecase}}" placement="top">
                          {{subdata?.plan?.name|titlecase}}
                        </div>

                      </td>
                      <td>{{subdata?.plan?.unitPrice}}</td>
                      <td>{{subdata?.plan?.noOfUsers}}</td>
                      <td>{{subdata?.plan?.unitPrice * subdata?.plan?.noOfUsers}}</td>
                      <td>{{subdata?.currentMRR}}</td>
                      <td class="text-center">{{subdata?.tallySerialNo?subdata?.tallySerialNo:'N/A'}}</td>
                      <td class="text-center">
                        {{subdata?.tallySerialNoByCustomer?subdata?.tallySerialNoByCustomer:'N/A'}}</td>
                      <td>{{subdata?.startDate? (subdata?.startDate
                        | date:"dd/MM/yy"):"Not Activated"}}</td>
                      <td>{{subdata?.endDate? (subdata?.endDate
                        | date:"dd/MM/yy"):"Not Activated"}}</td>
                      <td>{{subdata?.lastRenewDate?(subdata?.lastRenewDate
                        | date:"dd/MM/yy"):"N/A"}}</td>
                      <td [ngClass]="[(subdata?.plan?.unitPrice>100)?subdata?.isDeleted?'text-danger':
                                            subdata?.isExpired?'text-danger':'text-success':'text-danger']">
                        {{(subdata?.plan?.unitPrice>100)?subdata?.isDeleted?'Deleted':subdata?.isExpired?'Expired':
                        'Live':subdata?.isDeleted?'Deleted':subdata?.isExpired?
                        'Trial Expired':'Trial'}}</td>
                      <td [ngClass]="[subdata?.isActive? 'text-success': 'text-danger']">
                        {{subdata?.isActive?'Active':'InActive'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-4 float-end me-4" *ngIf="isSubscriptionAvailable">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>