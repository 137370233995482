import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { InstanceLogService } from 'src/app/services/instance-log.service';
import * as moment from 'moment';
@Component({
  selector: 'app-instance-log',
  templateUrl: './instance-log.component.html',
  styleUrls: ['./instance-log.component.scss']
})
export class InstanceLogComponent implements OnInit {

  instance: any = [];
  isInstanceLogAvailable: boolean = false
  totalCount: number = 0
  page: number = 1
  minDate !:any
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  searchForm = new FormGroup({
    actionType: new FormControl(''),
    userName: new FormControl(''),
    email: new FormControl(''),
    subId: new FormControl(''),
    startDate : new FormControl(''),
    endDate : new FormControl('')
  })

  constructor(
    private instanceLogService: InstanceLogService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    this.instanceLogList();
  }

  resetSearchForm() {
    this.searchForm.reset();
    this.config.currentPage = 1;
    this.instanceLogList()
  }

  instanceLogList() {
    const searchField = {
      startDate : this.searchForm.controls.startDate.value,
      endDate : this.searchForm.controls.endDate.value,
      ...this.searchForm.value
    }
    this.instanceLogService.instanceLog(this.config.currentPage, this.config.itemsPerPage, JSON.stringify(searchField)).subscribe((res: any) => {
      if (res.success && res.instanceLogs.length > 0 && res.instanceLogs[0]._id) {
        this.instance = res.instanceLogs;
        this.isInstanceLogAvailable = true
        this.config.currentPage = res.totalInstanceLog.pages;
        this.config.totalItems = res.totalInstanceLog.total;
      }
      else {
        this.isInstanceLogAvailable = false
        this.instance = []
        this.config.currentPage = 1;
        this.config.totalItems = 0;
      }
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.instanceLogList()
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
