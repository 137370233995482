import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {
  constructor (private router : Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userRole=localStorage.getItem('role')
    if( userRole === 'manager' || userRole === 'salesManager'|| userRole === 'super admin'){
      return true
    }else{
      this.router.navigateByUrl('cloud-user')
      return false;
    }
  }
  
}
